import request from '@/utils/request'

// 查询列表
export function selectPage(query) {
  return request({
    url: "/purchase/plan/getPage",
    method: "get",
    params: query
  });
}

//查询所有列表
export function selectList(state) {
  return request({
    url: "/purchase/plan/getList/"+state,
    method: "get"
  });
}

// 查询明细
export function selectPlanOne(id) {
  return request({
    url: '/purchase/plan/'+id,
    method: "get"
  });
}

// 新增
export function addData(data) {
  return request({
    url: "/purchase/plan",
    method: "post",
    data: data
  });
}

// 修改
export function updateData(data) {
  return request({
    url: "/purchase/plan",
    method: "put",
    data: data
  });
}

// 删除
export function delData(id) {
  return request({
    url: "/purchase/plan/"+id,
    method: "delete"
  });
}

// 提交
export function submitData(id) {
  return request({
    url: "/purchase/plan/"+id,
    method: "put"
  });
}
