<template>
    <div class="app-container" v-if="showEcharts">
        <div style="width: 100%;height: 500px;" ref="echartsRef1"></div>
        <div style="width: 100%;height: 300px;padding-top: 10px" ref="echartsRef2"></div>
        <div style="width: 100%;height: 500px;padding-top: 30px" ref="echartsRef3"></div>
    </div>
</template>
<script>
    import {getEchart1,getEchart2,getEchart3} from '@/echarts/report/inventory/outstockCategory'
    import {countByCategorytime,sevenDaysExpendQuantity} from '@/api/report/inReport/outstockTendency/outstockTendency'

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    this.showEcharts = this.isShow
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart1) {
                                this.dataEchart1.resize()
                            }
                            if (this.dataEchart2) {
                                this.dataEchart2.resize()
                            }
                            if (this.dataEchart3) {
                                this.dataEchart3.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                showEcharts: false,
                dataEchart1: null,
                dataEchart2: null,
                dataEchart3: null,
                // 查询参数
                queryParams: {
                }
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                let result1 = await countByCategorytime(this.queryParams)
                let result2 = await sevenDaysExpendQuantity(this.queryParams)

                let res = result1.data.outstock.map(item => {
                    let top = {}
                    top.value = item.count
                    top.name = item.category
                    return top
                })
                let strList = result1.data.outstock.map(item => item.category)
                let numList = result1.data.outstock.map(item => item.count)
                this.dataEchart1 = this.$echarts.init(this.$refs.echartsRef1)
                this.dataEchart1.setOption(getEchart1(res))
                this.dataEchart2 = this.$echarts.init(this.$refs.echartsRef2)
                this.dataEchart2.setOption(getEchart2(strList, numList))

                let time = result2.data.assemblyExpend.map(item => item.date)

                let assemblyExpend = result2.data.assemblyExpend.map(
                    item => item.categoryCount
                )
                let directExpend = result2.data.directExpend.map(item => item.categoryCount)
                let disassemblyExpend = result2.data.disassemblyExpend.map(
                    item => item.categoryCount
                )
                let purReturnExpend = result2.data.purReturnExpend.map(
                    item => item.categoryCount
                )
                let receiveExpend = result2.data.receiveExpend.map(item => item.categoryCount)
                let replenishmentExpend = result2.data.replenishmentExpend.map(
                    item => item.categoryCount
                )
                let saleExpend = result2.data.saleExpend.map(item => item.categoryCount)
                let subcontractExpend = result2.data.subcontractExpend.map(
                    item => item.categoryCount
                )
                let transferExpend = result2.data.transferExpend.map(
                    item => item.categoryCount
                )
                this.dataEchart3 = this.$echarts.init(this.$refs.echartsRef3)
                this.dataEchart3.setOption(getEchart3(time,assemblyExpend,directExpend,disassemblyExpend,purReturnExpend,receiveExpend,replenishmentExpend,saleExpend,subcontractExpend,transferExpend))
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart1)
                if (this.dataEchart1) {
                    this.dataEchart1.clear();
                    this.dataEchart1.dispose();
                    this.dataEchart1 = null;
                }
                window.removeEventListener('resize', this.dataEchart2)
                if (this.dataEchart2) {
                    this.dataEchart2.clear();
                    this.dataEchart2.dispose();
                    this.dataEchart2 = null;
                }
                window.removeEventListener('resize', this.dataEchart3)
                if (this.dataEchart3) {
                    this.dataEchart3.clear();
                    this.dataEchart3.dispose();
                    this.dataEchart3 = null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
