import request from '@/utils/request'

// 查询列表
export function selectPageTransfer(query) {
  return request({
    url: "/cw/transfer/getPage",
    method: "get",
    params: query
  });
}

// 删除
export function delDataTransfer(id) {
  return request({
    url: "/cw/transfer/"+id,
    method: "delete"
  });
}

// 根据id查询
export function selectOne(id) {
  return request({
    url: "/cw/transfer/"+id,
    method: "get"
  });
}

// 新增
export function addData(data) {
  return request({
    url: "/cw/transfer",
    method: "post",
    data: data
  });
}

// 修改
export function updateData(data) {
  return request({
    url: "/cw/transfer",
    method: "put",
    data: data
  });
}

//查询所有账户
export function findAllAccount() {
  return request({
    url: "/cw/account/getList",
    method: "get"
  });
}
