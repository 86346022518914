<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'竞争对手'" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-icon name="plus" class="mrg-r-20" @click="handleAdd"/>
                </template>
                <!--<template v-slot:icon2>
                    <i class="iconfont icon-gengduo" style="font-size: 20px"></i>
                </template>-->
            </navBar>

            <div class="common-wrap">
                <van-search
                        show-action
                        v-model="queryParams.condition"
                        placeholder="请输入搜索关键词"
                        @search="handleQuery"
                >
                    <template #action>
                        <div class="search-btn-label" @click="handleQuery">搜索</div>
                    </template>
                </van-search>
            </div>

            <div class="main">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                    >
                        <div class="list" v-for="item in dataList" :key="item.id">
                            <div class="line"></div>
                            <div class="item-tab" @click="handleGoDetail(item.id)">
                                <div class="title-wrap flex-spa-ver-cen">
                                    <div class="tit over_hidden">
                                        {{ item.name || '--' }}
                                    </div>
                                    <div class="level-btn">
                                        {{ item.levelName || '--' }}
                                    </div>
                                </div>
                                <div class="con">
                                    <div class="desc-wrap flex">
                                        <div class="label">法人代表：</div>
                                        <div class="value red">{{ item.legalPerson || '--' }}</div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">企业性质：</div>
                                        <div class="value">{{ item.natureName || '--' }}</div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">注册资本：</div>
                                        <div class="value">{{ item.capital || '--' }}</div>
                                    </div>
                                </div>
                                <div class="time-wrap flex-spa-ver-cen" @click.stop="operateAction(item.id)">
                                    <div class="time">办公电话：{{ item.phone || '--' }}</div>
                                    <van-icon name="weapp-nav"/>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
        <van-action-sheet
                v-model="actionShow"
                :actions="actions"
                @select="onSelect"
                cancel-text="取消"
                close-on-click-action
        />
    </div>
</template>

<script>
    import {delList, selectPage} from '@/api/crm/competitor';

    export default {
        components: {},

        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                queryParams: {
                    pageNum: 1,
                    pageSize: 5,
                    condition: null,
                },
                dataList: [],
                //操作的动作面板
                actionShow: false,
                //操作面板的操作
                actions: [
                    {name: '编辑', className: 'customer-opea-action'},
                    {name: '删除', className: 'customer-opea-action'}
                ],
                itemId: null,
            };
        },

        mounted() {
            this.getList();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/crm/workbench');
                this.$router.go(-1)
            },

            async getList() {
                this.loading = true;
                const result = await selectPage(this.queryParams);
                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.dataList = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.dataList = records;
                } else {
                    this.dataList = [...this.dataList, ...records];
                }
                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
            },

            onLoad() {
                if (this.refreshing) {
                    this.dataList = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.dataList.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            getListParams(index) {
                this.finished = false;
                this.loading = false;
                this.dataList = [];
                this.queryParams.screen = index;
                this.handleQuery();
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },

            handleAdd() {
                // 新增线索页面
                this.$router.push({
                    path: '/crm/compete/add',
                });
            },

            handleGoDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/crm/compete/detail',
                    query: {
                        id,
                    },
                });
            },

            //点击操作展示动作面板
            operateAction(id) {
                this.itemId = id;
                this.actionShow = true;
            },

            //操作的动作面板被点击
            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                switch (item.name) {
                    case '编辑':
                        this.handleEdit();
                        break;
                    case '删除':
                        this.handleDelete();
                        break;
                }
            },

            // 编辑
            handleEdit() {
                const obj = this.dataList.find((ele) => ele.id == this.itemId);

                // 新增页面
                this.$router.push({
                    path: '/crm/compete/add',
                    query: {
                        obj: JSON.stringify(obj),
                    },
                });
            },

            // 删除
            handleDelete() {
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认此条信息?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await delList(this.itemId);
                        this.$toast.success('删除成功');
                        this.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

  .search {
    padding-top: 40px;
    background: #fff;
  }

  .my-tabs {
    padding-top: 30px;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 0;
        flex: 1;
      }

      .level-btn {
        width: 80px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;
    }
  }

  ::v-deep .van-tabs__line {
    background-color: #0091ff;
  }
</style>
