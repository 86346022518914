import * as echarts from "echarts";

export const getEchart = (strList, dataList,xname,yname) => {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '24%',
      right: '20%',
      bottom: '8%'
    },
    xAxis: {
      type: 'value',
      name: yname
    },
    yAxis: {
      type: 'category',
      data: strList,
      name: xname
    },
    series: [
      {
        data: dataList,
        type: 'bar'
      }
    ]
  };
};

export const getEchart2 = (priceList) => {
  return {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'horizontal',
      left: 'top'
    },
    series: [
      {
        name: '物料分类',
        type: 'pie',
        radius: '50%',
        data: priceList,
        /*label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },*/
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
};

