<template>
    <div class="app-container">
        <Header :moudle="'purchase'"/>
        <div class="common-wrap">
            <div class="flex-spa-bet border-bom">
                <span class="title">采购简报</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>全部</span>
            </div>

            <div class="item-sale flex-spa-bet">
                <div class="item">
                    <div class="label">新增订单</div>
                    <div class="count">{{ countObj.orderNum }}</div>
                </div>
                <div class="item">
                    <div class="label">新增合同</div>
                    <div class="count">{{ countObj.contractNum }}</div>
                </div>
            </div>

            <div class="item-sale flex-spa-bet">
                <div class="item">
                    <div class="label">新增付款</div>
                    <div class="count">{{ countObj.paymentNum }}</div>
                </div>
                <div class="item">
                    <div class="label">新增退款</div>
                    <div class="count">{{ countObj.returnNum }}</div>
                </div>
            </div>
        </div>

        <div class="common-wrap">
            <div class="flex-spa-bet border-bom">
                <span class="title">供应商统计</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>前四条</span>
            </div>
            <div style="width: 100%;height: 250px;padding-top: 10px" ref="echartsRef1"></div>
        </div>

        <div class="common-wrap">
            <div class="flex-spa-bet border-bom">
                <span class="title">采购计划</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>--</span>
            </div>
            <div class="table-header flex-spa-bet">
                <span class="se-tr-title">计划标题</span>
                <span class="se-tr-typeName">采购类型</span>
                <span class="se-tr-purchaseName">销售人员</span>
                <span class="se-tr-purchaseDate">采购时间</span>
            </div>
            <div class="overdue">
                <div class="table-body">
                    <div class="flex-spa-ver-cen" v-for="(item, index) in queryList" :key="index">
                        <div class="flex-ver-cen" style="width: 100%;margin-top: 5px">
                            <span class="se-tr-title">{{ item.title }}</span>
                            <span class="se-tr-typeName">{{ item.typeName }}</span>
                            <span class="se-tr-purchaseName">{{ item.purchaseName }}</span>
                            <span class="se-tr-purchaseDate">{{ item.purchaseDate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '@/components/header';
    import {getAdd, getNum, getPlan} from "@/api/purchase/workbench";
    import {getEchart} from "@/echarts/report/purchase/purWork";

    export default {
        components: {
            Header,
        },

        data() {
            return {
                query: { timeState: '', timeEnd: '' },
                countObj: {},
                dataEchart1: null,
                queryList: [],
            };
        },

        mounted() {
            //获取数量
            this.getCount()

            //供应商统计
            this.supplierStatistic()

            //查询采购计划
            this.findPlanList()

            window.addEventListener('resize', () => {
                if (this.dataEchart1) {
                    this.dataEchart1.resize()
                }
            })
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            //获取数量
            async getCount(){
                const { data } = await getAdd(this.query)
                this.countObj = data
            },

            //供应商统计
            async supplierStatistic(){
                const { data } = await getNum(this.query)
                const strList = []
                const dataList = []
                data.forEach(item=>{
                    strList.push({
                        'value': item.supplierName
                    })
                    dataList.push({
                        'value': item.num
                    })
                })
                this.dataEchart1 = await this.$echarts.init(this.$refs.echartsRef1)
                const echartOption = getEchart(strList, dataList,'数量','供应商')
                this.dataEchart1.setOption(echartOption)
            },

            //查询采购计划
            async findPlanList(){
                const { data } = await getPlan(this.query)
                this.queryList = data
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart1)
                if (this.dataEchart1) {
                    this.dataEchart1.clear();
                    this.dataEchart1.dispose();
                    this.dataEchart1 = null;
                }
            }
        },
    };
</script>
<style lang="scss" scoped>
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
  }

  .more {
    font-size: 22px;
    font-weight: 400;
    color: #b5b5b5;
  }

  .common-wrap {
    padding: 20px;
    margin-top: 20px;

    .left-tit {
      font-size: 28px;
      color: #444444;

      .tit {
        width: 460px;
      }
    }

    .day {
      font-size: 22px;
      color: #e02020;
    }

    .time {
      font-size: 22px;

      color: #999999;
    }

    .item-sale {
      .item {
        padding: 28px 50px 36px;
        flex: 1;

        .label {
          font-size: 24px;
          color: #aaaaaa;
        }

        .count {
          font-size: 32px;
          font-weight: 800;
          color: #333333;
          margin-top: 10px;
        }
      }
    }
  }

  .table-header {
    padding: 35px 0px 5px 10px;
    width: 100%;
  }

  .table-body {
    padding: 10px 0px 5px 10px;
    width: 100%;
  }

  .overdue {
    display: flex;
    flex: 1;
    overflow: scroll;
    height: 400px;
    margin-top: 10px;
  }

  .se-tr-title {
    color: #aaaaaa;
    font-size: 28px;
    width: 35%;
  }

  .se-tr-typeName {
    color: #aaaaaa;
    font-size: 28px;
    width: 22%;
  }

  .se-tr-purchaseName {
    color: #aaaaaa;
    font-size: 28px;
    width: 20%;
  }

  .se-tr-purchaseDate {
    color: #aaaaaa;
    font-size: 28px;
    width: 22%;
  }
</style>
