import request from '@/utils/request'

// 权限的列表
export function findPage(query) {
  return request({
    url: `/system/approveFlow/findPage`,
    method: 'GET',
    params: query
  })
}

// 根据流程id查询单条
export function findOne(flowId) {
  return request({
    url: `/system/approveFlow/findOne/`+flowId,
    method: 'GET'
  })
}

// 查询所有流程类型
export function findAllType() {
  return request({
    url: `/system/approve/findTypeList`,
    method: 'GET'
  })
}

// 新增流程
export function addApproveFlow(data) {
  return request({
    url: `/system/approveFlow`,
    method: "POST",
    data
  });
}

// 修改流程
export function updateApproveFlow(data) {
  return request({
    url: `/system/approveFlow`,
    method: "PUT",
    data
  });
}

// 根据id删除流程
export function deleteFlow(flowId) {
  return request({
    url: `/system/approveFlow/`+flowId,
    method: 'DELETE'
  })
}

// 根据用户id和模块名查询流程
export function findFlowByUserId(params) {
  return request({
    url: `/system/approveFlow/findFlowByUserId`,
    method: 'get',
    params
  })
}
