<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <div class="line"></div>
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="客户名称"
                            name="pattern"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入客户名称' }]"
                    />
                    <van-field
                            readonly
                            clickable
                            name="customerSource"
                            v-model="formInline.customerSourceName"
                            label="客户来源"
                            placeholder="请选择"
                            @click="showSourcePicker = true"
                            :required="fieldObj.客户来源"
                            :rules="[{ required: fieldObj.客户来源, message: '请选择客户来源' }]"
                    />
                    <van-popup v-model="showSourcePicker" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="customerSourceOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm(
                  $event,
                  'customerSource',
                  'customerSourceName',
                  'dictLabel',
                  'showSourcePicker'
                )
              "
                                @cancel="showSourcePicker = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            name="industry"
                            v-model="formInline.industryName"
                            label="客户行业"
                            placeholder="请选择"
                            @click="showIndustryPicker = true"
                            :required="fieldObj.客户行业"
                            :rules="[{ required: fieldObj.客户行业, message: '请选择客户行业' }]"
                    />
                    <van-popup v-model="showIndustryPicker" position="bottom">
                        <van-picker
                                show-toolbar
                                value-key="dictLabel"
                                :columns="industryOptions"
                                @confirm="
                onConfirm($event, 'industry', 'industryName', 'dictLabel', 'showIndustryPicker')
              "
                                @cancel="showIndustryPicker = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            name="level"
                            v-model="formInline.levelName"
                            label="客户级别"
                            placeholder="请选择"
                            @click="showLevelPicker = true"
                            :required="fieldObj.客户级别"
                            :rules="[{ required: fieldObj.客户级别, message: '请选择客户级别' }]"
                    />
                    <van-popup v-model="showLevelPicker" position="bottom">
                        <van-picker
                                show-toolbar
                                value-key="dictLabel"
                                :columns="levelOptions"
                                @confirm="onConfirm($event, 'level', 'levelName', 'dictLabel', 'showLevelPicker')"
                                @cancel="showLevelPicker = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.phone"
                            label="手机" name="phone"
                            :required="fieldObj.手机"
                            :rules="[{ required: fieldObj.手机, message: '请输入手机' }]"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.weChat"
                            label="微信" name="weChat"
                            :required="fieldObj.微信"
                            :rules="[{ required: fieldObj.微信, message: '请输入微信' }]"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.qq"
                            label="QQ"
                            name="qq"
                            :required="fieldObj.QQ"
                            :rules="[{ required: fieldObj.QQ, message: '请输入QQ' }]"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.contactWay"
                            label="联系方式"
                            name="contactWay"
                            :required="fieldObj.联系方式"
                            :rules="[{ required: fieldObj.联系方式, message: '请输入联系方式' }]"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.contactsName"
                            label="联系人"
                            name="contactsName"
                            :required="fieldObj.联系人"
                            :rules="[{ required: fieldObj.联系人, message: '请输入联系人' }]"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.website"
                            label="网址"
                            name="website"
                            :required="fieldObj.网址"
                            :rules="[{ required: fieldObj.网址, message: '请输入网址' }]"
                            placeholder="请输入"
                    />

                    <van-field
                            readonly
                            clickable
                            name="address"
                            v-model="formInline.addressName"
                            label="地区选择"
                            placeholder="点击选择省市区"
                            @click="showArea = true"
                            :required="fieldObj.所在地区"
                            :rules="[{ required: fieldObj.所在地区, message: '请选择所在地区' }]"
                    />
                    <van-popup v-model="showArea" position="bottom">
                        <van-area
                                :value="formInline.address"
                                :area-list="areaList"
                                @confirm="onAddressConfirm"
                                @cancel="showArea = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.addressDetail"
                            label="详细地址"
                            name="addressDetail"
                            placeholder="请输入"
                            :required="fieldObj.详细地址"
                            :rules="[{ required: fieldObj.详细地址, message: '请输入详细地址' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            name="calendar"
                            :value="formInline.nextContactTime"
                            label="下次联系时间"
                            placeholder="点击选择日期"
                            @click="showCalendar = true"
                            :required="fieldObj.下次联系时间"
                            :rules="[{ required: fieldObj.下次联系时间, message: '请选择下次联系时间' }]"
                    />
                    <div class="line"></div>

                    <van-field
                            v-model="formInline.nextContactContent"
                            label="下次联系内容"
                            name="nextContactContent"
                            placeholder="请输入"
                            :required="fieldObj.下次联系内容"
                            :rules="[{ required: fieldObj.下次联系内容, message: '请输入下次联系内容' }]"
                    />

                    <van-field
                            v-model="formInline.remark"
                            rows="1"
                            autosize
                            label="备注"
                            placeholder="请输入"
                            type="text"
                            :required="fieldObj.备注"
                            :rules="[{ required: fieldObj.备注, message: '请输入备注' }]"
                    />

                    <van-field
                            v-for="(item, index) in formInline.fieldList" :key="index"
                            v-model="item.fieldValue"
                            :label="item.fieldName"
                            placeholder="请输入"
                            :required="item.required"
                            :rules="[{ required: fieldObj.required, message: '请输入'+item.fieldName }]"
                    />

                    <van-field name="uploader" label="附件">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead" :max-count="1"/>
                        </template>
                    </van-field>
                    <van-button class="btn" block type="info" native-type="submit">提交</van-button>
                </van-form>
            </div>
        </div>

        <van-popup v-model="showCalendar" style="width: 70%">
            <van-datetime-picker
                    v-model="currentDate"
                    type="datetime"
                    title="请选择跟进时间"
                    @confirm="onConfirmTime"
                    @cancel="showCalendar = false"
            />
        </van-popup>
    </div>
</template>
<script>
    import {areaList} from '@vant/area-data';
    import {addCustomer, editCustomer} from '@/api/crm/customer';
    import {changeToCurrentDay} from '@/utils/ruoyi';
    import {submiteUpload} from "@/api/system/config";
    import {findField} from "@/api/system/sysfield";

    export default {
        data() {
            return {
                currentDate: new Date(),
                formInline: {
                    name: '',
                    address: null,
                    addressDetail: null,
                    customerSourceName: null,
                    contactWay: null,
                    industryName: null,
                    levelName: null,
                    phone: null,
                    nextContactTime: null,
                    nextContactContent: null,
                    website: null,
                    qq: null,
                    weChat: null,
                    contactsName: null,
                    time: null,
                    remark: null,
                },
                pattern: /\d{6}/,
                areaList,
                showArea: false,
                showCalendar: false,
                showSourcePicker: false,
                showLevelPicker: false,
                showIndustryPicker: false,
                customerSourceOptions: [],
                levelOptions: [],
                industryOptions: [],
                title: '新增客户',
                //名片
                uploader: [],
                uploadParams: {
                    businessType: 'customer_card',
                    businessId: undefined,
                    file: null,
                },
                fieldObj: {},
            };
        },

        mounted() {
            //查询字段
            this.findFieldList()

            // 客户来源
            this.getDicts('clue_source').then((response) => {
                this.customerSourceOptions = response.data;
            });

            // 客户级别
            this.getDicts('customer_level').then((response) => {
                this.levelOptions = response.data;
            });

            // 客户行业
            this.getDicts('industry').then((response) => {
                this.industryOptions = response.data;
            });

            //回显
            this.findDetail();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/crm/customer');
                this.$router.go(-1)
            },

            //查询字段
            async findFieldList() {
                let result = await findField('crm_customer')
                let fieldList = JSON.parse(result.data)

                if (fieldList.length == 0) {
                    fieldList = [
                        {fieldName: '客户来源', required: false, isFixed: true},
                        {fieldName: '客户行业', required: false, isFixed: true},
                        {fieldName: '客户级别', required: false, isFixed: true},
                        {fieldName: '手机', required: false, isFixed: true},
                        {fieldName: '微信', required: false, isFixed: true},
                        {fieldName: 'QQ', required: false, isFixed: true},
                        {fieldName: '联系方式', required: false, isFixed: true},
                        {fieldName: '联系人', required: false, isFixed: true},
                        {fieldName: '网址', required: false, isFixed: true},
                        {fieldName: '所在地区', required: false, isFixed: true},
                        {fieldName: '详细地址', required: false, isFixed: true},
                        {fieldName: '下次联系时间', required: false, isFixed: true},
                        {fieldName: '下次联系内容', required: false, isFixed: true},
                        {fieldName: '备注', required: false, isFixed: true}
                    ]
                }

                let definedfieldList = []
                fieldList.forEach(item => {
                    if (item.isFixed) {
                        this.fieldObj[item.fieldName] = item.required
                    } else if (!this.formInline.id) {
                        definedfieldList.push(item)
                    }
                })

                if (this.formInline.id) {
                    definedfieldList = JSON.parse(this.formInline.fields)
                }
                this.$set(this.formInline, 'fieldList', definedfieldList)
                this.$forceUpdate()
            },

            //回显
            findDetail() {
                const itemDetail = this.$route.query.obj ? JSON.parse(this.$route.query.obj) : '';
                if (itemDetail) {
                    this.formInline = itemDetail;
                    this.formInline.addressName = itemDetail.address;
                    this.formInline.address = itemDetail.address;
                    this.formInline.customerSourceName = itemDetail.customerSourceStr;
                    this.formInline.customerSource = itemDetail.customerSource;
                    this.formInline.level = itemDetail.level;
                    this.formInline.levelName = itemDetail.levelStr;
                    this.formInline.industryName = itemDetail.industryStr;
                    this.formInline.industry = itemDetail.industry;
                    this.formInline.time = itemDetail.nextContactTime;
                    this.formInline.nextContactTime = itemDetail.nextContactTime;

                    delete this.formInline.leaderId;

                    this.title = '编辑客户';
                } else {
                    this.formInline = {};
                    this.title = '新增客户';
                }
            },

            onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            onAddressConfirm(value) {
                this.formInline.addressName = `${value[0].name},${value[1].name},${value[2].name}`;
                this.formInline.address = `${value[0].name},${value[1].name},${value[2].name}`;

                this.showArea = false;
            },

            //确认时间
            onConfirmTime(value) {
                const time = changeToCurrentDay(value);
                this.formInline.nextContactTime = time;
                this.showCalendar = false;
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },

            async onSubmit() {
                this.formInline.fields = JSON.stringify(this.formInline.fieldList)
                delete this.formInline.fieldList
                let formData = this.objectToFormData(this.formInline)
                const result = this.formInline.id
                    ? await editCustomer(formData)
                    : await addCustomer(formData);
                if (result.code == 200) {
                    this.$toast(result.message);
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },
            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
