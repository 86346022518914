<template>
    <div class="app-container">
        <navBar :title="'客户详情'" @handleGoBack="handleGoBack">
            <template v-slot:icon1>
                <van-icon
                        name="star-o"
                        class="mrg-r-20"
                        @click="handleStar"
                        :style="{ color: collectColor[detailObj.isCollection] }"
                />
            </template>
            <!--<template v-slot:icon2>
              <i class="iconfont icon-gengduo" style="font-size: 20px"></i>
            </template>-->
        </navBar>

        <div class="item-tab">
            <div class="title-wrap">
                <div class="flex-spa-ver-cen">
                    <div class="tit over_hidden">{{ detailObj.name || '--' }}</div>
                    <div class="level-btn">{{ detailObj.levelStr || '--' }}</div>
                </div>
                <div class="time">创建时间：{{ detailObj.createTime || '--' }}</div>
            </div>
            <div class="con">
                <div class="desc-wrap flex">
                    <div class="label">线索来源：</div>
                    <div class="value">{{ detailObj.customerSourceStr || '--' }}</div>
                </div>
                <div class="desc-wrap flex">
                    <div class="label">客户行业：</div>
                    <div class="value">{{ detailObj.industryStr || '--' }}</div>
                </div>
                <div class="desc-wrap flex">
                    <div class="label">联系方式：</div>
                    <div class="value">{{ detailObj.phone || '--' }}</div>
                </div>
            </div>
        </div>

        <van-tabs v-model="activeName">
            <van-tab title="详细资料" name="a">
                <baseInfoVue/>
            </van-tab>
            <van-tab title="跟进记录" name="b">
                <ItemFollowList :pages="'customer'"/>
            </van-tab>
            <van-tab title="联系人" name="e" v-if="this.$auth.hasPermi('crm:contacts:list')">
                <contactVue :pages="'customer'"/>
            </van-tab>
            <van-tab title="团队成员" name="team" v-if="this.$auth.hasPermi('crm:customer:listRelatedTeam')">
                <TeamVue :pages="'team'"/>
            </van-tab>
            <van-tab title="商机" name="cost" v-if="this.$auth.hasPermi('crm:cost:list')">
                <Cost/>
            </van-tab>
            <van-tab title="订单" name="order" v-if="this.$auth.hasPermi('crm:order:list')">
                <Order/>
            </van-tab>

            <van-tab title="相关附件" name="c">
                <AppendixVue :businessType="'customer'"/>
            </van-tab>
            <van-tab title="操作记录" name="d">
                <RecordVue :businessType="'crm_customer'"/>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import baseInfoVue from './components/baseInfo.vue';
    import ItemFollowList from '@/views/components/crm/ItemFollowList.vue';
    import RecordVue from '@/views/components/crm/Record.vue';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import contactVue from '@/views/components/crm/contact.vue';
    import TeamVue from '@/views/components/crm/Team.vue';
    import Cost from '@/views/components/crm/Cost.vue';
    import Order from '@/views/components/crm/Order.vue';
    import {getDetailByCustomerId} from '@/api/crm/customer';
    import {fetchCancelFocusOn, fetchFocusOn} from '@/api/crm/common';
    import {COLLECTION_STATUS} from '@/views/constant';

    export default {
        components: {
            baseInfoVue,
            ItemFollowList,
            RecordVue,
            AppendixVue,
            contactVue,
            TeamVue,
            Cost,
            Order,
        },

        data() {
            return {
                activeName: 'a',
                customerId: this.$route.query.id,
                detailObj: {},
                collectColor: COLLECTION_STATUS,
            };
        },

        mounted() {
            this.getDetail();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/crm/customer');
                this.$router.go(-1)
            },

            async getDetail() {
                const result = await getDetailByCustomerId({customerId: this.customerId});
                const {data = {}} = result;
                this.detailObj = data;
                this.$store.commit('customer/SET_CUSTOMER_DETAIL', JSON.stringify(data));
            },

            async handleStar() {
                const params = {
                    businessId: this.$route.query.id,
                    businessType: 1, //	业务类型 0：线索，1：客户，2：联系人3，商机
                };

                // 状态是0和2，点五角星按钮调关注的接口
                // 状态是1和3，点五角星按钮调取消关注的接口
                if (this.detailObj.isCollection == 1 || this.detailObj.isCollection == 3) {
                    await fetchCancelFocusOn(params);
                    this.$toast('取消关注');
                } else {
                    await fetchFocusOn(params);
                    this.$toast('关注成功');
                }
                await this.getDetail();
            },
        },
    };
</script>

<style lang="scss" scoped>
  .item-tab {
    background: #fff;
    padding: 0 33px;
    margin-top: 20px;

    .title-wrap {
      padding: 26px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 0;
        flex: 1;
      }

      .level-btn {
        width: 80px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }

      .time {
        font-size: 24px;
        margin-top: 10px;
        color: #999999;
      }
    }

    .con {
      padding: 30px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 30px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }
  }

  ::v-deep .van-tabs--line .van-tabs__wrap {
    height: 80px !important;
  }

  ::v-deep .van-tab {
    // padding: 40px 0;
    height: 80px !important;
    line-height: 80px !important;
    padding-bottom: 0 !important;
  }
</style>
