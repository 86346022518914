<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <!--<van-field
                            v-model="formInline.title"
                            label="汇报标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入汇报标题' }]"
                    />-->

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.typeName"
                            label="汇报类别"
                            placeholder="请选择"
                            @click="showTypePicker = true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择汇报类别' }]"
                    />
                    <van-popup v-model="showTypePicker" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="typeOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'type', 'typeName', 'dictLabel', 'showTypePicker')
              "
                                @cancel="showTypePicker = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.time"
                            label="日期"
                            placeholder="点击选择日期"
                            @click="showPickerDay = true"
                            v-if="this.formInline.type == 1"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerDay" @confirm="onConfirmDay"/>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.time"
                            label="周"
                            placeholder="请选择"
                            @click="showPickerWeek = true"
                            v-if="false && this.formInline.type == 0"
                    />
                    <van-popup v-model="showPickerWeek" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="weekOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'time', 'time', 'dictLabel', 'showPickerWeek')
              "
                                @cancel="showPickerWeek = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.time"
                            label="月份"
                            placeholder="点击选择月份"
                            @click="showPickerMonth = true"
                            v-if="this.formInline.type == 4"
                    />
                    <van-popup v-model="showPickerMonth" style="width: 70%">
                        <van-datetime-picker
                                v-model="formInline.timeMonth"
                                type="year-month"
                                title="选择年月"
                                :min-date="minDate"
                                @confirm="onConfirmMonth"
                                @cancel="showPickerMonth = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.time"
                            label="季度"
                            placeholder="请选择"
                            @click="showPickerSeason = true"
                            v-if="this.formInline.type == 3"
                    />
                    <van-popup v-model="showPickerSeason" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="seasonOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'time', 'time', 'dictLabel', 'showPickerSeason')
              "
                                @cancel="showPickerSeason = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.time"
                            label="年度"
                            placeholder="请选择"
                            @click="showPickerYear = true"
                            v-if="this.formInline.type == 2"
                    />
                    <van-popup v-model="showPickerYear" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="yearOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'time', 'time', 'dictLabel', 'showPickerYear')
              "
                                @cancel="showPickerYear = false"
                        />
                    </van-popup>

                    <textAreaInput :remark="formInline.content" @change="handleContentChange"/>

                    <van-button class="btn" block type="info" native-type="submit">提交</van-button>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
    import {addreport} from '@/api/oa/word';
    import {formatDate} from "@/utils/ruoyi";

    export default {
        data() {
            return {
                formInline: {},
                showTypePicker: false,
                showPickerDay: false,
                showPickerWeek: false,
                showPickerMonth: false,
                showPickerSeason: false,
                showPickerYear: false,
                typeOptions: [
                    {dictValue: 1, dictLabel: '日报'},
                    {dictValue: 0, dictLabel: '周报'},
                    {dictValue: 4, dictLabel: '月报'},
                    {dictValue: 3, dictLabel: '季报'},
                    {dictValue: 2, dictLabel: '年报'}
                ],
                weekOptions: [
                    {dictValue: '第一周', dictLabel: '第一周'},
                    {dictValue: '第二周', dictLabel: '第二周'},
                    {dictValue: '第三周', dictLabel: '第三周'},
                    {dictValue: '第四周', dictLabel: '第四周'},
                    {dictValue: '第五周', dictLabel: '第五周'}
                ],
                seasonOptions: [
                    {dictValue: '第一季', dictLabel: '第一季'},
                    {dictValue: '第二季', dictLabel: '第二季'},
                    {dictValue: '第三季', dictLabel: '第三季'},
                    {dictValue: '第四季', dictLabel: '第四季'}
                ],
                yearOptions: [
                    {dictValue: '2023', dictLabel: '2023'},
                    {dictValue: '2024', dictLabel: '2024'},
                    {dictValue: '2025', dictLabel: '2025'},
                    {dictValue: '2026', dictLabel: '2026'},
                    {dictValue: '2027', dictLabel: '2027'},
                    {dictValue: '2028', dictLabel: '2028'},
                    {dictValue: '2029', dictLabel: '2029'},
                    {dictValue: '2030', dictLabel: '2030'}
                ],
                title: '新建汇报',
                minDate: new Date(2010, 0, 1),
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/oa/work');
                this.$router.go(-1)
            },

            //回显
            findDetail() {
                const itemDetail = this.$route.query.obj ? JSON.parse(this.$route.query.obj) : '';
                if (itemDetail) {
                    this.formInline = itemDetail;
                    this.title = '编辑汇报';
                } else {
                    this.formInline = {type: 0,typeName: '周报'};
                    this.title = '新建汇报';
                }
            },

            //内容
            handleContentChange(val) {
                this.formInline.content = val;
            },

            //类别确认
            onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;

                if (key == 'type') {
                    this.formInline.time = ''
                }
            },

            //日期确认
            onConfirmDay(value) {
                this.formInline.time = formatDate(value, 'yyyy-MM-dd');
                this.showPickerDay = false;
            },

            //月份确认
            onConfirmMonth(value) {
                this.formInline.time = formatDate(value, 'yyyy-MM');
                this.showPickerMonth = false;
            },


            async onSubmit() {
                // const data = this.objectToFormData(params);
                const data = JSON.stringify(this.formInline)
                let result = await addreport(data);
                if (result.code == 200) {
                    this.$toast.success('添加成功');
                    this.handleGoBack()
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
