<template>
    <div class="main">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    offset="100"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <div class="list" v-for="(item,index) in list" :key="index">
                    <div class="item-tab" @click="handleGoDetail(item.id)">
                        <div class="title-wrap flex-spa-ver-cen">
                            <div class="tit over_hidden">{{ item.approveTitle }}</div>
                            <div class="level-btn">{{ item.applyTime }}</div>
                        </div>
                        <div class="con">
                            <div class="desc-wrap flex">
                                <div class="label">申请类型：</div>
                                <div class="value">{{ item.typeStr }}</div>
                            </div>
                            <div class="desc-wrap flex">
                                <div class="label">申请时间：</div>
                                <div class="value">{{ item.createTime }}</div>
                            </div>
                        </div>
                        <div class="time-wrap flex-spa-ver-cen">
                            <div class="flex-ver-cen">
                                <img :src="item.avatar" alt="" class="img"/>
                                <div class="time">由 {{ item.applyStr }} 提交</div>
                            </div>
                            <p v-if="item.approveState == 0">{{ item.approveStateStr }}</p>
                            <p v-if="item.approveState == 1" style="color: green; font-weight: bold">
                                {{ item.approveStateStr }}</p>
                            <p v-if="item.approveState == 2" style="color: red; font-weight: bold">
                                {{ item.approveStateStr }}</p>
                        </div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import {findFinishPage, findMyApplyPage, findWaitPage} from "@/api/approve/approve";
    import {parseTime} from "@/utils/ruoyi";

    export default {
        props: {
            type: String,
            approveState: Number,
            showFilter: Boolean,
            approveTitle: String,
            typeId: String
        },

        watch: {
            approveTitle: {
                handler() {
                    this.queryParams.approveTitle = this.approveTitle
                }
            },
            approveState: {
                handler() {
                    this.queryParams.approveState = this.approveState
                }
            },
            typeId: {
                handler() {
                    this.queryParams.typeId = this.typeId
                }
            },
            showFilter: {
                handler() {
                    if (!this.showFilter) {
                        this.queryParams.pageNum = 1
                        this.getList()
                    }
                }
            }
        },

        data() {
            return {
                refreshing: false, // 下拉
                finished: false,
                loading: false,
                queryParams: {
                    pageNum: 1,
                    pageSize: 5
                },
                list: []
            };
        },

        methods: {
            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.list.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            async getList() {
                this.loading = true;
                let result = {}
                if (this.type == 'myApply') {
                    result = await findMyApplyPage(this.queryParams);
                } else if (this.type == 'wait') {
                    result = await findWaitPage(this.queryParams);
                } else if (this.type == 'finish') {
                    result = await findFinishPage(this.queryParams);
                }

                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.list = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.list = records;
                } else {
                    this.list = [...this.list, ...records];
                }

                //转换时间
                this.list.forEach(item => {
                    item.applyTime = parseTime(new Date(item.createTime), '{h}:{i}:{s}')
                })

                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
                this.$forceUpdate()
            },

            handleGoDetail(id) {
                this.$router.push({
                    path: '/myApply/applyDetail',
                    query: {
                        id,
                    },
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
  .item-tab {
    padding: 20px 33px;
    margin: 16px auto;
    width: 720px;
    height: 300px;
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 0;
        line-height: 38px;
        flex: 1;
      }

      .level-btn {
        width: 80px;
        height: 40px;
        // background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #999;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      padding: 20px 0;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;

      .img {
        width: 52px;
        height: 52px;
        border-radius: 100%;
        margin-right: 10px;
      }

      p {
        color: #f79c00;
      }
    }
  }
</style>
