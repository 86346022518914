<template>
  <van-popup
    v-model="showTransferPop"
    style="width: 300px; padding: 20px 10px; height: 300px; overflow: auto"
    ><van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell
          :title="`单选框 ${item}`"
          v-for="item in 20"
          :key="item"
          clickable
          @click="radio = '1'"
        >
          <template #right-icon>
            <van-radio :name="item" />
          </template>
        </van-cell>
      </van-cell-group> </van-radio-group
  ></van-popup>
</template>

<script>
export default {
  props: {
    transferPop: Boolean,
  },

  watch: {
    transferPop: {
      handler(val) {
        this.showTransferPop = val;
      },
      immediate: true,
      deep: true,
    },
  },

  data() {
    return {
      showTransferPop: false,
      radio: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-cell {
  height: 60px;
  line-height: 60px;
  font-size: 28px;
  padding: 0 30px;
}
::v-deep .van-cell__title,
::v-deep .van-radio {
  height: 60px;
  line-height: 60px;
  font-size: 28px;
}
</style>
