<template>
    <div class="app-container">
        <div class="wrap">
            <navBar title="发票详情" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first>
                    <div class="title">基本信息</div>
                    <van-field
                            v-model="formInline.number"
                            label="发票编号"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.customer"
                            label="客户名称"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.price"
                            label="开票金额"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.invoiceDate"
                            label="开票日期"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.typeStr"
                            label="开票类型"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.remark"
                            label="备注"
                            placeholder="请输入"
                    />

                    <div class="title">抬头信息</div>
                    <van-field
                            v-model="formInline.crmInvoiceHead.typeStr"
                            label="抬头类型"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.crmInvoiceHead.name"
                            label="开票抬头"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.crmInvoiceHead.number"
                            label="纳税人识别号"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.crmInvoiceHead.bank"
                            label="开户行"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.crmInvoiceHead.accountAddress"
                            label="开票地址"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.crmInvoiceHead.accountName"
                            label="开户账号"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.crmInvoiceHead.phone"
                            label="电话"
                            placeholder="请输入"
                    />

                    <div class="title">系统信息</div>
                    <van-field
                            v-model="formInline.leaderId"
                            label="负责人"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.createBy"
                            label="创建人"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.createTime"
                            label="创建时间"
                            placeholder="请输入"
                    />

                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
    import {getAllInvoiceHeadInfo} from '@/api/cw/crmInvoiceHead/crmInvoiceHead';

    export default {
        data() {
            return {
                formInline: {
                    crmInvoiceHead: {}
                },
                id: this.$route.query.id,
                type: this.$route.query.type,
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                let itemDetail = await getAllInvoiceHeadInfo({invoiceId: this.id});
                this.formInline = itemDetail.data
            },

        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .title {
    padding: 20px 40px;
    background: #fafafa;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
