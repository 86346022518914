<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.expendTitle"
                            label="出库标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输出库标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.whName"
                            label="出库仓库"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择出库仓库' }]"
                            @click="showPickerWarehouse = true"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.warehouseTubeStr"
                            label="负责人"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择负责人' }]"
                            @click="showPop= true"
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.applyTime"
                            label="申请日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择申请日期' }]"
                            @click="showPickerApply = true"
                    />

                    <van-field
                            v-model="formInline.totalMoney"
                            label="出库总价"
                            placeholder="请输入"
                            type="number"
                            disabled
                    />

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
                            <van-icon name="delete-o" @click="handleDelete(index)"/>
                        </div>

                        <van-field
                                v-model="item.materialSpecification"
                                label="规格型号"
                                disabled
                        />

                        <van-field
                                v-model="item.materialUnit"
                                label="单位"
                                disabled
                        />

                        <van-field
                                v-model="item.bathNumber"
                                label="批次"
                                disabled
                        />

                        <van-field
                                v-model="item.materialNum"
                                label="库存数量"
                                disabled
                        />

                        <van-field
                                v-model="item.price"
                                label="出库价"
                                placeholder="请输入"
                                :required="true"
                                :rules="[{ required: true, message: '请输入出库价' }]"
                                @input="itemTotalPriceCal(item)"
                        />

                        <van-field
                                v-model="item.expendQuantity"
                                label="出库数量"
                                placeholder="请输入"
                                :disabled="type == 'edit'"
                                :required="true"
                                :rules="[{ required: true, message: '请输入出库数量' }]"
                                @input="itemTotalPriceCal(item)"
                        />

                        <van-field
                                v-model="item.itemTotalPrice"
                                label="总金额"
                                placeholder="请选择"
                                disabled
                        />

                        <van-field
                                v-model="item.remark"
                                label="备注"
                                placeholder="请输入"
                        />

                    </div>
                    <div class="add-btn flex-hor-ver-cen" @click="handleAdd">
                        <van-icon name="add-o" class="mrg-r-20"/>
                        添加物料
                    </div>

                    <AppendixVue :businessType="'outstock'" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <van-popup v-model="showPickerApply" style="width: 70%">
            <van-datetime-picker
                    v-model="currentDate"
                    type="datetime"
                    title="请选择时间"
                    @confirm="onConfirmTime"
                    @cancel="showPickerApply = false"
            />
        </van-popup>

        <WarehouseTree :showPop="showPickerWarehouse" @handleClose="handleCloseWarehouse"/>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>

        <MaterialStock :showDialog="showPickerMaterial" :whId="formInline.whId" @handleClose="handleCloseMaterial"
                       @submitData="submitMaterial"/>
    </div>
</template>
<script>
    import {addOutStock, fetchExpendDetail, updateOutStock} from '@/api/inventory/directIn';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import WarehouseTree from '@/components/warehouseTree'
    import SelectOneUser from '@/components/selectOneUser/index'
    import MaterialStock from "@/components/materialStock/index";
    import {changeToCurrentDay} from "@/utils/ruoyi";
    import {submiteUpload} from "@/api/system/config";

    export default {
        components: {MaterialStock, WarehouseTree, AppendixVue, SelectOneUser},
        data() {
            return {
                formInline: {
                    expendCategory: 0
                },
                currentDate: new Date(),
                title: '新建出库',
                id: this.$route.query.id,
                type: this.$route.query.type,
                uploader: [],
                uploadParams: {
                    businessType: 'outstock',
                    businessId: undefined,
                    file: null,
                },
                showPickerWarehouse: false,
                showPickerMaterial: false,
                showPop: false,
                showPickerApply: false,
                //物料数组
                dataList: []
            };
        },

        mounted() {
            //页面获取焦点后，定位到页面最顶部
            scrollTo(0, 0)
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/inventory/outstock');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        expendCategory: 0
                    };
                    this.title = '新建出库';
                } else {
                    let itemDetail = await fetchExpendDetail({id: this.id});
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    this.dataList = itemDetail.materialList
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑出库';
                    } else {
                        this.title = '出库详情';
                    }
                }
            },

            //关闭仓库弹窗
            handleCloseWarehouse(resultObj) {
                if (resultObj) {
                    this.whId = resultObj.id
                    this.formInline.whId = resultObj.id
                    this.formInline.whName = resultObj.name
                }
                this.showPickerWarehouse = false
            },

            //日期提交
            onConfirmTime(value) {
                this.formInline.applyTime = changeToCurrentDay(value);
                this.showPickerApply = false;
            },

            //选择人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.warehouseTube = activeId
                this.formInline.warehouseTubeStr = names
            },

            //关闭选择人
            handleCloseUser() {
                this.showPop = false
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            //删除节点
            handleDelete(index) {
                this.dataList.splice(index, 1);
            },

            //添加节点
            handleAdd() {
                if (this.formInline.whId) {
                    this.showPickerMaterial = true
                } else {
                    this.$toast.fail('请先选择出库仓库');
                }
            },

            //关闭物料弹窗
            handleCloseMaterial() {
                this.showPickerMaterial = false
            },

            //物料提交
            submitMaterial(obj) {
                let oldMaterial = this.dataList.find(item => item.materialId == obj.id)
                if (!oldMaterial) {
                    this.dataList.push({
                        materialId: obj.materialId,
                        materialName: obj.materialName,
                        materialCode: obj.materialCode,
                        materialSpecification: obj.materialSpecification,
                        materialUnit: obj.materialUnit,
                        bathNumber: obj.bathNumber,
                        materialNum: obj.materialNum,
                        price: obj.price,
                        expendQuantity: 0,
                        remark: ''
                    });
                }

                this.handleCloseMaterial()
            },

            //物料单条明细总金额计算
            itemTotalPriceCal(item) {
                item.itemTotalPrice = ((item.expendQuantity ? item.expendQuantity : 0) * (item.price ? item.price : 0)).toFixed(2)
                this.enterPriceCal();
            },

            //总价计算
            enterPriceCal() {
                let totalPrice = 0
                let totalQuantity = 0
                this.dataList.forEach(item => {
                    totalPrice += Number(item.itemTotalPrice)
                    totalQuantity += Number(item.expendQuantity)
                })
                this.formInline.enterPrice = totalPrice
                this.formInline.totalMoney = totalPrice
                this.formInline.totalQuantity = totalQuantity
            },

            async onSubmit() {
                if (this.dataList.length == 0) {
                    this.$toast.fail('请添加出库物料');
                    return;
                }

                let flag = true
                this.dataList.forEach(item => {
                    delete item.createBy
                    delete item.createTime
                    delete item.updateBy
                    delete item.updateTime
                    delete item.companyId
                    if (!item.expendQuantity) {
                        this.$toast.fail('请先填写出库数量！')
                        flag = false
                        return;
                    }
                    if (item.expendQuantity > item.materialNum) {
                        this.$toast.fail('出库数量不能大于库存数量！')
                        flag = false
                        return;
                    }
                })

                if (!flag) {
                    return
                }

                this.formInline.materialJson = JSON.stringify(this.dataList)
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateOutStock(data) : await addOutStock(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
