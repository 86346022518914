<template>
    <div class="app-container">
        <Header :moudle="'purchase'"/>
        <div class="content-one flex-spa-bet">

        </div>
    </div>
</template>
<script>
    import Header from '@/components/header';

    export default {
        components: {
            Header,
        },

        data() {
            return {};
        },

        mounted() {
        },

        methods: {
        },
    };
</script>
<style lang="scss" scoped>
  .content-one {
    width: 710px;
    margin: 0 auto;
  }
</style>
