export const getEchart1 = (supplierName, num) => {
    return {
        xAxis: {
            type: "value",
            name: '数量',
            nameTextStyle: {
                color: "#666",
                padding: [0, 0, 0, 0],
                fontSize: 14
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#192a44"
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#eee"
                }
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#333",
                    fontSize: 14
                }
            }
        },
        grid: {
            left: "3%",
            right: "12%",
            bottom: "5%",
            top: "10%",
            containLabel: true
        },
        yAxis: {
            type: "category",
            data: supplierName,
            name: '物料',
            nameTextStyle: {
                color: "#666",
                padding: [0, 0, 0, 0],
                fontSize: 14
            },
            axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                    color: "#eee"
                }
            },
            axisLabel: {
                //坐标轴刻度标签的相关设置
                textStyle: {
                    color: "#333",
                    // padding: 16,
                    fontSize: 14
                }
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#192a44"
                }
            }
        },
        color: ["rgba(148, 204, 116, 1)"],
        series: [
            {
                data: num,
                type: "bar"
            }
        ]
    }
};

export const getEchart2 = (materialNames, enterCategory, materialCount) => {
    return {
        grid: {
            top: "10%",
            bottom: "20%",
            left: "10%",
            right: "15%",
            containLabel: true
        },
        tooltip: {},
        visualMap: {
            max: 20,
            inRange: {
                color: [
                    "#313695",
                    "#4575b4",
                    "#74add1",
                    "#abd9e9",
                    "#e0f3f8",
                    "#ffffbf",
                    "#fee090",
                    "#fdae61",
                    "#f46d43",
                    "#d73027",
                    "#a50026"
                ]
            }
        },
        xAxis3D: {
            type: "category",
            data: materialNames,
            name: '物料'
        },
        yAxis3D: {
            type: "category",
            data: enterCategory,
            name: '入库类型'
        },
        zAxis3D: {
            type: "value",
            name: '数量'
        },
        grid3D: {
            boxWidth: 200,
            boxDepth: 80,
            light: {
                main: {
                    intensity: 1.2
                },
                ambient: {
                    intensity: 0.3
                }
            }
        },
        series: [
            {
                type: "bar3D",
                data: materialCount.map(function(item) {
                    return {
                        value: [item[0], item[1], item[2]]
                    };
                }),
                shading: "color",
                label: {
                    show: false,
                    fontSize: 16,
                    borderWidth: 1
                },
                itemStyle: {
                    opacity: 0.4
                },
                emphasis: {
                    label: {
                        fontSize: 20,
                        color: "#900"
                    },
                    itemStyle: {
                        color: "#900"
                    }
                }
            }
        ]
    };
};
