<template>
    <div class="app-container">
        <navBar title="" :showNav="false"></navBar>
        <Header :moudle="'crm'"/>
        <div class="common-wrap">
            <div class="flex-spa-bet border-bom" @click="findMore">
                <span class="title">遗忘提醒</span>
                <span class="more">查看更多</span>
            </div>
            <div
                    class="flex-spa-bet inner mrg-t-10"
                    v-for="(item, index) in statisticsUnFollow"
                    :key="index"
            >
                <div class="left-tit flex">
                    <van-icon name="warning-o"/>
                    <div class="tit mrg-l-10 over_hidden">{{ item.name }}</div>
                </div>
                <div class="day">{{ item.day }}</div>
            </div>
        </div>

        <!--<div class="common-wrap">
              <div class="flex-spa-bet border-bom">
                <span class="title">时间助手</span>
                <span class="add-btn">+ 新建</span>
              </div>
              <div class="flex-spa-bet inner mrg-t-10">
                <div class="left-tit flex">
                  <van-icon name="warning-o" />
                  <div class="tit mrg-l-10 over_hidden">前往某公司总部 回访需求</div>
                </div>
                <div class="time">09:30-10:30</div>
              </div>
              <div class="flex-spa-bet inner">
                <div class="left-tit flex">
                  <van-icon name="warning-o" />
                  <div class="tit mrg-l-10 over_hidden">对新注册的客户进行电话回访</div>
                </div>
                <div class="time">09:30-10:30</div>
              </div>
              <div class="flex-spa-bet inner">
                <div class="left-tit flex">
                  <van-icon name="warning-o" />
                  <div class="tit mrg-l-10 over_hidden">对新注册的客户进行电话回访</div>
                </div>
                <div class="time">09:30-10:30</div>
              </div>
            </div>-->

        <div class="common-wrap">
            <div class="flex-spa-bet border-bom">
                <span class="title">销售简报</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>本月</span>
            </div>

            <div class="item-sale flex-spa-bet">
                <div class="item">
                    <div class="label">新增线索</div>
                    <div class="count">{{ addTotal.crmClueNum }}</div>
                </div>
                <div class="item">
                    <div class="label">新增线索跟进</div>
                    <div class="count">{{ addTotal.crmClueFollowNum }}</div>
                </div>
            </div>

            <div class="item-sale flex-spa-bet">
                <div class="item">
                    <div class="label">新增客户</div>
                    <div class="count">{{ addTotal.crmCustomerNum }}</div>
                </div>
                <div class="item">
                    <div class="label">新增客户跟进</div>
                    <div class="count">{{ addTotal.crmCustomerFollowNum }}</div>
                </div>
            </div>

            <div class="item-sale flex-spa-bet">
                <div class="item">
                    <div class="label">新增联系人</div>
                    <div class="count">{{ addTotal.crmContactsNum }}</div>
                </div>
                <div class="item">
                    <div class="label">新增联系人跟进</div>
                    <div class="count">{{ addTotal.crmContactsFollowNum }}</div>
                </div>
            </div>

            <div class="item-sale flex-spa-bet">
                <div class="item">
                    <div class="label">新增商机</div>
                    <div class="count">{{ addTotal.crmCostNum }}</div>
                </div>
                <div class="item">
                    <div class="label">新增商机跟进</div>
                    <div class="count">{{ addTotal.crmCostFollowNum }}</div>
                </div>
            </div>
        </div>

        <div class="common-wrap">
            <div class="flex-spa-bet border-bom">
                <span class="title">业绩排行</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>本月</span>
            </div>
            <div class="table">
                <div class="table-header flex-spa-bet">
                    <span class="fir-tr">排名</span>
                    <span class="se-tr">客户新增</span>
                    <!--          <span class="thi-tr">完成率</span>-->
                </div>
                <div class="table-body">
                    <div class="item-tr flex-spa-ver-cen" v-for="(item, index) in rankList" :key="index">
                        <div class="name-wrap flex-ver-cen">
                            <div class="num">{{ index + 1 }}</div>
                            <img class="img" :src="item.avatar"/>
                            <div class="name">{{ item.realname }}</div>
                        </div>
                        <div class="performance">{{ item.num }}</div>
                        <!--            <div class="percent">120%</div>-->
                    </div>
                </div>
            </div>
        </div>

        <!--遗忘提醒 查看更多-->
        <van-dialog
                v-model="dialogVisible"
                title="超过7天未跟进的客户"
                width="95%"
                style="font-size: 20px"
                :showConfirmButton="false"
        >
            <div class="common-wrap">
                <van-search
                        show-action
                        v-model="queryParams.param"
                        placeholder="请输入搜索关键词"
                        @search="handleQuery"
                >
                    <template #action>
                        <div class="search-btn-label" @click="handleQuery" style="font-size: 14px">搜索</div>
                    </template>
                </van-search>
            </div>
            <div class="main">
                <van-list finished-text="没有更多了">
                    <div class="list" v-for="item in dialogList" :key="item.id">
                        <div class="line"></div>
                        <div class="item-tab" @click="goDetail(item.id,item.name)">
                            <div class="title-wrap flex-spa-ver-cen">
                                <div class="tit over_hidden">
                                    {{ item.name || '--' }}
                                </div>
                                <div class="level-btn">
                                    {{ item.levelStr || '--' }}
                                </div>
                            </div>
                            <div class="con">
                                <div class="desc-wrap flex">
                                    <div class="label">客户来源：</div>
                                    <div class="value">{{ item.customerSourceStr || '--' }}</div>
                                </div>
                                <div class="desc-wrap flex">
                                    <div class="label">未跟进天数：</div>
                                    <div class="value">{{ item.day || '--' }}</div>
                                </div>
                                <div class="desc-wrap flex">
                                    <div class="label">手机号：</div>
                                    <div class="value">{{ item.phone || '--' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
            <van-pagination
                    v-show="total > 0"
                    v-model="queryParams.pageNum"
                    :total-items="total"
                    :show-page-size="pageCount > 3 ? 3 : pageCount"
                    :items-per-page="3"
                    force-ellipses
                    style="margin-bottom: 20px; margin-top: 20px; font-size: 16px"
                    @change="handleQuery"
            />
            <van-button
                    type="info"
                    style="width: 100%; height: 50px; font-size: 20px"
                    @click="handleClose"
            >取消
            </van-button>
        </van-dialog>
    </div>
</template>
<script>
    import Header from '@/components/header';
    import {statisticsUnFollow7} from '@/api/crm/customer';
    import {fetchCount, fetchCrmRankingList} from '@/api/crm/work';

    export default {
        components: {
            Header,
        },

        mounted() {
            //遗忘提醒
            this.statisticsUnFollow7();
            //查询新增统计
            this.fetchCount();
            //排行榜
            this.fetchCrmRankingList();
        },

        data() {
            return {
                //遗忘提醒
                statisticsUnFollow: [],
                //遗忘提醒弹窗
                dialogVisible: false,
                //遗忘提醒查询条件
                queryParams: {
                    pageNum: 1,
                    pageSize: 3,
                },
                //遗忘提醒弹窗数据
                dialogList: [],
                //遗忘提醒弹窗总条数
                total: 0,
                //总页数
                pageCount: 0,
                //新增统计
                addTotal: {},
                //排行榜
                rankList: [],
            };
        },

        methods: {
            //遗忘提醒
            async statisticsUnFollow7() {
                const result = await statisticsUnFollow7(this.queryParams);
                this.statisticsUnFollow = result.data.records;
                this.total = result.data.total;
                this.pageCount = result.data.pages;

                let date = new Date();
                this.statisticsUnFollow.forEach((item) => {
                    item.day =
                        Math.floor(
                            (date.getTime() - new Date(item.followTime.replace(/-/g, '/')).getTime()) /
                            (24 * 3600 * 1000)
                        ) + '天';
                });
            },

            //遗忘提醒 查看更多
            findMore() {
                this.dialogVisible = true;
                this.handleQuery();
            },

            //遗忘提醒 查询
            async handleQuery() {
                const result = await statisticsUnFollow7(this.queryParams);
                this.dialogList = result.data.records;
                this.total = result.data.total;
                this.pageCount = result.data.pages;

                let date = new Date();
                this.dialogList.forEach((item) => {
                    item.day =
                        Math.floor(
                            (date.getTime() - new Date(item.followTime.replace(/-/g, '/')).getTime()) /
                            (24 * 3600 * 1000)
                        ) + '天';
                });
            },

            //遗忘提醒弹窗关闭
            handleClose() {
                this.dialogVisible = false;
            },

            //新增统计
            async fetchCount() {
                let result = await fetchCount({screen: 1, time: 1, type: 1});
                this.addTotal = result.data;
            },

            //排行榜
            async fetchCrmRankingList() {
                let result = await fetchCrmRankingList({screen: 0, time: 1, type: 1});
                this.rankList = result.data;
            },

            //进入客户详情
            goDetail(id, name) {
                // 进入详情页面
                this.$router.push({
                    path: '/crm/customer/detail',
                    query: {
                        id,
                        name
                    },
                });
            }
        },
    };
</script>
<style lang="scss" scoped>
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
  }

  .more {
    font-size: 22px;
    font-weight: 400;
    color: #b5b5b5;
  }

  .add-btn {
    font-size: 22px;
    color: #0091ff;
  }

  .inner {
    padding: 20px 20px 10px 20px;
  }

  .border-bom {
    padding: 10px 25px 20px;
    border-bottom: 1px solid #ebebeb;
  }

  .common-wrap {
    padding: 20px;
    margin-top: 20px;

    .left-tit {
      font-size: 28px;
      color: #444444;

      .tit {
        width: 460px;
      }
    }

    .day {
      font-size: 22px;
      color: #e02020;
    }

    .time {
      font-size: 22px;

      color: #999999;
    }

    .item-sale {
      .item {
        padding: 28px 50px 36px;
        flex: 1;

        .label {
          font-size: 24px;
          color: #aaaaaa;
        }

        .count {
          font-size: 32px;
          font-weight: 800;
          color: #333333;
          margin-top: 10px;
        }
      }
    }
  }

  .table {
    .table-header {
      padding: 24px 25px;

      span {
        display: inline-block;
        color: #b5b5b5;

        font-size: 22px;
      }

      .fir-tr {
        width: 358px;
      }

      .se-tr {
        width: 124px;
      }

      .thi-tr {
        width: 72px;
      }
    }

    .table-body {
      padding: 0 25px;
      font-size: 26px;

      .item-tr {
        margin-bottom: 20px;
      }

      .name-wrap {
        width: 358px;

        .img {
          width: 54px;
          height: 54px;
          margin: 0 15px;
        }
      }

      .performance {
        width: 124px;
      }

      .percent {
        width: 72px;
      }
    }
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 0;
        line-height: 38px;
        flex: 1;
      }

      .level-btn {
        width: 80px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
