<template>
  <div>
      <div v-if="this.$auth.hasPermi('hr:checkwork:add')">
          <van-cell :value="`${currentMonth}月汇总`" class="mrg-t-20"> </van-cell>
          <div class="content1 flex-spa-bet">
              <div class="item-con">
                  <div class="count">{{ totalObj.workLen || 0 }}</div>
                  <div class="label">平均工时</div>
              </div>
              <div class="item-con">
                  <div class="count">{{ totalObj.lateCount || 0 }}</div>
                  <div class="label">迟到</div>
              </div>
              <div class="item-con">
                  <div class="count">{{ totalObj.leaveEarlyCount || 0 }}</div>
                  <div class="label">早退</div>
              </div>
          </div>
      </div>
    <div class="calendar-wrap mrg-t-20">
      <div class="title">
        考勤记录<span>（{{ currentMonth }}月）</span>
      </div>
      <vue-hash-calendar
        :visible="true"
        :isShowWeekView="isShowWeekView"
        format="YY-MM-DD"
        @click="handleCalendarClick"
      ></vue-hash-calendar>
    </div>
    <div class="detail">
      <div class="title">打卡详情</div>
      <!-- v-for="item in list" :key="item.id" -->
      <div class="list-detail flex-spa-ver-cen" v-if="list.length > 0">
        <div class="left">
          <div class="item-list mrg-t-20 flex-ver-cen" v-for="item in list" :key="item.id">
            <div class="icon mrg-r-10">{{ item.clockTypeName }}</div>
            <div class="time mrg-r-10">{{ item.clockTime }}</div>
            <div class="address over_hidden">{{ item.clockAddress }}</div>
          </div>
        </div>
        <div class="total-hour" v-if="this.$auth.hasPermi('hr:checkwork:add')">
          <div class="label">今日总工时</div>
          <div class="hour">{{ workHours }}</div>
        </div>
      </div>
      <van-empty v-else description="暂无打卡记录" image-size="150" />
    </div>
  </div>
</template>
<script>
import { getCurrentMonth, getCurrentYearMonth, getCurrentMonthDay2 } from '@/utils/ruoyi';
import {
  fetchCollectByMonth,
  fetcFindDakaList,
  fetcFindWorkTimeByDay,
} from '@/api/hr/hrAttendance';
export default {
  data() {
    return {
      isShowWeekView: true,
      currentMonth: getCurrentMonth(),
      currentMonth2: getCurrentYearMonth(), // 2022-7-4
      totalObj: {},
      params: {
        userId: localStorage.getItem('userId'),
        day: getCurrentMonthDay2(),
      },
      list: [],
      workHours: 0,
    };
  },

  mounted() {
    this.getCollectByMonth();
    this.getDakaDetailList();
    this.getWorkHours();
  },

  methods: {
    async getCollectByMonth() {
      const result = await fetchCollectByMonth({ month: this.currentMonth2 });
      this.totalObj = result.data;
    },

    async getDakaDetailList() {
      const result = await fetcFindDakaList(this.params);
      result.data.forEach((ele) => {
        if (ele.clockType == 0) {
          ele.clockTypeName = '上';
        } else if (ele.clockType == 1) {
          ele.clockTypeName = '下';
        } else if (ele.clockType == 2) {
          ele.clockTypeName = '补';
        } else {
          ele.clockTypeName = '外';
        }
      });
      this.list = result.data;
    },

    async getWorkHours() {
      const result = await fetcFindWorkTimeByDay({
        day: this.params.day,
      });
      this.workHours = result.data;
    },

    handleCalendarClick(day) {
      this.params.day = day;
      this.getDakaDetailList();
      this.getWorkHours();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-cell__value {
  padding: 26px 60px;
  font-size: 30px;
  font-weight: bold;
  color: #111111;
}
.content1 {
  background: #fff;
  padding: 30px 80px;
  .item-con {
    text-align: center;
    .count {
      font-size: 40px;
      font-weight: 800;
      color: #111111;
    }
    .label {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      margin-top: 20px;
    }
  }
}

.calendar-wrap {
  background: #fff;
  padding: 40px 40px;
  .title {
    font-size: 30px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
    span {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
    }
  }
}

.detail {
  padding: 40px;
  background: #fff;
  margin-top: 20px;
  .title {
    color: #999999;
    font-size: 24px;
  }
  .list-detail {
    .icon {
      width: 40px;
      height: 40px;
      background: #0091ff;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      border-radius: 100%;
    }
    .item-list:nth-child(2) {
      .icon {
        background: #c8c8c8;
      }
    }

    .time,
    .address {
      color: #333333;
      font-size: 26px;
    }
    .address {
      width: 240px;
    }
  }

  .total-hour {
    font-size: 22px;
    color: #333333;
    text-align: center;
    border-left: 1px solid #d8d8d8;
    height: 100px;
    padding: 20px 0 0 20px;
    margin-top: 30px;
    .label {
      margin-bottom: 20px;
    }
    .hour {
      width: 117px;
      height: 33px;
      background: #0091ff;
      border-radius: 19px 19px 19px 19px;
      color: #fff;
      font-size: 22px;
      line-height: 33px;
    }
  }
}
</style>
