import request from '@/utils/request'
import { serverUrl } from '@/utils/request'

export const baseURL = serverUrl
export function getRList(userId) {
  return request({
    url: `/oa/meeting/getRList`,
    method: 'GET',
    params: { userId }
  })
}
// 会议列表
export function getList(query) {
  return request({
    url: `/oa/meeting/getList`,
    method: 'GET',
    params: query
  })
}
// 会议单条
export function getId(meetingId) {
  return request({
    url: `/oa/meeting/getId`,
    method: 'GET',
    params: { meetingId }
  })
}
// 会议室列表
export function getListRoom() {
  return request({
    url: `/oa/meeting/getListRoom`,
    method: 'GET'
  })
}
// 新建会议室
export function addMeetingRoom(meetingRoom) {
  return request({
    url: `/oa/meeting/addMeetingRoom`,
    method: 'POST',
    data: meetingRoom
  })
}
// 修改会议室
export function updateMeetingRoom(meetingRoom) {
  return request({
    url: `/oa/meeting/updateMeetingRoom`,
    method: 'PUT',
    data: meetingRoom
  })
}
// 会议室单条
export function getIdRoom(meetingRoomId) {
  return request({
    url: `/oa/meeting/getIdRoom`,
    method: 'GET',
    params: { meetingRoomId }
  })
}
export function deleteRoom(meetingRoomId) {
  return request({
    url: `/oa/meeting/deleteRoom`,
    method: 'DELETE',
    params: { meetingRoomId }
  })
}
export function findSelfAndSub(userId) {
  return request({
    url: `/system/user/findSelfAndSub/${userId}`,
    method: 'GET',
  })
}
