<template>
  <div class="inner-container">
    <navBar :title="'公司介绍'" @handleGoBack="handleGoBack"> </navBar>
    <div class="content">
      山东壹米信息科技有限公司立于2018年,拥有多个子公司,涵盖软件、云服务器、人工智能、广告、投资基金等多个领域;我们是一支对客户充满责任感和激情的团队,我们崇尚学习、善于创新、真诚服务、提供共赢,确保以量快的时间、最优的服务、最大限度地满足客户的需求,为中国信息化建设不断贡献自己的力量公司不断吸纳高端IT人才就业,开创中国式精确管理,主要从事企业内部运营管理研究和软件研发,全面提供具有自主知识产权各类管理软件,咨询服务与解决方案。致力于OA办公管理系统,
      CRM管理系统, ERP管理系统,力争打造行业尖端。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleGoBack() {
      this.$router.replace('/staticSystem/about');
        this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container {
  height: 100vh;
  background: #f3f4f5;
}
.content {
  padding: 20px 40px;
  line-height: 45px;
  font-size: 28px;
  color: #444444;
  text-indent: 2em;
}
</style>
