import request from "@/utils/request";
import { serverUrl } from "@/utils/request";

export const baseURL = serverUrl;
// 权限设置查询
export function queryschedule(workTypName) {
  return request({
    url: `/oa/work/getListType`,
    method: "GET",
    params: workTypName
  });
}
// 工作汇报权限根据id查询
export function getIdType(workTypeId) {
  return request({
    url: `/oa/work/getIdType`,
    method: "GET",
    params: { workTypeId }
  });
}
// 新建工作汇报权限
export function addType(workType) {
  return request({
    url: `/oa/work/addType`,
    method: "POST",
    data: workType
  });
}
// 修改工作汇报权限
export function updateType(workType) {
  return request({
    url: `/oa/work/updateType`,
    method: "PUT",
    data: workType
  });
}
// 删除工作汇报权限
export function deleteType(workTypeId) {
  return request({
    url: `/oa/work/deleteType`,
    method: "DELETE",
    params: { workTypeId }
  });
}
// 我收到工作汇报列表
export function getSendList(query) {
  return request({
    url: `/oa/work/getSendList`,
    method: "GET",
    params: query
  });
}
// 我发布的
export function getMyList(query) {
  return request({
    url: `/oa/work/getMyList`,
    method: "GET",
    params: query
  });
}
// 新建工作汇报
export function addreport(work) {
  return request({
    url: `/oa/work/add`,
    method: "POST",
    data: work
  });
}
// 删除工作汇报
export function deletereporte(workId) {
  return request({
    url: `/oa/work/delete`,
    method: "DELETE",
    params: { workId }
  });
}
// 根据id获取单条
export function getId(workId) {
  return request({
    url: `/oa/work/getId`,
    method: "GET",
    params: { workId }
  });
}
// 获取评论
export function getComment(workId) {
  return request({
    url: `/oa/work/getComment`,
    method: "GET",
    params: { workId }
  });
}
// 新建工作汇报评论
export function addComment(comment) {
  return request({
    url: `/oa/work/addComment`,
    method: "POST",
    data: comment
  });
}
// 工作汇报评论删除
export function deleteComment(commentId) {
  return request({
    url: `/oa/work/deleteComment`,
    method: "DELETE",
    params: { commentId }
  });
}

// 统计接收的汇报数量
export function statisticWork() {
  return request({
    url: `/oa/work/statisticWork`,
    method: "GET"
  });
}
