import * as echarts from "echarts";

export const getEchart = (strList, clueList,customerList,costList,orderList,xname,yname) => {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      // data: ['线索', '客户', '商机', '订单']
    },
    grid: {
      left: '3%',
      right: '7%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: false,
    },
    yAxis: {
      type: 'category',
      data: strList,
      name: xname
    },
    series: [
      {
        name: '线索',
        type: 'bar',
        data: clueList
      },
      {
        name: '客户',
        type: 'bar',
        data: customerList
      },
      {
        name: '商机',
        type: 'bar',
        data: costList
      },
      {
        name: '订单',
        type: 'bar',
        data: orderList
      }
    ]
  };
};
