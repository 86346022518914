<template>
    <div>
        <navBar :title="'审批详情'" @handleGoBack="handleGoBack"></navBar>
        <div class="detail-wrap">
            <div class="top-detail">
                <div class="flex-spa-bet bor-bom">
                    <div class="title-time">
                        <div class="title">{{ form.approveTitle }}</div>
                        <div class="time">申请时间：{{ form.data?form.data.time:form.createTime }}</div>
                    </div>
                    <img src="../../../assets/images/sq_record_img3.png" alt="" v-if="form.approveState == 0"/>
                    <img src="../../../assets/images/sq_record_img.png" alt="" v-if="form.approveState == 1"/>
                    <img src="../../../assets/images/sq_record_img2.png" alt="" v-if="form.approveState == 2"/>
                </div>
                <div class="item-label flex">
                    <label>申请部门</label>
                    <p>{{ form.data?form.data.deptName:form.deptName }}</p>
                </div>

                <template v-if="form.typeStr == '请假申请' || form.typeStr == '外出申请' || form.typeStr == '出差申请'">
                    <div class="item-label flex">
                        <label>申请时长</label>
                        <p>{{ form.data.duration }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>开始时间</label>
                        <p>{{ form.data.starttime }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>结束时间</label>
                        <p>{{ form.data.endtime }}</p>
                    </div>

                    <div class="item-label">
                        <label>申请事由</label>
                        <p class="mrg-t-20" style="line-height: 25px">
                            {{ form.data.remark }}
                        </p>
                    </div>
                </template>

                <template v-else-if="form.typeStr == '用车申请'">
                    <div class="item-label flex">
                        <label>车辆名称</label>
                        <p>{{ form.data.carName }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>开始时间</label>
                        <p>{{ form.data.starttime }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>结束时间</label>
                        <p>{{ form.data.endtime }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>出发地</label>
                        <p>{{ form.data.depart }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>目的地</label>
                        <p>{{ form.data.purpose }}</p>
                    </div>
                    <div class="item-label">
                        <label>用车事由</label>
                        <p class="mrg-t-20" style="line-height: 25px">
                            {{ form.data.duration }}
                        </p>
                    </div>
                    <div class="item-label">
                        <label>备注</label>
                        <p class="mrg-t-20" style="line-height: 25px">
                            {{ form.data.remark }}
                        </p>
                    </div>
                </template>

                <template v-else-if="form.typeStr == '资产申请'">
                    <div class="item-label flex">
                        <label>资产名称</label>
                        <p>{{ form.data.name }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>开始时间</label>
                        <p>{{ form.data.starttime }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>结束时间</label>
                        <p>{{ form.data.endtime }}</p>
                    </div>
                    <div class="item-label">
                        <label>备注</label>
                        <p class="mrg-t-20" style="line-height: 25px">
                            {{ form.data.remark }}
                        </p>
                    </div>
                </template>

                <template v-else-if="form.typeStr == '会议申请'">
                    <div class="item-label flex">
                        <label>会议名称</label>
                        <p>{{ form.data.name }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>召集人</label>
                        <p>{{ form.data.conveneName }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>联系人</label>
                        <p>{{ form.data.contactName }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>会议地点</label>
                        <p>{{ form.data.roomName }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>开始时间</label>
                        <p>{{ form.data.startTime }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>结束时间</label>
                        <p>{{ form.data.endTime }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>参会人员</label>
                        <p>{{ form.data.staffUser }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>其他人员</label>
                        <p>{{ form.data.otherStaff }}</p>
                    </div>
                    <div class="item-label">
                        <label>会议内容</label>
                        <p class="mrg-t-20" style="line-height: 25px">
                            {{ form.data.remark }}
                        </p>
                    </div>
                </template>

                <template v-else-if="form.typeStr == '报销申请'">
                    <div class="item-label flex">
                        <label>报销日期</label>
                        <p>{{ form.data.time }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>单据数量</label>
                        <p>{{ form.data.input }}</p>
                    </div>

                    <div v-for="item in form.data.itemCount" :key="item" style="margin-top: 20px">
                        <div class="item-label flex">
                            <label>日期</label>
                            <p>{{ form.data['time'+item] }}</p>
                        </div>
                        <div class="item-label flex">
                            <label>费用项目</label>
                            <p>{{ form.data['project'+item] }}</p>
                        </div>
                        <div class="item-label flex">
                            <label>费用类别</label>
                            <p>{{ form.data['category'+item] }}</p>
                        </div>
                        <div class="item-label flex">
                            <label>发票数量</label>
                            <p>{{ form.data['quantity'+item] }}</p>
                        </div>
                        <div class="item-label flex">
                            <label>金额</label>
                            <p>{{ form.data['money'+item] }}</p>
                        </div>
                        <div class="item-label flex">
                            <label>备注</label>
                            <p>{{ form.data['remark'+item] }}</p>
                        </div>
                    </div>

                    <div class="item-label flex" style="margin-top: 20px">
                        <label>金额合计</label>
                        <p>{{ form.data.moneytotal }}</p>
                    </div>
                    <div class="item-label flex">
                        <label>金额大写</label>
                        <p>{{ form.data.Uppercase }}</p>
                    </div>
                </template>

                <div class="item-label" v-if="form.sysAnnexList && form.sysAnnexList.length>0">
                    <label>附件</label>
                    <div class="fj-wrap flex mrg-t-20" v-for="(item,index) in form.sysAnnexList" :key="index">
                        <div class="flex">
                            <img :src="item.annexUrl" alt=""/>
                            <div class="title-wrap">
                                <div class="tit">{{ item.annexName }}</div>
                                <div class="number">{{ item.fileSize/1024 }}M</div>
                            </div>
                        </div>
                        <div class="preview-btn" v-if="item.fileFormat == 'png' || item.fileFormat == 'jpg'" @click="photoView(item.annexUrl)">预览</div>
                    </div>
                </div>
            </div>

            <div class="bottom-detail">
                <div class="title">审批流程</div>

                <div class="item-status" v-for="(item,index) in form.approveNodeList" :key="index">
                    <div class="right">
                        <div class="flex name-wrap">
                            <div class="name">{{ item.approverStr }}</div>
                        </div>
                        <div class="status-time flex-spa-bet">
                            <div class="status" style="color: #f78c00" v-if="item.approveNode == form.currentNode">审批中</div>
                            <div class="status" style="color: #30c39d" v-if="item.approveNode < form.currentNode && form.approveState != 2">审批通过</div>
                            <div class="status" style="color: #ff6464" v-if="item.approveNode < form.currentNode && form.approveState == 2">已驳回</div>
                            <div class="status" style="color: #0091ff" v-if="item.approveNode > form.currentNode">等待审批</div>
                            <div class="time">{{item.operateDate}}</div>
                        </div>
                        <div class="remark" v-if="item.operatingOpinion && item.approveNode < form.currentNode">
                            <div class="over_hidden_2">
                                {{item.operatingOpinion}}
                            </div>
                        </div>
                        <div v-if="form.isApprove && form.approveState != 2 && item.approveNode == form.currentNode">
                            <div class="remark over_hidden_2">
                                <textarea rows="2" class="text-area" v-model="operatingOpinion"></textarea>
                            </div>
                            <div class="btn-wrap">
                                <van-button round class="btn" @click="operateSubmit(0, form.id)">驳回到初始节点</van-button>
                                <van-button round class="btn" @click="operateSubmit(-1, form.id)">驳回到上一节点</van-button>
                                <van-button round type="info" class="btn" @click="operateSubmit(1, form.id)">通过</van-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <van-popup v-model="photoShow" style="width: 80%">
            <img :src="annexUrl" alt="" width="100%"/>
        </van-popup>
    </div>
</template>

<script>
    import {findOne, operateApprove} from "@/api/approve/approve";
    import {getId} from "@/api/oa/car";
    import {getIdRoom} from "@/api/oa/meeting";
    import {getAllUser} from "@/api/system/user";

    export default {
        data() {
            return {
                form: {
                    data: {}
                },
                approveId: this.$route.query.id,
                //图片预览
                photoShow: false,
                //图片地址
                annexUrl: '',
                //审批意见
                operatingOpinion: ''
            };
        },

        mounted() {
            //查询详情
            this.findOne();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/approve/myApply');
                this.$router.go(-1)
            },

            //查询详情
            async findOne(){
                let result = await findOne(this.approveId)
                let obj = result.data
                obj.data = JSON.parse(obj.remark)

                if(obj.typeStr == '用车申请'){
                    let findOne = await getId(obj.data.carId);
                    obj.data.carName = findOne.data.carNumber;
                }else if(obj.typeStr == '会议申请'){
                    //查询会议室
                    let meetRoom = await getIdRoom(obj.data.roomId);
                    obj.data.roomName = meetRoom.data.name;

                    //查询所有用户
                    let result = await getAllUser();
                    let userList = result.data;

                    if(obj.data.conveneId){
                        //查询召集人
                        obj.data.conveneName = userList.find(item=>item.id == obj.data.conveneId).realname;
                    }

                    if(obj.data.contactId){
                        //查询联系人
                        obj.data.contactName = userList.find(item=>item.id == obj.data.contactId).realname;
                    }

                    //查询参会人员
                    if(obj.data.staffIds){
                        let staffIds = obj.data.staffIds.split(",")
                        let staffUserList = userList.filter(item=> {
                            return staffIds.indexOf(String(item.id)) >= 0
                        })
                        obj.data.staffUser = staffUserList.map((item)=>item.realname);
                    }
                }else if(obj.typeStr == '报销申请'){
                    let itemCount = 0;
                    if(obj.data.time5){
                        itemCount = 5
                    }else if(obj.data.time4){
                        itemCount = 4
                    }else if(obj.data.time3){
                        itemCount = 3
                    }else if(obj.data.time2){
                        itemCount = 2
                    }else if(obj.data.time1){
                        itemCount = 1
                    }
                    obj.data.itemCount = itemCount
                }
                this.form = obj
            },

            //图片预览
            photoView(annexUrl){
                this.photoShow = true;
                this.annexUrl = annexUrl;
            },

            //驳回/通过
            async operateSubmit(operating, approveId) {
                let formdata = new FormData();
                formdata.set('approveId',approveId);
                formdata.set('operating',operating);
                formdata.set('operatingOpinion',this.operatingOpinion);
                await operateApprove(formdata)

                this.operatingOpinion = '';
                this.$toast.success('操作成功');
                await this.findOne();
            }
        },
    };
</script>

<style lang="scss" scoped>
  .detail-wrap {
    height: 100vh;
    background: #f8f8fa;

    .top-detail {
      background: #fff;
      border-top: 20px solid #f8f8f8;
      padding: 30px;
    }

    .bor-bom {
      border-bottom: 1px solid #ececec;
      padding: 20px 0;

      .title {
        font-size: 34px;
        font-weight: 800;
        color: #111111;
        margin-bottom: 20px;
      }

      .time {
        font-size: 24px;
        font-weight: 400;
        color: #999999;
      }

      img {
        width: 116px;
        height: 90px;
      }
    }

    .item-label {
      padding: 36px 0 0;

      label {
        font-size: 28px;
        font-weight: 400;
        color: #999999;
        margin-right: 40px;
      }

      p {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
      }

      .fj-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 103px;
        background: #f8f8fa;
        padding: 20px;

        .title-wrap {
          margin-left: 20px;

          .tit {
            font-size: 30px;
            font-weight: bold;
            color: #333333;
          }

          .number {
            font-size: 22px;
            font-weight: 400;
            color: #999999;
            margin-top: 6px;
          }
        }

        .preview-btn {
          width: 101px;
          height: 48px;
          border-radius: 24px 24px 24px 24px;
          opacity: 1;
          border: 1px solid #54afff;
          font-size: 24px;
          color: #0091ff;
          text-align: center;
          line-height: 48px;
          cursor: pointer;
        }

        img {
          width: 42px;
          height: 54px;
        }
      }
    }
  }

  .bottom-detail {
    background: #fff;
    padding: 30px 40px;
    margin-top: 20px;

    .title {
      font-size: 30px;
      font-weight: bold;
      color: #111111;
      margin-bottom: 40px;
    }

    .item-status {
      position: relative;
      // padding: 0 0 20px;
      &::before {
        display: block;
        content: '';
        width: 28px;
        height: 28px;
        background: url('../../../assets/images/apply_icon1.png') no-repeat center;
        background-size: 100% 100%;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: -14px;
      }

      .right {
        border-left: 1px dashed #bfbfbf;
        flex: 1;
        padding: 0 0 40px 40px;
        font-size: 30px;

        .name-wrap {
          display: flex;
          flex-wrap: wrap;

          .name {
            margin: 0 30px 10px 0;
            font-size: 28px;
          }
        }

        .status-time {
          width: 610px;
          margin: 20px 0;
          font-size: 24px;

          .time {
            color: #aaaaaa;
          }
        }

        .remark {
          width: 620px;
          height: 130px;
          background: #f8f8fa;
          border-radius: 20px 20px 20px 20px;
          padding: 30px;
          color: #333333;
          font-size: 28px;
          line-height: 34px;

          .text-area {
            width: 100%;
            border: none;
            background: none;
          }
        }

        .btn-wrap {
          width: 610px;
          text-align: right;
          margin-top: 30px;

          ::v-deep .van-button--info {
            width: 160px;
            height: 80px;
            background: #3768ef;
            font-size: 28px;
            margin-left: 10px;
          }

          ::v-deep .van-button--default {
            width: 220px;
            height: 80px;
            background: #ffffff;
            font-size: 26px;
          }
        }
      }
    }
  }
</style>
