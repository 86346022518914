<template>
    <div class="app-container">
        <div class="wrap statistic">
            <navBar :title="title" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-popover
                            v-model="showPopover"
                            trigger="click"
                            placement="bottom-end"
                            :actions="actions"
                            @select="onSelect"
                    >
                        <template #reference>
                            <van-icon name="exchange" class="mrg-r-20"/>
                        </template>
                    </van-popover>
                </template>
            </navBar>
            <ReportSalesForecast :isShow="judgeObj['reportSalesForecast']"/>
            <ReportSaleFunnel :isShow="judgeObj['reportSaleFunnel']"/>
            <ReportBusinessAdd :isShow="judgeObj['reportBusinessAdd']"/>
            <ReportPersonnelFollow :isShow="judgeObj['reportPersonnelFollow']"/>
        </div>
    </div>
</template>
<script>
    import ReportSalesForecast from './components/reportSalesForecast';
    import ReportSaleFunnel from './components/reportSaleFunnel';
    import ReportBusinessAdd from './components/reportBusinessAdd';
    import ReportPersonnelFollow from './components/reportPersonnelFollow';

    export default {
        components:{
            ReportSalesForecast,
            ReportSaleFunnel,
            ReportBusinessAdd,
            ReportPersonnelFollow,
        },
        data() {
            return {
                title: '',
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                queryParams: {
                    pageNum: 1,
                    pageSize: 5
                },
                dataList: [],
                //菜单切换
                showPopover: false,
                itemId: null,
                //菜单数组
                actions: this.$route.query.children.map(item => {return {path: item.imgUrl,text: item.title, className: 'appendix-item-action-name2'}}),
                //菜单是否显示
                judgeObj: {},
                //上一次点击的菜单
                oldPath: ''
            };
        },

        mounted() {
            //组装数据
            this.packData()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/statement/workbench');
                this.$router.go(-1)
            },

            //组装数据
            packData(){
                this.actions.forEach(item=>{
                    this.judgeObj[item.path] = false
                })

                if(this.actions){
                    this.judgeObj[this.actions[0].path] = true
                    this.title = this.actions[0].text
                }else{
                    this.title = '客户报表'
                }
            },

            handleAdd() {
                this.showPopover = true
            },

            //点击菜单
            onSelect(action) {
                if(this.oldPath){
                    this.judgeObj[this.oldPath] = false
                }else{
                    this.judgeObj['reportSalesForecast'] = false
                }
                this.title = action.text
                this.judgeObj[action.path] = true
                this.oldPath = action.path
                this.$forceUpdate()
            },
        }
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

</style>
