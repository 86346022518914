<template>
  <div class="app-container">
    <navBar :title="'补卡申请记录'" @handleGoBack="handleGoBack"> </navBar>

    <div class="count">本月（{{ total }}）</div>

    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="item-list" v-for="item in list" :key="item.id">
        <div class="name-wrap flex-spa-bet">
          <div class="name">{{ item.createBy }}提交的补卡申请</div>
          <div class="status" :style="{ color: approveStateColor[item.approveStateStr] }">
            审批{{ item.approveStateStr }}
          </div>
        </div>
        <div class="desc-wrap">
          <div class="time flex mrg-b-20">
            <div class="label">补卡时间：</div>
            <div class="value">{{ item.clockTime }}</div>
          </div>
          <div class="reason flex">
            <div class="label">补卡理由：</div>
            <div class="value">{{ item.remark || '--' }}</div>
          </div>
        </div>
        <div class="flex-spa-bet bottom">
          <div class="time">{{ item.createTime }}</div>
          <i class="iconfont icon-gengduo" style="font-size: 20px" @click="handleDetail(item)"></i>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { fetchReissueCardList } from '@/api/hr/hrAttendance';
import { approveStateColor } from '@/views/constant';
export default {
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      total: 0,
      approveStateColor,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },

  mounted() {
    this.getReissueCardList();
  },

  methods: {
    async getReissueCardList() {
      const result = await fetchReissueCardList(this.queryParams);

      const { records = [], total = 0 } = result.data;

      // 空数组
      if (records.length == 0) {
        this.list = [];
        this.finished = true;
        return;
      }

      // 数组小于10条
      if (this.queryParams.pageNum == 1 && records.length <= this.queryParams.pageSize) {
        this.list = records;
        this.finished = true;
      } else {
        // 数组大于十条
        this.list = this.list.concat(records);
        this.finished = this.list.length >= total;
      }

      this.total = total;
    },

    handleGoBack() {
      this.$router.replace('/attendance');
        this.$router.go(-1)
    },

    onLoad() {
      if (this.list.length <= this.total) {
        return;
      }
      this.queryParams.pageNum++;
      this.getReissueCardList();
    },

    handleDetail(item) {
      this.$router.push({
        path: '/attendance/cardRecordDetail',
        query: {
          ...item,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.count {
  font-size: 24px;
  color: #666666;
  padding: 20px 30px;
}

.item-list {
  background: #fff;
  padding: 30px 40px;
  margin-bottom: 20px;
  .name-wrap {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 20px;
    .name {
      font-size: 32px;
      font-weight: bold;
      color: #111111;
    }
    .status {
      color: #61c876;
      font-size: 24px;
    }
  }

  .desc-wrap {
    font-size: 28px;
    color: #111111;
    padding: 20px 0;
    border-bottom: 1px solid #f3f3f3;
    .label {
      color: #999999;
    }
    .value {
      color: #111111;
    }
  }

  .bottom {
    padding-top: 20px;
    color: #333333;

    font-size: 26px;
  }
}
</style>
