<template>
    <div>
        <navBar :title="'权限设置'" @handleGoBack="handleGoBack">
            <div
                    slot="icon1"
                    style="font-weight: bold"
                    class="add-btn icon-tianjia iconfont mrg-r-20"
                    @click="handleAdd"
            ></div>
        </navBar>

        <!-- 搜索 -->
        <div class="flex-ver-cen search-bg">
            <van-search
                    class="search-input"
                    v-model="flowTitle"
                    shape="round"
                    placeholder="搜索流程标题"
            />
            <van-button class="search-btn" round @click="handleFilter">
                <template #icon>
                    <div class="iconfont icon-shaixuan"></div>
                </template>
                筛选
            </van-button
            >
        </div>
        <div class="tabs-wrap">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        offset="100"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <div class="list" v-for="(item,index) in list" :key="index">
                        <div class="item-tab" @click="handleGoDetail(item.id)">
                            <div class="tit over_hidden">
                                {{ item.flowTitle }}
                            </div>
                            <div class="con">
                                <div class="desc-wrap flex">
                                    <div class="label">流程分类：</div>
                                    <div class="value">{{ item.typeId }}</div>
                                </div>
                                <div class="desc-wrap flex">
                                    <div class="label">创建时间：</div>
                                    <div class="value">{{ item.createTime }}</div>
                                </div>
                            </div>
                            <div class="time-wrap flex-ver-cen">
                                <div class="btn mrg-r-20" @click.stop="delData(item.id)">删除</div>
                                <div class="btn edit-btn" @click.stop="handleGoDetail(item.id)">编辑</div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>

        <!-- 筛选弹窗 -->
        <van-popup v-model="showFilter" position="bottom" :style="{ height: '55%' }">
            <div class="pop-main">
                <div class="title">筛选</div>
                <div class="tit">流程类型</div>
                <div class="flex-wrap">
                    <van-cascader
                            v-model="typeIdOne"
                            :options="typeOptions"
                            @finish="onFinish"
                            :field-names="{ text: 'label', value: 'id', children: 'children' }"
                    />
                </div>

                <div class="confirm-btn" @click="handleConfirm">确认</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import {deleteFlow, findAllType, findPage} from "@/api/approve/approveFlow";

    export default {
        data() {
            return {
                flowTitle: '',
                showFilter: false,
                currentStatus: 0,
                currentForm: 0,
                refreshing: false, // 下拉
                finished: false,
                loading: false,
                queryParams: {
                    pageNum: 1,
                    pageSize: 5
                },
                list: [],
                //流程类型
                typeId: '',
                typeIdOne: '',
                //流程类型树结构
                typeOptions: [],
                //删除弹窗
                delShow: false
            };
        },

        mounted() {
            //查询流程类型树结构
            this.findAllType();
            //查询列表
            this.getList();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/approve/myApply');
                this.$router.go(-1)
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },

            //查询流程类型树结构
            async findAllType() {
                let result = await findAllType();
                let typeOptions = result.data
                typeOptions.forEach(item => {
                    item.id = String(item.id)
                    if (item.children) {
                        item.children.forEach(detail => {
                            detail.id = String(detail.id)
                        })
                    }
                })
                this.typeOptions = typeOptions
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.list.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            async getList() {
                this.loading = true;

                let result = await findPage(this.queryParams);
                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.list = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.list = records;
                } else {
                    this.list = [...this.list, ...records];
                }

                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
                this.$forceUpdate()
            },

            handleFilter() {
                this.showFilter = true;
            },

            handleConfirm() {
                this.showFilter = false;
                this.queryParams.pageNum = 1;
                this.queryParams.typeId = this.typeId
                this.getList();
            },

            handleGoDetail(id) {
                this.$router.push({
                    path:'/myApply/addProcess',
                    query: {
                        id
                    }
                });
            },

            handleAdd() {
                this.$router.push('/myApply/addProcess');
            },

            // 全部选项选择完毕后，会触发 finish 事件
            onFinish({selectedOptions}) {
                this.typeId = selectedOptions.map((option) => option.id).join(',');
            },

            //删除
            delData(id) {
                let that = this
                this.$dialog.confirm({
                    message: '是否确认删除',
                    width: '80%'
                })
                    .then(async () => {
                        // on confirm
                        await deleteFlow(id);
                        that.delShow = false;
                        await that.handleQuery();
                        that.$toast.fail('删除成功');

                    })
                    .catch(() => {
                        // on cancel
                        that.delShow = false;
                    });
            }
        },
    };
</script>

<style lang="scss" scoped>
  .search-bg {
    padding: 0 22px 0 10px;
    background: #fff;

    .search-btn {
      width: 170px;
      height: 70px;
    }

    .search-input {
      flex: 1;
    }
  }

  // 筛选
  .pop-main {
    padding: 0 50px;

    .title {
      text-align: center;
      font-weight: bold;
      color: #111111;
      font-size: 32px;
      padding-top: 48px;
    }

    .tit {
      font-size: 24px;
      font-weight: 500;
      color: #111111;
      margin: 50px 0 30px;
    }

    .btn {
      width: 190px;
      height: 70px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #cccccc;
      text-align: center;
      line-height: 70px;
      font-size: 28px;
      color: #666666;
      cursor: pointer;
      margin-right: 40px;
      margin-bottom: 40px;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .active-btn {
      border: 1px solid #3768ef;
      background: #3768ef;
      color: #fff;
    }

    .confirm-btn {
      width: 650px;
      height: 90px;
      background: #3768ef;
      border-radius: 12px 12px 12px 12px;
      font-size: 28px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 90px;
      margin-top: 60px;
    }
  }

  .tabs-wrap {
    background: #f8f8fa;

    .item-tab {
      padding: 20px 33px;
      margin: 16px auto;
      width: 720px;
      height: 300px;
      background: #ffffff;
      border-radius: 20px 20px 20px 20px;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 100%;
        line-height: 38px;
      }

      .con {
        padding: 28px 0;
        border-bottom: 1px solid #ececec;

        .desc-wrap {
          font-size: 28px;
          margin-bottom: 20px;

          .label {
            color: #999;
          }

          .value {
            color: #111;
          }

          .red {
            color: #f7ad00;
          }
        }

        .desc-wrap:last-child {
          margin-bottom: 0;
        }
      }

      .time-wrap {
        font-size: 26px;
        margin: 20px 0 20px;

        justify-content: flex-end;

        .btn {
          width: 110px;
          height: 54px;
          background: #ffffff;
          border-radius: 12px 12px 12px 12px;
          border: 1px solid #cccccc;
          text-align: center;
          line-height: 54px;
          cursor: pointer;
        }

        .edit-btn {
          border: 1px solid #3768ef;
          color: #3768ef;
        }
      }
    }
  }
</style>
