//

import request from "@/utils/request";
import { serverUrl } from "@/utils/request";

export const baseURL = serverUrl;

// 查询库存管理工作台
export function fetchStockBenchCount(query) {
  return request({
    url: "/inventory/workbench/count",
    method: "get",
    params: query
  });
}

// 查询库存管理根据类型统计
export function fetchStockBenchCountByCategory(query) {
  return request({
    url: "/inventory/workbench/countByCategory",
    method: "get",
    params: query
  });
}
// 查询库存管理前20条出入库单
export function fetchStockTwentyRecord(query) {
  return request({
    url: "/inventory/workbench/getTwentyRecord",
    method: "get",
    params: query
  });
}
// 查询库存管理 最近七日出入库单数量
export function fetchStockSevenDaysCount(query) {
  return request({
    url: "/inventory/workbench/getSevenDaysCount",
    method: "get",
    params: query
  });
}
export function getRecord() {
  return request({
    url: "/inventory/workbench/getRecord",
    method: "get",
  });
}

export function findSimpleOfWarehouse() {
  return request({
    url: "/inventory/workbench/findSimpleOfWarehouse",
    method: "get",
  });
}

export function reportSimpleEnterExpend() {
  return request({
    url: "/inventory/workbench/reportSimpleEnterExpend",
    method: "get",
  });
}
