<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.whName"
                            label="仓库名称"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入仓库名称' }]"
                    />

                    <van-field
                            v-model="formInline.whNo"
                            label="仓库编号"
                            placeholder="请输入"
                    />

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.whStateName"
                            label="仓库状态"
                            placeholder="请选择"
                            @click="showPickerState = true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择仓库状态' }]"
                    />
                    <van-popup v-model="showPickerState" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="stateOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'whState', 'whStateName', 'dictLabel', 'showPickerState')
              "
                                @cancel="showPickerState = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.whAdminName"
                            label="仓库管理员"
                            placeholder="请选择"
                            @click="showPop = true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择仓库管理员' }]"
                    />

                    <van-field
                            v-model="formInline.phoneNum"
                            label="手机号码"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.fixedNum"
                            label="固定电话"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.weChat"
                            label="微信账号"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.address"
                            label="详细地址"
                            placeholder="请输入"
                    />

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.whmanName"
                            label="保管员"
                            placeholder="请选择"
                            @click="showPop2 = true"
                    />

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'" :disabled="type == 'detail'"/>


                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交</van-button>
                </van-form>
            </div>
        </div>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleClose"/>
        <SelectOneUser2 :showPop="showPop2" @popSubmit="popSubmit2" @handleClose="handleClose2" :isMultiple="true"/>
    </div>
</template>
<script>
    import {addWarehouse,updateWarehouse,getWarehouse} from '@/api/inventory/warehourse';
    import SelectOneUser from '@/components/selectOneUser'
    import SelectOneUser2 from '@/components/selectOneUser'

    export default {
        components: {SelectOneUser,SelectOneUser2},
        data() {
            return {
                formInline: {
                },
                title: '新建仓库',
                showPickerDay: false,
                minDate: new Date(2010, 0, 1),
                id: this.$route.query.id,
                parentId: this.$route.query.parentId,
                type: this.$route.query.type,
                showPickerState: false,
                showPop: false,
                showPop2: false,
                stateOptions:[
                    {dictValue: true, dictLabel: '启用'},
                    {dictValue: false, dictLabel: '停用'}
                ]
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/inventory/warehouse');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if(this.type == 'add'){
                    this.formInline = {
                        parentId: this.parentId
                    };
                    this.title = '新建仓库';
                }else{
                    let itemDetail = await getWarehouse({id: this.id});
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    this.formInline.whStateName = this.formInline.whState?'启用':'停用'
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑仓库';
                    } else{
                        this.title = '仓库详情';
                    }
                }
            },

            //确认
            onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //选择人提交
            popSubmit(activeId, names){
                this.showPop = false
                this.formInline.whAdminId = activeId
                this.formInline.whAdminName = names
            },

            //关闭选择人
            handleClose(){
                this.showPop = false
            },

            //选择人提交2
            popSubmit2(activeId, names){
                this.showPop2 = false
                this.formInline.whmanIds = activeId
                this.formInline.whmanName = names
            },

            //关闭选择人2
            handleClose2(){
                this.showPop2 = false
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            async onSubmit(values) {
                const params = {
                    ...values,
                    ...this.formInline,
                };
                const data = JSON.stringify(this.formInline)
                const result = this.formInline.id ? await updateWarehouse(data) : await addWarehouse(params);
                if (result.code == 200) {
                    this.$toast.success('添加成功');
                        this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
