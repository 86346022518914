<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.title"
                            label="计划标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入计划标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.typeName"
                            label="采购类型"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择采购类型' }]"
                            @click="showPickerType= true"
                    />
                    <van-popup v-model="showPickerType" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="typeOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'type', 'typeName', 'dictLabel', 'showPickerType')
              "
                                @cancel="showPickerType = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.purchaseName"
                            label="采购人"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择采购人' }]"
                            @click="showPop= true"
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.purchaseDate"
                            label="采购日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择采购日期' }]"
                            @click="showPickerApply = true"
                    />
                    <van-calendar :min-date="minDate" :max-date="maxDate" v-model="showPickerApply"
                                  @confirm="onConfirmApply($event)"/>

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
                            <van-icon name="delete-o" @click="handleDelete(index)"/>
                        </div>

                        <van-field
                                v-model="item.materialSpecification"
                                label="规格型号"
                                disabled
                        />

                        <van-field
                                v-model="item.materialUnit"
                                label="单位"
                                disabled
                        />

                        <van-field
                                readonly
                                clickable
                                v-model="item.supplierName"
                                label="供应商"
                                placeholder="请选择"
                                :required="true"
                                :rules="[{ required: true, message: '请选择供应商' }]"
                                @click="handlePop('showPickerSupplier',index)"
                        />

                        <van-field
                                v-model="item.price"
                                label="单价"
                                placeholder="请输入"
                                :required="true"
                                :rules="[{ required: true, message: '请输入单价' }]"
                                @input="itemTotalPriceCal(item)"
                        />

                        <van-field
                                v-model="item.num"
                                label="数量"
                                placeholder="请输入"
                                :required="true"
                                :rules="[{ required: true, message: '请输入数量' }]"
                                @input="itemTotalPriceCal(item)"
                        />

                        <van-field
                                v-model="item.total"
                                label="总金额"
                                disabled
                        />

                        <van-field
                                v-model="item.remark"
                                label="备注"
                                placeholder="请输入"
                        />

                    </div>
                    <div class="add-btn flex-hor-ver-cen" @click="handleAdd">
                        <van-icon name="add-o" class="mrg-r-20"/>
                        添加物料
                    </div>

                    <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <Supplier :showDialog="showPickerSupplier" @handleClose="showPickerSupplier = false" @submitData="submitSupplier"/>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>

        <Material :showDialog="showPickerMaterial" @handleClose="handleCloseMaterial" @submitData="submitMaterial"/>
    </div>
</template>
<script>
    import {addData, selectPlanOne, updateData} from '@/api/purchase/plan';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import Supplier from '@/components/supplier/index'
    import SelectOneUser from '@/components/selectOneUser/index'
    import Material from "@/components/material/index";
    import {changeToCurrentDay, formatDate} from "@/utils/ruoyi";
    import {submiteUpload} from "@/api/system/config";

    export default {
        components: {Material, AppendixVue, Supplier, SelectOneUser},
        data() {
            return {
                formInline: {},
                title: '新建计划',
                id: this.$route.query.id,
                type: this.$route.query.type,
                uploader: [],
                uploadParams: {
                    businessType: 'pur_plan',
                    businessId: undefined,
                    file: null,
                },
                showPickerType: false,
                showPickerSupplier: false,
                showPickerMaterial: false,
                showPop: false,
                showPickerApply: false,
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(2050, 12, 31),
                //物料数组
                dataList: [],
                //采购类型
                typeOptions: [],
                //选中的明细索引
                itemIndex: null
            };
        },

        mounted() {
            //采购类型
            this.getDicts('purchase_type').then((response) => {
                this.typeOptions = response.data;
            });
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/purchase/purchasePlan');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        enterCategory: 0
                    };
                    this.title = '新建计划';
                } else {
                    let itemDetail = await selectPlanOne(this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    this.dataList = itemDetail.purAnticipatePlanSubDisplays
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑计划';
                    } else {
                        this.title = '计划详情';
                    }
                }
            },

            //确认
            onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //日期提交
            onConfirmTime(value) {
                this.formInline.purchaseDate = changeToCurrentDay(value);
                this.showPickerApply = false;
            },

            //选择人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.purchaseId = activeId
                this.formInline.purchaseName = names
            },

            //关闭选择人
            handleCloseUser() {
                this.showPop = false
            },

            //日期确认
            onConfirmApply(value) {
                this.formInline.purchaseDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerApply = false;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            //触发明细弹窗
            handlePop(showPicker,index) {
                this[showPicker] = true;
                this.itemIndex = index
            },

            //供应商提交
            submitSupplier(id, name) {
                this.dataList[this.itemIndex].supplierId = id
                this.dataList[this.itemIndex].supplierName = name
                this.showPickerSupplier = false
                this.$forceUpdate()
            },

            //删除节点
            handleDelete(index) {
                this.dataList.splice(index, 1);
            },

            //添加节点
            handleAdd() {
                this.showPickerMaterial = true;
            },

            //关闭物料弹窗
            handleCloseMaterial() {
                this.showPickerMaterial = false
            },

            //物料提交
            submitMaterial(obj) {
                let oldMaterial = this.dataList.find(item => item.materialId == obj.id)
                if (!oldMaterial) {
                    this.dataList.push({
                        materialId: obj.id,
                        materialName: obj.materialName,
                        materialCode: obj.materialCode,
                        materialSpecification: obj.materialSpecification,
                        materialUnit: obj.materialUnit,
                        supplierName: '',
                        price: 0,
                        num: 0,
                        total: 0,
                        remark: ''
                    });
                }

                this.handleCloseMaterial()
            },

            //物料单条明细总金额计算
            itemTotalPriceCal(item) {
                item.total = ((item.num ? item.num : 0) * (item.price ? item.price : 0)).toFixed(2)
                this.priceCal();
            },

            //总价计算
            priceCal() {
                let purchasePrice = 0
                this.dataList.forEach(item => {
                    purchasePrice += Number(item.total)
                })
                this.formInline.purchasePrice = purchasePrice
            },

            async onSubmit() {
                if (this.dataList.length == 0) {
                    this.$toast.fail('请添加采购物料');
                    return;
                }

                this.dataList.forEach(item => {
                    delete item.createBy
                    delete item.createTime
                    delete item.updateBy
                    delete item.updateTime
                    delete item.companyId
                })

                this.formInline.purAnticipatePlanSubs = this.dataList
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateData(data) : await addData(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if(this.uploadParams.file){
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
