import request from '@/utils/request'

// 查询采购退款列表
export function getAllProPaymentList(query){
  return request({
    url: "/cw/proPayment/getList",
    method: "get",
    params: query
  });
}
// 查询采购退款列表
export function getProPaymentInfo(query){
  return request({
    url: "/cw/proPayment/getId",
    method: "get",
    params: query
  });
}
// 新增退款子计划
export function addProPaymentSub(data){
  return request({
    url: "/cw/proPayment/addReturnSub",
    method: "post",
    data
  });
}

export function delProPaymentSub(subId){
  return request({
    url: "/cw/proPayment/deleteReturnSub",
    method: "delete",
    params: subId
  });
}

