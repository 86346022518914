<template>
    <div class="app-container">
        <navBar :title="'竞争对手详情'" @handleGoBack="handleGoBack"/>

        <div class="item-tab">
            <div class="title-wrap">
                <div class="flex-spa-ver-cen">
                    <div class="tit over_hidden">{{ detailObj.name || '--' }}</div>
                    <div class="level-btn">{{ detailObj.levelName || '--' }}</div>
                </div>
                <div class="time">创建时间：{{ detailObj.createTime || '--' }}</div>
            </div>
            <div class="con">
                <div class="desc-wrap flex">
                    <div class="label">企业性质：</div>
                    <div class="value">{{ detailObj.natureName || '--' }}</div>
                </div>
                <div class="desc-wrap flex">
                    <div class="label">法人代表：</div>
                    <div class="value">{{ detailObj.legalPerson || '--' }}</div>
                </div>
                <div class="desc-wrap flex">
                    <div class="label">注册资本：</div>
                    <div class="value">{{ detailObj.capital || '--' }}</div>
                </div>
            </div>
        </div>

        <van-tabs v-model="activeName">
            <van-tab title="详细资料" name="a">
                <baseInfoVue/>
            </van-tab>
            <van-tab title="相关附件" name="c">
                <AppendixVue :businessType="'crmcompete'"/>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import baseInfoVue from './components/baseInfo.vue';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import {COLLECTION_STATUS} from "@/views/constant";
    import {getDetailByCompeteId} from "@/api/crm/competitor";

    export default {
        components: {
            baseInfoVue,
            AppendixVue
        },

        data() {
            return {
                activeName: 'a',
                dataId: this.$route.query.id,
                detailObj: {},
                collectColor: COLLECTION_STATUS,
            };
        },

        mounted() {
            this.getDetail();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/crm/compete');
                this.$router.go(-1)
            },

            async getDetail() {
                const result = await getDetailByCompeteId({id: this.dataId});
                const {data = {}} = result;
                this.detailObj = data;
                this.$store.commit('customer/SET_COMPETE_DETAIL', JSON.stringify(data));
            },
        }

    };
</script>

<style lang="scss" scoped>
  .item-tab {
    background: #fff;
    padding: 0 33px;
    margin-top: 20px;

    .title-wrap {
      padding: 26px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 0;
        flex: 1;
      }

      .level-btn {
        width: 80px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }

      .time {
        font-size: 24px;
        margin-top: 10px;
        color: #999999;
      }
    }

    .con {
      padding: 30px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 30px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }
  }

  ::v-deep .van-tabs--line .van-tabs__wrap {
    height: 80px !important;
  }

  ::v-deep .van-tab {
    // padding: 40px 0;
    height: 80px !important;
    line-height: 80px !important;
    padding-bottom: 0 !important;
  }
</style>
