import request from '@/utils/request'

// 查询采购发票列表
export function getProInvoiceList(query){
  return request({
    url: "/cw/proInvoice/getList",
    method: "get",
    params: query
  });
}
// 查询采购发票详情
export function getAllInvoiceHeadInfo(query){
  return request({
    url: "/cw/proInvoice/getId",
    method: "get",
    params: query
  });
}
// 新建
export function addCrmInvoice(data){
  return request({
    url: "/cw/proInvoice/addCrmInvoice",
    method: "post",
    data: data
  });
}
// 修改
export function updateCrmInvoice(data){
  return request({
    url: "/cw/proInvoice/updateCrmInvoice",
    method: "put",
    data: data
  });
}
// 删除
export function deleteProInvoice(invoiceIds){
  return request({
    url: "/cw/proInvoice/deleteCrmInvoice/"+invoiceIds,
    method: "delete"
  });
}
// 开票
export function openInvoice(invoiceId){
  return request({
    url: "/cw/proInvoice/open",
    method: "put",
    params: invoiceId
  });
}
