<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="'新建跟进'"> </navBar>
      <div class="add-form">
        <div class="line"></div>
        <van-form validate-first @failed="onFailed">
          <van-field
            readonly
            clickable
            name="picker"
            v-model="formInline.source"
            label="跟进方式"
            placeholder="请选择"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>

          <van-field
            readonly
            clickable
            name="calendar"
            :value="formInline.time"
            label="开始时间"
            placeholder="点击选择日期"
            @click="showCalendar = true"
          />
          <van-calendar v-model="showCalendar" @confirm="onConfirm" />
          <van-field
            readonly
            clickable
            name="calendar"
            :value="formInline.time"
            label="结束时间"
            placeholder="点击选择日期"
            @click="showCalendar = true"
          />
          <van-calendar v-model="showCalendar" @confirm="onConfirm" />

          <textAreaInput />

          <van-field name="radio" label="是否远程">
            <template #input>
              <van-radio-group v-model="formInline.radio" direction="horizontal">
                <van-radio name="1">是 </van-radio>
                <van-radio name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field
            v-model="formInline.name"
            label="远程时长"
            name="asyncValidator"
            placeholder="请输入"
            :rules="[{ required: true, message: '请输入远程时长' }]"
          />

          <van-field name="radio" label="是否通话">
            <template #input>
              <van-radio-group v-model="formInline.radio" direction="horizontal">
                <van-radio name="1">是 </van-radio>
                <van-radio name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field
            v-model="formInline.name"
            label="通话时长"
            name="asyncValidator"
            placeholder="请输入"
            :rules="[{ required: true, message: '请输入通话时长' }]"
          />
          <van-field name="radio" label="微信/QQ">
            <template #input>
              <van-radio-group v-model="formInline.radio" direction="horizontal">
                <van-radio name="1">微信 </van-radio>
                <van-radio name="2">QQ</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field
            v-model="formInline.name"
            label="聊天记录"
            name="asyncValidator"
            placeholder="请输入"
            :rules="[{ required: true, message: '请输入聊天记录' }]"
          />
          <van-field name="radio" label="是否线下会谈">
            <template #input>
              <van-radio-group v-model="formInline.radio" direction="horizontal">
                <van-radio name="1">是 </van-radio>
                <van-radio name="2">否 </van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field
            v-model="formInline.name"
            label="会谈有效时长"
            name="asyncValidator"
            placeholder="请输入"
            :rules="[{ required: true, message: '请输入会谈有效时长' }]"
          />

          <van-field
            v-model="formInline.name"
            label="聊天记录"
            name="asyncValidator"
            placeholder="请输入"
            :rules="[{ required: true, message: '请输入聊天记录' }]"
          />
          <van-field name="radio" label="是否报价">
            <template #input>
              <van-radio-group v-model="formInline.radio" direction="horizontal">
                <van-radio name="1">是 </van-radio>
                <van-radio name="2">否 </van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <div class="line"></div>
          <van-field name="uploader" label="附件">
            <template #input>
              <van-uploader v-model="uploader" />
            </template>
          </van-field>
          <van-button class="btn" block type="info" native-type="submit">提交</van-button>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import { areaList } from '@vant/area-data';
export default {
  data() {
    return {
      formInline: {
        name: '',
        sourse: null,
        email: null,
        phone: null,
        level: null,
        industry: null,
        area: null,
        address: null,
        time: null,
        remark: null,
      },
      pattern: /\d{6}/,
      showPicker: false,
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      customerList: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      customerIndustry: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      areaList,
      showArea: false,
      showCalendar: false,
      uploader: [],
    };
  },

  methods: {
    handleGoBack() {
        this.$router.replace({path: '/crm/customer', replace: true});
    },

    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },

    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      return /1\d{10}/.test(val);
    },

    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        Toast.loading('验证中...');

        setTimeout(() => {
          Toast.clear();
          resolve(/\d{6}/.test(val));
        }, 1000);
      });
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}
// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}
::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}
// 导航样式 --end

::v-deep .van-radio__label {
  line-height: 100px;
}

::v-deep .van-radio__icon .van-icon {
  width: none;
  height: none;
  line-height: none;
}
::v-deep .van-radio__icon {
  line-height: 0;
}
</style>
