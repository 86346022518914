<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title+'联系人'" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <div class="line"></div>
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="姓名"
                            name="pattern"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入正确内容' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            name="customerId"
                            v-model="formInline.customerName"
                            label="客户名称"
                            placeholder="请选择"
                            @click="showCustomerDialog"
                            :required="true"
                            :rules="[{ required: true, message: '请输入正确内容' }]"
                    />
                    <van-field
                            v-model="formInline.phone"
                            label="手机"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.email"
                            label="邮箱"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            readonly
                            clickable
                            name="industry"
                            v-model="formInline.postStr"
                            label="职务"
                            placeholder="请选择"
                            @click="showPickerPost = true"
                    />
                    <van-popup v-model="showPickerPost" position="bottom">
                        <van-picker
                                show-toolbar
                                value-key="dictLabel"
                                :columns="postOptions"
                                @confirm="
                onConfirm($event, 'post', 'postStr', 'dictLabel', 'showPickerPost')
              "
                                @cancel="showPickerPost = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.isCruxName"
                            label="是否首要"
                            placeholder="请选择"
                            @click="showPickerFirst = true"
                    />
                    <van-popup v-model="showPickerFirst" position="bottom">
                        <van-picker
                                show-toolbar
                                value-key="dictLabel"
                                :columns="isCruxOptions"
                                @confirm="
                onConfirm($event, 'isCrux', 'isCruxName', 'dictLabel', 'showPickerFirst')
              "
                                @cancel="showPickerFirst = false"
                        />
                    </van-popup>
                    <van-field
                            v-model="formInline.address"
                            label="地址"
                            placeholder="请输入"
                    />
                    <van-field
                            readonly
                            clickable
                            :value="formInline.nextContactTime2"
                            label="下次联系时间"
                            placeholder="点击选择日期"
                            @click="showCalendarNext = true"
                    />
                    <van-calendar v-model="showCalendarNext" @confirm="onConfirmNextDate"/>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.sexName"
                            label="性别"
                            placeholder="请选择"
                            @click="showPickerSex = true"
                    />
                    <van-popup v-model="showPickerSex" position="bottom">
                        <van-picker
                                show-toolbar
                                value-key="dictLabel"
                                :columns="sexOptions"
                                @confirm="
                onConfirm($event, 'sex', 'sexName', 'dictLabel', 'showPickerSex')
              "
                                @cancel="showPickerSex = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            name="calendar"
                            :value="formInline.birthday2"
                            label="生日"
                            placeholder="点击选择日期"
                            @click="showCalendarBirthday = true"
                    />
                    <van-calendar v-model="showCalendarBirthday" @confirm="onConfirmBirthday"
                                  :min-date="new Date('1970-01-01')"/>
                    <div class="line"></div>

                    <van-field
                            v-model="formInline.remark"
                            rows="1"
                            autosize
                            label="备注"
                            placeholder="请输入"
                            type="text"
                    />
                    <van-button class="btn" block type="info" native-type="submit">提交</van-button>
                </van-form>
            </div>
        </div>
        <CustomerPop
                :customerVisible="customerVisible"
                @submitCustomerList="submitCustomerList"
                @handleClose="handleClose"
        />
    </div>
</template>
<script>
    import {addContacts, updateContacts} from "@/api/crm/contacts";
    import CustomerPop from "@/components/crm/CustomerPop";

    export default {
        components: {
            CustomerPop
        },
        data() {
            return {
                //性别
                sexOptions: [
                    {dictLabel: '女', dictValue: '女'},
                    {dictLabel: '男', dictValue: '男'}
                ],
                //是否关键联系人
                isCruxOptions: [
                    {dictLabel: '是', dictValue: true},
                    {dictLabel: '否', dictValue: false}
                ],
                title: '新增',
                //职务
                postOptions: [],
                formInline: {
                    sex: '男',
                    isCrux: false
                },
                showPickerSex: false,
                showPickerFirst: false,
                pattern: /\d{6}/,
                //下次联系时间
                showCalendarNext: false,
                //生日
                showCalendarBirthday: false,
                //职务
                showPickerPost: false,
                //客户弹窗
                customerVisible: false,
            };
        },

        mounted() {
            //职务
            this.getDicts('duty').then((response) => {
                this.postOptions = response.data;
            });

            let type = this.$route.query.type
            if (type && type == 'customer') {
                let itemDetail = this.$route.query.obj
                //从客户管理中新增联系人
                this.formInline.customerId = itemDetail.id
                this.formInline.customerName = itemDetail.name
                this.title = '新增'
            } else {
                let itemDetail = this.$route.query.obj ? JSON.parse(this.$route.query.obj) : '';
                if (itemDetail) {
                    this.formInline = itemDetail;

                    this.formInline.isCruxName = this.formInline.isCrux ? '是' : '否'
                    this.formInline.sexName = this.formInline.sex
                    this.formInline.birthday2 = this.formInline.birthday
                    this.formInline.nextContactTime2 = this.formInline.nextContactTime

                    delete this.formInline.leaderId;

                    this.title = '编辑';
                } else {
                    this.title = '新增';
                }
            }
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/crm/contacts');
                this.$router.go(-1)
            },

            //展示选择客户的弹窗
            showCustomerDialog() {
                this.customerVisible = true;
            },

            //客户提交
            submitCustomerList(id, name) {
                this.formInline.customerId = id;
                this.formInline.customerName = name;
                this.customerVisible = false
            },

            //关闭选择客户的弹窗
            handleClose() {
                this.customerVisible = false;
            },

            onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //下次联系时间
            onConfirmNextDate(date) {
                this.showCalendarNext = false;
                this.formInline.nextContactTime2 = this.formatDate(date, 'yy-MM-dd');
                this.formInline.nextContactTime = this.formatDate(date);
            },

            //生日
            onConfirmBirthday(date) {
                this.showCalendarBirthday = false;
                this.formInline.birthday2 = this.formatDate(date, 'yy-MM-dd');
                this.formInline.birthday = this.formatDate(date);
            },

            formatDate(date, key) {
                if (key) {
                    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                } else {
                    return `${date.getFullYear()}-${
                        date.getMonth() + 1
                    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}
      `;
                }
            },

            async onSubmit() {
                const result = this.formInline.id ? await updateContacts(this.formInline) : await addContacts(this.formInline);
                if (result.code == 200) {
                    this.$toast(result.message);
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
