import request from "@/utils/request";
import { serverUrl } from "@/utils/request";

// 查询入库列表
export function selectDirectInPage(query) {
  return request({
    url: "/inventory/entering/selectPage",
    method: "get",
    params: query
  });
}

// 上传附件
export function importImg(data) {
  return request({
    url: "/system/file",
    method: "post",
    data
  });
}

// 查询出库列表
export function selectDirectOutPage(query) {
  return request({
    url: "/inventory/expend/selectPage",
    method: "get",
    params: query
  });
}

// 新增
export function addInStock(data) {
  return request({
    url: "inventory/entering/directWarehousing",
    method: "post",
    data
  });
}
// 修改
export function updateInStock(data) {
  return request({
    url: "inventory/entering/updateWarehousing",
    method: "put",
    data
  });
}
// 删除直接入库
export function delDirectInList(id) {
  return request({
    url: "/inventory/entering/delDirect/" + id,
    method: "delete"
  });
}

// 查询所有入库单的所有入库明细
export function fetchInWareDetail(params) {
  return request({
    url: "/inventory/entering/selectAllDetial",
    method: "get",
    params
  });
}

// 删除
export function delWaitInList(id) {
  return request({
    url: "/inventory/entering/" + id,
    method: "delete"
  });
}

// 请求单条数据
export function fetchWaitInDetail(params) {
  return request({
    url: "/inventory/entering/selectOne",
    method: "get",
    params
  });
}

// 待入库新增
export const addWaitInStock = data => {
  return request({
    url: "/inventory/entering/addWarehousing",
    method: "post",
    data
  });
};
// 待入库修改
export function updateWaitInStock(data) {
  return request({
    url: "/inventory/entering/updateWarehousing",
    method: "put",
    data
  });
}

// 直接出库新增
export const addOutStock = data => {
  return request({
    url: "/inventory/expend/directExpend",
    method: "post",
    data
  });
};

// 直接出库修改
export function updateOutStock(data) {
  return request({
    url: "/inventory/entering/updateWarehousing",
    method: "put",
    data
  });
}

// 待出库新增
export const addWaitOutStock = data => {
  return request({
    url: "/inventory/expend/addExpend",
    method: "post",
    data
  });
};

// 待出库修改
export function updateWaitOutStock(data) {
  return request({
    url: "/inventory/expend/updateExpend",
    method: "put",
    data
  });
}

// 审批弹窗提交请求
export function submitAppvoral(data) {
  return request({
    url: "/inventory/entering/approve/",
    method: "put",
    data
  });
}

// 提交操作
export function dataSubmit(id) {
  return request({
    url: "/inventory/entering/commit/" + id,
    method: "put"
  });
}

// 查询入库明细
export function fetchEnterMagDetail(params) {
  return request({
    url: "/inventory/entering/selectOne",
    method: "get",
    params
  });
}

export const confirmInWare = data => {
  return request({
    url: "/inventory/entering/waitWarehousing",
    method: "put",
    data
  });
};

// 出库提交
export const dataOutSubmit = id => {
  return request({
    url: "/inventory/expend/commit/" + id,
    method: "put"
  });
};

// 删除直接出库
export function delDirectOutList(id) {
  return request({
    url: "/inventory/expend/delDirect/" + id,
    method: "delete"
  });
}

// 出库删除
export const delWaitOutList = id => {
  return request({
    url: "/inventory/expend/" + id,
    method: "delete"
  });
};

// 待出库
export const fetchWaitOutDetail = params => {
  return request({
    url: "/inventory/expend/selectOne",
    method: "get",
    params
  });
};

// 出库审核
export const submitOutAppvoral = data => {
  return request({
    url: "inventory/expend/approve",
    method: "put",
    data
  });
};

// 出库管理回显
export const fetchExpendDetail = params => {
  return request({
    url: "inventory/expend/selectOne",
    method: "get",
    params
  });
};

// 确认出库信息
export const updateOutMagOrder = data => {
  return request({
    url: "inventory/expend/waitExpend",
    method: "post",
    data
  });
};

// 查询出库明细
export const fetchOutWareDetail = params => {
  return request({
    url: "inventory/expend/selectAllDetial",
    method: "get",
    params
  });
};

// 查询出库记录
export const selectRecordOutPage = params => {
  return request({
    url: "inventory/expend/selectAllEnterRecord",
    method: "get",
    params
  });
};

// 查询出库记录详情页
export function fetchExpendRecordDetail(id) {
  return request({
    url: "inventory/expend/selectMaterialsByIds/" + id,
    method: "get"
  });
}

// 发货单
export const confirmDelivery = data => {
  return request({
    url: "inventory/deliver/confirmDelivery",
    method: "post",
    data
  });
};

// 发货管理
export function fetchInvoiceDetail(params) {
  return request({
    url: "inventory/deliver/selectPage",
    method: "get",
    params
  });
}

// 调拨管理列表
export function selectAllotPage(params) {
  return request({
    url: "inventory/transfer/selectPage",
    method: "get",
    params
  });
}

// 调拨管理提交
export const dataAllotSubmit = params => {
  return request({
    url: "/inventory/transfer/commit",
    method: "put",
    params
  });
};
// 调拨管理修改
export const updateAllotStock = data => {
  return request({
    url: "inventory/transfer/updateTransfer",
    method: "put",
    data
  });
};

// 调拨管理删除
export const delAllotList = id => {
  return request({
    url: "inventory/transfer/" + id,
    method: "delete"
  });
};

// 调拨管理新增
export const addAllotStock = data => {
  return request({
    url: "inventory/transfer",
    method: "post",
    data
  });
};

// 调拨管理根据ID回显
export const fetchTransferDetail = params => {
  return request({
    url: "inventory/transfer/selectOne",
    method: "get",
    params
  });
};

// 调拨单审批通过的回调
export function passApproveTransfer(transferId) {
  return request({
    url: "/inventory/transfer/approvePass/"+transferId,
    method: "put"
  });
}

// 调拨单到货
export function arrival(transferId) {
  return request({
    url: "/inventory/transfer/arrival/"+transferId,
    method: "put"
  });
}
