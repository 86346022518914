<template>
    <div class="app-container">
        <van-popup v-model="show" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="rangeType"
                    @confirm="onConfirmRange"
                    @cancel="handleClose"
            />
        </van-popup>

        <!--选择部门-->
        <department :showPop="chooseDeptPicker" @handleClose="handleCloseDept"/>

        <!--选择用户-->
        <chooseUser :showPop="chooseUserPicker" @handleClose="handleCloseUser"/>
    </div>
</template>

<script>
    import department from './department';
    import chooseUser from './chooseUser';

    export default {
        components: {chooseUser, department},
        props: {
            //适用人群弹窗
            showRangePicker: Boolean,
        },
        data() {
            return {
                show: false,
                //适用类型
                rangeType: ['所有人', '用户', '部门'],
                formInline: {
                    applyType: 0, //适用类型
                    applyTypeStr: '', //适用类型汉字
                    rangeIdList: [], //部门/用户 id
                    rangeStr: '', //部门/用户 汉字
                },
                //选择部门弹窗
                chooseDeptPicker: false,
                //选择用户弹窗
                chooseUserPicker: false,
                //选中部门/用户的数组
                dataList: [],
            };
        },
        watch: {
            showRangePicker: {
                handler() {
                    this.show = this.showRangePicker;
                },
                immediate: true,
                deep: true,
            },
            show: {
                handler() {
                    if (!this.show) {
                        this.handleClose();
                    } else {
                        this.dataList = []
                    }
                },
                immediate: true,
                deep: true,
            },
        },
        methods: {
            //打开适用人群弹窗
            onConfirmRange(value) {
                this.formInline.applyTypeStr = value;
                let applyType;
                if (value == '所有人') {
                    applyType = 0;
                    this.handleClose();
                } else if (value == '部门') {
                    applyType = 1;
                    this.chooseDeptPicker = true;
                } else if (value == '用户') {
                    applyType = 3;
                    this.chooseUserPicker = true;
                }
                this.formInline.applyType = applyType;
            },

            //用户弹窗关闭
            handleCloseUser(list) {
                list.forEach((item) => {
                    this.dataList.push({id: item.id, name: item.realname});
                });
                this.chooseUserPicker = false;
                this.handleClose();
            },

            //部门弹窗关闭
            handleCloseDept(list) {
                list.forEach((item) => {
                    this.dataList.push({id: item.id, name: item.name});
                });
                this.chooseDeptPicker = false;
                this.handleClose();
            },

            //取消/关闭弹窗
            handleClose() {
                this.show = false;
                this.$emit('handleClose', this.dataList, this.formInline.applyType);
            },
        },
    };
</script>
