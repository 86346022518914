<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'会议室列表'" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-icon name="plus" class="mrg-r-20" @click="handleAdd"/>
                </template>
            </navBar>

            <div class="main">
                <van-list
                        v-model="loading"
                        offset="100"
                        :finished="true"
                >
                    <div class="list" v-for="item in dataList" :key="item.id">
                        <div class="line"></div>
                        <div class="item-tab" @click="handleGoDetail(item.id)">
                            <div class="title-wrap flex-spa-ver-cen">
                                <div class="tit over_hidden">
                                    {{ item.name || '--' }}
                                </div>
                            </div>
                            <div class="con">
                                <div class="desc-wrap flex">
                                    <div class="label">创建人：</div>
                                    <div class="value">{{ item.createBy || '--' }}</div>
                                </div>
                                <div class="desc-wrap flex">
                                    <div class="label">容纳人数：</div>
                                    <div class="value">{{ item.number || '--' }}</div>
                                </div>
                                <div class="desc-wrap flex">
                                    <div class="label">会议室设备：</div>
                                    <div class="value red">{{ item.equipment || '--' }}</div>
                                </div>
                                <div class="desc-wrap flex">
                                    <div class="label">会议室描述：</div>
                                    <div class="value" v-html="item.roomDescribe"></div>
                                </div>
                            </div>
                            <div class="time-wrap flex-spa-ver-cen" @click.stop="operateAction(item.id)">
                                <div class="time">{{ item.createTime || '--' }}</div>
                                <van-icon name="weapp-nav"/>
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
        <van-action-sheet
                v-model="actionShow"
                :actions="actions"
                @select="onSelect"
                cancel-text="取消"
                close-on-click-action
        />
    </div>
</template>

<script>
    import {deleteRoom, getListRoom} from '@/api/oa/meeting';

    export default {
        data() {
            return {
                loading: false,
                dataList: [],
                //操作的动作面板
                actionShow: false,
                //操作面板的操作
                actions: [
                    {name: '编辑', className: 'customer-opea-action'},
                    {name: '删除', className: 'customer-opea-action'}
                ],
                itemId: null,
            };
        },

        mounted() {
            this.getList()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/oa/oaWorkbench');
                this.$router.go(-1)
            },

            async getList() {
                this.loading = true;
                let result = await getListRoom();
                this.dataList = result.data;
                this.loading = false;
            },

            handleAdd() {
                // 新增页面
                this.$router.push({
                    path: '/oa/meeting/add',
                });
            },

            handleGoDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/oa/meeting/detail',
                    query: {
                        id
                    },
                });
            },

            //点击操作展示动作面板
            operateAction(id) {
                this.itemId = id;
                this.actionShow = true;
            },

            //操作的动作面板被点击
            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                switch (item.name) {
                    case '编辑':
                        this.handleEdit();
                        break;
                    case '删除':
                        this.handleDelete();
                        break;
                }
            },

            // 编辑
            handleEdit() {
                const obj = this.dataList.find((ele) => ele.id == this.itemId);

                // 新增页面
                this.$router.push({
                    path: '/oa/meeting/add',
                    query: {
                        obj: JSON.stringify(obj),
                    },
                });
            },

            // 删除
            handleDelete() {
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认此会议室?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await deleteRoom(this.itemId);
                        this.$toast.success('删除成功');
                        await this.getList();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },


        },
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        line-height: 38px;
        width: 0;
        flex: 1;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          white-space: nowrap;
          color: #999;
        }

        .value {
          color: #111;
          white-space: nowrap;
          overflow: hidden;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;
    }
  }

  ::v-deep .van-tabs__line {
    background-color: #0091ff;
  }

  .transfer-select-wrap {
    font-size: 28px;
  }

  ::v-deep .van-checkbox__label {
    line-height: 40px;
    font-size: 28px;
    padding-top: 10px;
  }

  ::v-deep .van-checkbox__icon .van-icon {
    width: 28px;
    height: 28px;
  }
</style>
