import request from "@/utils/request";
// 查询客户下所有销售退货管理
export function fetchOrderListByCustomerId(params) {
  return request({
    url: "/crm/return/getOrder",
    method: "get",
    params
  });
}

// 获取销售退货抬头
export function fetchHeadList(params) {
  return request({
    url: "/cw/invoiceHead/getList",
    method: "get",
    params
  });
}

// 新建销售退货
export function addCrmReturn(data) {
  return request({
    url: "/crm/return/addReturn",
    method: "post",
    data
  });
}
// 获取数量 fetchCount
export function fetchCount(query) {
  return request({
    url: "/crm/return/getNumber",
    method: "get"
  });
}
// 获取查询列表
export function selectPage(query) {
  return request({
    url: "crm/return/getList",
    method: "get",
    params: query
  });
}
// 删除
export function delList(ids) {
  return request({
    url: "/crm/return/deleteReturn/" + ids,
    method: "delete"
  });
}

// 提交销售退货管理
export function dataReturnSubmit(params) {
  return request({
    url: "/crm/return/submitReturn",
    method: "put",
    params
  });
}

// 获取单条信息
export function selectItemByReturn(params) {
  return request({
    url: "/crm/return/getId",
    method: "get",
    params
  });
}

// 更新销售退货
export function updateCrmReturn(data) {
  return request({
    url: "/crm/return/updateReturn",
    method: "put",
    data
  });
}

//
export function getStatisticsAdd(params) {
  return request({
    url: "/crm/saleWork/getStatisticsAdd",
    method: "get",
    params
  });
}

// 审核
export function disAppvoral(data) {
  return request({
    url: "/crm/return/inspectReturn",
    method: "put",
    data
  });
}

// 获取单条信息
export function getOrderDetails(params) {
  return request({
    url: "/crm/return/getOrderDetails",
    method: "get",
    params
  });
}
