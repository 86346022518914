import request from '@/utils/request'

// 查询采购退款列表
export function getAllPurReturnList(query){
  return request({
    url: "/cw/purReturn/getList",
    method: "get",
    params: query
  });
}
// 查询采购退款列表
export function getAllPurReturnInfo(query){
  return request({
    url: "/cw/purReturn/getId",
    method: "get",
    params: query
  });
}
// 新增退款子计划
export function addReturnSub(data){
  return request({
    url: "/cw/purReturn/addReturnSub",
    method: "post",
    data
  });
}

export function delReturnSub(cwReturnSubId){
  return request({
    url: "/cw/purReturn/deleteReturnSub",
    method: "delete",
    params: cwReturnSubId
  });
}

