<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.title"
                            label="合同标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入合同标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.ourRepresentativeName"
                            label="我方代表"
                            placeholder="请选择"
                            @click="showPop= true"
                    />

                    <van-field
                            v-model="formInline.oppositeRepresentative"
                            label="对方代表"
                            placeholder="请输入"
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.startTime"
                            label="开始日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择始日期' }]"
                            @click="showPickerBegin = true"
                    />
                    <van-calendar :min-date="minDate" :max-date="maxDate" v-model="showPickerBegin"
                                  @confirm="onConfirmBegin($event)"/>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.endTime"
                            label="结束日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择结束日期' }]"
                            @click="showPickerEnd = true"
                    />
                    <van-calendar :min-date="minDate" :max-date="maxDate" v-model="showPickerEnd"
                                  @confirm="onConfirmEnd($event)"/>

                    <van-field
                            disabled
                            v-model="orderMsg.typeName"
                            label="采购订单"
                    />

                    <van-field
                            disabled
                            v-model="orderMsg.typeName"
                            label="采购类型"
                    />

                    <van-field
                            disabled
                            v-model="orderMsg.purchaseName"
                            label="采购人"
                    />

                    <van-field
                            disabled
                            :value="orderMsg.purchaseDate"
                            label="采购日期"
                    />


                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <van-field
                            disabled
                            :value="orderMsg.purchasePrice"
                            label="物料金额"
                    />

                    <van-field
                            disabled
                            v-model="orderMsg.discountPrice"
                            label="优惠金额"
                    />

                    <van-field
                            disabled
                            v-model="orderMsg.otherPrice"
                            label="其他费用"
                    />

                    <van-field
                            disabled
                            :value="orderMsg.price"
                            label="总金额"
                    />

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
                        </div>

                        <van-field
                                v-model="item.materialSpecification"
                                label="规格型号"
                                disabled
                        />

                        <van-field
                                v-model="item.materialUnit"
                                label="单位"
                                disabled
                        />

                        <van-field
                                disabled
                                v-model="item.supplierName"
                                label="供应商"
                        />

                        <van-field
                                v-model="item.price"
                                label="单价"
                                disabled
                        />

                        <van-field
                                v-model="item.num"
                                label="数量"
                                disabled
                        />

                        <van-field
                                v-model="item.total"
                                label="总金额"
                                disabled
                        />

                        <van-field
                                disabled
                                v-model="item.remark"
                                label="备注"
                                placeholder="请输入"
                        />

                    </div>

                    <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>
        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>
    </div>
</template>
<script>
    import {addData, selectOne, updateData} from '@/api/purchase/contract';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import {submiteUpload} from "@/api/system/config";
    import SelectOneUser from '@/components/selectOneUser/index'
    import {formatDate} from "@/utils/ruoyi";
    import {selectOrderOne} from "@/api/purchase/order";

    export default {
        components: {AppendixVue,SelectOneUser},
        data() {
            return {
                formInline: {},
                orderMsg: {},
                title: '新建合同',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPop: false,
                showPickerBegin: false,
                showPickerEnd: false,
                uploader: [],
                uploadParams: {
                    businessType: 'pur_contract',
                    businessId: undefined,
                    file: null,
                },
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(2050, 12, 31),
                //物料数组
                dataList: []
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
                this.$router.replace('/purchase/purchaseContract');
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {};

                    //根据订单id查询订单信息
                    selectOrderOne(this.id).then(
                        response => {
                            this.formInline.orderId = this.id
                            this.orderMsg.title = response.data.title
                            this.orderMsg.typeName = response.data.typeName
                            this.orderMsg.purchaseName = response.data.purchaseName
                            this.orderMsg.purchaseDate = response.data.purchaseDate
                            this.orderMsg.purchasePrice = response.data.purchasePrice
                            this.orderMsg.discountPrice = response.data.discountPrice
                            this.orderMsg.otherPrice = response.data.otherPrice
                            this.orderMsg.price = response.data.price
                            this.dataList = response.data.purAnticipatePlanSubDisplays
                        }
                    )
                    this.title = '新建合同';
                } else {
                    let itemDetail = await selectOne(this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;

                    //订单信息
                    this.orderMsg.title = itemDetail.orderTitle
                    this.orderMsg.typeName = itemDetail.typeName
                    this.orderMsg.purchaseName = itemDetail.purchaseName
                    this.orderMsg.purchaseDate = itemDetail.purchaseDate
                    this.orderMsg.purchasePrice = itemDetail.purchasePrice
                    this.orderMsg.discountPrice = itemDetail.discountPrice
                    this.orderMsg.otherPrice = itemDetail.otherPrice
                    this.orderMsg.price = itemDetail.price

                    this.dataList = itemDetail.purAnticipatePlanSubDisplays
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑合同';
                    } else {
                        this.title = '合同详情';
                    }
                }
            },

            //选择人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.ourRepresentative = activeId
                this.formInline.ourRepresentativeName = names
            },

            //关闭选择人
            handleCloseUser() {
                this.showPop = false
            },

            //开始日期确认
            onConfirmBegin(value) {
                this.formInline.startTime = formatDate(value, 'yyyy-MM-dd');
                this.showPickerBegin = false;
            },

            //结束日期确认
            onConfirmEnd(value) {
                this.formInline.endTime = formatDate(value, 'yyyy-MM-dd');
                this.showPickerEnd = false;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            async onSubmit() {
                this.dataList.forEach(item => {
                    delete item.createBy
                    delete item.createTime
                    delete item.updateBy
                    delete item.updateTime
                    delete item.companyId
                })

                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateData(data) : await addData(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
