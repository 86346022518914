<template>
  <div>
    <van-cell :value="`${currentMonth}月汇总`" class="mrg-t-20 first-van-cell"> </van-cell>

    <div class="calendar-wrap mrg-t-20">
      <div class="title">
        考勤记录<span>（{{ currentMonth }}月）</span>
      </div>
      <vue-hash-calendar
        :visible="true"
        :isShowWeekView="isShowWeekView"
        format="YY-MM-DD"
        @click="handleCalendarClick"
      ></vue-hash-calendar>
    </div>

    <div class="content2">
      <van-circle
        v-model="currentRate"
        :rate="teamObj.rate"
        stroke-width="80"
        size="140px"
        :layer-color="'#F0F0F0'"
      >
        <template #default>
          <div class="person-count">
            <div class="count">{{ teamObj.clockNum }} / {{ teamObj.totalNum }}</div>
            <div class="desc">打卡人数/应到人数</div>
          </div>
        </template>
      </van-circle>
    </div>

    <div class="content1 flex-spa-bet">
      <div class="item-con">
        <div class="count">{{ teamObj.unClockNum }}</div>
        <div class="label">未打卡</div>
      </div>
      <div class="item-con">
        <div class="count">{{ teamObj.lateNum }}</div>
        <div class="label">迟到</div>
      </div>
      <div class="item-con">
        <div class="count">{{ teamObj.outWorkNum }}</div>
        <div class="label">外勤</div>
      </div>
    </div>

    <van-tabs v-model="active" class="mrg-t-20">
      <van-tab title="出勤" name="a">
        <template v-if="teamObj.workList.length > 0">
          <van-cell v-for="(item, index) in teamObj.workList" :key="index">
            <div class="flex-spa-bet item-cell">
              <img :src="avatar" alt="" />

              <div class="right-con">
                <div class="cell-title flex-spa-ver-cen">
                  <div class="tit">{{ item.realname }}</div>
                  <!-- <div class="time"><van-icon name="arrow" /></div> -->
                </div>
                <div class="desc over_hidden">
                  {{ item.deptName }}
                </div>
              </div>
            </div>
          </van-cell>
        </template>

        <van-empty v-else description="暂无数据" image-size="150" />
      </van-tab>

      <van-tab title="迟到" name="c">
        <template v-if="teamObj.lateList.length > 0">
          <van-cell v-for="(item, index) in teamObj.lateList" :key="index">
            <div class="flex-spa-bet item-cell">
              <img :src="avatar" alt="" />

              <div class="right-con">
                <div class="cell-title flex-spa-ver-cen">
                  <div class="tit">{{ item.realname }}</div>
                  <!-- <div class="time"><van-icon name="arrow" /></div> -->
                </div>
                <div class="desc over_hidden">
                  {{ item.deptName }}
                </div>
              </div>
            </div>
          </van-cell>
        </template>

        <van-empty v-else description="暂无数据" image-size="150" />
      </van-tab>
      <van-tab title="早退" name="d">
        <template v-if="teamObj.leaveEarlyList.length > 0">
          <van-cell v-for="(item, index) in teamObj.leaveEarlyList" :key="index">
            <div class="flex-spa-bet item-cell">
              <img :src="avatar" alt="" />

              <div class="right-con">
                <div class="cell-title flex-spa-ver-cen">
                  <div class="tit">{{ item.realname }}</div>
                  <!-- <div class="time"><van-icon name="arrow" /></div> -->
                </div>
                <div class="desc over_hidden">
                  {{ item.deptName }}
                </div>
              </div>
            </div>
          </van-cell>
        </template>
        <van-empty v-else description="暂无数据" image-size="150" />
      </van-tab>
      <van-tab title="缺卡" name="e">
        <template v-if="teamObj.lackList.length > 0">
          <van-cell v-for="(item, index) in teamObj.lackList" :key="index">
            <div class="flex-spa-bet item-cell">
              <img :src="item.avatar" alt="" />

              <div class="right-con">
                <div class="cell-title flex-spa-ver-cen">
                  <div class="tit">{{ item.realname }}</div>
                  <!-- <div class="time"><van-icon name="arrow" /></div> -->
                </div>
                <div class="desc over_hidden">
                  {{ item.deptName }}
                </div>
              </div>
            </div>
          </van-cell>
        </template>
        <van-empty v-else description="暂无数据" image-size="150" />
      </van-tab>
      <van-tab title="外勤" name="b">
        <template v-if="teamObj.outWorkList.length > 0">
          <van-cell v-for="(item, index) in teamObj.outWorkList" :key="index">
            <div class="flex-spa-bet item-cell">
              <img :src="avatar" alt="" />

              <div class="right-con">
                <div class="cell-title flex-spa-ver-cen">
                  <div class="tit">{{ item.realname }}</div>
                  <!-- <div class="time"><van-icon name="arrow" /></div> -->
                </div>
                <div class="desc over_hidden">
                  {{ item.deptName }}
                </div>
              </div>
            </div>
          </van-cell>
        </template>
        <van-empty v-else description="暂无数据" image-size="150" />
      </van-tab>
      <van-tab title="旷工" name="f">
        <template v-if="teamObj.absentList.length > 0"
          ><van-cell v-for="(item, index) in teamObj.absentList" :key="index">
            <div class="flex-spa-bet item-cell">
              <img :src="item.avatar" alt="" />

              <div class="right-con">
                <div class="cell-title flex-spa-ver-cen">
                  <div class="tit">{{ item.realname }}</div>
                  <!-- <div class="time"><van-icon name="arrow" /></div> -->
                </div>
                <div class="desc over_hidden">
                  {{ item.deptName }}
                </div>
              </div>
            </div>
          </van-cell></template
        >
        <van-empty v-else description="暂无数据" image-size="150" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { getCurrentMonth, getCurrentYearMonth, getCurrentMonthDay2 } from '@/utils/ruoyi';

import { fetchCollectByTeam } from '@/api/hr/hrAttendance';
export default {
  data() {
    return {
      isShowWeekView: true,
      currentMonth: getCurrentMonth(),
      currentRate: 0,
      active: 'a',
      avatar: this.$store.getters.avatar,
      day: getCurrentMonthDay2(),
      teamObj: {},
    };
  },

  mounted() {
    this.getCollectByTeam();
  },

  methods: {
    async getCollectByTeam() {
      const result = await fetchCollectByTeam({ day: this.day });
      const { clockNum = 0, totalNum = 0, lateList } = result.data || {};
      result.data.rate = totalNum == 0 ? 0 : Math.round(clockNum / totalNum);
      this.teamObj = result.data;
    },

    handleCalendarClick(day) {
      this.day = day;
      this.getCollectByTeam();
    },
  },
};
</script>
<style lang="scss" scoped>
.first-van-cell ::v-deep .van-cell__value {
  padding: 30px;
}
::v-deep .van-cell__value {
  padding: 26px 60px;
  font-size: 30px;
  font-weight: bold;
  color: #111111;
}

.content2 {
  background: #fff;
  text-align: center;
  .person-count {
    margin-top: 86px;
    .count {
      font-size: 40px;
      font-weight: 800;
      color: #111111;
    }
    .desc {
      font-size: 22px;
      font-weight: 400;
      color: #666666;
      margin-top: 15px;
    }
  }
}

.content1 {
  background: #fff;
  padding: 30px 80px;
  .item-con {
    text-align: center;
    .count {
      font-size: 40px;
      font-weight: 800;
      color: #111111;
    }
    .label {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      margin-top: 20px;
    }
  }
}

.calendar-wrap {
  background: #fff;
  padding: 40px 40px;
  .title {
    font-size: 30px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
    span {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
    }
  }
}

.detail {
  padding: 40px;
  background: #fff;
  margin-top: 20px;
  .title {
    color: #999999;
    font-size: 24px;
  }
  .list-detail {
    .icon {
      width: 40px;
      height: 40px;
      background: #0091ff;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      border-radius: 100%;
    }
    .icon2 {
      background: #c8c8c8;
    }
    .time,
    .address {
      color: #333333;
      font-size: 26px;
    }
    .address {
      width: 240px;
    }
  }

  .total-hour {
    font-size: 22px;
    color: #333333;
    text-align: center;
    border-left: 1px solid #d8d8d8;
    height: 100px;
    padding: 20px 0 0 20px;
    margin-top: 30px;
    .label {
      margin-bottom: 20px;
    }
    .hour {
      width: 117px;
      height: 33px;
      background: #0091ff;
      border-radius: 19px 19px 19px 19px;
      color: #fff;
      font-size: 22px;
      line-height: 33px;
    }
  }
}

.item-cell {
  padding: 20px 0;

  img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
  }

  .right-con {
    width: 530px;
    .cell-title {
      padding-top: 10px;
      .tit {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
      .time {
        padding-top: 10px;
        font-size: 20px;
        color: #bbbbbb;
      }
    }
    .desc {
      color: #888;
      font-size: 24px;
      margin-top: 15px;
    }
  }
}
::v-deep .van-cell__value {
  padding: 0 30px;
}
</style>
