<template>
    <div class="app-container">
        <navBar :title="'商机详情'" @handleGoBack="handleGoBack">
            <template v-slot:icon1>
                <van-icon
                        name="star"
                        class="mrg-r-20"
                        @click="handleStar"
                        :style="{ color: collectColor[detailObj.isCollection] }"
                />
            </template>
        </navBar>

        <div class="item-tab">
            <div class="title-wrap">
                <div class="flex-spa-ver-cen">
                    <div class="tit over_hidden">{{ detailObj.name || '--' }}</div>
                </div>
                <div class="time">创建时间：{{ detailObj.createTime || '--' }}</div>
            </div>
            <div class="con">
                <div class="desc-wrap flex">
                    <div class="label">客户名称：</div>
                    <div class="value">{{ detailObj.customer || '--' }}</div>
                </div>
                <div class="desc-wrap flex">
                    <div class="label">商机金额：</div>
                    <div class="value">{{ detailObj.price || '--' }}</div>
                </div>
                <div class="desc-wrap flex">
                    <div class="label">负责人：</div>
                    <div class="value">{{ detailObj.leaderId || '--' }}</div>
                </div>
            </div>
        </div>

        <div class="flex-spa-bet cost-steps">
            <div class="item-step active-item-step">初步洽谈</div>
            <div class="item-step active-item-step2">深入沟通</div>
            <!-- 选中状态加class  active-item-step2 -->
            <div class="item-step">产品报价</div>
            <!-- 选中状态加class  active-item-step3 -->
            <div class="item-step">成交商机</div>
        </div>

        <van-tabs v-model="activeName">
            <van-tab title="详细资料" name="a">
                <baseInfoVue/>
            </van-tab>
            <van-tab title="跟进" name="b">
                <ItemFollowList :pages="'cost'"/>
            </van-tab>
            <van-tab title="产品" name="c">
                <CostProductList :costId="Number(dataId)"/>
            </van-tab>
            <van-tab title="附件" name="d">
                <AppendixVue :businessType="'cost'"/>
            </van-tab>
            <van-tab title="操作记录" name="e">
                <RecordVue :businessType="'crm_cost'"/>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import baseInfoVue from './components/baseInfo.vue';
    import CostProductList from './components/costProductList';
    import ItemFollowList from '@/views/components/crm/ItemFollowList.vue';
    import RecordVue from '@/views/components/crm/Record.vue';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import {COLLECTION_STATUS} from '@/views/constant';
    import {getDetailByCostId} from "@/api/crm/bussiness";
    import {fetchCancelFocusOn, fetchFocusOn} from "@/api/crm/common";

    export default {
        components: {
            baseInfoVue,
            ItemFollowList,
            RecordVue,
            AppendixVue,
            CostProductList
        },

        data() {
            return {
                activeName: 'a',
                dataId: this.$route.query.id,
                detailObj: {},
                collectColor: COLLECTION_STATUS,
            };
        },

        mounted() {
            this.getDetail();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/crm/cost');
                this.$router.go(-1)
            },

            async getDetail() {
                const result = await getDetailByCostId({id: this.dataId});
                const {data = {}} = result;
                this.detailObj = data;
                this.$store.commit('customer/SET_COST_DETAIL', JSON.stringify(data));
            },

            async handleStar() {
                const params = {
                    businessId: this.$route.query.id,
                    businessType: 3, //	业务类型 0：线索，1：客户，2：联系人3，商机
                };

                // 状态是0和2，点五角星按钮调关注的接口
                // 状态是1和3，点五角星按钮调取消关注的接口
                if (this.detailObj.isCollection == 1 || this.detailObj.isCollection == 3) {
                    await fetchCancelFocusOn(params);
                    this.$toast('取消关注');
                } else {
                    await fetchFocusOn(params);
                    this.$toast('关注成功');
                }
                await this.getDetail();
            },
        },
    };
</script>

<style lang="scss" scoped>
  .item-tab {
    background: #fff;
    padding: 0 33px;
    margin-top: 20px;

    .title-wrap {
      padding: 26px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 0;
        flex: 1;
      }

      .level-btn {
        width: 80px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }

      .time {
        font-size: 24px;
        margin-top: 10px;
        color: #999999;
      }
    }

    .con {
      padding: 30px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 30px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }
  }

  ::v-deep .van-tabs--line .van-tabs__wrap {
    height: 80px !important;
  }

  ::v-deep .van-tab {
    // padding: 40px 0;
    height: 80px !important;
    line-height: 80px !important;
    padding-bottom: 0 !important;
  }
</style>
