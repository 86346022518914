import request from '@/utils/request'

// 根据物料获取采购分析
export function getMaterialFormByName(query){
  return request({
    url: "/purchase/materialForm/getMaterialFormByName",
    method: "get",
    params: query
  });
}
// 根据物料类型获取采购分析
export function getMaterialFormByType(query){
  return request({
    url: "/purchase/materialForm/getMaterialFormByType",
    method: "get",
    params: query
  });
}
// 采购询价分析
export function getPurInquiryForm(query){
  return request({
    url: "/purchase/purInquiryForm/getPurInquiryForm",
    method: "get",
    params: query
  });
}
// 根据供应商类型分类
export function getSupplierType(query){
  return request({
    url: "/purchase/supplierPortrait/getSupplierType",
    method: "get",
    params: query
  });
}
// 根据供应商类型级别
export function getSupplierLevel(query){
  return request({
    url: "/purchase/supplierPortrait/getSupplierLevel",
    method: "get",
    params: query
  });
}
// 根据供应商类型行业
export function getSupplierIndustry(query){
  return request({
    url: "/purchase/supplierPortrait/getSupplierIndustry",
    method: "get",
    params: query
  });
}

// 采购趋势订单数量
export function getOrderNumber(query){
  return request({
    url: "/purchase/purchaseTrend/getOrderNumber",
    method: "get",
    params: query
  });
}
// 采购趋势订单采购物料数量
export function getMaterialNumber(query){
  return request({
    url: "/purchase/purchaseTrend/getMaterialNumber",
    method: "get",
    params: query
  });
}
// 采购趋势采购类型统计
export function getOrderType(query){
  return request({
    url: "/purchase/purchaseTrend/getOrderType",
    method: "get",
    params: query
  });
}
// 采购趋势列表统计
export function getList(query){
  return request({
    url: "/purchase/purchaseTrend/getList",
    method: "get",
    params: query
  });
}

// 按照物料名称统计金额
export function getPriceMaterial(query){
  return request({
    url: "/purchase/purchasePrice/getMaterial",
    method: "get",
    params: query
  });
}
// 按照采购类型统计金额
export function getMaterialTypePrice(query){
  return request({
    url: "/purchase/purchasePrice/getMaterialTypePrice",
    method: "get",
    params: query
  });
}
// 按照月份统计采购金额
export function getPriceOrderNumber(query){
  return request({
    url: "/purchase/purchasePrice/getOrderNumber",
    method: "get",
    params: query
  });
}

// 按照月份统计采购金额
export function getPurchaseReturn(query){
  return request({
    url: "/purchase/purchaseReturn/getReturn",
    method: "get",
    params: query
  });
}

