<template>
    <div class="app-container" v-if="showEcharts">
        <div style="width: 100%;height: 350px;padding-top: 30px" ref="echartsRef"></div>

        <div class="table">
            <div class="table-header flex-spa-bet">
                <span class="se-tr">销售阶段</span>
                <span class="se-tr">商机数量</span>
                <span class="se-tr">转化率(%)</span>
                <span class="se-tr">销售金额（元）</span>
            </div>
            <div class="table-body">
                <div class="itemDiv item-tr flex-spa-ver-cen" v-for="(item, index) in tableData" :key="index">
                    <van-divider/>
                    <div class="itemCol name-wrap flex-ver-cen">
                        <div class="se-tr">{{ item.name }}</div>
                        <div class="se-tr rightData">{{ item.num }}</div>
                        <div class="se-tr rightData">{{ item.probability }}</div>
                        <div class="se-tr rightData">{{ item.price }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getEchart} from '@/echarts/report/crm/salesFunnel'
    import {getCostFunnel, getCostFunnelList} from '@/api/report/crm/crmReport'

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    this.showEcharts = this.isShow
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart) {
                                this.dataEchart.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                showEcharts: false,
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                tableData: [],
                dataEchart: null,
                // 查询参数
                queryParams: {
                    leaderId: undefined
                }
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                let result = await getCostFunnel(
                    this.queryParams.leaderId ? {leaderId: this.queryParams.leaderId} : {}
                )
                let strList = []
                let numList = []
                result.data.forEach(item => {
                    strList.push(item.name)
                    numList.push({
                        "name": item.name,
                        "value": item.num
                    })
                })

                //销售预测分析图
                this.dataEchart = await this.$echarts.init(this.$refs.echartsRef)
                const echartOption = getEchart(strList, numList, numList[0].num)
                this.dataEchart.setOption(echartOption)


                //列表
                result = await getCostFunnelList(this.queryParams)
                this.tableData = result.data
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart)
                if (this.dataEchart) {
                    this.dataEchart.clear();
                    this.dataEchart.dispose();
                    this.dataEchart = null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
  .table {
    margin-top: 50px;

    .table-header {
      padding: 40px 25px 0 25px;

      span {
        display: inline-block;
        color: #b5b5b5;

        font-size: 22px;
      }

      .fir-tr {
        width: 358px;
      }

      .se-tr {
        padding-left: 20px;
        width: 25%;
      }

      .thi-tr {
        width: 72px;
      }
    }

    .table-body {
      padding: 10px 30px;
      font-size: 26px;

      .itemDiv {
        width: 100%;
        margin-top: 30px;

        .itemCol {
          width: 100%;

          .se-tr {
            width: 25%;
          }

          .rightData {
            padding-left: 30px;
          }
        }
      }


    }
  }
</style>
