import request from "@/utils/request";
//查询所有启用的仓库
export function selectWareByEnable() {
  return request({
    url: "inventory/warehouse/selectByEnable",
    method: "get"
  });
}
// 查询七天各类别入库数量
export function sevenDaysEnterQuantity(query) {
  return request({
    url: "inventory/report/sevenDaysEnterQuantity",
    method: "get",
    params: query
  });
}
// 入库单
export function selectEnterTwohundred(query) {
  return request({
    url: "inventory/report/selectEnterTwohundred",
    method: "get",
    params: query
  });
}
// 入库类别
export function countByCategory(whId) {
  return request({
    url: "inventory/workbench/countByCategory",
    method: "get",
    params: { whId }
  });
}
// 入库类别有时间
export function countByCategorytime(query) {
  return request({
    url: "inventory/workbench/countByCategory",
    method: "get",
    params: query
  });
}
export function selectEnterRank(query) {
  return request({
    url: "inventory/report/selectEnterRank",
    method: "get",
    params: query
  });
}
export function selectExpendRank(query) {
  return request({
    url: "inventory/report/selectExpendRank",
    method: "get",
    params: query
  });
}
