<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.theme"
                            label="回访标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入回访标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.customer"
                            label="客户名称"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择客户' }]"
                            @click="showPickerCustomer = true"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.order"
                            label="回访订单"
                            placeholder="请选择"
                            @click="showPickerOrder= true"
                    />
                    <van-popup v-model="showPickerOrder" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="orderList"
                                value-key="theme"
                                @confirm="
                onConfirmOrder($event, 'orderId', 'order', 'theme', 'showPickerOrder')
              "
                                @cancel="showPickerOrder = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.contacts"
                            label="客户联系人"
                            placeholder="请选择"
                            @click="showPickerContact= true"
                    />
                    <van-popup v-model="showPickerContact" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="contactList"
                                value-key="name"
                                @confirm="
                onConfirmContact($event, 'contactsId', 'contacts', 'name', 'showPickerContact')
              "
                                @cancel="showPickerContact = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.typeStr"
                            label="回访形式"
                            placeholder="请选择"
                            @click="showPickerType= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择收入类型' }]"
                    />
                    <van-popup v-model="showPickerType" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="typeOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'type', 'typeStr', 'dictValue','dictLabel', 'showPickerType')
              "
                                @cancel="showPickerType = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.satisfactionStr"
                            label="满意程度"
                            placeholder="请选择"
                            @click="showPickerSatisfaction= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择收入类型' }]"
                    />
                    <van-popup v-model="showPickerSatisfaction" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="satisfactionOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'satisfaction', 'satisfactionStr', 'dictValue','dictLabel', 'showPickerSatisfaction')
              "
                                @cancel="showPickerType = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.leader"
                            label="负责人"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择负责人' }]"
                            @click="showPop= true"
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.visitDate"
                            label="回访日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择回访日期' }]"
                            @click="showPickerVisitDate = true"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerVisitDate"
                                  @confirm="onConfirmAppointment($event)"/>

                    <textAreaInput :remark="formInline.content" @change="handleContentChange" :title="'回访内容'"/>

                    <textAreaInput :remark="formInline.remark" @change="handleRemarkChange" :title="'客户反馈'"/>

                    <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <CustomerPop :customerVisible="showPickerCustomer" @submitCustomerList="submitCustomerList"
                     @handleClose="handleCloseCustomer"/>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>
    </div>
</template>
<script>
    import {addData, fetchAllContactsList, fetchAllOrderList, selectOne, updateData} from '@/api/crm/visit/index'
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import CustomerPop from '@/components/crm/CustomerPop'
    import SelectOneUser from '@/components/selectOneUser/index'
    import {formatDate} from "@/utils/ruoyi";
    import {submiteUpload} from "@/api/system/config";

    export default {
        components: {AppendixVue, SelectOneUser, CustomerPop},
        data() {
            return {
                formInline: {},
                title: '新建回访',
                id: this.$route.query.id,
                type: this.$route.query.type,
                uploader: [],
                uploadParams: {
                    businessType: 'visit',
                    businessId: undefined,
                    file: null,
                },
                minDate: new Date(2010, 0, 1),
                showPickerCustomer: false,
                showPickerContact: false,
                showPickerType: false,
                showPickerSatisfaction: false,
                showPickerVisitDate: false,
                showPickerOrder: false,
                showPop: false,
                //回访形式
                typeOptions: [],
                //满意程度
                satisfactionOptions: [],
                //订单数组
                orderList: [],
                //联系人数组
                contactList: []
            };
        },

        mounted() {
            // 回访形式
            this.getDicts('follow_type').then(response => {
                this.typeOptions = response.data
            })

            // 满意程度
            this.getDicts('satisfaction').then(response => {
                this.satisfactionOptions = response.data
            })

            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        enterCategory: 0
                    };
                    this.title = '新建回访';
                } else {
                    let itemDetail = await selectOne({visitId: this.id});
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑回访';
                    } else {
                        this.title = '回访详情';
                    }
                }
            },

            //客户提交
            async submitCustomerList(id, name) {
                this.formInline.customerId = id;
                this.formInline.customer = name;

                //改变客户后
                this.orderList = []
                this.formInline.orderId = null
                this.formInline.order = ''
                this.formInline.contactsId = null
                this.formInline.contacts = ''

                //根据客户查询订单
                const result = await fetchAllOrderList({customerId: this.formInline.customerId})
                this.orderList = result.data

                //根据客户查询联系人
                const res = await fetchAllContactsList({customerId: this.formInline.customerId})
                this.contactList = res.data

                this.showPickerCustomer = false
            },

            //关闭选择客户的弹窗
            handleCloseCustomer() {
                this.showPickerCustomer = false;
            },

            //确认联系人
            async onConfirmContact(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //确认订单
            async onConfirmOrder(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //确认
            onConfirm(event, key, keyName, eventKey1, eventKey2, visible) {
                this.formInline[key] = event[eventKey1];
                this.formInline[keyName] = event[eventKey2];
                this[visible] = false;
            },

            //签约日期确认
            onConfirmAppointment(value) {
                this.formInline.visitDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerVisitDate = false;
            },

            //负责人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.leaderId = activeId
                this.formInline.leader = names
            },

            //关闭负责人
            handleCloseUser() {
                this.showPop = false
            },

            //回访内容
            handleContentChange(val) {
                this.formInline.content = val;
            },

            //客户反馈
            handleRemarkChange(val) {
                this.formInline.remark = val;
            },

            async onSubmit() {
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateData(data) : await addData(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
