<template>
    <div class="add-form">
        <div v-for="(item, index) in dataList" :key="index">
            <div class="flex-spa-bet list-tit-wrap">
                <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
                <van-icon name="edit" @click="handleUpdate(item)" style="margin-left: 150px"/>
                <van-icon name="delete-o" @click="handleDelete(item.id)"/>
            </div>

            <van-field
                    v-model="item.materialCategoryStr"
                    label="类别"
                    disabled
            />

            <van-field
                    v-model="item.materialUnit"
                    label="单位"
                    disabled
            />

            <van-field
                    v-model="item.materialCostPrice"
                    label="成本"
                    disabled
            />

            <van-field
                    v-model="item.originalCost"
                    label="标准价格"
                    disabled
            />

            <van-field
                    v-model="item.price"
                    label="售价"
                    disabled
            />

            <van-field
                    v-model="item.quantity"
                    label="数量"
                    disabled
            />

            <van-field
                    v-model="item.discount"
                    label="折扣"
                    disabled
            />

            <van-field
                    v-model="item.subtotal"
                    label="小计"
                    disabled
            />
        </div>
        <div class="bg"></div>
        <div class="btn" @click="handleAdd">
            <van-icon name="plus" class="mrg-r-10"/>
            添加产品
        </div>

        <CostProduct :showDialog="showPickerMaterial" :formObj="costForm" @handleClose="handleCloseMaterial"
                     @submitList="submitMaterial"/>
    </div>
</template>
<script>
    import {fetchProductList, removeProduct} from '@/api/crm/bussiness'
    import CostProduct from "@/components/costProduct/index";

    export default {
        components: {CostProduct},
        props: {
            costId: Number,
        },

        data() {
            return {
                showPickerMaterial: false,
                costForm: {},
                dataList: [],
            };
        },

        mounted() {
            this.getList();
        },

        methods: {
            async getList() {
                const result = await fetchProductList({id: this.costId})
                this.dataList = result.data
            },

            handleUpdate(item) {
                this.costForm = {
                    id: item.id,
                    costId: item.costId,
                    productId: item.productId,
                    materialName: item.materialName,
                    materialCode: item.materialCode,
                    materialUnit: item.materialUnit,
                    originalCost: item.originalCost,
                    price: item.price,
                    discount: item.discount,
                    quantity: item.quantity,
                    subtotal: item.subtotal,
                    remark: item.remark
                }
                this.showPickerMaterial = true;
            },

            //关闭物料弹窗
            handleCloseMaterial() {
                this.costForm = {}
                this.showPickerMaterial = false
            },

            //物料提交
            async submitMaterial() {
                await this.getList()
                this.handleCloseMaterial()
            },

            handleAdd() {
                this.costForm = {
                    id: undefined,
                    costId: this.costId,
                    productId: undefined,
                    materialName: '',
                    materialCode: '',
                    materialUnit: '',
                    originalCost: 0,
                    price: 0,
                    discount: 0,
                    quantity: 0,
                    subtotal: 0,
                    remark: ''
                }
                this.showPickerMaterial = true;
            },

            handleDelete(id) {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除此产品?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await removeProduct(id);
                        this.$toast.success('删除成功');
                        await that.getList();
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
        },
    };
</script>
<style lang="scss" scoped>
  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .bg {
    margin-bottom: 140px;
    width: 100%;
    height: 90px;
    background: #fafafa;
  }

  .btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 750px;
    height: 110px;
    line-height: 110px;
    background: #fff;
    color: #0091ff;
    font-size: 30px;
    text-align: center;
  }
</style>
