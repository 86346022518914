<template>
    <div class="follow-list">
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                class="list"
        >
            <div class="item-list" v-for="item in dataList" :key="item.id">
                <div class="flex-spa-ver-cen top">
                    <div class="flex-ver-cen">
                        <img class="avatar"/>
                        <div class="name-wrap mrg-l-10">
                            <div class="name">{{ item.name }}</div>
                            <div class="post">{{ item.postStr }}</div>
                        </div>
                    </div>
                    <img src="../../../assets/images/notice_icon6.png" alt=""/>
                </div>
                <div class="contact-methods">
                    <div class="label">手机</div>
                    <div class="value">{{ item.phone }}</div>
                </div>
                <div class="contact-methods">
                    <div class="label">性别</div>
                    <div class="value">{{ item.sex }}</div>
                </div>
            </div>
        </van-list>
        <div class="bg"></div>
        <div class="btn" @click="handleAdd">
            <van-icon name="plus" class="mrg-r-10"/>
            新增联系人
        </div>
    </div>
</template>
<script>
    import {fetchContactList} from '@/api/crm/customer';

    export default {
        props: {
            pages: String,
        },

        data() {
            return {
                loading: false,
                finished: true,
                pathObj: {
                    customer: '/crm/contacts/addContacts',
                },
                funObj: {
                    customer: fetchContactList,
                },
                dataList: [],
            };
        },

        mounted() {
            this.getList();
        },

        methods: {
            onLoad() {
            },

            async getList() {
                const result = await this.funObj[this.pages]({
                    customerId: this.$route.query.id,
                    pageSize: 100,
                    pageNum: 1
                });
                const {data = []} = result;
                this.dataList = data.records;
            },

            handleAdd() {
                // 新增页面
                this.$router.push({
                    path: '/crm/contacts/add',
                    query: {
                        obj: {
                            id: this.$route.query.id,
                            name: this.$route.query.name
                        },
                        type: 'customer'
                    },
                });
            },
        },
    };
</script>
<style lang="scss" scoped>
  .list {
    box-sizing: border-box;
    background: #fff;
  }

  .item-list {
    padding: 15px 40px 5px;
    border-top: 20px solid #fafafa;

    .top {
      height: 80px;
      padding-top: 10px;
    }

    .avatar {
      width: 70px;
      height: 70px;
      border-radius: 4px 4px 4px 4px;
    }

    .name-wrap {
      .name {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
      }

      .post {
        font-size: 20px;
        font-weight: 400;
        color: #999999;
        margin-top: 10px;
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }

    .tit {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
    }

    .time {
      font-size: 22px;
      font-weight: 400;
      color: #b5b5b5;
    }

    .contact-methods {
      display: flex;
      font-size: 24px;
      font-weight: 400;
      color: #111111;
      margin: 20px 0;

      .label {
        color: #999999;
        margin-right: 20px;
      }
    }
  }

  .bg {
    margin-bottom: 140px;
    width: 100%;
    height: 90px;
    background: #fafafa;
  }

  .btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 750px;
    height: 110px;
    line-height: 110px;
    background: #fff;
    color: #0091ff;
    font-size: 30px;
    text-align: center;
  }
</style>
