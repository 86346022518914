import { render, staticRenderFns } from "./supplierType.vue?vue&type=template&id=66b78a92&scoped=true&"
import script from "./supplierType.vue?vue&type=script&lang=js&"
export * from "./supplierType.vue?vue&type=script&lang=js&"
import style0 from "./supplierType.vue?vue&type=style&index=0&id=66b78a92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b78a92",
  null
  
)

export default component.exports