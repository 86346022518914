export const getEchart1 = (strList, numList) => {
    return {
        backgroundColor: "#fff",
        barWidth: 10,
        grid: {
            top: "10%",
            bottom: "10%",
            left: "18%",
            right: "12%"
        },
        // grid: {
        //   top: "10%",
        //   left: "10%",
        //   width: "85%",
        //   height: "65%",
        //   containLabel: false
        // },
        xAxis: {
            type: "value",
            name: '数量',
            nameTextStyle: {
                color: "#666",
                padding: [0, 0, 0, 0],
                fontSize: 14
            },
            splitLine: {
                lineStyle: {
                    color: "#ddd",
                    type: "dashed"
                }
            },
            axisTick: {
                show: false
            },
            axisLine: {
                //  改变y轴颜色
                lineStyle: {
                    color: "#eee"
                }
            },
            axisLabel: {
                //  改变x轴字体颜色和大小
                textStyle: {
                    color: "#333",
                    fontSize: 14
                }
            }
        },
        yAxis: {
            type: "category",
            name: '负责人',
            nameTextStyle: {
                color: "#666",
                padding: [0, 0, 0, 0],
                fontSize: 14
            },
            data: strList,
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                //  改变y轴颜色
                lineStyle: {
                    color: "#eee"
                }
            },
            axisLabel: {
                interval: 0,
                formatter: function(value) {
                    if (value.length > 10) {
                        return `${value.slice(0, 10)}`;
                    }
                    return value;
                },
                margin: 15,
                //  改变y轴字体颜色和大小
                //formatter: '{value} m³ ', //  给y轴添加单位
                textStyle: {
                    align: "right",
                    color: "#333",
                    fontSize: 14
                }
            }
        },
        series: [
            {
                type: "bar",
                name: "产出",
                barWidth: 20,
                itemStyle: {
                    normal: {
                        label: {
                            show: false, //开启显示
                            position: "insideRight", //在上方显示
                            textStyle: {
                                //数值样式
                                color: "#FFFFFF",
                                fontSize: 16,
                                fontWeight: 600
                            }
                        },
                        color: "#83bb74"
                        // color: function(params) {
                        //   var colorList = [
                        //     "#fb7c54",
                        //     "#fbcc34",
                        //     "#83bb74",
                        //     "#64b384",
                        //     "rgba(168, 190, 178, 1)",
                        //     "rgba(84, 116, 195, 1)",
                        //     "rgba(116, 196, 219, 1)"
                        //   ];
                        //   return colorList[params.dataIndex];
                        // }
                        // barBorderRadius: 10
                    }
                },
                data: numList
            }
        ]
    }
};

export const getEchart2 = (materialNames, enterCategory, materialCount) => {
    return {
        tooltip: {},
        visualMap: {
            max: 20,
            bottom: 100,
            inRange: {
                color: [
                    "#313695",
                    "#4575b4",
                    "#74add1",
                    "#abd9e9",
                    "#e0f3f8",
                    "#ffffbf",
                    "#fee090",
                    "#fdae61",
                    "#f46d43",
                    "#d73027",
                    "#a50026"
                ]
            }
        },
        xAxis3D: {
            type: "category",
            data: materialNames
        },
        yAxis3D: {
            type: "category",
            data: enterCategory
        },
        zAxis3D: {
            type: "value"
        },
        grid3D: {
            boxWidth: 200,
            boxDepth: 80,
            viewControl: {
                // projection: 'orthographic'
            },
            light: {
                main: {
                    intensity: 1.2,
                    shadow: true
                },
                ambient: {
                    intensity: 0.3
                }
            }
        },
        series: [
            {
                type: "bar3D",
                data: materialCount.map(function(item) {
                    return {
                        value: [item[0], item[1], item[2]]
                    };
                }),
                shading: "lambert",
                label: {
                    show: false,
                    fontSize: 16,
                    borderWidth: 1
                },
                itemStyle: {
                    opacity: 0.4
                },
                emphasis: {
                    label: {
                        fontSize: 20,
                        color: "#900"
                    },
                    itemStyle: {
                        color: "#900"
                    }
                }
            }
        ]
    }
};
