import request from '@/utils/request'

// 根据经纬度获取地点
export function findAddressByLngLatTDT(query) {
    return request({
        url: '/system/ymxx/findAddressByLngLatTDT',
        method: 'get',
        params: query,
    })
}

// 根据地点获取经纬度
export function findLngLatByAddressTDT(query) {
    return request({
        url: '/system/ymxx/findLngLatByAddressTDT',
        method: 'get',
        params: query,
    })
}
