<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'物料库存(总)'" @handleGoBack="handleGoBack">
            </navBar>

            <div class="common-wrap">
                <van-field
                        readonly
                        clickable
                        name="picker"
                        v-model="queryParams.whName"
                        label="仓库"
                        placeholder="请选择"
                        @click="showPickerWarehouse= true"
                />

                <van-field
                        readonly
                        clickable
                        name="picker"
                        v-model="queryParams.materialName"
                        label="物料"
                        placeholder="请选择"
                        @click="showPickerMaterial= true"
                />

                <van-field
                        readonly
                        clickable
                        name="picker"
                        v-model="queryParams.time"
                        label="日期"
                        placeholder="请选择"
                        @click="showDate= true"
                />
                <van-calendar :min-date="minDate" v-model="showDate" type="range" @confirm="onConfirmDate"/>
            </div>

            <div class="main">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                            v-model="loading"
                            offset="100"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                    >
                        <div class="list" v-for="item in dataList" :key="item.id">
                            <div class="line"></div>
                            <div class="item-tab">
                                <div class="title-wrap flex-spa-ver-cen">
                                    <div class="tit over_hidden">
                                        {{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）
                                    </div>
                                </div>
                                <div class="con">
                                    <div class="desc-wrap flex">
                                        <div class="label">库存量：</div>
                                        <div class="value">{{ item.materialNum || '--' }}</div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">单个成本：</div>
                                        <div class="value">{{ item.costPrice || '--' }}</div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">总成本：</div>
                                        <div class="value red">{{ item.totalCostPrice || '--' }}</div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">所属仓库：</div>
                                        <div class="value" v-html="item.whName"></div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">规格型号：</div>
                                        <div class="value" v-html="item.materialSpecification"></div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">产品单位：</div>
                                        <div class="value" v-html="item.materialUnit"></div>
                                    </div>
                                </div>
                                <div class="time-wrap flex-spa-ver-cen">
                                    <div class="time">创建时间：{{ item.createTime || '--' }}</div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>

        <Material :showDialog="showPickerMaterial" @handleClose="handleCloseMaterial" @submitData="submitMaterial"/>
        <WarehouseTree :showPop="showPickerWarehouse" @handleClose="handleCloseWarehouse"/>
    </div>
</template>

<script>
    import {selectTotalPage} from '@/api/inventory/materialStock';
    import {formatDate} from "@/utils/ruoyi";
    import Material from '@/components/material/index'
    import WarehouseTree from '@/components/warehouseTree'

    export default {
        components: {Material, WarehouseTree},
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                queryParams: {
                    pageNum: 1,
                    pageSize: 5,
                    condition: null
                },
                dataList: [],
                showDate: false,
                showPickerMaterial: false,
                showPickerWarehouse: false,
                minDate: new Date(2010, 0, 1),
            };
        },

        mounted() {
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/inventory/inventoryWorkbench');
                this.$router.go(-1)
            },

            async getList() {
                this.loading = true;
                let result = await selectTotalPage(this.queryParams);

                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.dataList = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.dataList = records;
                } else {
                    this.dataList = [...this.dataList, ...records];
                }
                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
            },

            onLoad() {
                if (this.refreshing) {
                    this.dataList = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.dataList.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            getListParams() {
                this.finished = false;
                this.loading = false;
                this.dataList = [];

                this.handleQuery();
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },

            //日期提交
            onConfirmDate(date) {
                let [start, end] = date;
                this.showDate = false;
                let beginTime = formatDate(start, 'yyyy-MM-dd')
                let endTime = formatDate(end, 'yyyy-MM-dd')
                this.queryParams.time = beginTime + '~' + endTime
                this.queryParams.beginTime = beginTime
                this.queryParams.endTime = endTime
                this.getListParams()
            },

            //关闭物料弹窗
            handleCloseMaterial() {
                this.showPickerMaterial = false
            },

            //物料提交
            submitMaterial(obj) {
                this.queryParams.materialId = obj.id
                this.queryParams.materialName = obj.materialName
                this.handleCloseMaterial()
                this.getListParams()
            },

            //关闭仓库弹窗
            handleCloseWarehouse(resultObj) {
                if (resultObj) {
                    this.queryParams.whId = resultObj.id
                    this.queryParams.whName = resultObj.name
                    this.getListParams()
                }
                this.showPickerWarehouse = false
            }
        },
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

  .search {
    padding-top: 40px;
    background: #fff;
  }

  .my-tabs {
    padding-top: 30px;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        line-height: 38px;
        width: 0;
        flex: 1;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          white-space: nowrap;
          color: #999;
        }

        .value {
          color: #111;
          white-space: nowrap;
          overflow: hidden;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;
    }
  }

  ::v-deep .van-tabs__line {
    background-color: #0091ff;
  }

  .transfer-select-wrap {
    font-size: 28px;
  }

  ::v-deep .van-checkbox__label {
    line-height: 40px;
    font-size: 28px;
    padding-top: 10px;
  }

  ::v-deep .van-checkbox__icon .van-icon {
    width: 28px;
    height: 28px;
  }
</style>
