<template>
    <div class="app-container">
        <Header :moudle="'inventory'"/>
        <van-divider />
        <van-row>
            <van-col span="12">
                <div style="margin-left: 20px;margin-top: 10px">
                    <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px">总入库量</div>
                    <div class="flex-ver-cen">
                        <img src="../../../assets/images/enterDayQuantity.png" style="width: 30px;">
                        <span style="font-size: 18px;font-weight: 800;color: #8893FB;">{{ reportSimpleEnterExpendObj.enterTotalQuantity }}</span>
                    </div>
                </div>
            </van-col>
            <van-col span="12">
                <div style="margin-left: 20px;margin-top: 10px">
                    <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px">今日入库量</div>
                    <div class="flex-ver-cen">
                        <img src="../../../assets/images/enterTotalQuantity.png" style="width: 30px;">
                        <span style="font-size: 18px;font-weight: 800;color: #8893FB;">{{ reportSimpleEnterExpendObj.enterDayQuantity }}</span>
                    </div>
                </div>
            </van-col>
            <van-col span="12">
                <div style="margin-left: 20px;margin-top: 20px">
                    <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px">总入库成本</div>
                    <div class="flex-ver-cen">
                        <img src="../../../assets/images/enterTotalPrice.png" style="width: 30px;">
                        <span style="font-size: 18px;font-weight: 800;color: #8893FB;">{{ reportSimpleEnterExpendObj.enterTotalPrice }}</span>
                    </div>
                </div>
            </van-col>
            <van-col span="12">
                <div style="margin-left: 20px;margin-top: 20px">
                    <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px">今日入库成本</div>
                    <div class="flex-ver-cen">
                        <img src="../../../assets/images/enterDayPrice.png" style="width: 30px;">
                        <span style="font-size: 18px;font-weight: 800;color: #8893FB;">{{ reportSimpleEnterExpendObj.enterDayPrice }}</span>
                    </div>
                </div>
            </van-col>
            <van-col span="12">
                <div style="margin-left: 20px;margin-top: 20px">
                    <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px">总出库量</div>
                    <div class="flex-ver-cen">
                        <img src="../../../assets/images/enterTotalQuantity.png" style="width: 30px;">
                        <span style="font-size: 18px;font-weight: 800;color: #8893FB;">{{ reportSimpleEnterExpendObj.expendTotalQuantity }}</span>
                    </div>
                </div>
            </van-col>
            <van-col span="12">
                <div style="margin-left: 20px;margin-top: 20px">
                    <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px">今日出库量</div>
                    <div class="flex-ver-cen">
                        <img src="../../../assets/images/expendDayQuantity.png" style="width: 30px;">
                        <span style="font-size: 18px;font-weight: 800;color: #8893FB;">{{ reportSimpleEnterExpendObj.expendDayQuantity }}</span>
                    </div>
                </div>
            </van-col>
            <van-col span="12">
                <div style="margin-left: 20px;margin-top: 20px">
                    <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px">总出库成本</div>
                    <div class="flex-ver-cen">
                        <img src="../../../assets/images/expendTotalPrice.png" style="width: 30px;">
                        <span style="font-size: 18px;font-weight: 800;color: #8893FB;">{{ reportSimpleEnterExpendObj.expendTotalPrice }}</span>
                    </div>
                </div>
            </van-col>
            <van-col span="12">
                <div style="margin-left: 20px;margin-top: 20px">
                    <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px">今日出库成本</div>
                    <div class="flex-ver-cen">
                        <img src="../../../assets/images/expendDayPrice.png" style="width: 30px;">
                        <span style="font-size: 18px;font-weight: 800;color: #8893FB;">{{ reportSimpleEnterExpendObj.expendDayPrice }}</span>
                    </div>
                </div>
            </van-col>
        </van-row>
        <div style="width: 100%;background-color: #f3f3f3;margin-top: 10px;padding-top: 10px;padding-bottom: 10px">
            <div style="background-color: #ffffff;width: 92%;margin-left: 4%;margin-top: 10px;border-radius:10px;" v-for="(item,index) in simpleOfWarehouseList" :key="index">
                <div style="padding: 0px 10px 5px 15px;">
                    <span style="font-size: 16px;font-weight: bold">{{ item.whName }}</span>
                </div>
                <van-row>
                    <van-col span="11">
                        <div style="background-color: #F6F8FB;width: 100%;margin-left: 8px;height: 40px;" class="flex-ver-cen"
                             @click="handleMaterialStockClick(item.id)">
                            <div style="font-size: 12px;color: #999999;margin-left: 10%">库存量</div>
                            <div
                                    style="font-size: 12px;font-weight: bold;margin-left: 5px;color: #111111">{{ item.materialNum }}</div>
                        </div>
                    </van-col>
                    <van-col span="11">
                        <div style="background-color: #F6F8FB;width: 100%;margin-left: 20px;height: 40px;" class="flex-ver-cen"
                             @click="handleLossNumClick(item.id)">
                            <span style="font-size: 12px;color: #999999;margin-left: 10%">报损量</span>
                            <span
                                    style="font-size: 12px;font-weight: bold;margin-left: 5px;color: #E20000">{{ item.lossNum }}</span>
                        </div>
                    </van-col>
                </van-row>
                <van-row style="margin-top: 20px">
                    <van-col span="11">
                        <div style="background-color: #F6F8FB;width: 100%;margin-left: 8px;height: 40px;" class="flex-ver-cen"
                             @click="handleEnterNumClick(item.id)">
                            <span style="font-size: 12px;color: #999999;margin-left: 10%">入库量</span>
                            <span
                                    style="font-size: 12px;font-weight: bold;margin-left: 5px;color: #111111">{{ item.enterNum }}</span>
                        </div>
                    </van-col>
                    <van-col span="11">
                        <div style="background-color: #F6F8FB;width: 100%;margin-left: 20px;height: 40px;" class="flex-ver-cen"
                             @click="handleExpendNumClick(item.id)">
                            <span style="font-size: 12px;color: #999999;margin-left: 10%">出库量</span>
                            <span
                                    style="font-size: 12px;font-weight: bold;margin-left: 5px;color: #111111">{{ item.expendNum }}</span>
                        </div>
                    </van-col>
                </van-row>
                <van-row style="margin-top: 20px;padding-bottom: 10px">
                    <van-col span="11">
                        <div style="background-color: #F6F8FB;width: 100%;margin-left: 8px;height: 40px;" class="flex-ver-cen"
                             @click="handleExpendNumClick(item.id)">
                            <span style="font-size: 12px;color: #999999;margin-left: 10%">出库总成本</span>
                            <span
                                    style="font-size: 12px;font-weight: bold;margin-left: 5px;color: #111111">{{ item.expendPriceTotal }}</span>
                        </div>
                    </van-col>
                    <van-col span="11">
                        <div style="background-color: #F6F8FB;width: 100%;margin-left: 20px;height: 40px;" class="flex-ver-cen"
                             @click="handleExpendNumClick(item.id, 'today')">
                            <span style="font-size: 12px;color: #999999;margin-left: 10%">今日出库成本</span>
                            <span
                                    style="font-size: 12px;font-weight: bold;margin-left: 5px;color: #111111">{{ item.expendPriceToday }}</span>
                        </div>
                    </van-col>
                </van-row>
            </div>
        </div>

        <EnterMsg
                :addDialog="enterNumDialog"
                :id="simpleWhId"
                @handleClose="handleClose"
        />

        <ExpendMsg
                :addDialog="expendNumDialog"
                :id="simpleWhId"
                :expendNumDay="expendNumDay"
                @handleClose="handleClose"
        />

        <SimpleMaterialStock
                :addDialog="materialStockDialog"
                :id="simpleWhId"
                @handleClose="handleClose"
        />

        <ExperimentMsg
                :addDialog="lossNumDialog"
                :id="simpleWhId"
                @handleClose="handleClose"
        />
    </div>
</template>
<script>
    import Header from '@/components/header';
    import {findSimpleOfWarehouse, reportSimpleEnterExpend} from "@/api/inventory/workBench";
    import {getCurrentMonthDay2} from "@/utils/ruoyi";
    import EnterMsg from './components/EnterMsg'
    import ExpendMsg from './components/ExpendMsg'
    import SimpleMaterialStock from './components/SimpleMaterialStock'
    import ExperimentMsg from './components/ExperimentMsg'

    export default {
        components: {
            Header,
            EnterMsg,
            ExpendMsg,
            SimpleMaterialStock,
            ExperimentMsg,
        },

        data() {
            return {
                simpleOfWarehouseList: [],
                simpleWhId: undefined,
                //仓库统计中的出库日期查询条件
                expendNumDay: '',
                materialStockDialog: false,
                enterNumDialog: false,
                expendNumDialog: false,
                lossNumDialog: false,
                dataEcharts1: null,
                dataEcharts2: null,
                reportSimpleEnterExpendObj: {},
            };
        },

        mounted() {
            //获取仓库简要统计
            this.findSimpleOfWarehouse()

            //简要统计出入库数量、价格
            this.reportSimpleEnterExpend()

            window.addEventListener('resize', () => {
                if (this.dataEcharts1) {
                    this.dataEcharts1.resize()
                }
                if (this.dataEcharts2) {
                    this.dataEcharts2.resize()
                }
            })
        },

        beforeDestroy() {
            this.echartsDestroy()
        },

        methods: {
            //获取仓库简要统计
            async findSimpleOfWarehouse() {
                const result = await findSimpleOfWarehouse();
                this.simpleOfWarehouseList = result.data;
            },

            //点击库存量
            handleMaterialStockClick(whId) {
                this.simpleWhId = whId
                this.materialStockDialog = true
            },

            //点击入库量
            handleEnterNumClick(whId) {
                this.simpleWhId = whId
                this.enterNumDialog = true
            },

            //点击出库量
            handleExpendNumClick(whId, val) {
                this.simpleWhId = whId
                this.expendNumDialog = true

                if(val == 'today'){
                    this.expendNumDay = getCurrentMonthDay2()
                }else{
                    this.expendNumDay = ''
                }
            },

            //点击报损量
            handleLossNumClick(whId) {
                this.simpleWhId = whId
                this.lossNumDialog = true
            },

            //关闭弹窗
            handleClose(val) {
                this[val] = false
                this.simpleWhId = undefined
            },

            //简要统计出入库数量、价格
            async reportSimpleEnterExpend(){
                let result = await reportSimpleEnterExpend()
                this.reportSimpleEnterExpendObj = result.data
            },

            echartsDestroy() {
                window.removeEventListener('resize', this.dataEcharts1)
                if (this.dataEcharts1) {
                    this.dataEcharts1.clear();
                    this.dataEcharts1.dispose();
                    this.dataEcharts1 = null;
                }
                window.removeEventListener('resize', this.dataEcharts2)
                if (this.dataEcharts2) {
                    this.dataEcharts2.clear();
                    this.dataEcharts2.dispose();
                    this.dataEcharts2 = null;
                }
            }
        },
    };
</script>
<style lang="scss" scoped>
</style>
