import request from '@/utils/request'

// 查询列表
export function selectPageEntry(query) {
  return request({
    url: "/cw/entry/getPage",
    method: "get",
    params: query
  });
}

// 删除
export function delDataEntry(id) {
  return request({
    url: "/cw/entry/"+id,
    method: "delete"
  });
}

// 根据id查询
export function selectOne(id) {
  return request({
    url: "/cw/entry/"+id,
    method: "get"
  });
}

// 新增
export function addData(data) {
  return request({
    url: "/cw/entry",
    method: "post",
    data: data
  });
}

// 修改
export function updateData(data) {
  return request({
    url: "/cw/entry",
    method: "put",
    data: data
  });
}

//查询所有类型
export function findAllType() {
  return request({
    url: "/cw/income/getList/0",
    method: "get"
  });
}

//查询所有账户
export function findAllAccount() {
  return request({
    url: "/cw/account/getList",
    method: "get"
  });
}
