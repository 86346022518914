<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="合同名称"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入合同名称' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.customer"
                            label="客户名称"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择客户' }]"
                            @click="showPickerCustomer = true"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.order"
                            label="订单"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择订单' }]"
                            @click="showPickerOrder= true"
                    />
                    <van-popup v-model="showPickerOrder" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="orderList"
                                value-key="theme"
                                @confirm="
                onConfirmOrder($event, 'orderId', 'order', 'theme', 'showPickerOrder')
              "
                                @cancel="showPickerOrder = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.discountPrice"
                            label="合同金额"
                            disabled
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.contactStr"
                            label="签约人"
                            placeholder="请选择"
                            @click="showPickerContact= true"
                    />
                    <van-popup v-model="showPickerContact" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="contactList"
                                value-key="name"
                                @confirm="
                onConfirmContact($event, 'contactsId', 'contactStr', 'name', 'showPickerContact')
              "
                                @cancel="showPickerContact = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.leaderStr"
                            label="负责人"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择负责人' }]"
                            @click="showPop= true"
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.appointmentTime"
                            label="签约日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择签约日期' }]"
                            @click="showPickerAppointment = true"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerAppointment"
                                  @confirm="onConfirmAppointment($event)"/>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.contractStateTime"
                            label="开始日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择始日期' }]"
                            @click="showPickerBegin = true"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerBegin"
                                  @confirm="onConfirmBegin($event)"/>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.contractEndTime"
                            label="结束日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择结束日期' }]"
                            @click="showPickerEnd = true"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerEnd"
                                  @confirm="onConfirmEnd($event)"/>

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
                        </div>

                        <van-field
                                v-model="item.materialCategoryStr"
                                label="产品类别"
                                disabled
                        />

                        <van-field
                                v-model="item.materialUnit"
                                label="单位"
                                disabled
                        />

                        <van-field
                                disabled
                                v-model="item.originalCost"
                                label="标准价格"
                        />

                        <van-field
                                v-model="item.price"
                                label="售价"
                                disabled
                        />

                        <van-field
                                v-model="item.quantity"
                                label="数量"
                                disabled
                        />
                    </div>

                    <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <CustomerPop :customerVisible="showPickerCustomer" @submitCustomerList="submitCustomerList"
                     @handleClose="handleCloseCustomer"/>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>
    </div>
</template>
<script>
    import {
        addCrmContract,
        fetchAllOrderByCustomerId,
        selectItemByContract,
        updateCrmContract
    } from '@/api/crm/contract';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import CustomerPop from '@/components/crm/CustomerPop'
    import SelectOneUser from '@/components/selectOneUser/index'
    import {formatDate} from "@/utils/ruoyi";
    import {submiteUpload} from "@/api/system/config";
    import {getDetailByOrderId} from "@/api/crm/order";
    import {fetchContactsByCustomerId} from "@/api/crm/common";

    export default {
        components: {AppendixVue, SelectOneUser, CustomerPop},
        data() {
            return {
                formInline: {},
                title: '新建合同',
                id: this.$route.query.id,
                type: this.$route.query.type,
                uploader: [],
                uploadParams: {
                    businessType: 'crmcontract',
                    businessId: undefined,
                    file: null,
                },
                minDate: new Date(2010, 0, 1),
                showPickerCustomer: false,
                showPickerContact: false,
                showPickerAppointment: false,
                showPickerOrder: false,
                showPickerBegin: false,
                showPickerEnd: false,
                showPop: false,
                //订单数组
                orderList: [],
                //联系人数组
                contactList: [],
                //明细数组
                dataList: []
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        enterCategory: 0
                    };
                    this.title = '新建合同';
                } else {
                    let itemDetail = await selectItemByContract({contractId: this.id});
                    itemDetail = itemDetail.data
                    this.dataList = itemDetail.costProducts
                    this.formInline = itemDetail;
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑合同';
                    } else {
                        this.title = '合同详情';
                    }
                }
            },

            //客户提交
            async submitCustomerList(id, name) {
                this.formInline.customerId = id;
                this.formInline.customer = name;

                //改变客户后，表单重置
                this.orderList = []
                this.formInline.orderId = null
                this.formInline.order = ''
                this.formInline.orderPrice = 0
                this.formInline.price = 0
                this.formInline.taxRate = 0
                this.formInline.taxPrice = 0
                const result = await fetchAllOrderByCustomerId({customerId: this.formInline.customerId})
                this.orderList = result.data.records

                const res = await fetchContactsByCustomerId({
                    customerId: this.formInline.customerId,
                    pageSize: 1000,
                    pageNum: 1
                })
                this.contactList = res.data.records

                this.showPickerCustomer = false
            },

            //关闭选择客户的弹窗
            handleCloseCustomer() {
                this.showPickerCustomer = false;
            },

            //确认签约人
            async onConfirmContact(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //确认订单
            async onConfirmOrder(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];

                const result = await getDetailByOrderId({orderId: this.formInline.orderId})
                this.formInline.discountPrice = result.data.discountPrice
                this.dataList = result.data.costProducts || []

                this[visible] = false;
            },

            //签约日期确认
            onConfirmAppointment(value) {
                this.formInline.appointmentTime = formatDate(value, 'yyyy-MM-dd');
                this.showPickerAppointment = false;
            },

            //开始日期确认
            onConfirmBegin(value) {
                this.formInline.contractStateTime = formatDate(value, 'yyyy-MM-dd');
                this.showPickerBegin = false;
            },

            //结束日期确认
            onConfirmEnd(value) {
                this.formInline.contractEndTime = formatDate(value, 'yyyy-MM-dd');
                this.showPickerEnd = false;
            },

            //选择人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.leaderId = activeId
                this.formInline.leaderStr = names
            },

            //关闭选择人
            handleCloseUser() {
                this.showPop = false
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            async onSubmit() {
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateCrmContract(data) : await addCrmContract(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
