<template>
  <div class="app-container">
    <navBar :title="'选择用户'" @handleGoBack="handleGoBack">
      <template v-slot:icon2>
        <div class="confirm-btn" @click.stop="handleConfirmClick">确定</div>
      </template>
    </navBar>
    <div class="wrap">
      <van-search class="search-input" v-model="value" placeholder="请输入搜索关键词" />
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-cell class="my-cell" v-for="item in list" :key="item.id">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="left-cell flex-ver-cen">
            <img :src="item.avatar || '../../assets/images/chidao.png'" alt="" class="avatar" />
            <div class="name">{{ item.realname }}</div>
          </div>
        </template>
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <van-checkbox v-model="item.checked" checked-color="#294D7D"></van-checkbox>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
import { getAllUser } from '@/api/system/user';
export default {
  data() {
    return {
      value: null,
      list: [],
      loading: false,
      finished: true,
      checked: true,
    };
  },

  computed: {
    applyIdList() {
      return this.$store.state.attendance.applyIdList;
    },
  },

  watch: {
    applyIdList: {
      handler(val) {},
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.getAllUserList();
  },

  methods: {
    async getAllUserList() {
      const result = await getAllUser();
      result.data.forEach((ele) => (ele.checked = false));
      this.list = JSON.parse(JSON.stringify(result.data));
    },

    onLoad() {},

    handleGoBack() {
      this.$router.replace('/attendance/ruleSet/0');
        this.$router.go(-1)
    },

    handleConfirmClick() {
      const applyIdList = this.list.filter((ele) => ele.checked == true);
      this.$store.commit('attendance/SET_APPLY_ID_LIST', applyIdList);
      this.$router.push({
        path: '/attendance/ruleSet/0',
        query: {
          sign: 'user',
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  background: #fff;
}
.search-input {
  width: 710px;
  margin: 0 auto;
}
.my-cell {
  padding: 24px 40px;
}
.left-cell {
  .avatar {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 100%;
  }
  .name {
    font-size: 30px;
    font-weight: bold;
  }
}

::v-deep .van-checkbox__icon .van-icon {
  width: 25px;
  height: 25px;
}
::v-deep .van-checkbox__icon {
  line-height: none;
}

.confirm-btn {
  width: 100px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: #0091ff;
  color: #fff;
  font-size: 24px;
  border-radius: 5px;
}
</style>
