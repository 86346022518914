import request from '@/utils/request'

// 查询采购付款列表
export function getAllPaymentList(query){
  return request({
    url: "/cw/payment/getList",
    method: "get",
    params: query
  });
}
// 查询采购付款详情
export function getAllPaymentInfo(query){
  return request({
    url: "/cw/payment/getId",
    method: "get",
    params: query
  });
}
// 查询采购付款列表
export function updateCollection(data){
  return request({
    url: "/cw/payment/updateCollection",
    method: "put",
    data
  });
}

// 查询所有采购订单
export function getALLPurchaseOrderList(query){
  return request({
    url: "/purchase/order/getList/0",
    method: "get",
    params: query
  });
}
