<template>
    <div class="app-container">
        <div class="wrap statistic">
            <navBar :title="title" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-popover
                            v-model="showPopover"
                            trigger="click"
                            placement="bottom-end"
                            :actions="actions"
                            @select="onSelect"
                    >
                        <template #reference>
                            <van-icon name="exchange" class="mrg-r-20"/>
                        </template>
                    </van-popover>
                </template>
            </navBar>
            <InstockTendency :isShow="judgeObj['instockTendency']"/>
            <InstockCategory :isShow="judgeObj['instockCategory']"/>
            <InstockMaterial :isShow="judgeObj['instockMaterial']"/>
            <InstockAchievement :isShow="judgeObj['instockAchievement']"/>
            <OutstockTendency :isShow="judgeObj['outstockTendency']"/>
            <OutstockMaterial :isShow="judgeObj['outstockMaterial']"/>
            <OutstockCategory :isShow="judgeObj['outstockCategory']"/>
            <OutstockAchievement :isShow="judgeObj['outstockAchievement']"/>
        </div>
    </div>
</template>
<script>
    import InstockTendency from './components/instockTendency';
    import InstockCategory from './components/instockCategory';
    import InstockMaterial from './components/instockMaterial';
    import InstockAchievement from './components/instockAchievement';
    import OutstockTendency from './components/outstockTendency';
    import OutstockMaterial from './components/outstockMaterial';
    import OutstockCategory from './components/outstockCategory';
    import OutstockAchievement from './components/outstockAchievement';

    export default {
        components: {
            InstockTendency,
            InstockCategory,
            InstockMaterial,
            InstockAchievement,
            OutstockTendency,
            OutstockMaterial,
            OutstockCategory,
            OutstockAchievement
        },
        data() {
            return {
                title: '',
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                queryParams: {
                    pageNum: 1,
                    pageSize: 5
                },
                dataList: [],
                //菜单切换
                showPopover: false,
                itemId: null,
                //菜单数组
                actions: this.$route.query.children.map(item => {
                    return {path: item.imgUrl, text: item.title, className: 'appendix-item-action-name2'}
                }),
                //菜单是否显示
                judgeObj: {},
                //上一次点击的菜单
                oldPath: ''
            };
        },

        mounted() {
            //组装数据
            this.packData()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/statement/workbench');
                this.$router.go(-1)
            },

            //组装数据
            packData() {
                this.actions.forEach(item => {
                    this.judgeObj[item.path] = false
                })

                if (this.actions) {
                    this.judgeObj[this.actions[0].path] = true
                    this.title = this.actions[0].text
                } else {
                    this.title = '库存报表'
                }
            },

            handleAdd() {
                this.showPopover = true
            },

            //点击菜单
            onSelect(action) {
                if (this.oldPath) {
                    this.judgeObj[this.oldPath] = false
                } else {
                    this.judgeObj['instockTendency'] = false
                }
                this.title = action.text
                this.judgeObj[action.path] = true
                this.oldPath = action.path
                this.$forceUpdate()
            },
        }
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

</style>
