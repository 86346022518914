<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <div class="line"></div>
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
            v-model="formInline.name"
            label="线索名称"
            name="name"
            placeholder="请输入"
            :required="true"
            :rules="[{ required: true, message: '请输入线索名称' }]"
          />
          <van-field
            readonly
            clickable
            name="clueSource"
            v-model="formInline.clueSourceName"
            label="线索来源"
            placeholder="请选择"
            @click="showSourcePicker = true"
            :required="true"
            :rules="[{ required: true, message: '请选择线索来源' }]"
          />
          <van-popup v-model="showSourcePicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="clueSourceOptions"
              value-key="dictLabel"
              @confirm="
                onConfirm($event, 'clueSource', 'clueSourceName', 'dictLabel', 'showSourcePicker')
              "
              @cancel="showSourcePicker = false"
            />
          </van-popup>
          <van-field
            v-model="formInline.contactWay"
            label="邮箱"
            name="contactWay"
            placeholder="请输入"
          />
          <van-field v-model="formInline.phone" label="手机" name="phone" placeholder="请输入" />

          <van-field
            readonly
            clickable
            name="level"
            v-model="formInline.levelName"
            label="客户级别"
            placeholder="请选择"
            @click="showLevelPicker = true"
            :required="true"
            :rules="[{ required: true, message: '请选择客户级别' }]"
          />
          <van-popup v-model="showLevelPicker" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictLabel"
              :columns="levelOptions"
              @confirm="onConfirm($event, 'level', 'levelName', 'dictLabel', 'showLevelPicker')"
              @cancel="showLevelPicker = false"
            />
          </van-popup>

          <van-field
            readonly
            clickable
            name="industry"
            v-model="formInline.industryName"
            label="客户行业"
            placeholder="请选择"
            @click="showIndustryPicker = true"
            :required="true"
            :rules="[{ required: true, message: '请选择客户行业' }]"
          />
          <van-popup v-model="showIndustryPicker" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictLabel"
              :columns="industryOptions"
              @confirm="
                onConfirm($event, 'industry', 'industryName', 'dictLabel', 'showIndustryPicker')
              "
              @cancel="showIndustryPicker = false"
            />
          </van-popup>

          <van-field
            readonly
            clickable
            name="address"
            v-model="formInline.addressName"
            label="地区选择"
            placeholder="点击选择省市区"
            @click="showArea = true"
          />
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :value="formInline.address"
              :area-list="areaList"
              @confirm="onAddressConfirm"
              @cancel="showArea = false"
            />
          </van-popup>

          <van-field
            v-model="formInline.addressDetail"
            label="详细地址"
            name="addressDetail"
            placeholder="请输入"
          />

          <van-field
            readonly
            clickable
            :value="formInline.time"
            label="下次联系"
            placeholder="点击选择日期"
            @click="showCalendar = true"
          />
          <van-calendar v-model="showCalendar" @confirm="onDateConfirm" />
          <div class="line"></div>

          <van-field
            v-model="formInline.remark"
            rows="1"
            autosize
            label="备注"
            placeholder="请输入"
            type="text"
            name="remark"
          />
          <van-button class="btn" block type="info" native-type="submit">提交</van-button>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import { areaList } from '@vant/area-data';
import { addClue, editClue } from '@/api/crm/clue';

export default {
  data() {
    return {
      formInline: {
        name: '',
        address: null,
        addressName: null,
        addressDetail: null,
        clueSourceName: null,
          nextContactTime: null,
          nextContactContent: null,
        contactWay: null,
        industryName: null,
        levelName: null,
        time: null,
        remark: null,
      },
      pattern: /\d{6}/,
      showPicker: false,
      areaList,
      showArea: false,
      showCalendar: false,
      clueSourceOptions: [],
      showSourcePicker: false,
      showLevelPicker: false,
      showIndustryPicker: false,
      levelOptions: [],
      industryOptions: [],
      title: '新增线索',
    };
  },

  mounted() {
    const itemDetail = this.$route.query.obj ? JSON.parse(this.$route.query.obj) : '';
    if (itemDetail) {
      this.formInline.id = itemDetail.id;
      this.formInline.name = itemDetail.name;
      this.formInline.addressName = itemDetail.address;
      this.formInline.address = itemDetail.address;
      this.formInline.addressDetail = itemDetail.addressDetail;
      this.formInline.clueSourceName = itemDetail.clueSourceStr;
      this.formInline.clueSource = itemDetail.clueSource;
      this.formInline.contactWay = itemDetail.contactWay;
      this.formInline.phone = itemDetail.phone;
      this.formInline.level = itemDetail.level;
      this.formInline.levelName = itemDetail.levelStr;
      this.formInline.industryName = itemDetail.industryStr;
      this.formInline.industry = itemDetail.industry;
      this.formInline.time = itemDetail.nextContactTime;
      this.formInline.nextContactTime = itemDetail.nextContactTime;
      this.formInline.remark = itemDetail.remark;
      this.title = '编辑线索';
    } else {
      this.formInline = {};
      this.title = '新增线索';
    }
    // 线索来源
    this.getDicts('clue_source').then((response) => {
      this.clueSourceOptions = response.data;
    });

    // 客户级别
    this.getDicts('customer_level').then((response) => {
      this.levelOptions = response.data;
    });

    // 客户行业
    this.getDicts('industry').then((response) => {
      this.industryOptions = response.data;
    });
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/crm/clue');
        this.$router.go(-1)
    },

    onConfirm(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['dictValue'];
      this.formInline[keyName] = event[eventKey];
      this[visible] = false;
    },

    onAddressConfirm(value) {
      this.formInline.addressName = `${value[0].name},${value[1].name},${value[2].name}`;
      this.formInline.address = `${value[0].name},${value[1].name},${value[2].name}`;

      this.showArea = false;
    },

    formatDate(date, key) {
      if (key) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      } else {
        return `${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}
      `;
      }
    },

    onDateConfirm(date) {
      this.showCalendar = false;
      this.formInline.time = this.formatDate(date, 'yy-MM-dd');
      this.formInline.nextContactTime = this.formatDate(date);
    },

    async onSubmit(values) {
      const params = {
        ...values,
        ...this.formInline,
      };
      const data = this.objectToFormData(params);
      const result = this.formInline.id ? await editClue(data) : await addClue(params);
      if (result.code == 200) {
        this.$toast(result.message);
          this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end
</style>
