import request from "@/utils/request";

// 查询列表
export function selectPage(query) {
  return request({
    url: "/crm/compete/getList",
    method: "get",
    params: query
  });
}

// 添加竞争对手
export function addCompete(data) {
  return request({
    url: "/crm/compete/add",
    method: "post",
    data
  });
}
// 编辑竞争对手
export function editCompete(data) {
  return request({
    url: "/crm/compete/update",
    method: "PUT",
    data
  });
}

// 根据销售竞争对手Id获取信息
export function getDetailByCompeteId(params) {
  return request({
    url: "/crm/compete/getId",
    method: "get",
    params
  });
}

// 删除竞争对手
export function delList(competeIds) {
  return request({
    url: "/crm/compete/delete/" + competeIds,
    method: "delete"
  });
}

// 根据用户id查询用户信息
export function fetchUserInfo(params) {
  return request({
    url: "/system/user/baseMsg/" + params,
    method: "get"
  });
}
