<template>
    <div class="wrap">
        <navBar :title="'消息通知'" @handleGoBack="handleGoBack">
        </navBar>
        <div class="article-title">{{ form.title }}</div>
        <div class="article-meta">
            <text>{{ form.createBy }} 发起于 {{ parseTime(form.createTime, '{y}-{m}-{d} {h}:{i}') }}</text>
        </div>
        <div class="article-content">
            {{ form.content }}
        </div>
        <div class="u-p-t-5"></div>
    </div>
</template>
<script>
    import {parseTime} from "@/utils/ruoyi";

    export default {
        data() {
            return {
                form: {
                    title: '最好用的快速开发平台',
                    author_name: '系统管理员',
                    published_at: '2021-4-20 12:30'
                },
            };
        },
        created() {
            this.form = this.$route.query.content ? JSON.parse(this.$route.query.content) : ''
        },
        methods: {
            //转换时间
            parseTime(time, pattern) {
                return parseTime(time, pattern)
            },

            handleGoBack() {
                this.$router.replace('/staticSystem/msg');
                this.$router.go(-1)
            },
        }
    };
</script>
<style lang="scss">
  page {
    background-color: #f8f8f8;
  }

  .article-title {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 15px;
    margin: 15px;
    border-bottom: 1px solid #c0c4cb;
    color: #51a0d5;
  }

  .article-meta {
    padding: 5px 25px 15px;
    color: gray;
  }

  .article-content {
    padding: 0 15px;
    overflow: hidden;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
    text-indent: 30px;

    ::v-deep p {
      margin-bottom: 10px;
    }
  }
</style>
