<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'发票管理'" @handleGoBack="handleGoBack">
                <template v-slot:icon1 v-if="this.tabTitle != '销售发票'">
                    <van-icon name="plus" class="mrg-r-20" @click="handleAdd"/>
                </template>
            </navBar>

            <div class="common-wrap">
                <van-search
                        show-action
                        v-model="queryParams.name"
                        placeholder="请输入编号/标题"
                        @search="handleQuery"
                >
                    <template #action>
                        <div class="search-btn-label" @click="handleQuery">搜索</div>
                    </template>
                </van-search>
            </div>

            <van-tabs class="my-tabs" @click="getListParams" v-model="active" ref="tabsRef">
                <van-tab title="发票抬头" :color="'#0091FF'" v-if="this.$auth.hasPermi('cw:invoiceHead:list')">
                    <div class="main">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    offset="100"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"
                            >
                                <div class="list" v-for="item in dataList" :key="item.id">
                                    <div class="line"></div>
                                    <div class="item-tab" @click="handleGoDetail(item.id)">
                                        <div class="title-wrap flex-spa-ver-cen">
                                            <div class="tit over_hidden">
                                                {{ item.name || '--' }}
                                            </div>
                                            <div class="level-btn">
                                                {{ item.typeStr }}
                                            </div>
                                        </div>
                                        <div class="con">
                                            <div class="desc-wrap flex">
                                                <div class="label">纳税人识别号：</div>
                                                <div class="value">{{ item.number || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">开户行：</div>
                                                <div class="value">{{ item.bank }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">开户账号：</div>
                                                <div class="value red">{{ item.accountName }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">联系电话：</div>
                                                <div class="value red">{{ item.phone }}</div>
                                            </div>
                                        </div>
                                        <div class="time-wrap flex-spa-ver-cen" @click.stop="operateAction(item.id)">
                                            <div class="time">创建日期：{{ item.createTime || '--' }}</div>
                                            <van-icon name="weapp-nav"/>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
                <van-tab title="销售发票" :color="'#0091FF'" v-if="this.$auth.hasPermi('cw:crmInvoiceHead:list')">
                    <div class="main">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    offset="100"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"
                            >
                                <div class="list" v-for="item in dataList" :key="item.id">
                                    <div class="line"></div>
                                    <div class="item-tab" @click="handleGoDetail(item.id)">
                                        <div class="title-wrap flex-spa-ver-cen">
                                            <div class="tit over_hidden">
                                                {{ item.name || '--' }}（{{ item.number || '--' }}）
                                            </div>
                                            <div class="level-btn">
                                                {{ item.typeStr }}
                                            </div>
                                        </div>
                                        <div class="con">
                                            <div class="desc-wrap flex">
                                                <div class="label">关联订单：</div>
                                                <div class="value">{{ item.order }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">开票金额：</div>
                                                <div class="value red">{{ item.price }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">负责人：</div>
                                                <div class="value red">{{ item.leaderId }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">开票日期：</div>
                                                <div class="value red">{{ item.invoiceDate }}</div>
                                            </div>
                                        </div>
                                        <div class="time-wrap flex-spa-ver-cen">
                                            <div class="time">创建日期：{{ item.createTime || '--' }}</div>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
                <van-tab title="采购发票" :color="'#0091FF'" v-if="this.$auth.hasPermi('cw:purInvoiceHead:list')">
                    <div class="main">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    offset="100"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"
                            >
                                <div class="list" v-for="item in dataList" :key="item.id">
                                    <div class="line"></div>
                                    <div class="item-tab" @click="handleGoDetail(item.id)">
                                        <div class="title-wrap flex-spa-ver-cen">
                                            <div class="tit over_hidden">
                                                {{ item.name || '--' }}（{{ item.number || '--' }}）
                                            </div>
                                            <div class="level-btn">
                                                {{ item.typeStr }}
                                            </div>
                                        </div>
                                        <div class="con">
                                            <div class="desc-wrap flex">
                                                <div class="label">关联订单：</div>
                                                <div class="value">{{ item.order || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">供应商：</div>
                                                <div class="value">{{ item.supplier || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">收票金额：</div>
                                                <div class="value">{{ item.price }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">负责人：</div>
                                                <div class="value red">{{ item.leaderName }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">收票日期：</div>
                                                <div class="value red">{{ item.invoiceDate }}</div>
                                            </div>
                                        </div>
                                        <div class="time-wrap flex-spa-ver-cen" @click.stop="operateAction(item.id)">
                                            <div class="time">创建日期：{{ item.createTime || '--' }}</div>
                                            <van-icon name="weapp-nav"/>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
                <van-tab title="生产发票" :color="'#0091FF'" v-if="this.$auth.hasPermi('cw:proInvoiceHead:list')">
                    <div class="main">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    offset="100"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"
                            >
                                <div class="list" v-for="item in dataList" :key="item.id">
                                    <div class="line"></div>
                                    <div class="item-tab" @click="handleGoDetail(item.id)">
                                        <div class="title-wrap flex-spa-ver-cen">
                                            <div class="tit over_hidden">
                                                {{ item.name || '--' }}（{{ item.number || '--' }}）
                                            </div>
                                            <div class="level-btn">
                                                {{ item.typeStr || '--' }}
                                            </div>
                                        </div>
                                        <div class="con">
                                            <div class="desc-wrap flex">
                                                <div class="label">加工商：</div>
                                                <div class="value">{{ item.subcontractor || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">关联订单：</div>
                                                <div class="value">{{ item.subcontract || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">收票金额：</div>
                                                <div class="value">{{ item.price }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">负责人：</div>
                                                <div class="value">{{ item.leaderName }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">收票日期：</div>
                                                <div class="value red">{{ item.invoiceDate }}</div>
                                            </div>
                                        </div>
                                        <div class="time-wrap flex-spa-ver-cen" @click.stop="operateAction(item.id)">
                                            <div class="time">创建日期：{{ item.createTime || '--' }}</div>
                                            <van-icon name="weapp-nav"/>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
                <van-tab title="入账开票" :color="'#0091FF'" v-if="this.$auth.hasPermi('cw:cwEntryInvoice:list')">
                    <div class="main">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    offset="100"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"
                            >
                                <div class="list" v-for="item in dataList" :key="item.id">
                                    <div class="line"></div>
                                    <div class="item-tab" @click="handleGoDetail(item.id)">
                                        <div class="title-wrap flex-spa-ver-cen">
                                            <div class="tit over_hidden">
                                                {{ item.name || '--' }}（{{ item.number || '--' }}）
                                            </div>
                                            <div class="level-btn">
                                                {{ item.typeStr || '--' }}
                                            </div>
                                        </div>
                                        <div class="con">
                                            <div class="desc-wrap flex">
                                                <div class="label">入账名称：</div>
                                                <div class="value">{{ item.entry || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">总金额：</div>
                                                <div class="value">{{ item.totalAmount || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">开票金额：</div>
                                                <div class="value">{{ item.price }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">开票日期：</div>
                                                <div class="value red">{{ item.invoiceDate }}</div>
                                            </div>
                                        </div>
                                        <div class="time-wrap flex-spa-ver-cen" @click.stop="operateAction(item.id)">
                                            <div class="time">创建日期：{{ item.createTime || '--' }}</div>
                                            <van-icon name="weapp-nav"/>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
                <van-tab title="出账收票" :color="'#0091FF'" v-if="this.$auth.hasPermi('cw:cwExpenditureInvoice:list')">
                    <div class="main">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    offset="100"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"
                            >
                                <div class="list" v-for="item in dataList" :key="item.id">
                                    <div class="line"></div>
                                    <div class="item-tab" @click="handleGoDetail(item.id)">
                                        <div class="title-wrap flex-spa-ver-cen">
                                            <div class="tit over_hidden">
                                                {{ item.name || '--' }}（{{ item.number || '--' }}）
                                            </div>
                                            <div class="level-btn">
                                                {{ item.typeStr || '--' }}
                                            </div>
                                        </div>
                                        <div class="con">
                                            <div class="desc-wrap flex">
                                                <div class="label">出账名称：</div>
                                                <div class="value">{{ item.expenditure || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">总金额：</div>
                                                <div class="value">{{ item.totalAmount }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">收票金额：</div>
                                                <div class="value">{{ item.price }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">负责人：</div>
                                                <div class="value">{{ item.leader }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">收票日期：</div>
                                                <div class="value red">{{ item.invoiceDate }}</div>
                                            </div>
                                        </div>
                                        <div class="time-wrap flex-spa-ver-cen" @click.stop="operateAction(item.id)">
                                            <div class="time">创建日期：{{ item.createTime || '--' }}</div>
                                            <van-icon name="weapp-nav"/>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <van-action-sheet
                v-model="actionShow"
                :actions="actions"
                @select="onSelect"
                cancel-text="取消"
                close-on-click-action
        />
    </div>
</template>

<script>
    import {deleteInvoiceHead, getAllInvoiceHeadList} from '@/api/cw/invoiceHead/invoiceHead';
    import {getAllCrmInvoiceList} from '@/api/cw/crmInvoiceHead/crmInvoiceHead';
    import {deletePurInvoice, getPurInvoiceList} from '@/api/cw/purInvoiceHead/purInvoiceHead'
    import {deleteProInvoice, getProInvoiceList} from '@/api/cw/proInvoiceHead/proInvoiceHead'
    import {deleteEntryInvoice, getEntryInvoiceList} from '@/api/cw/cwEntryInvoice/cwEntryInvoice'
    import {deleteExpendInvoice, getExpendInvoiceList} from '@/api/cw/cwExpenditureInvoice/cwEntryInvoice'

    export default {
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                active: 0,
                tabTitle: '',
                queryParams: {
                    pageNum: 1,
                    pageSize: 5
                },
                //操作的动作面板
                actionShow: false,
                //操作面板的操作
                actions: [
                    {name: '编辑', className: 'customer-opea-action'},
                    {name: '删除', className: 'customer-opea-action'}
                ],
                itemId: null,
                dataList: [],
                pathArray: {
                    '发票抬头':'invoiceHead',
                    '销售发票':'crmInvoice',
                    '采购发票':'purInvoice',
                    '生产发票':'proInvoice',
                    '入账开票':'cwEntryInvoice',
                    '出账收票':'cwExpenditureInvoice'
                }
            };
        },

        mounted() {
            this.tabTitle = this.$refs.tabsRef.children[0].title
            this.getList()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/cw/cwWorkbench');
                this.$router.go(-1)
            },

            async getList() {
                this.loading = true;
                let result = {}
                if (this.tabTitle == '发票抬头') {
                    result = await getAllInvoiceHeadList(this.queryParams)
                } else if (this.tabTitle == '销售发票') {
                    this.queryParams.screen = 0
                    result = await getAllCrmInvoiceList(this.queryParams)
                } else if (this.tabTitle == '采购发票') {
                    result = await getPurInvoiceList(this.queryParams)
                } else if (this.tabTitle == '生产发票') {
                    result = await getProInvoiceList(this.queryParams)
                } else if (this.tabTitle == '入账开票') {
                    result = await getEntryInvoiceList(this.queryParams)
                } else if (this.tabTitle == '出账收票') {
                    result = await getExpendInvoiceList(this.queryParams)
                }

                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.dataList = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.dataList = records;
                } else {
                    this.dataList = [...this.dataList, ...records];
                }
                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
            },

            onLoad() {
                if (this.refreshing) {
                    this.dataList = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.dataList.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            getListParams(index,title) {
                this.tabTitle = title
                this.finished = false;
                this.loading = false;
                this.dataList = [];
                this.queryParams.screen = index;
                this.handleQuery();
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },

            //点击操作展示动作面板
            operateAction(id) {
                this.itemId = id;
                this.actionShow = true;
            },

            //操作的动作面板被点击
            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                switch (item.name) {
                    case '编辑':
                        this.handleEdit();
                        break;
                    case '删除':
                        this.handleDelete();
                        break;
                }
            },

            handleAdd() {
                // 新增页面
                this.$router.push({
                    path: '/cw/' + this.pathArray[this.tabTitle] + '/add',
                    query: {
                        type: 'add'
                    },
                });
            },

            handleGoDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/cw/' + this.pathArray[this.tabTitle] + '/add',
                    query: {
                        id,
                        type: 'detail'
                    },
                });
            },

            // 编辑
            handleEdit() {
                // 新增页面
                this.$router.push({
                    path: '/cw/' + this.pathArray[this.tabTitle] + '/add',
                    query: {
                        id: this.itemId,
                        type: 'edit'
                    },
                });
            },

            // 删除
            handleDelete() {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除此数据?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        if (this.tabTitle == '发票抬头') {
                            await deleteInvoiceHead({invoiceHeadId: this.itemId})
                        } else if (this.tabTitle == '采购发票') {
                            await deletePurInvoice(this.itemId)
                        } else if (this.tabTitle == '生产发票') {
                            await deleteProInvoice(this.itemId)
                        } else if (this.tabTitle == '入账开票') {
                            await deleteEntryInvoice(this.itemId)
                        } else if (this.tabTitle == '出账开票') {
                            await deleteExpendInvoice(this.itemId)
                        }
                        this.$toast.success('删除成功');
                        await that.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

  .search {
    padding-top: 40px;
    background: #fff;
  }

  .my-tabs {
    padding-top: 30px;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        line-height: 38px;
        width: 0;
        flex: 1;
      }

      .level-btn {
        width: 150px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          white-space: nowrap;
          color: #999;
        }

        .value {
          color: #111;
          white-space: nowrap;
          overflow: hidden;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;
    }
  }

  ::v-deep .van-tabs__line {
    background-color: #0091ff;
  }

  .transfer-select-wrap {
    font-size: 28px;
  }

  ::v-deep .van-checkbox__label {
    line-height: 40px;
    font-size: 28px;
    padding-top: 10px;
  }

  ::v-deep .van-checkbox__icon .van-icon {
    width: 28px;
    height: 28px;
  }
</style>
