<template>
    <div class="detail-inner-con">
        <div class="title">基本信息</div>
        <div class="list-wrap">
            <div class="item-list flex-spa-bet">
                <div class="label">人员规模（人）</div>
                <div class="value over_hidden">{{ competeDetail.number || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">资本规模（元）</div>
                <div class="value over_hidden">{{ competeDetail.scale || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">年销售额（元）</div>
                <div class="value over_hidden">{{ competeDetail.annualSales || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">年利润额（元）</div>
                <div class="value over_hidden">{{ competeDetail.annualProfit || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">电子邮件</div>
                <div class="value over_hidden">{{ competeDetail.email || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">公司地址</div>
                <div class="value over_hidden">{{ competeDetail.address || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">公司官网</div>
                <div class="value over_hidden">{{ competeDetail.website || '--' }}</div>
            </div>
        </div>

        <div class="title">对手分析及对策</div>
        <div class="list-wrap">
            <div class="item-list flex-spa-bet">
                <div class="label">对手简介</div>
                <div class="value over_hidden">{{ competeDetail.briefIntroduction || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">业务方向</div>
                <div class="value over_hidden">{{ competeDetail.businessDirection || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">主要产品</div>
                <div class="value over_hidden">{{ competeDetail.mainProducts || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">最近签单情况</div>
                <div class="value over_hidden">{{ competeDetail.recentSigning || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">竞争优势</div>
                <div class="value over_hidden">{{ competeDetail.competitiveEdge || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">竞争劣势</div>
                <div class="value over_hidden">{{ competeDetail.competitiveDisadvantage || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">应对策略</div>
                <div class="value over_hidden">{{ competeDetail.copingStrategies || '--' }}</div>
            </div>
        </div>

        <div class="title">系统信息</div>
        <div class="list-wrap">
            <div class="item-list flex-spa-bet">
                <div class="label">创建人</div>
                <div class="value over_hidden">{{ competeDetail.createBy || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">创建时间</div>
                <div class="value over_hidden">{{ competeDetail.createTime || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">更新人</div>
                <div class="value over_hidden">{{ competeDetail.updateBy || '--' }}</div>
            </div>

            <div class="item-list flex-spa-bet">
                <div class="label">更新时间</div>
                <div class="value over_hidden">{{ competeDetail.updateTime || '--' }}</div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex';

    export default {
        computed: {
            ...mapGetters(['competeDetail']),
        },

        data() {
            return {};
        },

        mounted() {
        },
    };
</script>
<style lang="scss" scoped>
  .detail-inner-con {
  }

  .title {
    padding: 20px 40px;
    background: #fafafa;
    font-size: 24px;
    color: #666666;
  }

  .list-wrap {
    padding: 20px 40px;
    background: #fff;

    .item-list {
      padding: 30px 0;
      border-bottom: 1px solid #ececec;
      font-size: 28px;

      .label {
        width: 200px;
        color: #999999;
      }

      .value {
        width: 0;
        flex: 1;
        color: #333;
        text-align: right;
        margin-left: 20px;
      }
    }
  }
</style>
