<template>
    <div class="app-container">
        <van-popup v-model="show" style="width: 100%; height: 100%">
            <navBar :title="'选择供应商分类'" @handleGoBack="handleGoBack">
                <template v-slot:icon2>
                    <div class="confirm-btn" @click="handleConfirmClick">确定</div>
                </template>
            </navBar>
            <vs-tree
                    v-if="data.length > 0"
                    class="my-tree"
                    :data="data"
                    ref="tree"
                    showCheckbox
                    highlightCurrent
                    accordion
                    animation
                    :showRadio="true"
            ></vs-tree>
        </van-popup>
    </div>
</template>
<script>
    import {selectAllCategroy} from '@/api/purchase/supplier';

    export default {
        props: {
            showPop: Boolean,
        },
        data() {
            return {
                value: null,
                data: [],
                show: false,
                dataList: [],
            };
        },

        watch: {
            showPop: {
                handler() {
                    this.show = this.showPop;
                    if (this.show) {
                        this.getTreeSelect();
                    }
                },
                immediate: true,
                deep: true,
            },
        },

        methods: {
            async getTreeSelect() {
                const result = await selectAllCategroy();
                this.data = this.handleName(result.data);
            },

            handleName(list) {
                const newList = list.map((ele) => {
                    return {
                        name: ele.label,
                        id: ele.id,
                        children: ele.children && ele.children.length > 0 ? this.handleName(ele.children) : [],
                    };
                });
                return newList;
            },

            //返回
            handleGoBack() {
                let nodes = this.$refs.tree.getCheckedNodes();
                this.dataList = nodes.map((node) => {
                    return {id: node.id, name: node.name};
                });

                this.$emit('handleClose', this.dataList);
            },

            //确认按钮
            handleConfirmClick() {
                this.handleGoBack();
            },
        },
    };
</script>
<style lang="scss" scoped>
  ::v-deep .vs-tree-node {
    font-size: 30px;
    height: 80px;
  }

  ::v-deep .vs-checkbox__inner,
  ::v-deep .vs-radio__inner {
    width: 30px;
    height: 30px;
  }

  ::v-deep .expand,
  ::v-deep .expand-empty {
    width: 100px;
  }

  ::v-deep .expand.vs-expand-icon:after {
    width: 40px;
    height: 30px;
    background-size: 100% 100%;
    margin-top: -8px;
  }

  ::v-deep .vs-checkbox__inner:after {
    width: 15px;
    height: 15px;
    top: 2px;
  }

  ::v-deep .is-indeterminate .vs-checkbox__inner:before {
    top: 7px;
  }

  .wrap {
    background: #fff;
  }

  .search-input {
    width: 710px;
    margin: 0 auto;
  }

  .my-tree {
    background: #fff;
    margin-top: 20px;
  }

  .confirm-btn {
    width: 100px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background: #0091ff;
    color: #fff;
    font-size: 24px;
    border-radius: 5px;
  }
</style>
