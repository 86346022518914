<template>
    <div>
        <van-dialog
                v-model="dialogVisible"
                title="客户查重" width="95%"
                style="font-size: 20px"
                :showConfirmButton="false"
        >
            <div class="common-wrap">
                <van-search
                        show-action
                        v-model="queryParams.customerName"
                        placeholder="请输入客户名称/手机"
                        @search="handleQuery"
                >
                    <template #action>
                        <div class="search-btn-label" @click="handleQuery" style="font-size: 14px">搜索</div>
                    </template>
                </van-search>
            </div>
            <div class="main">
                <van-list
                        finished-text="没有更多了"
                >
                    <div class="list" v-for="item in dataList" :key="item.id">
                        <div class="line"></div>
                        <div class="item-tab">
                            <div class="title-wrap flex-spa-ver-cen">
                                <div class="tit over_hidden">
                                    {{ item.name || '--' }}
                                </div>
                            </div>
                            <div class="con">
                                <div class="desc-wrap flex">
                                    <div class="label">负责人：</div>
                                    <div class="value">{{ item.leaderId || '--' }}</div>
                                </div>
                                <div class="desc-wrap flex">
                                    <div class="label">创建时间：</div>
                                    <div class="value">{{ item.createTime || '--' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
            <van-pagination
                    v-show="total > 0"
                    v-model="queryParams.pageNum"
                    :total-items="total"
                    :show-page-size="pageCount>3?3:pageCount"
                    :items-per-page="3"
                    force-ellipses
                    style="margin-bottom: 20px;margin-top:20px;font-size: 16px"
                    @change="getCustomerList"
            />
            <van-button type="info" style="width: 100%;height: 50px;font-size: 20px" @click="handleClose">取消
            </van-button>
        </van-dialog>
    </div>
</template>
<script>
    import {checkDuplicate} from '@/api/crm/customer'

    export default {
        props: {
            customerVisible: Boolean
        },
        watch: {
            customerVisible: {
                handler() {
                    this.dialogVisible = this.customerVisible;
                },
                deep: true,
                immediate: true
            }
        },

        data() {
            return {
                queryParams: {
                    pageNum: 1,
                    pageSize: 3
                },
                total: 0,
                //总页数
                pageCount: 0,
                dialogVisible: false,
                dataList: []
            }
        },

        methods: {
            async getCustomerList() {
                const {data} = await checkDuplicate(this.queryParams)
                this.dataList = data.records
                this.total = data.total
                this.pageCount = data.pages
            },

            handleQuery() {
                this.queryParams.pageNum = 1
                this.getCustomerList()
            },

            handleClose() {
                this.dialogVisible = false
                this.$emit('handleClose')
            },
        }
    }
</script>
<style lang="scss" scoped>
  .title {
    font-size: 18px;
    margin: 10px 0;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 0;
        line-height: 38px;
        flex: 1;
      }

      .level-btn {
        width: 80px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
