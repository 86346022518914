<template>
    <div class="app-container">
        <div class="wrap">
            <navBar title="退款详情" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first>
                    <van-field
                            v-model="formInline.theme"
                            label="退款标题"
                            disabled
                    />
                    <van-field
                            v-model="formInline.order"
                            label="关联订单"
                            disabled
                    />
                    <van-field
                            v-model="formInline.refundAmount"
                            label="总金额"
                            disabled
                    />
                    <van-field
                            v-model="formInline.createBy"
                            label="负责人"
                            disabled
                    />
                    <van-field
                            v-model="formInline.returnDate"
                            label="退款时间"
                            disabled
                    />
                    <van-field
                            v-model="formInline.returnState"
                            label="退款状态"
                            disabled
                    />

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"
                                   :disabled="true"/>

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">当前第{{ index + 1 }}期</div>
                            <van-icon name="delete-o" @click="handleDelete(item.id)"/>
                        </div>

                        <van-field
                                v-model="item.name"
                                label="计划名称"
                                disabled
                        />

                        <van-field
                                v-model="item.price"
                                label="退款金额"
                                disabled
                        />

                        <van-field
                                v-model="item.returnPriceDate"
                                label="退款时间"
                                disabled
                        />
                    </div>
                    <div class="add-btn flex-hor-ver-cen" @click="handleAdd">
                        <van-icon name="add-o" class="mrg-r-20"/>
                        添加子计划
                    </div>
                </van-form>
            </div>
        </div>

        <van-dialog
                v-model="showPicker"
                title="退款子计划"
                width="70%"
                style="font-size: 14px;"
                :showConfirmButton="false"
                :close-on-click-overlay="true"
        >
            <van-form validate-first>
                <van-field
                        v-model="subForm.refundAmount"
                        label-width="80px"
                        style="line-height: 40px;"
                        label="总金额"
                        disabled
                />

                <van-field
                        v-model="subForm.name"
                        label-width="80px"
                        style="line-height: 40px;"
                        label="计划名称"
                        :required="true"
                        :rules="[{ required: true, message: '请输入计划名称' }]"
                />
                <van-field
                        v-model="subForm.price"
                        label-width="80px"
                        label="退款金额"
                        placeholder="请输入"
                        style="line-height: 40px;"
                        :required="true"
                        :rules="[{ required: true, message: '请输入退款金额' }]"
                />

                <van-field
                        readonly
                        clickable
                        :value="subForm.returnPriceDate"
                        label-width="80px"
                        style="line-height: 40px;"
                        label="退款日期"
                        placeholder="点击选择日期"
                        :required="true"
                        :rules="[{ required: true, message: '请选择始日期' }]"
                        @click="showPickerDate = true"
                />

                <van-field
                        v-model="subForm.remark"
                        label-width="80px"
                        style="line-height: 40px;"
                        label="备注"
                        placeholder="请输入"
                />
            </van-form>
            <van-button type="info" style="width: 100%;height: 30px;font-size: 18px" @click="submitFormSub">确认
            </van-button>
        </van-dialog>
        <van-calendar :min-date="minDate" v-model="showPickerDate"
                      @confirm="onConfirmDate($event)"/>

    </div>
</template>
<script>
    import {getAllPurReturnInfo, addReturnSub, delReturnSub} from '@/api/cw/purReturn/purReturn';
    import {formatDate} from "@/utils/ruoyi";

    export default {
        data() {
            return {
                formInline: {},
                subForm: {},
                id: this.$route.query.id,
                showPickerDate: false,
                showPicker: false,
                minDate: new Date(2010, 0, 1),
                //明细数组
                dataList: []
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                let itemDetail = await getAllPurReturnInfo({returnId: this.id});
                itemDetail = itemDetail.data
                this.formInline = itemDetail;
                this.dataList = itemDetail.cwReturnSubs
            },

            //日期确认
            onConfirmDate(value) {
                this.subForm.returnPriceDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerDate = false;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            //添加子计划
            handleAdd() {
                this.subForm = {
                    returnId: this.formInline.id,
                    refundAmount: this.formInline.refundAmount,
                    returnPriceDate: ''
                }
                this.showPicker = true;
            },

            //删除子计划
            handleDelete(id) {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除子退款?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await delReturnSub({cwReturnSubId: id});
                        this.$toast.success('删除成功');
                        await that.findDetail();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },

            //提交
            async submitFormSub() {
                if (Number(this.subForm.price) > Number(this.subForm.refundAmount)) {
                    this.$toast.fail("退款金额不能大于退款总金额");
                    return;
                }
                if (this.subForm.price < 0) {
                    this.$toast.fail("退款金额不能为负数");
                    return;
                }

                await addReturnSub(this.subForm).then(() => {
                    this.$toast.success('操作成功');
                    this.showPicker = false;
                    this.findDetail()
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
