<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'仓库管理'" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-icon name="plus" class="mrg-r-20" @click="handleAdd(0)"/>
                </template>
            </navBar>
            <vs-tree
                    v-if="data.length > 0"
                    class="my-tree"
                    :data="data"
                    ref="tree"
                    showCheckbox
                    highlightCurrent
                    accordion
                    animation
                    :showCheckbox="false"
                    @click="nodeClick"
            >
            </vs-tree>
        </div>

        <van-action-sheet
                v-model="actionShow"
                :actions="actions"
                @select="onSelect"
                cancel-text="取消"
                close-on-click-action
        />
    </div>
</template>
<script>
    import {warehouseTreeselect,delWarehouse} from '@/api/inventory/warehourse';

    export default {
        data() {
            return {
                formInline: {},
                data: [],
                dataList: [],
                showPicker: false,
                //操作的动作面板
                actionShow: false,
                //操作面板的操作
                actions: [
                    {name: '添加子仓库', className: 'customer-opea-action'},
                    {name: '编辑此仓库', className: 'customer-opea-action'},
                    {name: '删除此仓库', className: 'customer-opea-action'}
                ],
                itemId: null,
            };
        },

        mounted() {
            //回显
            this.getTreeSelect();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/inventory/inventoryWorkbench');
                this.$router.go(-1)
            },

            //回显
            async getTreeSelect() {
                const result = await warehouseTreeselect();
                this.data = this.handleName(result.data);
            },

            handleName(list) {
                const newList = list.map((ele) => {
                    return {
                        name: ele.label,
                        id: ele.id,
                        children: ele.children && ele.children.length > 0 ? this.handleName(ele.children) : [],
                    };
                });
                return newList;
            },

            nodeClick(event, node){
                this.itemId = node.data.id;
                this.formInline.typeLevel = node.data.typeLevel
                this.actionShow = true;
            },

            //操作的动作面板被点击
            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                switch (item.name) {
                    case '添加子仓库':
                        this.handleAdd(this.itemId);
                        break;
                    case '编辑此仓库':
                        this.handleEdit();
                        break;
                    case '删除此仓库':
                        this.handleDelete();
                        break;
                }
            },

            //添加
            handleAdd(id){
                // 新增页面
                this.$router.push({
                    path: '/inventory/warehourse/add',
                    query: {
                        type: 'add',
                        parentId: id
                    },
                });
            },

            //编辑
            handleEdit(){
                // 新增页面
                this.$router.push({
                    path: '/inventory/warehourse/add',
                    query: {
                        id: this.itemId,
                        type: 'edit'
                    },
                });
            },

            // 删除
            handleDelete() {
                if(this.itemId == 0){
                    this.$toast.fail('禁止操作根节点');
                    return;
                }

                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除此仓库',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await delWarehouse(this.itemId);
                        this.$toast.success('删除成功');
                        location.reload();
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
        },
    };
</script>

<style lang="scss" scoped>
  ::v-deep .vs-tree-node {
    font-size: 30px;
    height: 80px;
  }

  ::v-deep .vs-checkbox__inner,
  ::v-deep .vs-radio__inner {
    width: 30px;
    height: 30px;
  }

  ::v-deep .expand,
  ::v-deep .expand-empty {
    width: 100px;
  }

  ::v-deep .expand.vs-expand-icon:after {
    width: 40px;
    height: 30px;
    background-size: 100% 100%;
    margin-top: -8px;
  }

  ::v-deep .vs-checkbox__inner:after {
    width: 15px;
    height: 15px;
    top: 2px;
  }

  ::v-deep .is-indeterminate .vs-checkbox__inner:before {
    top: 7px;
  }

  .wrap {
    background: #fff;
  }

  .search-input {
    width: 710px;
    margin: 0 auto;
  }

  .my-tree {
    background: #fff;
    margin-top: 20px;
  }

  .confirm-btn {
    width: 100px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background: #0091ff;
    color: #fff;
    font-size: 24px;
    border-radius: 5px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
