import request from '@/utils/request'

// 查询采购发票列表
export function getPurInvoiceList(query){
  return request({
    url: "/cw/purInvoice/getList",
    method: "get",
    params: query
  });
}
// 查询采购发票详情
export function getAllInvoiceHeadInfo(query){
  return request({
    url: "/cw/purInvoice/getId",
    method: "get",
    params: query
  });
}
// 新建
export function addCrmInvoice(data){
  return request({
    url: "/cw/purInvoice/addCrmInvoice",
    method: "post",
    data: data
  });
}
// 修改
export function updateCrmInvoice(data){
  return request({
    url: "/cw/purInvoice/updateCrmInvoice",
    method: "put",
    data: data
  });
}
// 删除
export function deletePurInvoice(invoiceIds){
  return request({
    url: "/cw/purInvoice/deleteCrmInvoice/"+invoiceIds,
    method: "delete"
  });
}
// 开票
export function openInvoice(invoiceId){
  return request({
    url: "/cw/purInvoice/open",
    method: "put",
    params: invoiceId
  });
}
