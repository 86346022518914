<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.theme"
                            label="退货标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入退货标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.order"
                            label="采购订单"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择采购订单' }]"
                            @click="showPickerOrder= true"
                    />
                    <van-popup v-model="showPickerOrder" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="orderOptions"
                                value-key="title"
                                @confirm="
                onConfirmOrder($event, 'orderId', 'order', 'title', 'showPickerOrder')
              "
                                @cancel="showPickerOrder = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.typeStr"
                            label="退货类别"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择退货类别' }]"
                            @click="showPickerType= true"
                    />
                    <van-popup v-model="showPickerType" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="typeOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'type', 'typeStr', 'dictLabel', 'showPickerType')
              "
                                @cancel="showPickerType = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.returnDate"
                            label="退货日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择退货日期' }]"
                            @click="showPickerReturn = true"
                    />
                    <van-calendar :min-date="minDate" :max-date="maxDate" v-model="showPickerReturn"
                                  @confirm="onConfirmReturn($event)"/>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.isWarehousingStr"
                            label="是否需要出库"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择是否需要出库' }]"
                            @click="showPickerWarehousing= true"
                    />
                    <van-popup v-model="showPickerWarehousing" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="warehousingOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'isWarehousing', 'isWarehousingStr', 'dictLabel', 'showPickerWarehousing')
              "
                                @cancel="showPickerWarehousing = false"
                        />
                    </van-popup>

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.refundAccountStr"
                            label="退款账户"
                            placeholder="请选择"
                            @click="showPickerAccount= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择退款账户' }]"
                    />

                    <van-field
                            type="number"
                            v-model="formInline.customerAmount"
                            placeholder="请输入"
                            label="供应商承担金额"
                            @input="handleTotalPrice()"
                    />

                    <van-field
                            type="number"
                            v-model="formInline.otherAmount"
                            placeholder="请输入"
                            label="其他费用"
                            @input="handleTotalPrice()"
                    />

                    <van-field
                            disabled
                            :value="formInline.refundAmount"
                            label="退款金额"
                    />

                    <van-field
                            v-model="formInline.returnType"
                            placeholder="请输入"
                            label="退款方式"
                    />

                    <van-field
                            v-model="formInline.returnPurType"
                            placeholder="请输入"
                            label="退货方式"
                    />

                    <van-field
                            v-model="formInline.returnAddress"
                            placeholder="请输入"
                            label="退货地址"
                    />

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
                        </div>

                        <van-field
                                v-model="item.materialSpecification"
                                label="规格型号"
                                disabled
                        />

                        <van-field
                                v-model="item.materialUnit"
                                label="单位"
                                disabled
                        />

                        <van-field
                                disabled
                                v-model="item.supplierName"
                                label="供应商"
                        />
                        <van-field
                                v-model="item.price"
                                label="单价"
                                disabled
                                @input="handleDetailTotal(item)"
                        />

                        <van-field
                                v-model="item.num"
                                label="数量"
                                disabled
                        />

                        <van-field
                                v-model="item.returnQuantity"
                                label="可退数量"
                                disabled
                        />

                        <van-field
                                type="number"
                                v-model="item.returnNumber"
                                label="退货数量"
                                :required="true"
                                :rules="[{ required: true, message: '请选择退货数量' }]"
                                @input="handleDetailTotal(item)"
                        />

                        <van-field
                                v-model="item.total"
                                label="总金额"
                                disabled
                        />

                        <van-field
                                v-model="item.remark"
                                label="备注"
                                placeholder="请输入"
                        />

                    </div>

                    <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>
        <Account :showDialog="showPickerAccount" @handleClose="handleCloseAccount" @submitList="submitList"/>
    </div>
</template>
<script>
    import {addData, getOrderDetails, selectOne, updateData} from '@/api/purchase/return';
    import {getReturnList} from '@/api/purchase/order';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import Account from '@/components/account'
    import {submiteUpload} from "@/api/system/config";
    import {formatDate} from "@/utils/ruoyi";

    export default {
        components: {AppendixVue, Account},
        data() {
            return {
                formInline: {
                    isWarehousing: false,
                    isWarehousingStr: '否'
                },
                title: '新建退货',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerOrder: false,
                showPickerType: false,
                showPickerAccount: false,
                showPickerReturn: false,
                showPickerWarehousing: false,
                uploader: [],
                uploadParams: {
                    businessType: 'pur_return',
                    businessId: undefined,
                    file: null,
                },
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(2050, 12, 31),
                //物料数组
                dataList: [],
                //采购订单
                orderOptions: [],
                //类别数组
                typeOptions: [],
                warehousingOptions: [
                    {'dictLabel': '否', 'dictValue': false},
                    {'dictLabel': '是', 'dictValue': true}
                ]
            };
        },

        mounted() {
            //查询采购订单
            getReturnList().then(response => {
                this.orderOptions = response.data
            })

            //退货类别字典
            this.getDicts('return_type').then(response => {
                this.typeOptions = response.data
            })

            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/purchase/purchaseReturn');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        isWarehousing: false,
                        isWarehousingStr: '否'
                    };
                    this.title = '新建退货';
                } else {
                    let itemDetail = await selectOne(this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail.crmReturn;
                    this.formInline.isWarehousingStr = itemDetail.isWarehousing ? '是' : '否';
                    this.dataList = itemDetail.purReturnPlanSubs
                    this.dataList.forEach(item => {
                        item.returnQuantity = item.returnNumber
                    })
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑退货';
                    } else {
                        this.title = '退货详情';
                    }
                }
            },

            //日期确认
            onConfirmReturn(value) {
                this.formInline.returnDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerReturn = false;
            },

            //确认
            async onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //确认
            async onConfirmOrder(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;

                //根据订单id查询物料明细
                await getOrderDetails(this.formInline.orderId).then(
                    response => {
                        let result = response.data
                        result.forEach(item => {
                            item.returnNumber = item.returnQuantity
                            item.planSubId = item.id
                        })
                        this.dataList = result

                        this.handleTotalPrice()
                        this.$forceUpdate()
                    }
                )
            },

            //账户关闭
            handleCloseAccount() {
                this.showPickerAccount = false
            },

            //账户提交
            submitList(id, name) {
                this.formInline.refundAccount = id;
                this.formInline.refundAccountStr = name;
                this.showPickerAccount = false

            },

            //单条明细总金额计算
            handleDetailTotal(item) {
                item.total = ((item.returnNumber ? item.returnNumber : 0) * (item.price ? item.price : 0)).toFixed(2)
                this.handleTotalPrice();
            },

            //计算金额
            handleTotalPrice() {
                let refundAmount = 0
                this.dataList.forEach(item => {
                    refundAmount += Number(item.total)
                })
                this.formInline.refundAmount = refundAmount - Number(this.formInline.customerAmount ? this.formInline.customerAmount : 0) + Number(this.formInline.otherAmount ? this.formInline.otherAmount : 0)
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            async onSubmit() {
                this.dataList.forEach(item => {
                    delete item.createBy
                    delete item.createTime
                    delete item.updateBy
                    delete item.updateTime
                    delete item.companyId
                })

                this.formInline.purReturnDetails = this.dataList
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateData(data) : await addData(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
