<template>
  <div class="app-container">
    <navBar :title="'统计'" @handleGoBack="handleGoBack">
    </navBar>

    <van-tabs v-model="active">
      <van-tab title="我的统计" name="my">
        <MyStaticVue />
      </van-tab>
      <van-tab title="团队统计" name="team" v-if="this.$auth.hasPermi('hr:checkwork:add')">
        <TeamStaticVue />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import MyStaticVue from './components/MyStatic.vue';
import TeamStaticVue from './components/TeamStatic.vue';
export default {
  components: {
    MyStaticVue,
    TeamStaticVue,
  },

  data() {
    return {
      active: 'my',
    };
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/attendance');
        this.$router.go(-1)
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}

::v-deep .van-tab {
  padding: 60px 50px;
  font-size: 30px;
  font-weight: bold !important;
}

::v-deep .van-tab--active {
  font-weight: bold !important;
}
::v-deep .van-tabs__line {
  height: 6px;
}
</style>
