import * as echarts from "echarts";

// 柱状图
export const getEchartBar1 = (strList1,dataList1,xname,yname) => {
  return {
    title: {
      text: '供应商排名',
      left: 'left'
    },
    tooltip: {
      trigger: 'item'
    },
    grid: {
      left: '20%',
      right: '20%',
      bottom: '8%'
    },
    xAxis: {
      type: 'value',
      name: yname
    },
    yAxis: {
      type: 'category',
      data: strList1,
      name: xname
    },
    series: [
      {
        data: dataList1,
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        }
      }
    ]
  };
};

export const getEchartBar2 = (strList, dataList,xname) => {
  return {
    title: {
      text: '供应商统计',
      left: 'left'
    },
    grid: {
      left: '20%',
      right: '20%',
      bottom: '8%'
    },
    tooltip: {
      trigger: 'item'
    },
    dataset: {
      dimensions: strList,
      source: dataList
    },
    xAxis: { },
    yAxis: {type: 'category' ,name: xname},
    series: [{ type: 'bar' }, { type: 'bar' }]
  };
};
