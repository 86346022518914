import request from '@/utils/request'

// 销售预测分析
export function getSalesForecast(query){
  return request({
    url: "/crm/salesForecast",
    method: "get",
    params: query
  });
}

// 销售漏斗分析图
export function getCostFunnelList(query){
  return request({
    url: "/crm/costFunnel/getCostFunnelList",
    method: "get",
    params: query
  });
}

// 销售漏斗分析列表
export function getCostFunnel(query){
  return request({
    url: "/crm/costFunnel/getCostFunnel",
    method: "get",
    params: query
  });
}

// 业务新增总汇
export function businessAddTotal(query){
  return request({
    url: "/crm/businessAdd/getBusinessTime",
    method: "get",
    params: query
  });
}

// 人员跟进统计
export function personnelFollow(query){
  return request({
    url: "/crm/personnelFollow/personnelFollow",
    method: "get",
    params: query
  });
}

// 跟进类型统计 列表
export function personnelTypeFollow(query){
  return request({
    url: "/crm/personnelTypeFollow/personnelTypeFollow",
    method: "get",
    params: query
  });
}

// 跟进类型统计 图
export function personnelTypeTotal(query){
  return request({
    url: "/crm/personnelTypeFollow/personnelTypeTotal",
    method: "get",
    params: query
  });
}

// 业务综合统计 列表
export function businessTotal(query){
  return request({
    url: "/crm/businessYear/getBusinessYear",
    method: "get",
    params: query
  });
}

// 业务综合统计 图
export function businessTotal2(query){
  return request({
    url: "/crm/businessYear/getBusiness",
    method: "get",
    params: query
  });
}

// 业务年度统计
export function businessYear(query){
  return request({
    url: "/crm/businessTime/getBusinessTime",
    method: "get",
    params: query
  });
}

// 业务月度统计
export function businessMonth(query){
  return request({
    url: "/crm/businessMonth/businessMonth",
    method: "get",
    params: query
  });
}

// 成交均价分析 成交均价人员统计
export function getOrderAverageByUser(query){
  return request({
    url: "/crm/orderAverage/getOrderAverageByUser",
    method: "get",
    params: query
  });
}

// 成交均价分析 客户来源均价统计
export function getOrderCustomerSource(query){
  return request({
    url: "/crm/orderAverage/getOrderCustomerSource",
    method: "get",
    params: query
  });
}

// 成交均价分析 客户来源均价统计
export function getOrderIndustry(query){
  return request({
    url: "/crm/orderAverage/getOrderIndustry",
    method: "get",
    params: query
  });
}

// 回款金额统计/排名
export function getCollection(query){
  return request({
    url: "/crm/collection/getCollection",
    method: "get",
    params: query
  });
}

// 成交商机总汇
export function getOrderIncrease(query){
  return request({
    url: "/crm/orderIncrease/getOrderIncrease",
    method: "get",
    params: query
  });
}

// 产品销量总汇
export function getCostProduct(query){
  return request({
    url: "/crm/costProduct/getCostProduct",
    method: "get",
    params: query
  });
}

// 产品分类总汇
export function getCostProductType(query){
  return request({
    url: "/crm/costProductType/getCostProductType",
    method: "get",
    params: query
  });
}

// 产品时间销量
export function getCostProductTime(query){
  return request({
    url: "/crm/costProductTime/getCostProductTime",
    method: "get",
    params: query
  });
}

// 客户，线索，商机，订单排名获取
export function getCrmCustomerRanking(query){
  return request({
    url: "/crm/crmCustomerRanking/getCrmCustomerRanking",
    method: "get",
    params: query
  });
}

// 成交金额排名
export function getOrderRanking(query){
  return request({
    url: "/crm/orderRanking/getOrderRanking",
    method: "get",
    params: query
  });
}

// 合同金额排名
export function getContractRanking(query){
  return request({
    url: "/crm/contractRanking/getContractRanking",
    method: "get",
    params: query
  });
}

// 人员利润统计
export function getPersonnelProfit(query){
  return request({
    url: "/crm/personnelProfit/getPersonnelProfit",
    method: "get",
    params: query
  });
}

// 年度利润分析
export function yearOnYearGrowth(query){
  return request({
    url: "/crm/yearOnYearGrowth/getTime",
    method: "get",
    params: query
  });
}

// 月度利润分析
export function monthYearOnYearGrowth(query){
  return request({
    url: "/crm/monthYearOnYearGrowth/getTime",
    method: "get",
    params: query
  });
}

// 产品利润分析
export function CostProductYearOnYearGrowth(query){
  return request({
    url: "/crm/CostProductYearOnYearGrowth/getCostProduct",
    method: "get",
    params: query
  });
}





