import * as echarts from "echarts";

// 柱状图
export const getEchartBar = (strList, dataList,xname,yname) => {
  return {
    title: {
      text: '采购金额',
      left: 'left'
    },
    tooltip: {
      trigger: 'item'
    },
    grid: {
      left: '20%',
      right: '20%',
      bottom: '8%'
    },
    xAxis: {
      type: 'value',
      name: yname
    },
    yAxis: {
      type: 'category',
      data: strList,
      name: xname
    },
    series: [
      {
        data: dataList,
        type: 'bar'
      }
    ]
  };
};
// 饼状图
export const getEchartPie = (priceList) => {
  return {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    grid: {
      left: '20%',
      right: '20%',
      bottom: '8%'
    },
    series: [
      {
        name: '金额',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '40',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: priceList
      }
    ]
  };
};
// 折线图
export const getEchartLine = (nameList, quaList,xname,yname) => {
  return {
    title: {
      text: '统计金额',
      left: 'left'
    },
    tooltip: {
      trigger: 'item'
    },
    xAxis: {
      type: 'value',
      name: yname,
    },
    yAxis: {
      type: 'category',
      data: nameList,
      name: xname
    },
    series: [
      {
        data: quaList,
        type: 'line'
      }
    ]
  };
};
