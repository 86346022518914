import request from '@/utils/request'

// 查询采购发票列表
export function getEntryInvoiceList(query){
  return request({
    url: "/cw/cwEntryInvoice/getList",
    method: "get",
    params: query
  });
}
// 查询采购发票详情
export function getAllInvoiceHeadInfo(query){
  return request({
    url: "/cw/cwEntryInvoice/getId",
    method: "get",
    params: query
  });
}
// 新建
export function addCrmInvoice(data){
  return request({
    url: "/cw/cwEntryInvoice/add",
    method: "post",
    data: data
  });
}
// 修改
export function updateCrmInvoice(data){
  return request({
    url: "/cw/cwEntryInvoice/update",
    method: "put",
    data: data
  });
}
// 删除
export function deleteEntryInvoice(invoiceIds){
  return request({
    url: "/cw/cwEntryInvoice/delete/"+invoiceIds,
    method: "delete"
  });
}
// 开票
export function openInvoice(invoiceId){
  return request({
    url: "/cw/cwEntryInvoice/open",
    method: "put",
    params: invoiceId
  });
}
//查询所有入账
export function getEntryAllList(query){
  return request({
    url: "/cw/cwEntryInvoice/getEntryList",
    method: "get",
    params: query
  });
}
