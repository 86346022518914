import * as echarts from "echarts";

//待回款仪表盘
export const getEchart = (time, crmCollectionList,crmReturns,proSubPaymentList,purPaymentList) => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                lineStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'rgba(126,199,255,0)' // 0% 处的颜色
                            },
                            {
                                offset: 0.5,
                                color: 'rgba(126,199,255,1)' // 100% 处的颜色
                            },
                            {
                                offset: 1,
                                color: 'rgba(126,199,255,0)' // 100% 处的颜色
                            }
                        ],
                        global: false // 缺省为 false
                    }
                }
            }
        },
        dataset: {
            source: [
                // ["product", "销售回款", "采购付款", "销售退款", "生产付款"],
                // ["Matcha Latte", 43.3, 85.8, 93.7],
                // ["Milk Tea", 83.1, 73.4, 55.1],
                // ["Cheese Cocoa", 86.4, 65.2, 82.5],
                // ["Walnut Brownie", 72.4, 53.9, 39.1]
            ]
        },
        grid: {
            left: '0%',
            right: '1%',
            bottom: '15%',
            top: '1%',
            containLabel: true
        },
        legend: {
            data: ['销售回款', '采购付款', '销售退款', '生产付款'],
            // orient: "vertical",
            bottom: 'bottom'
        },
        yAxis: {
            type: 'category',
            data: time,
            axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                    color: '#eee'
                }
            },
            axisLabel: {
                //坐标轴刻度标签的相关设置
                textStyle: {
                    color: '#333',
                    // padding: 16,
                    fontSize: 14
                }
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                }
            }
        },
        xAxis: {
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#eee'
                }
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#333',
                    fontSize: 14
                }
            }
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
            {
                name: '销售回款',
                type: 'bar',
                data: crmCollectionList,
                barWidth: '12px'
            },
            {
                name: '采购付款',
                type: 'bar',
                barWidth: '12px',
                data: purPaymentList
            },
            { name: '销售退款', type: 'bar', barWidth: '12px', data: crmReturns },
            {
                name: '生产付款',
                type: 'bar',
                barWidth: '12px',
                data: proSubPaymentList
            }
        ],
        color: [
            'rgba(84, 116, 195, 1)',
            'rgba(165, 214, 63, 1)',
            'rgba(255, 195, 0, 1)',
            'rgba(67, 207, 124, 1)'
        ] //颜色
    };
};
