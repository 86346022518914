<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="发票抬头"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入发票抬头' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.typeStr"
                            label="抬头类型"
                            placeholder="请选择"
                            @click="showPickerType= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择抬头类型' }]"
                    />
                    <van-popup v-model="showPickerType" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="typeOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'type', 'typeStr', 'dictLabel', 'showPickerType')
              "
                                @cancel="showPickerType = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.bank"
                            label="开户行"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入开户行' }]"
                    />

                    <van-field
                            v-model="formInline.accountName"
                            label="开户账号"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入开户账号' }]"
                    />

                    <van-field
                            v-model="formInline.number"
                            label="纳税人识别号"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入纳税人识别号' }]"
                    />

                    <van-field
                            v-model="formInline.phone"
                            label="联系电话"
                            placeholder="请输入"
                    />

                    <textAreaInput :remark="formInline.accountAddress" @change="handleChangeAddress"
                                   :title="'开票地址'"/>

                    <textAreaInput :remark="formInline.remark" @change="handleChangeRemark"
                                   :title="'备注'"/>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
    import {addInvoiceHead, getInvoiceHeadInfo, updateInvoiceHead} from '@/api/cw/invoiceHead/invoiceHead';

    export default {
        data() {
            return {
                formInline: {},
                title: '新建抬头',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerType: false,
                //类型
                typeOptions: [],
            };
        },

        mounted() {
            this.getDicts('invoice_head_type').then((response) => {
                this.typeOptions = response.data;
            });
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {};
                    this.title = '新建抬头';
                } else {
                    setTimeout(async () => {
                        let itemDetail = await getInvoiceHeadInfo({invoiceHeadId: this.id});
                        itemDetail = itemDetail.data
                        let typeObj = this.typeOptions.find(item => item.dictValue == String(itemDetail.type))
                        itemDetail.typeStr = typeObj ? typeObj.dictLabel : ''
                        this.formInline = itemDetail;
                        if (itemDetail && this.type == 'edit') {
                            this.title = '编辑抬头';
                        } else {
                            this.title = '抬头详情';
                        }
                    }, 20)
                }
            },

            //确认
            onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //开票地址
            handleChangeAddress(val) {
                this.formInline.accountAddress = val;
            },

            //备注
            handleChangeRemark(val) {
                this.formInline.remark = val;
            },

            async onSubmit() {
                const result = this.formInline.id ? await updateInvoiceHead(this.formInline) : await addInvoiceHead(this.formInline);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
