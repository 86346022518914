<template>
  <div class="app-container">
    <van-dialog
        v-model="dialogVisible"
        title="关联产品" width="95%"
        style="font-size: 16px"
        :showConfirmButton="false"
    >
      <div class="add-form">
        <van-field
            readonly
            clickable
            v-model="formInline.materialName"
            label="物料名称"
            placeholder="请选择"
            label-width="120px"
            :required="true"
            :rules="[{ required: true, message: '请选择物料' }]"
            @click="showPickerMaterial = true"
        />

        <van-field
            v-model="formInline.materialCode"
            label="物料编号"
            label-width="120px"
            disabled
        />

        <van-field
            v-model="formInline.materialUnit"
            label="单位"
            label-width="120px"
            disabled
        />

        <van-field
            v-model="formInline.originalCost"
            label="原价"
            label-width="120px"
            disabled
        />

        <van-field
            v-model="formInline.price"
            label="售价"
            label-width="120px"
            type="number"
            :required="true"
            :rules="[{ required: true, message: '请填写售价' }]"
            @input="calTotal"
        />

        <van-field
            v-model="formInline.discount"
            label="折扣"
            label-width="120px"
            type="number"
            :required="true"
            :rules="[{ required: true, message: '请填写折扣' }]"
            @input="calTotal"
        />

        <van-field
            v-model="formInline.quantity"
            label="数量"
            label-width="120px"
            type="number"
            :required="true"
            :rules="[{ required: true, message: '请填写数量' }]"
            @input="calTotal"
        />

        <van-field
            v-model="formInline.subtotal"
            label="小计"
            label-width="120px"
            disabled
        />

        <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

        <div class="flex">
          <van-button type="default" style="width: 100%;height: 50px;font-size: 20px" @click="handleClose">取消
          </van-button>
          <van-button type="info" style="width: 100%;height: 50px;font-size: 20px" @click="submitData">确认</van-button>
        </div>
      </div>
    </van-dialog>

    <Material :showDialog="showPickerMaterial" @handleClose="handleCloseMaterial" @submitData="submitMaterial"/>
  </div>
</template>
<script>
import {addProduct, updateProduct} from "@/api/crm/bussiness";
import Material from "@/components/material/index";

export default {
  components: {Material},
  props: {
    formObj: Object,
    showDialog: Boolean
  },
  watch: {
    showDialog: {
      handler() {
        this.dialogVisible = this.showDialog;
        if (this.showDialog) {
          this.formInline = this.formObj
        }
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      formInline: {},
      dialogVisible: false,
      showPickerMaterial: false
    }
  },

  methods: {
    //计算小计
    calTotal() {
      this.formInline.subtotal = Number(this.formInline.price) * Number(this.formInline.quantity) - Number(this.formInline.discount)
    },

    //关闭物料弹窗
    handleCloseMaterial() {
      this.showPickerMaterial = false
    },

    //物料提交
    submitMaterial(obj) {
      this.formInline.productId = obj.id
      this.formInline.originalCost = obj.costPrice
      this.formInline.price = obj.price
      this.formInline.materialUnit = obj.materialUnit
      this.formInline.materialCode = obj.materialCode
      this.formInline.materialName = obj.materialName

      this.handleCloseMaterial()
    },

    //备注
    handleContentChange(val) {
      this.formInline.remark = val;
    },

    handleClose() {
      this.dialogVisible = false
      this.$emit('handleClose')
    },

    async submitData() {
      this.formInline.id ? await updateProduct(this.formInline) : await addProduct(this.formInline)
      this.$toast.success('操作成功');
      this.$emit('submitList')
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 18px;
  margin: 10px 0;
}
</style>
