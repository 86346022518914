import request from "@/utils/request";

// 查询列表
export function fetchCount(params) {
  return request({
    url: "/crm/work/getStatisticsAdd",
    method: "get",
    params
  });
}
// 商机汇总
export function fetchCostStatistics(params) {
  return request({
    url: "/crm/work/costStatistics",
    method: "get",
    params
  });
}

// 客户来源
export function fetchSourceStatistics(params) {
  return request({
    url: "/crm/portrait/getCustomerSource",
    method: "get",
    params
  });
}

// 客户行业
export function fetchIndustryStatistics(params) {
  return request({
    url: "/crm/portrait/getIndustry",
    method: "get",
    params
  });
}

// 客户跟进方式
export function fetchFollowStatistics(params) {
  return request({
    url: "/crm/portrait/getCustomerFollow",
    method: "get",
    params
  });
}

// 排行榜
export function fetchCrmRankingList(params) {
  return request({
    url: "/crm/work/crmRankingList",
    method: "get",
    params
  });
}

// 客户逾期统计
export function fetchCustomerStatistics(params) {
  return request({
    url: "/crm/work/customerStatistics",
    method: "get",
    params
  });
}

// 客户逾期统计列表
export function fetchCustomerStatisticsList(params) {
  return request({
    url: "/crm/work/customerStatisticsList",
    method: "get",
    params
  });
}
// 统计添加
export function fetchStatisticsAdd(params) {
  return request({
    url: "/crm/work/getStatisticsAdd",
    method: "get",
    params
  });
}
// 数据汇总
export function fetchSummary(params) {
  return request({
    url: "/crm/work/getSummary",
    method: "get",
    params
  });
}
export function getStatisticsAdd(params) {
  return request({
    url: "/crm/work/getStatisticsAdd",
    method: "get",
    params
  });
}

//  根据客户来源查询客户成交周期
export function bargainCustomerPeriodBySource(params) {
  return request({
    url: "/crm/work/bargainCustomerPeriodBySource",
    method: "get",
    params
  });
}

// 获取订单或者回款
export function fetchOrderOrCollection(params) {
  return request({
    url: "/crm/saleWork/getOrderOrCollection",
    method: "get",
    params
  });
}

// 获取逾期回款
export function fetchCollection(params) {
  return request({
    url: "/crm/saleWork/getCollection",
    method: "get",
    params
  });
}

// 获取30天内的订单金额
export function fetchOrderTrend(params) {
  return request({
    url: "/crm/saleWork/get30Order",
    method: "get",
    params
  });
}

// 查询待回款
export function fetchWaitCollection(params) {
  return request({
    url: "/crm/work/findWaitCollection",
    method: "get",
    params
  });
}
