<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="资产名称"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入资产名称' }]"
                    />

                    <van-field
                            v-model="formInline.price"
                            label="价格"
                            type="number"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入价格' }]"
                    />

                    <van-field
                            v-model="formInline.number"
                            label="数量"
                            type="number"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入数量' }]"
                    />

                    <van-field
                            v-model="formInline.region"
                            label="存放地点"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入存放地点' }]"
                    />

                    <van-field
                            v-model="formInline.model"
                            label="规格型号"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入规格型号' }]"
                    />

                    <van-field
                            v-model="formInline.unit"
                            label="计量单位"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入计量单位' }]"
                    />

                    <van-field
                            v-model="formInline.term"
                            label="使用期限"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入使用期限' }]"
                    />

                    <van-field
                            v-model="formInline.supplier"
                            label="供应商"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入供应商' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.productionDate"
                            label="生产日期"
                            placeholder="点击选择日期"
                            @click="showPickerProduction = true"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerProduction" @confirm="onConfirmProduction"/>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.buyDate"
                            label="购买日期"
                            placeholder="点击选择日期"
                            @click="showPickerBuy = true"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerBuy" @confirm="onConfirmBuy"/>

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'" />


                    <van-button class="btn" block type="info" native-type="submit">提交</van-button>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
    import {addAssets,updateAssets} from '@/api/oa/assets';
    import {formatDate} from "@/utils/ruoyi";

    export default {
        data() {
            return {
                formInline: {
                },
                title: '新建资产',
                showPickerProduction: false,
                showPickerBuy: false,
                minDate: new Date(2010, 0, 1),
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/oa/assets');
                this.$router.go(-1)
            },

            //回显
            findDetail() {
                const itemDetail = this.$route.query.obj ? JSON.parse(this.$route.query.obj) : '';
                if (itemDetail) {
                    this.formInline = itemDetail;
                    this.title = '编辑资产';
                } else {
                    this.formInline = {};
                    this.title = '新建资产';
                }
            },

            //生产日期确认
            onConfirmProduction(value) {
                this.formInline.productionDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerProduction = false;
            },

            //购买日期确认
            onConfirmBuy(value) {
                this.formInline.buyDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerBuy = false;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            async onSubmit(values) {
                const params = {
                    ...values,
                    ...this.formInline,
                };
                const data = JSON.stringify(this.formInline)
                const result = this.formInline.id ? await updateAssets(data) : await addAssets(params);
                if (result.code == 200) {
                    this.$toast.success('添加成功');
                        this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
