//查询自定义字段
import request from '@/utils/request'

export function findField(type) {
  return request({
    url: "/system/field/findField/"+type,
    method: "get"
  });
}

//设置自定义字段
export function setField(data) {
  return request({
    url: "/system/field/setField/",
    method: "post",
    data
  });
}
