<template>
  <div class="follow-list">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="list"
    >
      <div class="item-list" v-for="item in dataList" :key="item.id">
        <div class="flex-ver-cen">
          <img class="avatar" :src="item.avatar" />
          <div class="name-wrap mrg-l-10">
            <div class="name">{{ item.realname }}</div>
            <div class="post">{{ item.postName }}</div>
          </div>
        </div>
      </div>
    </van-list>
    <div class="bg"></div>
    <div class="btn" @click="handleAdd">
      <van-icon name="plus" class="mrg-r-10" />
      新增团队成员
    </div>

    <van-popup v-model="showPop" style="width: 80%; padding: 20px; border-radius: 8px">
      <van-tree-select
        class="treeSelect mrg-b-30"
        :items="items"
        :active-id.sync="activeIds"
        :main-active-index.sync="activeIndex"
      />
      <van-button style="width: 100%; height: 40px" block type="info" @click="contactPopSubmit"
        >提交</van-button
      >
    </van-popup>
  </div>
</template>
<script>
import { fetchTeamList } from '@/api/crm/customer';
import { treeAllDeptAndUser } from '@/api/system/dept';
import { submitMembers } from '@/api/crm/customer';

export default {
  props: {
    pages: String,
  },

  data() {
    return {
      loading: false,
      showPop: false,
      finished: true,
      pathObj: {
        team: '/crm/team/add',
      },
      dataList: [],
      activeIndex: 0,
      activeIds: [],
      items: [],
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    onLoad() {},

    async getList() {
      const result = await fetchTeamList({
        id: this.$route.query.id,
      });
      const { data = [] } = result;
      this.dataList = data;
    },

    async handleAdd() {
      const resultData = await treeAllDeptAndUser();
      this.items = resultData.data;

      this.items.forEach((item) => {
        this.packItems(item);
      });

      this.showPop = true;
      this.activeIds = [];
    },

    //递归label赋值给text
    packItems(item) {
      item.text = item.label;
      if (item.children) {
        item.children.forEach((detail) => {
          this.packItems(detail);
        });
      }
    },

    //团队成员-提交
    async contactPopSubmit() {
      if (!this.activeIds) {
        this.$toast.fail('请选择用户');
      }

      await submitMembers(this.$route.query.id, this.activeIds);
      await this.getList();
      this.showPop = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  box-sizing: border-box;
  background: #fff;
}

.item-list {
  padding: 25px 40px 15px;
  border-top: 20px solid #fafafa;

  .avatar {
    width: 70px;
    height: 70px;
    border-radius: 100%;
  }

  .name-wrap {
    .name {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
    }

    .post {
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      margin-top: 10px;
    }
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  .tit {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
  }

  .time {
    font-size: 22px;
    font-weight: 400;
    color: #b5b5b5;
  }

  .contact-methods {
    display: flex;
    font-size: 24px;
    font-weight: 400;
    color: #111111;
    margin: 20px 0;

    .label {
      color: #999999;
      margin-right: 20px;
    }
  }
}

.bg {
  margin-bottom: 140px;
  width: 100%;
  height: 90px;
  background: #fafafa;
}

.btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 110px;
  line-height: 110px;
  background: #fff;
  color: #0091ff;
  font-size: 30px;
  text-align: center;
}
::v-deep .van-sidebar-item {
  background: #fff;
}
::v-deep .van-sidebar-item--select:active,
::v-deep .van-sidebar-item--select {
  background: #f2f3f5;
}
::v-deep .van-sidebar-item--select::before {
  background-color: #0091ff;
}
::v-deep .van-tree-select__item--active {
  color: #0091ff;
}
</style>
