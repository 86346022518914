<template>
    <div class="app-container" v-if="showEcharts">
        <div style="width: 100%;height: 500px;padding-top: 30px" ref="echartsRef"></div>

        <div class="table">
            <div class="table-header flex-spa-bet">
                <span class="se-tr">姓名</span>
                <span class="se-tr">线索</span>
                <span class="se-tr">商机</span>
                <span class="se-tr">客户</span>
                <span class="se-tr">订单</span>
                <span class="se-tr">订单金额</span>
            </div>
            <div class="table-body">
                <div class="itemDiv item-tr flex-spa-ver-cen" v-for="(item, index) in tableData" :key="index">
                    <van-divider/>
                    <div class="itemCol name-wrap flex-ver-cen">
                        <div class="se-tr">{{ item.name }}</div>
                        <div class="se-tr rightData">{{ item.clue }}</div>
                        <div class="se-tr rightData">{{ item.cost }}</div>
                        <div class="se-tr rightData">{{ item.customer }}</div>
                        <div class="se-tr rightData">{{ item.order }}</div>
                        <div class="se-tr rightData">{{ item.orderPrice }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getEchart} from '@/echarts/report/crm/businessAdd'
    import {businessAddTotal} from '@/api/report/crm/crmReport'

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    this.showEcharts = this.isShow
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart) {
                                this.dataEchart.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                showEcharts: false,
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                tableData: [],
                dataEchart: null,
                // 查询参数
                queryParams: {
                    leaderId: undefined
                }
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                let result = await businessAddTotal(this.queryParams)
                this.tableData = result.data

                //图
                const strList = result.data.map(ele => ele.name)
                const clueList = result.data.map(ele => ele.clue)
                const customerList = result.data.map(ele => ele.customer)
                const costList = result.data.map(ele => ele.cost)
                const orderList = result.data.map(ele => ele.order)

                let xname = '业务人员'
                let yname = '汇总数量'
                this.dataEchart = await this.$echarts.init(this.$refs.echartsRef)
                const echartOption = getEchart(strList, clueList, customerList,costList,orderList,xname,yname)
                this.dataEchart.setOption(echartOption)
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart)
                if (this.dataEchart) {
                    this.dataEchart.clear();
                    this.dataEchart.dispose();
                    this.dataEchart = null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
  .table {
    margin-top: 60px;

    .table-header {
      padding: 10px 10px 5px 10px;

      span {
        display: inline-block;
        color: #b5b5b5;
        font-size: 22px;
      }

      .se-tr {
        width: 20%;
      }
    }

    .table-body {
      padding: 10px 10px 5px 10px;
      font-size: 26px;

      .itemDiv {
        width: 100%;
        margin-top: 30px;

        .itemCol {
          width: 100%;

          .se-tr {
            width: 16%;
          }

          .rightData {
            padding-left: 15px;
          }
        }
      }


    }
  }
</style>
