<template>
  <div class="detail-inner-con">
    <div class="title">基本信息</div>
    <div class="list-wrap">
      <div class="item-list flex-spa-bet">
        <div class="label">最后跟进时间</div>
        <div class="value over_hidden">{{ costDetail.followTime || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">最后跟进内容</div>
        <div class="value over_hidden">{{ costDetail.followContent || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">预计成交日期</div>
        <div class="value over_hidden">{{ costDetail.closingDate || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">备注</div>
        <div class="value over_hidden">{{ costDetail.remark || '--' }}</div>
      </div>
    </div>

    <div class="title">系统信息</div>
    <div class="list-wrap">
      <div class="item-list flex-spa-bet">
        <div class="label">创建人</div>
        <div class="value over_hidden">{{ costDetail.createBy || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">创建时间</div>
        <div class="value over_hidden">{{ costDetail.createTime || '--' }}</div>
      </div>

        <div class="item-list flex-spa-bet">
            <div class="label">更新人</div>
            <div class="value over_hidden">{{ costDetail.updateBy || '--' }}</div>
        </div>

      <div class="item-list flex-spa-bet">
        <div class="label">更新时间</div>
        <div class="value over_hidden">{{ costDetail.updateTime || '--' }}</div>
      </div>

    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters(['costDetail']),
    },

    data() {
        return {};
    },

    mounted() {
    },
};
</script>
<style lang="scss" scoped>
.detail-inner-con {
}
.title {
  padding: 20px 40px;
  background: #fafafa;
  font-size: 24px;
  color: #666666;
}

.list-wrap {
  padding: 20px 40px;
  background: #fff;
  .item-list {
    padding: 30px 0;
    border-bottom: 1px solid #ececec;
    font-size: 28px;
    .label {
      width: 200px;
      color: #999999;
    }
    .value {
      width: 0;
      flex: 1;
      color: #333;
      text-align: right;
      margin-left: 20px;
    }
  }
}
</style>
