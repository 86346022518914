import request from "@/utils/request";
// 入库物料统计
export function enterMaterialCount(query) {
  return request({
    url: "inventory/report/enterMaterialCount",
    method: "get",
    params: query
  });
}
// 出库物料统计
export function expendMaterialCount(query) {
  return request({
    url: "inventory/report/expendMaterialCount",
    method: "get",
    params: query
  });
}
