<template>
    <div class="follow-list">
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                class="list"
        >
            <div class="list" v-for="item in dataList" :key="item.id">
                <div class="line"></div>
                <div class="item-tab" @click="handleGoDetail(item.id)">
                    <div class="title-wrap flex-spa-ver-cen">
                        <div class="tit over_hidden">
                            {{ item.theme || '--' }}（{{ item.number || '--' }}）
                        </div>
                        <div class="level-btn">
                            {{ item.stateStr || '--' }}
                        </div>
                    </div>
                    <div class="con">
                        <div class="desc-wrap flex">
                            <div class="label">客户名称：</div>
                            <div class="value">{{ item.customer || '--' }}</div>
                        </div>
                        <div class="desc-wrap flex">
                            <div class="label">订单总额：</div>
                            <div class="value">{{ item.price }}</div>
                        </div>
                        <div class="desc-wrap flex">
                            <div class="label">优惠额度：</div>
                            <div class="value red">{{ item.discount }}</div>
                        </div>
                        <div class="desc-wrap flex">
                            <div class="label">优惠后总额：</div>
                            <div class="value red">{{ item.discountPrice }}</div>
                        </div>
                        <div class="desc-wrap flex">
                            <div class="label">负责人：</div>
                            <div class="value red">{{ item.leaderStr || '--' }}</div>
                        </div>
                        <div class="desc-wrap flex">
                            <div class="label">签单日期：</div>
                            <div class="value red">{{ item.signingDate || '--' }}</div>
                        </div>
                    </div>
                    <div class="time-wrap flex-spa-ver-cen"
                         @click.stop="operateAction(item.id,item.state)">
                        <div class="time">创建日期：{{ item.createTime || '--' }}</div>
                        <van-icon name="weapp-nav"/>
                    </div>
                </div>
            </div>
        </van-list>
        <van-action-sheet
                v-model="actionShow"
                :actions="actions"
                @select="onSelect"
                cancel-text="取消"
                close-on-click-action
        />
    </div>
</template>
<script>
    import {dataOrderSubmit, delList, findSelfSubByCustomerId} from '@/api/crm/order'

    export default {
        props: {
            pages: String,
        },

        data() {
            return {
                loading: false,
                finished: true,
                refreshing: false, // 下拉
                dataList: [],
                //操作的动作面板
                actionShow: false,
                //操作面板的操作
                actions: [],
                itemId: null,
            };
        },

        mounted() {
            this.getList();
        },
        methods: {
            onLoad() {
            },

            async getList() {
                this.loading = true;
                const result = await findSelfSubByCustomerId(this.$route.query.id);
                const {data = []} = result;

                this.finished = true;
                this.loading = false;
                this.dataList = data;
            },

            handleAdd() {
                // 新增页面
                this.$router.push({
                    path: '/crm/order/add',
                    query: {
                        type: 'add'
                    },
                });
            },

            handleGoDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/crm/order/add',
                    query: {
                        id,
                        type: 'detail'
                    },
                });
            },

            //点击操作展示动作面板
            operateAction(id, state) {
                this.itemId = id;

                if((state != 0 && state != 1)){
                    this.setAction('crm:order:submit','提交')
                }
                this.setAction('crm:order:update','编辑')
                this.setAction('crm:order:delete','删除')

                this.actionShow = true;
            },

            //设置权限
            setAction(perm, permName){
                if(this.$auth.hasPermi(perm)){
                    let obj = this.actions.find(item => item.name == permName)
                    if (!obj) {
                        this.actions.unshift(
                            {name: permName, className: 'customer-opea-action'}
                        )
                    }
                } else {
                    this.actions = this.actions.filter(item => item.name != permName)
                }
            },

            //操作的动作面板被点击
            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                switch (item.name) {
                    case '编辑':
                        this.handleEdit();
                        break;
                    case '删除':
                        this.handleDelete();
                        break;
                    case '提交':
                        this.handleSubmit();
                        break;
                }
            },

            // 编辑
            handleEdit() {
                // 新增页面
                this.$router.push({
                    path: '/crm/order/add',
                    query: {
                        id: this.itemId,
                        type: 'edit'
                    },
                });
            },

            // 删除
            handleDelete() {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除此订单?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await delList(this.itemId);
                        this.$toast.success('删除成功');
                        await that.getList();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },

            // 提交
            handleSubmit() {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认提交订单?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await dataOrderSubmit({orderId: this.itemId});
                        this.$toast.success('提交成功');
                        await that.getList();
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
        },
    };
</script>
<style lang="scss" scoped>
  .list {
    box-sizing: border-box;
    background: #fff;
  }

  .item-list {
    padding: 25px 40px 15px;
    border-top: 20px solid #fafafa;

    .avatar {
      width: 70px;
      height: 70px;
      border-radius: 100%;
    }

    .name-wrap {
      .name {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
      }

      .post {
        font-size: 20px;
        font-weight: 400;
        color: #999999;
        margin: 5px 0 0 10px;
      }
    }

    .desc {
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      margin-top: 10px;
    }
  }

  .bg {
    margin-bottom: 140px;
    width: 100%;
    height: 90px;
    background: #fafafa;
  }

  .btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 750px;
    height: 110px;
    line-height: 110px;
    background: #fff;
    color: #0091ff;
    font-size: 30px;
    text-align: center;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        line-height: 38px;
        width: 0;
        flex: 1;
      }

      .level-btn {
        width: 150px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          white-space: nowrap;
          color: #999;
        }

        .value {
          color: #111;
          white-space: nowrap;
          overflow: hidden;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;
    }
  }
</style>
