import request from "@/utils/request";

// 获取订单 查询列表
export function selectPage(query) {
  return request({
    url: "/crm/order/getList",
    method: "get",
    params: query
  });
}

// 根据订单id获取单条
export function selectItemByOrder(query) {
  return request({
    url: "/crm/order/getId",
    method: "get",
    params: query
  });
}

// 根据订单id获取产品
export function findProductByOrderId(orderId) {
  return request({
    url: "/crm/order/findProductByOrderId/"+orderId,
    method: "get"
  });
}

// 根据客户id获取本人及下属的订单信息
export function findSelfSubByCustomerId(customerId) {
  return request({
    url: "/crm/order/findSelfSubByCustomerId/"+customerId,
    method: "get"
  });
}
// 根据商机id获取订单信息
export function fetchOrderListByCostId(query) {
  return request({
    url: "/crm/order/getCostId",
    method: "get",
    params: query
  });
}

// 新建订单
export function addOrder(data) {
  return request({
    url: "/crm/order/addOrder",
    method: "post",
    data
  });
}

// 删除订单
export function removeOrder(orderIds) {
  return request({
    url: "/crm/order/deleteOrder/" + orderIds,
    method: "delete"
  });
}

// 更新顶单
export function editOrder(data) {
  return request({
    url: "/crm/order/updateOrder",
    method: "put",
    data
  });
}
// 删除订单
export function delList(ids) {
  return request({
    url: "/crm/order/deleteOrder/" + ids,
    method: "delete"
  });
}
// 获取数量 fetchCount
export function fetchCount(query) {
  return request({
    url: "/crm/order/getNumber",
    method: "get"
  });
}

// 获取单条信息
export function getDetailByOrderId(params) {
  return request({
    url: "/crm/order/getId",
    method: "get",
    params
  });
}

// 审批成功后调用
export function passApprove(costId) {
  return request({
    url: "/crm/order/passApprove/"+costId,
    method: "put"
  });
}

// 提交订单
export function dataOrderSubmit(params) {
  return request({
    url: "/crm/order/submitOrder",
    method: "put",
    params
  });
}

export function fetchCostListByCustomrId(params) {
  return request({
    url: "/crm/order/getCostList",
    method: "get",
    params
  });
}

export function warehousingOrder(orderId,expendId) {
  return request({
    url: "/crm/order/warehousingOrder/"+orderId+"/"+expendId,
    method: "post"
  });
}

//查询自定义字段
export function findField() {
  return request({
    url: "/crm/order/findField",
    method: "get"
  });
}

//设置自定义字段
export function setField(data) {
  return request({
    url: "/crm/order/setField/",
    method: "post",
    data
  });
}

export function qrCodeByCostProductId(costProductId) {
  return request({
    url: "/crm/order/qrCodeByCostProductId/"+costProductId,
    method: "get",
    responseType: 'blob'
  });
}
