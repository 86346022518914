import request from '@/utils/request'
// 查询列表
export function querynotice(query) {
  return request({
    url: '/oa/notice/getPage',
    method: 'get',
    params: query
  })
}
// 新增
export function addnotice(notice) {
  return request({
    url: '/oa/notice',
    method: 'post',
    data: notice
  })
}
// 删除
export function delnotice(id) {
  return request({
    url: `/oa/notice/${id}`,
    method: 'delete'
  })
}
export function refernotice(id) {
  return request({
    url: `/oa/notice/${id}`,
    method: 'GET'
  })
}
// 修改
export function amendnotice(notice) {
  return request({
    url: '/oa/notice',
    method: 'PUT',
    data: notice
  })
}
// 发布范围下拉框
export function Releaserange() {
  return request({
    url: `/system/dept/treeselectAndUser`,
    method: 'GET'
  })
}
// 获取角色选择框列表
export function optionselect() {
  return request({
    url: `/system/role/optionselect`,
    method: 'GET'
  })
}
// 获取用户下拉
export function getAllUsers() {
  return request({
    url: `/system/user/getAllUser`,
    method: 'GET'
  })
}
// 获取部门下拉
export function treeselectr() {
  return request({
    url: `/system/dept/treeselect`,
    method: 'GET'
  })
}
