<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="入账标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入入账标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.incomeName"
                            label="收入类型"
                            placeholder="请选择"
                            @click="showPickerType= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择收入类型' }]"
                    />
                    <van-popup v-model="showPickerType" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="typeOptions"
                                value-key="name"
                                @confirm="
                onConfirm($event, 'incomeId', 'incomeName', 'id','name', 'showPickerType')
              "
                                @cancel="showPickerType = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.accountName"
                            label="账户名称"
                            placeholder="请选择"
                            @click="showPickerAccount= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择账户名称' }]"
                    />

                    <van-field
                            v-model="formInline.price"
                            label="入账金额"
                            placeholder="请输入"
                            type="number"
                            :required="true"
                            :rules="[{ required: true, message: '请输入入账金额' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.entryTime"
                            label="入账日期"
                            placeholder="点击选择日期"
                            @click="showPickerDate = true"
                    />
                    <van-popup v-model="showPickerDate" style="width: 70%">
                        <van-datetime-picker
                                v-model="currentDate"
                                type="datetime"
                                title="请选择时间"
                                @confirm="onConfirmTime"
                                @cancel="showPickerDate = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.customerName"
                            label="客户名称"
                            placeholder="请输入"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.leaderName"
                            label="经办人员"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择经办人员' }]"
                            @click="showPop= true"
                    />

                    <textAreaInput :remark="formInline.remark" @change="handleChangeRemark"
                                   :title="'备注'"/>

                    <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>
        <Account :showDialog="showPickerAccount" @handleClose="handleCloseAccount" @submitList="submitList"/>
    </div>
</template>
<script>
    import {addData, findAllType, selectOne, updateData} from '@/api/cw/bank/entry';
    import Account from '@/components/account'
    import SelectOneUser from '@/components/selectOneUser'
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import {changeToCurrentDay} from "@/utils/ruoyi";
    import {submiteUpload} from "@/api/system/config";

    export default {
        components: {Account, AppendixVue, SelectOneUser},
        data() {
            return {
                formInline: {},
                title: '新建入账',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerAccount: false,
                showPickerType: false,
                showPickerDate: false,
                showPop: false,
                currentDate: new Date(),
                //类型
                typeOptions: [],
                uploader: [],
                uploadParams: {
                    businessType: 'cwentry',
                    businessId: undefined,
                    file: null,
                },
            };
        },

        mounted() {
            //查询所有类型
            findAllType().then(response => {
                this.typeOptions = response.data;
            });

            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/cw/bank');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {};
                    this.title = '新建入账';
                } else {
                    let itemDetail = await selectOne(this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑入账';
                    } else {
                        this.title = '入账详情';
                    }
                }
            },

            //确认
            onConfirm(event, key, keyName, eventKey1, eventKey2, visible) {
                this.formInline[key] = event[eventKey1];
                this.formInline[keyName] = event[eventKey2];
                this[visible] = false;
            },

            //日期提交
            onConfirmTime(value) {
                this.formInline.entryTime = changeToCurrentDay(value);
                this.showPickerDate = false;
            },

            //备注
            handleChangeRemark(val) {
                this.formInline.remark = val;
            },

            //账户关闭
            handleCloseAccount() {
                this.showPickerAccount = false
            },

            //账户提交
            submitList(id, name) {
                this.formInline.accountId = id;
                this.formInline.accountName = name;
                this.showPickerAccount = false
            },

            //选择人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.leaderId = activeId
                this.formInline.leaderName = names
            },

            //关闭选择人
            handleCloseUser() {
                this.showPop = false
            },

            async onSubmit() {
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateData(data) : await addData(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
