import request from '@/utils/request'

// 查询销售订单列表
export function findOrderList() {
  return request({
    url: "/custom/productFlow/findOrderList",
    method: "get"
  });
}

// 查询列表 带明细
export function findPage(query) {
  return request({
    url: "/custom/productFlow/findPage",
    method: "get",
    params: query
  });
}

// 查询列表 简要
export function findPageSimple(query) {
  return request({
    url: "/custom/productFlow/findPageSimple",
    method: "get",
    params: query
  });
}

// 删除
export function delData(id) {
  return request({
    url: "/custom/productFlow/"+id,
    method: "delete"
  });
}

// 根据订单id查询
export function findByOrderId(id) {
  return request({
    url: "/custom/productFlow/findByOrderId/"+id,
    method: "get"
  });
}

// 根据订单id查询流程进度
export function findProgressByOrderId(orderId) {
  return request({
    url: "/custom/productFlow/findProgressByOrderId/"+orderId,
    method: "get"
  });
}

// 新增
export function addData(data) {
  return request({
    url: "/custom/productFlow",
    method: "post",
    data: data
  });
}

// 修改
export function updateData(data) {
  return request({
    url: "/custom/productFlow",
    method: "put",
    data: data
  });
}
