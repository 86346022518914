<template>
  <div class="detail-inner-con">
    <div class="title">基本信息</div>
    <div class="list-wrap">
      <div class="item-list flex-spa-bet">
        <div class="label">线索姓名</div>
        <div class="value over_hidden">{{ clueDetail.name || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">客户级别</div>
        <div class="value over_hidden">{{ clueDetail.levelStr || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">邮箱</div>
        <div class="value over_hidden">{{ clueDetail.email || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">手机</div>
        <div class="value over_hidden">{{ clueDetail.phone || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">线索来源</div>
        <div class="value over_hidden">{{ clueDetail.clueSourceStr || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">客户行业</div>
        <div class="value over_hidden">{{ clueDetail.industryStr || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">地址</div>
        <div class="value over_hidden">
          {{ `${clueDetail.addressDetail}` || '--' }}
          <!-- {{ `${clueDetail.addressDetail}${clueDetail.address}` || '--' }} -->
        </div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">下次联系时间</div>
        <div class="value over_hidden">{{ clueDetail.nextContactTime || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet" style="border-bottom: none">
        <div class="label">备注</div>
        <div class="value over_hidden">{{ clueDetail.remark || '--' }}</div>
      </div>
    </div>

    <div class="title">系统信息</div>
    <div class="list-wrap">
      <div class="item-list flex-spa-bet">
        <div class="label">负责人</div>
        <div class="value over_hidden">{{ clueDetail.leaderId || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">最后跟进记录</div>
        <div class="value over_hidden">{{ clueDetail.followContent || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">创建人</div>
        <div class="value over_hidden">{{ clueDetail.createBy || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">创建时间</div>
        <div class="value over_hidden">{{ clueDetail.createTime || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">更新时间</div>
        <div class="value over_hidden">{{ clueDetail.updateTime || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet" style="border-bottom: none">
        <div class="label">最后跟进时间</div>
        <div class="value over_hidden">{{ clueDetail.followTime || '--' }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['clueDetail']),
  },

  data() {
    return {};
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.detail-inner-con {
}
.title {
  padding: 20px 40px;
  background: #fafafa;
  font-size: 24px;
  color: #666666;
}

.list-wrap {
  padding: 20px 40px;
  background: #fff;
  .item-list {
    padding: 30px 0;
    border-bottom: 1px solid #ececec;
    font-size: 28px;
    .label {
      width: 200px;
      color: #999999;
    }
    .value {
      width: 0;
      flex: 1;
      color: #333;
      text-align: right;
      margin-left: 20px;
    }
  }
}
</style>
