import request from "@/utils/request";

// 查询列表
export function selectPage(query) {
  return request({
    url: "/crm/contacts/getContactsList",
    method: "get",
    params: query
  });
}

// 查询跟进列表
export function fetchFollowList(query) {
  return request({
    url: "/crm/contacts/getFollowList",
    method: "get",
    params: query
  });
}

// 获取数量
export function fetchCount() {
  return request({
    url: "/crm/contacts/getNumber",
    method: "get"
  });
}

// 添加联系人
export function addContacts(data) {
  return request({
    url: "/crm/contacts/addContacts",
    method: "post",
    data
  });
}

// 添加团队成员
export function submitMembers(id, userIds) {
  return request({
    url: `/crm/contacts/addRelatedTeam/${id}/${userIds}`,
    method: "post"
  });
}

// 编辑联系人
export function updateContacts(data) {
  return request({
    url: "/crm/contacts/updateContacts",
    method: "PUT",
    data
  });
}

// 根据联系人Id获取信息
export function getDetailByContactId(params) {
  return request({
    url: "/crm/contacts/getId",
    method: "get",
    params
  });
}

// 删除联系人
export function delList(ids) {
  return request({
    url: "/crm/contacts/deleteContacts/" + ids,
    method: "delete"
  });
}

// 根据用户id查询用户信息
export function fetchUserInfo(params) {
  return request({
    url: "/system/user/baseMsg/" + params,
    method: "get"
  });
}

// 新建跟进
export function addFollowContacts(data) {
  return request({
    url: "/crm/contacts/addFollow",
    method: "POST",
    data
  });
}

// 编辑跟进
export function editFollowContacts(data) {
  return request({
    url: "/crm/contacts/updateFollow",
    method: "PUT",
    data
  });
}

// 删除跟进
export function delFollowList(data) {
  return request({
    url: "/crm/customer/deleteFollow",
    method: "delete",
    data
  });
}

// 转移联系人
export function fetchTransferCus(leaderId, ids) {
  return request({
    url: `/crm/contacts/transferCustomer/${leaderId}/${ids}`,
    method: "put"
  });
}

// 移除团队成员
export function removeUser(params) {
  return request({
    url: `/crm/contacts/deleteRelatedTeam/`,
    method: "delete",
    params
  });
}

// 新建联系人
export function addContack(data) {
  return request({
    url: "/crm/contacts/addContacts",
    method: "POST",
    data
  });
}

// 设为首要联系人
export function setMainContact(data) {
  return request({
    url: "/crm/contacts/setUp",
    method: "PUT",
    data
  });
}

// 联系人管理，团队成员,
export function fetchTeamList(query) {
  return request({
    url: "/crm/contacts/listRelatedTeam",
    method: "get",
    params: query
  });
}

// 获取联系人下的商机
export function fetchBussinessList(data) {
  return request({
    url: "/crm/contacts/getCost",
    method: "post",
    data
  });
}
// 解除关联联系人
export function deleteCostContacts(params) {
  return request({
    url: "/crm/cost/deleteCostContacts",
    method: "delete",
    params
  });
}

// 编辑客户
export function editContack(data) {
  return request({
    url: "/crm/contacts/updateContacts",
    method: "put",
    data
  });
}
