import request from '@/utils/request'

// 查询所有销售订单
export function getALLOrderList(query){
  return request({
    url: "/crm/currency/getALLOrderList",
    method: "get",
    params: query
  });
}

// 销售回款列表
export function getCollectionList(query){
  return request({
    url: "/cw/collection/getList",
    method: "get",
    params: query
  });
}

// 销售回款详情
export function getCollectionInfo(query){
  return request({
    url: "/cw/collection/getId",
    method: "get",
    params: query
  });
}

// 回款
export function updateCollection(data){
  return request({
    url: "/cw/collection/updateCollection",
    method: "PUT",
    data
  });
}
