<template>
    <div class="app-container" v-if="isShow">
        <div style="width: 100%;height: 400px;padding-top: 10px" ref="echartsRef1"></div>
        <div style="width: 100%;height: 1500px;padding-top: 30px" ref="echartsRef2"></div>
    </div>
</template>
<script>
    import {getEchart,getEchart2} from '@/echarts/report/purchase/materialForm'
    import {getMaterialFormByName,getMaterialFormByType} from '@/api/report/purReport/purReport'

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart1) {
                                this.dataEchart1.resize()
                            }
                            if (this.dataEchart2) {
                                this.dataEchart2.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                dataEchart1: null,
                dataEchart2: null,
                // 查询参数
                queryParams: {
                    leaderId: undefined
                }
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                let resultBar = await getMaterialFormByName(this.queryParams)
                let resultPie = await getMaterialFormByType(this.queryParams)

                // 柱状图
                let xname = '物料名称'
                let yname = '采购数量'
                const strList = []
                const dataList = []
                resultBar.data.forEach(item=>{
                    strList.push({
                        'value': item.name
                    })
                    dataList.push({
                        'value': item.num
                    })
                })
                this.dataEchart1 = await this.$echarts.init(this.$refs.echartsRef1)
                const echartOption = getEchart(strList, dataList,xname,yname)
                this.dataEchart1.setOption(echartOption)

                // 饼状图
                const priceList = []
                resultPie.data.forEach(item=>{
                    priceList.push({
                        "value": item.num,
                        "name": item.name
                    })
                })
                this.dataEchart2 = await this.$echarts.init(this.$refs.echartsRef2)
                this.dataEchart2.setOption(getEchart2(priceList))
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart1)
                if (this.dataEchart1) {
                    this.dataEchart1.clear();
                    this.dataEchart1.dispose();
                    this.dataEchart1 = null;
                }
                window.removeEventListener('resize', this.dataEchart2)
                if (this.dataEchart2) {
                    this.dataEchart2.clear();
                    this.dataEchart2.dispose();
                    this.dataEchart2 = null;
                }
            }
        }
    };
</script>
