<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'客户管理'" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-icon name="filter-o" class="mrg-r-30" @click="showFilter = true"/>
                    <van-button type="info" @click="handleAdd" style="margin-right: 10px">添加</van-button>
                    <van-button type="warning" @click="checkDialog = true">查重</van-button>
                    <!--                    <van-icon name="plus" class="mrg-r-20" @click="handleAdd"/>-->
                    <!--                    <van-icon name="coupon-o" class="mrg-r-20" @click="checkDialog = true"/>-->
                </template>
                <!--<template v-slot:icon2>
                  &lt;!&ndash; <van-icon name="weapp-nav" /> &ndash;&gt;
                  <i class="iconfont icon-gengduo" style="font-size: 20px"></i>
                </template>-->
            </navBar>

            <div class="common-wrap">
                <van-search
                        show-action
                        v-model="queryParams.condition"
                        placeholder="请输入客户名称/手机"
                        @search="handleQuery"
                >
                    <template #action>
                        <div class="search-btn-label" @click="handleQuery">搜索</div>
                    </template>
                </van-search>
            </div>

            <van-tabs class="my-tabs" @click="getListParams" v-model="queryParams.active">
                <van-tab v-for="(item, index) in list"
                         :title="item.name+'('+(item.name=='全部'?countObj.allNumber:(item.name=='我负责'?countObj.myNumber:(item.name=='下属'?countObj.subordinateNumber:(item.name=='关注'?countObj.followNumber:countObj.publicNumber))))+')'"
                         :key="index" :color="'#0091FF'">
                    <div class="main">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    offset="100"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"
                            >
                                <div class="list" v-for="item in dataList" :key="item.id">
                                    <div class="line"></div>
                                    <div class="item-tab" @click="handleGoDetail(item.id,item.name, item.isPublic)">
                                        <div class="title-wrap flex-spa-ver-cen">
                                            <div class="tit over_hidden">
                                                {{ item.name || '--' }}
                                            </div>
                                            <div class="level-btn">
                                                {{ item.levelStr || '--' }}
                                            </div>
                                        </div>
                                        <div class="con">
                                            <div class="desc-wrap flex">
                                                <div class="label">客户来源：</div>
                                                <div class="value">{{ item.customerSourceStr || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">客户行业：</div>
                                                <div class="value red">{{ item.industryStr || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">负责人：</div>
                                                <div class="value red">{{ item.leaderId || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">手机号：</div>
                                                <div class="value">{{ item.phone || '--' }}</div>
                                            </div>
                                        </div>
                                        <div class="time-wrap flex-spa-ver-cen" @click.stop="operateAction(item.id)">
                                            <div class="time">最后跟进时间：{{ item.createTime || '--' }}</div>
                                            <van-icon name="weapp-nav"/>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <van-action-sheet
                v-model="actionShow"
                :actions="actions"
                @select="onSelect"
                cancel-text="取消"
                close-on-click-action
        />

        <!-- 转移 -->
        <van-popup v-model="showPop" style="width: 80%; padding: 20px; border-radius: 8px">
            <van-tree-select
                    class="treeSelect mrg-b-30"
                    :items="items"
                    :active-id.sync="activeId"
                    :main-active-index.sync="activeIndex"
            />
            <div class="flex-ver-cen transfer-select-wrap mrg-b-30">
                <div class="mrg-r-10">同时变更负责人至</div>
                <van-checkbox class="mrg-r-30" shape="square" v-model="isCost" checked-color="#ee0a24"
                >商机
                </van-checkbox
                >
                <van-checkbox shape="square" v-model="isContacts" checked-color="#ee0a24"
                >联系人
                </van-checkbox
                >
            </div>
            <van-button style="width: 100%; height: 40px" block type="info" @click="contactPopSubmit"
            >提交
            </van-button
            >
        </van-popup>

        <!-- 添加下次联系 -->
        <van-popup v-model="nextContactPop" style="width: 95%; padding: 20px; border-radius: 8px">
            <van-form validate-first @submit="nextContactSubmit">
                <van-field
                        readonly
                        clickable
                        :value="nextContactForm.nextContactTime"
                        label="下次联系时间"
                        placeholder="点击选择日期"
                        @click="showCalendar = true"
                        :rules="[{ required: true, message: '请选择下次联系时间' }]"
                />
                <div class="line"></div>

                <van-field
                        v-model="nextContactForm.nextContactContent"
                        label="下次联系内容"
                        placeholder="请输入"
                />

                <van-button block type="info" native-type="submit"
                            style="height: 30px;font-size: 14px;margin-top: 20px">提交
                </van-button>
            </van-form>
        </van-popup>

        <van-popup v-model="showCalendar" style="width: 70%">
            <van-datetime-picker
                    v-model="nextContactForm.currentDate"
                    type="datetime"
                    title="请选择时间"
                    @confirm="onConfirmTime"
                    @cancel="showCalendar = false"
            />
        </van-popup>
        <van-popup v-model="showFilter" position="bottom">
            <span style="margin-left: 20px;margin-top: 20px;font-size: 14px; color: #cccccc">筛选条件:</span>
            <span style="margin-left: 47%;margin-top: 20px;font-size: 14px; color: #0091ff" @click="clearQueryParams">清除筛选</span>
            <van-field
                    readonly
                    clickable
                    :value="queryParams.time"
                    label="创建时间"
                    placeholder="点击选择日期"
                    class="query-class"
                    @click="showCalendarQuery = true"
            />
            <van-field
                    v-model="queryParams.deptName"
                    name="部门"
                    label="部门"
                    placeholder="点击选择部门"
                    class="query-class"
                    readonly
                    clickable
                    @click="chooseDeptPicker = true"
            />
            <van-field
                    v-model="queryParams.address"
                    name="地区"
                    label="地区"
                    placeholder="点击选择地区"
                    class="query-class"
                    readonly
                    clickable
                    @click="addressPop = true"
            />
            <van-button style="width: 100%; height: 50px;font-size: 14px" block type="info" @click="handleQuery"
            >查询
            </van-button
            >
        </van-popup>

        <!-- 售后二维码 -->
        <van-popup v-model="qrCodeOpen" style="width: 95%; padding: 20px; border-radius: 8px">
            <img :src="qrCodeImgUrl" alt=""/>
            <div style="margin-left: 30px">
                <van-field
                        v-for="(item, index) in qrCodeForm.fieldList" :key="index"
                        v-model="item.fieldValue"
                        :label="item.fieldName"
                        readonly
                />
            </div>
        </van-popup>

        <van-calendar v-model="showCalendarQuery" type="range" :min-date="minDate" @confirm="onDateConfirm" />

        <!--选择地区-->
        <van-popup v-model="addressPop">
            <van-area title="请选择地区" :area-list="areaList" :value="addressVal" @confirm="addressConfirm"/>
        </van-popup>

        <!--选择部门-->
        <department :showPop="chooseDeptPicker" @handleClose="handleCloseDept" :multiple="false"/>

        <CheckDuplicate :customerVisible="checkDialog" @handleClose="checkDialog=false"/>

        <!--现场打卡-->
        <SceneClock :customerVisible="sceneClockDialog" :customer-id="itemId" @handleClose="sceneClockDialog=false"/>
    </div>
</template>

<script>
    import {
        cancelContact,
        delList, fetchCount,
        fetchTransferCus,
        makeCall,
        moveInNotPublic,
        nextContact,
        putPublic,
        selectPage
    } from '@/api/crm/customer';
    import CheckDuplicate from './components/checkDuplicate'
    import SceneClock from './components/sceneClock'
    import {treeAllDeptAndUser} from '@/api/system/dept';
    import {changeToCurrentDay, formatDate} from "@/utils/ruoyi";
    import department from '@/components/selectUserAndDept/department';
    import {areaList} from '@vant/area-data';
    import {qrCodeByCostProductId} from "@/api/crm/order";
    import {findPermByCompany} from "@/api/system/company";

    export default {
        components: {CheckDuplicate, department,SceneClock},

        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                isCost: false,
                isContacts: false,
                queryParams: {
                    pageNum: 1,
                    pageSize: 5,
                    screen: '1',
                    condition: null,
                    active: 0
                },
                list: [
                    {
                        name: '我负责',
                        value: '1',
                    },
                    {
                        name: '下属',
                        value: '2',
                    },
                    {
                        name: '关注',
                        value: '3',
                    }
                ],
                dataList: [],
                //操作的动作面板
                actionShow: false,
                //操作面板的操作
                actions: [
                    {name: '微信聊天', className: 'customer-opea-action'},
                    {name: '放入公海', className: 'customer-opea-action'},
                    {name: '添加下次联系', className: 'customer-opea-action'},
                    {name: '取消下次联系', className: 'customer-opea-action'},
                ],
                itemId: null,
                showPop: false,
                activeIndex: 0,
                activeId: null,
                items: [],
                //是否为管理员
                isAdmin: JSON.parse(localStorage.getItem('isAdmin')),
                //添加下次联系弹窗
                nextContactPop: false,
                //下次联系表单
                nextContactForm: {},
                showCalendar: false,
                // 查重窗口
                checkDialog: false,
                //筛选弹出层
                showFilter: false,
                //选择部门弹窗
                chooseDeptPicker: false,
                //地区数据
                areaList,
                //地区弹窗
                addressPop: false,
                addressVal: undefined,
                // 数量
                countObj: {
                    allNumber: 0,
                    myNumber: 0,
                    subordinateNumber: 0,
                    followNumber: 0,
                    publicNumber: 0
                },
                //日期查询
                showCalendarQuery: false,
                minDate: new Date(2010, 0, 1),
                //现场打卡弹窗
                sceneClockDialog: false,
                //二维码弹窗
                qrCodeOpen: false,
                //二维码图片地址
                qrCodeImgUrl: '',
                //自定义字段
                qrCodeForm: {
                    fieldList: []
                },
                //是否有售后保修的权限
                permSaleAfterMaintenance: false,
            };
        },

        mounted() {
            if (this.isAdmin) {
                this.list.unshift({name: '全部', value: '0'})
            }
            if (this.$auth.hasPermi('crm:customer:publicSea')) {
                this.list.push({name: '公海', value: '4'})
            }

            //恢复上次查询条件
            let obj = localStorage.getItem("customer_query")
            if (obj) {
                this.queryParams = JSON.parse(obj)
            } else {
                this.queryParams.screen = this.list[0].value
            }
            //场景
            this.getCount()

            //查询公司是否有售后保修的权限
            findPermByCompany(this.objectToFormData({ perm: 'crm:maintenance:list' })).then(res=>{
                this.permSaleAfterMaintenance = res.data
            })
        },

        beforeDestroy() {
            //销毁前记住本次查询条件
            this.queryParams.pageNum--
            localStorage.setItem("customer_query", JSON.stringify(this.queryParams))
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/crm/workbench');
                this.$router.go(-1)
            },

            async getList() {
                this.loading = true;
                const result = await selectPage(this.queryParams);
                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.dataList = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.dataList = records;
                } else {
                    this.dataList = [...this.dataList, ...records];
                }
                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
            },

            onLoad() {
                if (this.refreshing) {
                    this.dataList = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.dataList.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            getListParams(index) {
                this.finished = false;
                this.loading = false;
                this.dataList = [];

                index = this.list[index].value

                this.queryParams.screen = index;

                if ('4' == index) {
                    //公海
                    let obj = this.actions.find(item => item.name == '放入公海')
                    if (obj) {
                        this.actions = this.actions.filter(item => item.name != '放入公海')
                        this.actions.push({name: '领用', className: 'customer-opea-action'})
                    }
                } else {
                    let obj = this.actions.find(item => item.name == '领用')
                    if (obj) {
                        this.actions = this.actions.filter(item => item.name != '领用')
                        this.actions.push({name: '放入公海', className: 'customer-opea-action'})
                    }
                }

                this.handleQuery();
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.showFilter = false
                this.getList();
                //场景
                this.getCount()
            },

            //场景统计
            async getCount() {
                const result = await fetchCount()
                this.countObj = result.data
            },

            //部门弹窗关闭
            handleCloseDept(list) {
                if (list && list.length > 0) {
                    this.queryParams.deptId = list[0].id
                    this.queryParams.deptName = list[0].name
                }
                this.chooseDeptPicker = false;
            },

            //地址确认
            addressConfirm(val) {
                this.queryParams.address = val.map(item => item.name).join(",")
                this.addressPop = false
            },

            //清除筛选
            clearQueryParams() {
                this.queryParams = {
                    pageNum: 1,
                    pageSize: 5,
                    screen: this.queryParams.screen,
                    active: this.queryParams.active,
                }
                this.handleQuery()
                this.showFilter = false
            },

            handleAdd() {
                // 新增线索页面
                this.$router.push({
                    path: '/crm/customer/add',
                });
            },

            handleGoDetail(id, name, isPublic) {
                if (isPublic == 1) {
                    this.$toast.fail('不能查看')
                    return
                }
                // 进入详情页面
                this.$router.push({
                    path: '/crm/customer/detail',
                    query: {
                        id,
                        name
                    },
                });
            },

            //点击操作展示动作面板
            operateAction(id) {
                this.itemId = id;

                this.setAction('crm:customer:phone', '拨号')
                this.setAction('crm:customer:update', '编辑')
                this.setAction('crm:customer:delete', '删除')
                this.setAction('crm:customer:transfer', '转移')
                this.setAction('crm:customerFollow:followAdd', '跟进')
                this.setAction('crm:customer:sceneClock', '现场打卡')
                if(this.permSaleAfterMaintenance){
                    this.setAction('crm:customer:list', '售后二维码')
                }

                this.actionShow = true;
            },

            //设置权限
            setAction(perm, permName) {
                if (this.$auth.hasPermi(perm)) {
                    let obj = this.actions.find(item => item.name == permName)
                    if (!obj) {
                        this.actions.unshift(
                            {name: permName, className: 'customer-opea-action'}
                        )
                    }
                } else {
                    this.actions = this.actions.filter(item => item.name != permName)
                }
            },

            //操作的动作面板被点击
            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                switch (item.name) {
                    case '拨号':
                        this.handleDial();
                        break;
                    case '编辑':
                        this.handleEdit();
                        break;
                    case '删除':
                        this.handleDelete();
                        break;
                    case '转移':
                        this.handleTransfer();
                        break;
                    case '领用':
                        this.moveInNotPublic();
                        break;
                    case '放入公海':
                        this.takePublicSea();
                        break;
                    case '添加下次联系':
                        this.addNextContact();
                        break;
                    case '取消下次联系':
                        this.cancelNextContact();
                        break;
                    case '微信聊天':
                        this.pullWeChat();
                        break;
                    case '售后二维码':
                        this.saleAfterQrCode();
                        break;
                    case '跟进':
                        this.followAdd();
                        break;
                    case '现场打卡':
                        this.sceneClock();
                        break;
                }
            },

            //拨号
            handleDial() {
                let obj = this.dataList.find((ele) => ele.id == this.itemId);
                if (obj.phone) {
                    makeCall(this.itemId, 1)
                    window.location.href = 'tel:' + obj.phone;
                } else {
                    this.$toast.success('此客户没有手机号，无法拨号');
                }

            },

            //微信聊天
            pullWeChat(){
                window.location.href = "weixin://";
            },

            //售后二维码
            async saleAfterQrCode(){
                let result = await qrCodeByCostProductId('c'+this.itemId)
                let url = window.URL.createObjectURL(new Blob([result]))
                this.qrCodeImgUrl = url
                this.qrCodeOpen = true

                //自定义字段
                let result2 = this.dataList.find(item=>item.id = this.itemId)
                this.qrCodeForm.fieldList = JSON.parse(result2.fields)
            },

            //现场打卡
            sceneClock(){
                this.sceneClockDialog = true
            },

            //跟进
            followAdd(){
                this.$router.push({
                    path: '/crm/customer/addFollow',
                    query: {
                        id: this.itemId,
                    },
                });
            },

            // 编辑
            handleEdit() {
                const obj = this.dataList.find((ele) => ele.id == this.itemId);
                // 新增线索页面
                this.$router.push({
                    path: '/crm/customer/add',
                    query: {
                        obj: JSON.stringify(obj),
                    },
                });
            },

            // 删除
            handleDelete() {
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除此客户?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await delList(this.itemId);
                        this.$toast.success('删除成功');
                        this.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },

            // 转移
            async handleTransfer() {
                const resultData = await treeAllDeptAndUser();
                this.items = resultData.data;

                this.items.forEach((item) => {
                    this.packItems(item);
                });

                this.showPop = true;
                this.activeId = null;
            },

            //递归label赋值给text
            packItems(item) {
                item.text = item.label;
                if (item.children) {
                    item.children.forEach((detail) => {
                        this.packItems(detail);
                    });
                }
            },

            //转移客户
            async contactPopSubmit() {
                if (!this.activeId) {
                    this.$toast.fail('请选择用户');
                }
                const params = {
                    leaderId: this.activeId,
                    isCost: this.isCost,
                    isContacts: this.isContacts,
                };
                let formdata = this.objectToFormData(params);
                const result = await fetchTransferCus(formdata, this.itemId);
                if (result.code == 200) {
                    this.$toast('转移成功');
                    await this.handleQuery();
                    this.showPop = false;
                }
            },

            // 放入公海
            async takePublicSea() {
                let that = this
                this.$dialog
                    .confirm({
                        title: '提示',
                        message: '确定将此客户放入公海吗?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await putPublic(that.itemId);
                        this.$toast.success('操作成功');
                        this.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },

            // 领用
            async moveInNotPublic() {
                let that = this
                this.$dialog
                    .confirm({
                        title: '提示',
                        message: '确定领用此客户吗?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await moveInNotPublic(that.itemId);
                        this.$toast.success('操作成功');
                        this.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },

            // 添加下次联系
            async addNextContact() {
                this.nextContactPop = true
            },

            //添加下次联系 提交
            async nextContactSubmit() {
                let result = await nextContact(this.objectToFormData(this.nextContactForm), this.itemId)
                if (result.code == 200) {
                    this.$toast(result.message);
                    this.nextContactPop = false
                }
            },

            //确认时间
            onConfirmTime(value) {
                const time = changeToCurrentDay(value);
                this.nextContactForm.nextContactTime = time;
                this.showCalendar = false;
            },

            //日期查询弹窗
            onDateConfirm(date) {
                let [start, end] = date;
                let beginTime = formatDate(start, 'yyyy-MM-dd')
                let endTime = formatDate(end, 'yyyy-MM-dd')
                this.queryParams.time = beginTime + '~' + endTime
                this.queryParams.timeState = beginTime
                this.queryParams.timeEnd = endTime

                this.handleQuery();

                this.showCalendarQuery = false;
            },

            // 取消下次联系
            cancelNextContact() {
                let that = this

                this.$dialog
                    .confirm({
                        title: '提示',
                        message: '确定取消下次联系吗??',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await cancelContact(that.itemId);
                        this.$toast.success('操作成功');
                        this.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
        },
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

  .search {
    padding-top: 40px;
    background: #fff;
  }

  .my-tabs {
    padding-top: 30px;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        line-height: 38px;
        width: 0;
        flex: 1;
      }

      .level-btn {
        width: 80px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;
    }
  }

  .query-class {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
  }

  ::v-deep .van-tabs__line {
    background-color: #0091ff;
  }

  .transfer-select-wrap {
    font-size: 28px;
  }

  ::v-deep .van-checkbox__label {
    line-height: 40px;
    font-size: 28px;
    padding-top: 10px;
  }

  ::v-deep .van-checkbox__icon .van-icon {
    width: 28px;
    height: 28px;
  }

  ::v-deep .van-field__label {
    font-size: 24px;
  }
</style>
