<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.accountName"
                            label="账户名称"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入账户名称' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.typeStr"
                            label="结算类型"
                            placeholder="请选择"
                            @click="showPickerType= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择结算类型' }]"
                    />
                    <van-popup v-model="showPickerType" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="typeOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'type', 'typeStr', 'dictLabel', 'showPickerType')
              "
                                @cancel="showPickerType = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.accountNumber"
                            label="账户账号"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入账户账号' }]"
                    />

                    <van-field
                            v-model="formInline.bank"
                            label="开户行"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.openAccount"
                            label="开户人姓名"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.phone"
                            label="联系人电话"
                            placeholder="请输入"
                    />

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
    import {addData, selectOne, updateData} from '@/api/cw/account/account';

    export default {
        data() {
            return {
                formInline: {},
                title: '新建账户',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerType: false,
                minDate: new Date(2010, 0, 1),
                //结算类型
                typeOptions: [
                    {dictValue: 0, dictLabel: '对公账户'},
                    {dictValue: 1, dictLabel: '对私账户'},
                    {dictValue: 2, dictLabel: '现金'}
                ],
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/cw/cwAccount');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {};
                    this.title = '新建账户';
                } else {
                    let itemDetail = await selectOne(this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑账户';
                    } else {
                        this.title = '账户详情';
                    }
                }
            },

            //确认
            onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            async onSubmit() {
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateData(data) : await addData(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
