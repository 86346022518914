import request from "@/utils/request";

// 生产委外 列表
export function selectSubContractPage(query) {
  return request({
    url: "/product/subcontract/selectAll",
    method: "get",
    params: query
  });
}

// 查询列表
export function selectPage(query) {
  return request({
    url: "/product/subcontractor/selectPage",
    method: "get",
    params: query
  });
}

// 删除
export function delContractor(id) {
  return request({
    url: "/product/subcontractor/" + id,
    method: "delete"
  });
}

// 根据id查询
export function selectOne(id) {
  return request({
    url: "/product/subcontractor/selectOne/" + id,
    method: "get"
  });
}

// 新增
export function addContractor(data) {
  return request({
    url: "/product/subcontractor",
    method: "post",
    data: data
  });
}

// 修改
export function updateContractor(data) {
  return request({
    url: "/product/subcontractor",
    method: "put",
    data: data
  });
}

// 查询全部委外加工商
export function selectAll() {
  return request({
    url: "/product/subcontractor/selectAll",
    method: "get"
  });
}
