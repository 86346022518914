import request from '@/utils/request'

// 查询列表
export function selectPage(query) {
  return request({
    url: "/purchase/order/getPage",
    method: "get",
    params: query
  });
}

//查询所有列表
export function selectList(state) {
  return request({
    url: "/purchase/order/getList/"+state,
    method: "get"
  });
}

//查询所有退货未完成的采购订单
export function getReturnList() {
  return request({
    url: "/purchase/order/getReturnList",
    method: "get"
  });
}

// 查询明细
export function selectOrderOne(id) {
  return request({
    url: '/purchase/order/'+id,
    method: "get"
  });
}

// 新增
export function addData(data) {
  return request({
    url: "/purchase/order",
    method: "post",
    data: data
  });
}

// 修改
export function updateData(data) {
  return request({
    url: "/purchase/order",
    method: "put",
    data: data
  });
}

// 采购
export function purAnticipatePlan(id) {
  return request({
    url: "/purchase/order/"+id,
    method: "put"
  });
}

// 送检
export function sendQuality(id) {
  return request({
    url: "/purchase/order/sendQuality/"+id,
    method: "put"
  });
}

// 删除
export function delData(id) {
  return request({
    url: "/purchase/order/"+id,
    method: "delete"
  });
}



