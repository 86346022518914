import * as echarts from "echarts";

export const getEchart = (strList, dataList,xname,yname) => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '24%',
            right: '12%',
            bottom: '10%'
        },
        xAxis: {
            type: 'value',
            name: xname
        },
        yAxis: {
            type: 'category',
            data: strList,
            name: yname
        },
        series: [
            {
                data: dataList,
                type: 'bar'
            }
        ]
    };
};
