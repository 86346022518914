<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="转账标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入转账标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.transferName"
                            label="转入账户"
                            placeholder="请选择"
                            @click="showPickerAccount1= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择转入账户' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.removeName"
                            label="转出账户"
                            placeholder="请选择"
                            @click="showPickerAccount2= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择转出账户' }]"
                    />

                    <van-field
                            v-model="formInline.price"
                            label="转账金额"
                            placeholder="请输入"
                            type="number"
                            :required="true"
                            :rules="[{ required: true, message: '请输入转账金额' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.transferTime"
                            label="转账日期"
                            placeholder="点击选择日期"
                            @click="showPickerDate = true"
                    />
                    <van-popup v-model="showPickerDate" style="width: 70%">
                        <van-datetime-picker
                                v-model="currentDate"
                                type="datetime"
                                title="请选择时间"
                                @confirm="onConfirmTime"
                                @cancel="showPickerDate = false"
                        />
                    </van-popup>

                    <textAreaInput :remark="formInline.remark" @change="handleChangeRemark"
                                   :title="'备注'"/>

                    <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <Account1 :showDialog="showPickerAccount1" @handleClose="handleCloseAccount1" @submitList="submitList1"/>
        <Account2 :showDialog="showPickerAccount2" @handleClose="handleCloseAccount2" @submitList="submitList2"/>
    </div>
</template>
<script>
    import {addData, selectOne, updateData} from '@/api/cw/bank/transfer';
    import Account1 from '@/components/account'
    import Account2 from '@/components/account'
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import {changeToCurrentDay} from "@/utils/ruoyi";
    import {submiteUpload} from "@/api/system/config";

    export default {
        components: {Account1, Account2, AppendixVue},
        data() {
            return {
                formInline: {},
                title: '新建转账',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerAccount1: false,
                showPickerAccount2: false,
                showPickerType: false,
                showPickerDate: false,
                currentDate: new Date(),
                uploader: [],
                uploadParams: {
                    businessType: 'cwtransfer',
                    businessId: undefined,
                    file: null,
                },
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/cw/bank');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {};
                    this.title = '新建转账';
                } else {
                    let itemDetail = await selectOne(this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑转账';
                    } else {
                        this.title = '转账详情';
                    }
                }
            },

            //日期提交
            onConfirmTime(value) {
                this.formInline.transferTime = changeToCurrentDay(value);
                this.showPickerDate = false;
            },

            //备注
            handleChangeRemark(val) {
                this.formInline.remark = val;
            },

            //账户关闭
            handleCloseAccount1() {
                this.showPickerAccount1 = false
            },

            //账户提交
            submitList1(id, name) {
                this.formInline.transferId = id;
                this.formInline.transferName = name;
                this.showPickerAccount1 = false
            },

            //账户关闭
            handleCloseAccount2() {
                this.showPickerAccount2 = false
            },

            //账户提交
            submitList2(id, name) {
                this.formInline.removeId = id;
                this.formInline.removeName = name;
                this.showPickerAccount2 = false
            },

            async onSubmit() {
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateData(data) : await addData(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
