import request from '@/utils/request'

// 查询列表
export function selectPage(query) {
  return request({
    url: "/purchase/payment/getList",
    method: "get",
    params: query
  });
}

// 删除
export function delData(id) {
  return request({
    url: "/purchase/payment/"+id,
    method: "delete"
  });
}

// 根据id查询
export function selectOne(id) {
  return request({
    url: "/purchase/payment/"+id,
    method: "get"
  });
}

// 新增
export function addData(data) {
  return request({
    url: "/purchase/payment",
    method: "post",
    data: data
  });
}

// 修改
export function updateData(data) {
  return request({
    url: "/purchase/payment",
    method: "put",
    data: data
  });
}

// 根据id查询子计划
export function selectOneSub(id) {
  return request({
    url: "/purchase/payment/getIdSub/"+id,
    method: "get"
  });
}

// 新增子计划
export function addSub(data) {
  return request({
    url: "/purchase/payment/addPurPaymentSub",
    method: "post",
    data: data
  });
}

// 修改子计划
export function updateSub(data) {
  return request({
    url: "/purchase/payment/updatePurPaymentSub",
    method: "put",
    data: data
  });
}

// 删除子计划
export function delSub(id) {
  return request({
    url: "/purchase/payment/deletepaymentSub/"+id,
    method: "delete"
  });
}
