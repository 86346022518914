<template>
    <div class="app-container">
        <div class="wrap">
            <navBar title="付款详情" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first>
                    <van-field
                            v-model="formInline.number"
                            label="总付款期数"
                            disabled
                    />
                    <van-field
                            v-model="formInline.currentNumber"
                            label="当前付款期数"
                            disabled
                    />
                    <van-field
                            v-model="formInline.price"
                            label="总金额"
                            disabled
                    />
                    <van-field
                            v-model="formInline.paymentPrice"
                            label="已付金额"
                            disabled
                    />
                    <van-field
                            v-model="formInline.notYerPrice"
                            label="待付金额"
                            disabled
                    />
                    <van-field
                            v-model="formInline.name"
                            label="关联计划"
                            disabled
                    />
                    <van-field
                            v-model="formInline.order"
                            label="关联订单"
                            disabled
                    />
                    <van-field
                            v-model="formInline.state"
                            label="付款状态"
                            disabled
                    />
                    <van-field
                            v-model="formInline.startTime"
                            label="开始日期"
                            disabled
                    />
                    <van-field
                            v-model="formInline.endTime"
                            label="结束日期"
                            disabled
                    />
                    <van-field
                            v-model="formInline.createTime"
                            label="创建时间"
                            disabled
                    />

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"
                                   :disabled="true"/>

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">当前第{{ index + 1 }}期</div>
                            <van-icon name="refund-o" @click="handleUpdate(item)"/>
                        </div>

                        <van-field
                                v-model="item.paymentDate"
                                label="付款时间"
                                disabled
                        />

                        <van-field
                                v-model="item.planPrice"
                                label="计划金额"
                                disabled
                        />

                        <van-field
                                v-model="item.price"
                                label="实付金额"
                                disabled
                        />

                    </div>
                </van-form>
            </div>
        </div>

        <van-dialog
                v-model="showPicker"
                title="付款子计划"
                width="70%"
                style="font-size: 14px;"
                :showConfirmButton="false"
                :close-on-click-overlay="true"
        >
            <van-form validate-first>
                <van-field
                        v-model="subForm.planPrice"
                        label-width="80px"
                        style="line-height: 40px;"
                        label="计划金额"
                        disabled
                />

                <van-field
                        v-model="subForm.notYerPrice"
                        label-width="80px"
                        style="line-height: 40px;"
                        label="未付金额"
                        disabled
                />
                <van-field
                        v-model="subForm.price"
                        label-width="80px"
                        label="付款金额"
                        placeholder="请输入"
                        style="line-height: 40px;"
                        :required="true"
                        :rules="[{ required: true, message: '请输入付款金额' }]"
                />

                <van-field
                        readonly
                        clickable
                        :value="subForm.collectionDate"
                        label-width="80px"
                        style="line-height: 40px;"
                        label="付款日期"
                        placeholder="点击选择日期"
                        :required="true"
                        :rules="[{ required: true, message: '请选择始日期' }]"
                        @click="showPickerDate = true"
                />

                <van-field
                        v-model="subForm.remark"
                        label-width="80px"
                        style="line-height: 40px;"
                        label="备注"
                        placeholder="请输入"
                />
            </van-form>
            <van-button type="info" style="width: 100%;height: 30px;font-size: 18px" @click="submitFormSub">确认
            </van-button>
        </van-dialog>
        <van-calendar :min-date="minDate" v-model="showPickerDate"
                      @confirm="onConfirmDate($event)"/>

    </div>
</template>
<script>
    import {getAllPaymentInfo, updateCollection} from '@/api/cw/payment/payment';
    import {formatDate} from "@/utils/ruoyi";

    export default {
        data() {
            return {
                formInline: {},
                subForm: {},
                id: this.$route.query.id,
                showPickerDate: false,
                showPicker: false,
                minDate: new Date(2010, 0, 1),
                //明细数组
                dataList: []
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                let itemDetail = await getAllPaymentInfo({paymentId: this.id});
                itemDetail = itemDetail.data
                this.formInline = itemDetail;
                this.dataList = itemDetail.sub
            },

            //日期确认
            onConfirmDate(value) {
                this.subForm.collectionDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerDate = false;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            //编辑子计划
            async handleUpdate(item) {
                this.subForm = {
                    id: item.id,
                    planPrice: item.planPrice,
                    notYerPrice: item.notYerPrice,
                    collectionDate: ''
                }
                this.showPicker = true
            },

            //提交
            async submitFormSub() {
                if (Number(this.subForm.price) > Number(this.subForm.notYerPrice)) {
                    this.$toast.fail("付款金额不能大于付款总金额");
                    return;
                }
                if (this.subForm.price < 0) {
                    this.$toast.fail("付款金额不能为负数");
                    return;
                }
                let formdata = new FormData();
                formdata.set("price", this.subForm.price);
                formdata.set("collectionDate", this.subForm.collectionDate);
                formdata.set("id", this.subForm.id);

                await updateCollection(formdata).then(() => {
                    this.$toast.success('操作成功');
                    this.showPicker = false;
                    this.findDetail()
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
