<template>
    <div class="app-container">
        <Header :moudle="'sale'"/>
        <div class="common-wrap">
            <div class="flex-spa-bet border-bom">
                <span class="title">销售简报</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>本月</span>
            </div>

            <div class="item-sale flex-spa-bet">
                <div class="item">
                    <div class="label">订单数量</div>
                    <div class="count">{{ countObj.orderNum }}</div>
                </div>
                <div class="item">
                    <div class="label">合同数量</div>
                    <div class="count">{{ countObj.contractNum }}</div>
                </div>
            </div>

            <div class="item-sale flex-spa-bet">
                <div class="item">
                    <div class="label">回款数量</div>
                    <div class="count">{{ countObj.collectionNum }}</div>
                </div>
                <div class="item">
                    <div class="label">退款数量</div>
                    <div class="count">{{ countObj.returnNum }}</div>
                </div>
            </div>
        </div>

        <div class="common-wrap" v-if="this.$auth.hasPermi('crm:collection:list')">
            <div class="flex-spa-bet border-bom">
                <span class="title">待回款金额仪表盘</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>本月</span>
            </div>
            <div style="width: 100%;height: 300px;margin-top: 10px;padding-top: 10px;" ref="waitCollection-echarts"></div>
        </div>

        <div class="common-wrap" v-if="this.$auth.hasPermi('crm:collection:list')">
            <div class="flex-spa-bet border-bom">
                <span class="title">逾期回款</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>本月</span>
            </div>
            <div class="table-header flex-spa-bet">
                <span class="se-tr-name">回款名称</span>
                <span class="se-tr-customer">客户名称</span>
            </div>
            <div class="overdue">
                <div class="table-body">
                    <div class="flex-spa-ver-cen" v-for="(item, index) in tableData" :key="index">
                        <div class="flex-ver-cen" style="width: 100%;margin-top: 5px">
                            <span class="se-tr-name">{{ item.name }}</span>
                            <span class="se-tr-customer rightData">{{ item.customer }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '@/components/header';
    import {getStatisticsAdd} from "@/api/crm/collection";
    import {fetchCollection, fetchWaitCollection} from "@/api/crm/work";
    import {getWaitCollectionEchart} from '@/echarts/report/crm/saleWork'

    export default {
        components: {
            Header
        },

        data() {
            return {
                tableData: [],
                countObj: {},
                params: {
                    screen: 0,
                    time: 1,
                    type: 1,
                    state: 0,
                    isRanking: false,
                    leaderId: this.$store.getters.userId
                },
                waitCollectionEcharts: null
            };
        },

        mounted() {
            //新增数量
            this.getCount()

            if (this.$auth.hasPermi('crm:collection:list')) {
                //逾期回款
                this.getCollection()
                // 待回款仪表盘
                this.waitCollection()
            }

            window.addEventListener('resize', () => {
                if (this.waitCollectionEcharts) {
                    this.waitCollectionEcharts.resize()
                }
            })
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            //新增数量
            async getCount() {
                const result = await getStatisticsAdd(this.params)
                this.countObj = result.data
            },

            //逾期回款
            async getCollection() {
                const result = await fetchCollection(this.params)
                this.tableData = result.data || []
            },

            // 待回款仪表盘
            async waitCollection() {
                const result = await fetchWaitCollection(this.params)
                this.waitCollList = result.data.waitCollection || []

                let price = result.data.price
                let collectionPrice = result.data.collectionPrice

                this.waitCollectionEcharts = this.$echarts.init(this.$refs['waitCollection-echarts'])
                this.waitCollectionEcharts.setOption(getWaitCollectionEchart(price, collectionPrice))
            },

            echartDestroy() {
                window.removeEventListener('resize', this.waitCollectionEcharts)
                if (this.waitCollectionEcharts) {
                    this.waitCollectionEcharts.clear();
                    this.waitCollectionEcharts.dispose();
                    this.waitCollectionEcharts = null;
                }
            }
        },
    };
</script>
<style lang="scss" scoped>
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
  }

  .more {
    font-size: 22px;
    font-weight: 400;
    color: #b5b5b5;
  }

  .common-wrap {
    padding: 20px;
    margin-top: 20px;

    .left-tit {
      font-size: 28px;
      color: #444444;

      .tit {
        width: 460px;
      }
    }

    .day {
      font-size: 22px;
      color: #e02020;
    }

    .time {
      font-size: 22px;

      color: #999999;
    }

    .item-sale {
      .item {
        padding: 28px 50px 36px;
        flex: 1;

        .label {
          font-size: 24px;
          color: #aaaaaa;
        }

        .count {
          font-size: 32px;
          font-weight: 800;
          color: #333333;
          margin-top: 10px;
        }
      }
    }
  }

  .table-header {
    padding: 35px 10px 5px 30px;
    width: 100%;

    .se-tr-name {
      color: #aaaaaa;
      font-size: 28px;
      width: 47%;
    }

    .se-tr-customer {
      color: #aaaaaa;
      font-size: 28px;
      width: 50%;
    }
  }

  .overdue {
    display: flex;
    flex: 1;
    overflow: scroll;
    height: 400px;
    margin-top: 10px;

    .table-body {
      padding: 10px 10px 5px 30px;
      width: 100%;

      .se-tr-name {
        color: #aaaaaa;
        font-size: 28px;
        width: 47%;
      }

      .se-tr-customer {
        color: #aaaaaa;
        font-size: 28px;
        width: 50%;
      }
    }
  }
</style>
