<template>
    <div class="app-container">
        <navBar :title="'会议室详情'" @handleGoBack="handleGoBack">
            <template v-slot:icon1 v-if="this.$auth.hasPermi('oa:meeting:deleteRoom')">
                <van-icon name="delete" size="20" class="mrg-r-20" @click="handleDelete"/>
            </template>
        </navBar>

        <div class="item-tab">
            <div class="title-wrap">
                <div class="flex-spa-ver-cen">
                    <div class="tit over_hidden">{{ detailObj.name || '--' }}</div>
                </div>
                <div class="time">创建时间：{{ detailObj.createTime || '--' }}</div>
            </div>
            <div class="con">
                <div class="desc-wrap flex">
                    <div class="label">创建人：</div>
                    <div class="value">{{ detailObj.createBy || '--' }}</div>
                </div>
                <div class="desc-wrap flex">
                    <div class="label">容纳人数：</div>
                    <div class="value">{{ detailObj.number || '--' }}</div>
                </div>
                <div class="desc-wrap flex">
                    <div class="label">会议室设备：</div>
                    <div class="value">{{ detailObj.equipment || '--' }}</div>
                </div>
                <div class="desc-wrap flex">
                    <div class="label">会议室描述：</div>
                    <div class="value">{{ detailObj.roomDescribe || '--' }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getIdRoom, deleteRoom} from "@/api/oa/meeting";

    export default {
        data() {
            return {
                dataId: this.$route.query.id,
                detailObj: {}
            };
        },

        mounted() {
            this.getDetail();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/oa/meeting');
                this.$router.go(-1)
            },

            async getDetail() {
                const result = await getIdRoom(this.dataId);
                const {data = {}} = result;
                this.detailObj = data;
            },

            //删除
            handleDelete() {
                let that = this
                this.$dialog.confirm({
                    message: '是否确认删除',
                    width: '80%'
                })
                    .then(async () => {
                        // on confirm
                        await deleteRoom(that.dataId);
                        await that.handleGoBack();
                        that.$toast.fail('删除成功');

                    })
                    .catch(() => {
                    });
            }
        }

    };
</script>

<style lang="scss" scoped>
  .item-tab {
    background: #fff;
    padding: 0 33px;
    margin-top: 20px;

    .title-wrap {
      padding: 26px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 0;
        flex: 1;
        margin-top: 10px;
        margin-bottom: 30px;
      }

      .time {
        font-size: 24px;
        margin-top: 10px;
        margin-left: 20px;
        color: #999999;
      }
    }

    .con {
      padding: 30px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 30px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }
  }

  ::v-deep .van-tabs--line .van-tabs__wrap {
    height: 80px !important;
  }

  ::v-deep .van-tab {
    // padding: 40px 0;
    height: 80px !important;
    line-height: 80px !important;
    padding-bottom: 0 !important;
  }
</style>
