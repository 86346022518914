<template>
    <div>
        <van-dialog
                v-model="dialogVisible"
                title="现场打卡" width="95%"
                style="font-size: 20px"
                @confirm="handleClock()"
                show-cancel-button
        >
            <div class="wq-content">
                <div class="time">打卡地点: {{ form.address }}</div>
                <div class="uploader">
                    <van-uploader v-model="fileList" :max-count="2" preview-size="90px" capture="camera"/>
                </div>

                <van-field
                        v-model="form.remark"
                        rows="3"
                        autosize
                        type="textarea"
                        maxlength="50"
                        placeholder="请备注打卡原因（50字内）"
                        show-word-limit
                />
            </div>
        </van-dialog>
    </div>
</template>
<script>
    import {sceneClock} from '@/api/crm/customer'
    import {submiteUpload} from "@/api/system/config";
    import {findAddressByLngLatTDT} from "@/api/system/ymxx";

    export default {
        props: {
            customerVisible: Boolean,
            customerId: Number
        },
        watch: {
            customerVisible: {
                handler() {
                    this.dialogVisible = this.customerVisible;
                    if(this.dialogVisible){
                        this.getCurrentPoint()
                    }
                },
                deep: true,
                immediate: true
            }
        },

        data() {
            return {
                queryParams: {
                    pageNum: 1,
                    pageSize: 3
                },
                form: {},
                dialogVisible: false,
                //附件列表
                fileList: [],
            }
        },

        methods: {
            //查询当前经纬度
            getCurrentPoint() {
                if (navigator.geolocation) {
                    let _this = this
                    navigator.geolocation.getCurrentPosition(async function (position) {
                        let lng = position.coords.longitude
                        let lat = position.coords.latitude
                        let result = await findAddressByLngLatTDT({lngLat: lng + ',' + lat})
                        _this.form.address = result.data;
                        _this.$forceUpdate()
                    },function (error) {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                alert('用户拒绝对获取地理位置的请求。')
                                break;
                            case error.POSITION_UNAVAILABLE:
                                alert('位置信息是不可用的。')
                                break;
                            case error.TIMEOUT:
                                alert('请求用户地理位置超时。')
                                break;
                            case error.UNKNOWN_ERROR:
                                alert('未知错误。')
                                break;
                        }
                    },{enableHighAccuracy: true})
                }
            },

            async handleClock() {
                if(this.form.address == undefined || this.form.address == ''){
                    this.$toast({
                        message: '正在获取地点信息，请稍后提交',
                        icon: 'fail',
                    });
                    return
                }

                this.form.customerId = this.customerId
                const result = await sceneClock(JSON.stringify(this.form));
                if (result.code == 200) {
                    if (this.fileList) {
                        this.fileList.forEach(item => {
                            let params = new FormData();
                            params.append('file', item.file);
                            params.append('businessType', 'crm_scene_clock');
                            params.append('businessId', result.data);
                            submiteUpload(params);
                        })
                        this.fileList = []
                    }
                    this.$toast({
                        message: '打卡成功',
                        icon: 'success',
                    });
                    this.handleClose()
                }
            },

            handleClose() {
                this.dialogVisible = false
                this.$emit('handleClose')
            },
        }
    }
</script>
<style lang="scss" scoped>
  .title {
    font-size: 18px;
    margin: 10px 0;
  }



  .wq-content {
    padding: 40px;

    .time {
      font-size: 24px;
      color: #0091ff;
      margin-bottom: 20px;
    }

    .address {
      margin-bottom: 20px;
    }

    .uploader {
      text-align: center;
      margin: 30px auto 20px;
    }
  }
</style>
