<template>
    <div class="app-container" v-if="showEcharts">
        <div style="width: 100%;height: 550px;padding-top: 30px" ref="echartsRef"></div>

        <div class="table">
            <div class="table-header flex-spa-bet">
                <span class="se-tr">姓名</span>
                <span class="se-tr">跟进次数</span>
                <span class="se-tr">线索</span>
                <span class="se-tr">客户</span>
                <span class="se-tr">商机</span>
            </div>
            <div class="table-body">
                <div class="itemDiv item-tr flex-spa-ver-cen" v-for="(item, index) in tableData" :key="index">
                    <van-divider/>
                    <div class="itemCol name-wrap flex-ver-cen">
                        <div class="se-tr">{{ item.realname }}</div>
                        <div class="se-tr rightData">{{ item.num }}</div>
                        <div class="se-tr rightData">{{ item.clue }}</div>
                        <div class="se-tr rightData">{{ item.customer }}</div>
                        <div class="se-tr rightData">{{ item.cost }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getEchart} from '@/echarts/report/crm/personnelFollow'
    import {personnelFollow} from '@/api/report/crm/crmReport'

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    this.showEcharts = this.isShow
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart) {
                                this.dataEchart.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                showEcharts: false,
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                tableData: [],
                dataEchart: null,
                // 查询参数
                queryParams: {
                    leaderId: undefined
                }
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                //列表
                let result = await personnelFollow(this.queryParams)
                this.tableData = result.data

                //图
                const strList = result.data.map(ele => ele.realname)
                const clueList = result.data.map(ele => ele.clue)
                const customerList = result.data.map(ele => ele.customer)
                const costList = result.data.map(ele => ele.cost)
                const totalList = result.data.map(ele => ele.num)

                const clueMax = Math.max.apply(Math,this.tableData.map(item => { return item.clue }))
                const customerMax = Math.max.apply(Math,this.tableData.map(item => { return item.customer }))
                const costMax = Math.max.apply(Math,this.tableData.map(item => { return item.cost }))
                const numMax = Math.max.apply(Math,this.tableData.map(item => { return item.num }))

                this.dataEchart = await this.$echarts.init(this.$refs.echartsRef)
                const echartOption = getEchart(strList, clueList, customerList,costList,totalList,(clueMax*1.5).toFixed(2),(customerMax*1.2).toFixed(2),(costMax*1.2).toFixed(2),(numMax*1.2).toFixed(2))
                setTimeout(()=>{
                    this.dataEchart.setOption(echartOption)
                },100)

            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart)
                if (this.dataEchart) {
                    this.dataEchart.clear();
                    this.dataEchart.dispose();
                    this.dataEchart = null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
  .table {
    margin-top: 60px;

    .table-header {
      padding: 10px 10px 5px 30px;

      span {
        display: inline-block;
        color: #b5b5b5;
        font-size: 22px;
      }

      .se-tr {
        width: 25%;
      }
    }

    .table-body {
      padding: 10px 10px 5px 30px;
      font-size: 26px;

      .itemDiv {
        width: 100%;
        margin-top: 30px;

        .itemCol {
          width: 100%;

          .se-tr {
            width: 25%;
          }

          .rightData {
            padding-left: 15px;
          }
        }
      }
    }
  }
</style>
