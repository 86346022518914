import request from "@/utils/request";
// 查询客户下所有发票管理
export function fetchColOrderListByCustomerId(params) {
  return request({
    url: "/crm/collection/getOrder",
    method: "get",
    params
  });
}

// 获取发票抬头
export function fetchHeadList(params) {
  return request({
    url: "/cw/invoiceHead/getList",
    method: "get",
    params
  });
}

// 新建发票
export function addCrmCollection(data) {
  return request({
    url: "/crm/collection/addCrmCollection",
    method: "post",
    data
  });
}
// 获取数量 fetchCount
export function fetchCount() {
  return request({
    url: "/crm/collection/getNumber",
    method: "get"
  });
}
// 获取查询列表
export function selectPage(query) {
  return request({
    url: "/crm/collection/getList",
    method: "get",
    params: query
  });
}
// 删除
export function delList(ids) {
  return request({
    url: "/crm/collection/deleteCollection/" + ids,
    method: "delete"
  });
}

// 审批-驳回
export function disAppvoral(data) {
  return request({
    url: "/crm/invoice/inspectInvoice",
    method: "put",
    data
  });
}

// 获取单条信息
export function selectItemByCollection(params) {
  return request({
    url: "/crm/collection/getId",
    method: "get",
    params
  });
}

// 根据订单id查询详情
export function selectItemByOrderId(params) {
  return request({
    url: "/crm/collection/getByOrderId",
    method: "get",
    params
  });
}

// 更新
export function updateCrmCollection(data) {
  return request({
    url: "/crm/collection/updateCollection",
    method: "put",
    data
  });
}

//
export function getStatisticsAdd(params) {
  return request({
    url: "/crm/saleWork/getStatisticsAdd",
    method: "get",
    params
  });
}

// 新建子回款
export function addCrmCollectionSub(data) {
  return request({
    url: "/crm/collection/addCrmCollectionSub",
    method: "post",
    data
  });
}

// 修改子回款
export function updateCrmCollectionSub(data) {
  return request({
    url: "/crm/collection/updateCrmCollectionSub",
    method: "put",
    data
  });
}

// 删除自回款
export function delSubList(params) {
  return request({
    url: "/crm/collection/deleteCollectionSub/",
    method: "delete",
    params
  });
}
