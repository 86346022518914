import * as echarts from "echarts";

// 柱状图
export const getEchartBar = (strList, dataList,xname,yname) => {
  return {
    title: {
      text: '采购数量',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    grid: {
      left: '20%',
      right: '20%',
      bottom: '8%'
    },
    xAxis: {
      type: 'value',
      name: yname
    },
    yAxis: {
      type: 'category',
      data: strList,
      name: xname
    },
    series: [
      {
        data: dataList,
        type: 'bar'
      }
    ]
  };
};
// 饼状图
export const getEchartPie = (priceList) => {
  return {
    title: {
      text: '采购数量',
      left: 'center'
    },
    grid: {
      top: '0'
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      left: 'left'
    },
    series: [
      {
        name: '采购数量',
        type: 'pie',
        radius: '50%',
        data: priceList,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
};
// 折线图
export const getEchartLine = (nameList, quaList,xname,yname) => {
  return {
    title: {
      text: '订单数量',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    grid: {
      left: '20%',
      right: '20%',
      bottom: '8%'
    },
    xAxis: {
      type: 'value',
      name: yname
    },
    yAxis: {
      type: 'category',
      data: nameList,
      name: xname
    },
    series: [
      {
        data: quaList,
        type: 'line'
      }
    ]
  };
};
