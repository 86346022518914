<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'工作汇报'" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-icon name="plus" class="mrg-r-20" @click="handleAdd"/>
                </template>
            </navBar>

            <div class="common-wrap">
                <van-search
                        show-action
                        v-model="queryParams.workTitle"
                        placeholder="请输入汇报标题"
                        @search="handleQuery"
                >
                    <template #action>
                        <div class="search-btn-label" @click="handleQuery">搜索</div>
                    </template>
                </van-search>
            </div>

            <van-tabs class="my-tabs" @click="getListParams" v-model="active">
                <van-tab v-for="(item, index) in list" :title="item.name" :key="index" :color="'#0091FF'">
                    <div class="main">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    offset="100"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"
                            >
                                <div class="list" v-for="item in dataList" :key="item.id">
                                    <div class="line"></div>
                                    <div class="item-tab" @click="handleGoDetail(item.id)">
                                        <div class="title-wrap flex-spa-ver-cen">
                                            <div class="tit over_hidden">
                                                {{ item.title || '--' }}
                                            </div>
                                        </div>
                                        <div class="con">
                                            <div class="desc-wrap flex">
                                                <div class="label">发布人：</div>
                                                <div class="value">{{ item.submitName || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">汇报时间：</div>
                                                <div class="value">{{ item.time || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">汇报类型：</div>
                                                <div class="value red">{{ item.type || '--' }}</div>
                                            </div>
                                            <!--<div class="desc-wrap flex">
                                                <div class="label">汇报内容：</div>
                                                <div class="value" v-html="item.content"></div>
                                            </div>-->
                                        </div>
                                        <div class="time-wrap flex-spa-ver-cen">
                                            <div class="time">{{ item.createTime || '--' }}</div>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
    import {
        getSendList,
        getMyList
    } from '@/api/oa/word';

    export default {
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                queryParams: {
                    pageNum: 1,
                    pageSize: 5,
                    condition: null
                },
                active: 0,
                list: [
                    {
                        name: '我发布的',
                        value: '0',
                    },
                    {
                        name: '我收到的',
                        value: '1',
                    }
                ],
                dataList: []
            };
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/oa/oaWorkbench');
                this.$router.go(-1)
            },

            async getList() {
                this.loading = true;
                let result = {}
                if(this.active == 0){
                    result = await getMyList(this.queryParams);
                }else if(this.active == 1){
                    result = await getSendList(this.queryParams);
                }

                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.dataList = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.dataList = records;
                } else {
                    this.dataList = [...this.dataList, ...records];
                }
                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
            },

            onLoad() {
                if (this.refreshing) {
                    this.dataList = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.dataList.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            getListParams() {
                this.finished = false;
                this.loading = false;
                this.dataList = [];

                this.handleQuery();
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },

            handleAdd() {
                // 新增页面
                this.$router.push({
                    path: '/oa/work/add',
                });
            },

            handleGoDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/oa/work/detail',
                    query: {
                        id,
                        type: this.active
                    },
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

  .search {
    padding-top: 40px;
    background: #fff;
  }

  .my-tabs {
    padding-top: 30px;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        line-height: 38px;
        width: 0;
        flex: 1;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          white-space: nowrap;
          color: #999;
        }

        .value {
          color: #111;
          white-space: nowrap;
          overflow: hidden;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;
    }
  }

  ::v-deep .van-tabs__line {
    background-color: #0091ff;
  }

  .transfer-select-wrap {
    font-size: 28px;
  }

  ::v-deep .van-checkbox__label {
    line-height: 40px;
    font-size: 28px;
    padding-top: 10px;
  }

  ::v-deep .van-checkbox__icon .van-icon {
    width: 28px;
    height: 28px;
  }
</style>
