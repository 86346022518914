<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
              v-model="formInline.theme"
              label="订单标题"
              placeholder="请输入"
              :required="true"
              :rules="[{ required: true, message: '请输入订单标题' }]"
          />

          <van-field
              readonly
              clickable
              name="customerId"
              v-model="formInline.customer"
              label="客户名称"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择客户' }]"
              @click="showPickerCustomer = true"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.cost"
              label="商机"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择商机' }]"
              @click="showPickerCost= true"
              v-if="$auth.hasPermi('crm:cost:list')"
          />
          <van-popup v-model="showPickerCost" position="bottom">
            <van-picker
                show-toolbar
                :columns="bussinessList"
                value-key="name"
                @confirm="
                onConfirm($event, 'costId', 'cost', 'name', 'showPickerCost')
              "
                @cancel="showPickerCost = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              :value="formInline.signingDate"
              label="签单日期"
              placeholder="点击选择日期"
              :required="true"
              :rules="[{ required: true, message: '请选择签单日期' }]"
              @click="showPickerApply = true"
          />
          <van-calendar :min-date="minDate" v-model="showPickerApply"
                        @confirm="onConfirmApply($event)"/>

          <van-field
              readonly
              clickable
              v-model="formInline.leaderStr"
              label="负责人"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择负责人' }]"
              @click="showPop= true"
          />

          <van-field
              v-model="formInline.price"
              label="产品总金额"
              disabled
          />

          <van-field
              v-model="formInline.discount"
              label="优惠金额"
              type="number"
              @input="handleInputChange"
          />

          <van-field
              v-model="formInline.discountPrice"
              label="优惠后总金额"
              disabled
          />

          <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

          <div v-for="(item, index) in dataList" :key="index">
            <div class="flex-spa-bet list-tit-wrap">
              <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
              <van-icon name="edit" @click="handleUpdate(item)"/>
            </div>

            <van-field
                v-model="item.materialSpecification"
                label="规格"
                disabled
            />

            <van-field
                v-model="item.materialUnit"
                label="单位"
                disabled
            />

            <van-field
                v-model="item.materialCostPrice"
                label="成本"
                disabled
            />

            <van-field
                v-model="item.originalCost"
                label="标准价格"
                disabled
            />

            <van-field
                v-model="item.price"
                label="售价"
                disabled
            />

            <van-field
                v-model="item.quantity"
                label="数量"
                disabled
            />

            <van-field
                v-model="item.discount"
                label="折扣"
                disabled
            />

            <van-field
                v-model="item.subtotal"
                label="小计"
                disabled
            />
          </div>
          <div class="add-btn flex-hor-ver-cen" @click="handleAdd">
            <van-icon name="add-o" class="mrg-r-20"/>
            添加产品
          </div>

          <div v-if="this.$auth.hasPermi('custom:productFlow:list') && type == 'detail'">
            <div style="margin: 20px 0 20px 15px; font-size: 18px;">生产进度：</div>
            <van-circle v-model="currentRate" :rate="30" :speed="100" :text="currentRate.toFixed(3) + '%'"
                        size="150" style="margin-left: 20px"/>
          </div>

          <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

          <div class="line"></div>
          <van-field name="uploader" label="附件" v-if="type != 'detail'">
            <template #input>
              <van-uploader v-model="uploader" :after-read="afterRead"/>
            </template>
          </van-field>

          <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
          </van-button>
        </van-form>
      </div>
    </div>

    <CustomerPop :customerVisible="showPickerCustomer" @submitCustomerList="submitCustomerList"
                 @handleClose="handleCloseCustomer"/>

    <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>

    <CostProduct :showDialog="showPickerMaterial" :formObj="costForm" @handleClose="handleCloseMaterial"
                 @submitList="submitMaterial"/>
  </div>
</template>
<script>
import {addOrder, editOrder, fetchCostListByCustomrId, getDetailByOrderId} from '@/api/crm/order';
import AppendixVue from '@/views/components/crm/Appendix.vue';
import CustomerPop from '@/components/crm/CustomerPop'
import SelectOneUser from '@/components/selectOneUser/index'
import CostProduct from "@/components/costProduct/index";
import {changeToCurrentDay, formatDate} from "@/utils/ruoyi";
import {submiteUpload} from "@/api/system/config";
import {addTempCost, delTempCost, fetchBusinessInfoById} from "@/api/crm/bussiness";
import {findProgressByOrderId} from "@/api/custom/productFlow";

export default {
  components: {AppendixVue, SelectOneUser, CostProduct, CustomerPop},
  data() {
    return {
      formInline: {},
      title: '新建订单',
      id: this.$route.query.id,
      type: this.$route.query.type,
      uploader: [],
      uploadParams: {
        businessType: 'crm_order',
        businessId: undefined,
        file: null,
      },
      showPickerCustomer: false,
      showPickerCost: false,
      showPickerMaterial: false,
      showPop: false,
      showPickerApply: false,
      minDate: new Date(2010, 0, 1),
      //产品成本总价
      materialCostPrice: 0,
      //利润
      profit: 0,
      //物料数组
      dataList: [],
      //商机数组
      bussinessList: [],
      //产品对象
      costForm: {},
      //选中的明细索引
      itemIndex: null,
      //生产进度
      currentRate: 0
    };
  },

  mounted() {
    //回显
    this.findDetail()
  },

  methods: {
    async handleGoBack() {
      if (!this.$auth.hasPermi('crm:cost:list')) {
        //删除临时商机
        await delTempCost()
      }
      this.$router.go(-1)
    },

    //回显
    async findDetail() {
      if (this.type == 'add') {
        this.formInline = {
          enterCategory: 0
        };
        this.title = '新建订单';
      } else {
        let itemDetail = await getDetailByOrderId({orderId: this.id});
        itemDetail = itemDetail.data
        this.formInline = itemDetail;
        this.dataList = itemDetail.costProducts
        if (itemDetail && this.type == 'edit') {
          this.title = '编辑订单';
        } else if (this.$auth.hasPermi('custom:productFlow:list')) {
          let result = await findProgressByOrderId(this.id)
          this.currentRate = result.data
          this.title = '订单详情';
        }
      }
    },

    //客户提交
    async submitCustomerList(id, name) {
      this.formInline.customerId = id;
      this.formInline.customer = name;

      //改变客户后，表单重置
      this.dataList = []
      this.formInline.costId = null
      this.formInline.price = 0
      this.formInline.discount = 0
      this.formInline.otherPrice = 0
      this.formInline.discountPrice = 0
      const result = await fetchCostListByCustomrId({
        customerId: id
      })
      this.bussinessList = result.data

      this.showPickerCustomer = false
    },

    //关闭选择客户的弹窗
    handleCloseCustomer() {
      this.showPickerCustomer = false;
    },

    //确认商机
    async onConfirm(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['id'];
      this.formInline[keyName] = event[eventKey];

      await this.findCostProduct()
      this.handleInputChange()

      this.formInline.theme = event['name']

      this[visible] = false;
    },

    //查询商机产品信息
    async findCostProduct() {
      const result = await fetchBusinessInfoById({id: this.formInline.costId})
      let productPrice = 0
      this.materialCostPrice = 0
      result.data.forEach(ele => {
        ele.quantity = ele.quantity ? ele.quantity : 0
        this.materialCostPrice = this.materialCostPrice + (ele.materialCostPrice * ele.quantity)
        productPrice += ele.subtotal;
      })
      this.dataList = result.data

      this.formInline.price = productPrice
      this.formInline.discount = 0
      this.$forceUpdate()
    },

    //日期提交
    onConfirmTime(value) {
      this.formInline.purchaseDate = changeToCurrentDay(value);
      this.showPickerApply = false;
    },

    //选择人提交
    popSubmit(activeId, names) {
      this.showPop = false
      this.formInline.leaderId = activeId
      this.formInline.leaderStr = names
    },

    //关闭选择人
    handleCloseUser() {
      this.showPop = false
    },

    //日期确认
    onConfirmApply(value) {
      this.formInline.signingDate = formatDate(value, 'yyyy-MM-dd');
      this.showPickerApply = false;
    },

    //优惠后总金额
    handleInputChange() {
      //优惠后总金额 = 产品总金额 - 优惠金额
      this.formInline.discountPrice = this.formInline.price - this.formInline.discount
      //计算利润
      this.profit = this.formInline.discountPrice - this.materialCostPrice - this.formInline.otherPrice
    },

    //备注
    handleContentChange(val) {
      this.formInline.remark = val;
    },

    //添加节点
    async handleAdd() {
      if (!this.$auth.hasPermi('crm:cost:list')) {
        //如果没有商机的权限
        let result = await addTempCost()
        this.formInline.costId = result.data
      }
      this.costForm = {
        id: undefined,
        costId: this.formInline.costId,
        productId: undefined,
        materialName: '',
        materialCode: '',
        materialUnit: '',
        originalCost: 0,
        price: 0,
        discount: 0,
        quantity: 0,
        subtotal: 0,
        remark: ''
      }
      this.showPickerMaterial = true;
    },

    //编辑节点
    handleUpdate(item) {
      this.costForm = {
        id: item.id,
        costId: item.costId,
        productId: item.productId,
        materialName: item.materialName,
        materialCode: item.materialCode,
        materialUnit: item.materialUnit,
        originalCost: item.originalCost,
        price: item.price,
        discount: item.discount,
        quantity: item.quantity,
        subtotal: item.subtotal,
        remark: item.remark
      }
      this.showPickerMaterial = true;
    },

    //关闭物料弹窗
    handleCloseMaterial() {
      this.costForm = {}
      this.showPickerMaterial = false
    },

    //物料提交
    submitMaterial() {
      this.findCostProduct()
      this.handleCloseMaterial()
    },

    //物料单条明细总金额计算
    itemTotalPriceCal(item) {
      item.total = ((item.num ? item.num : 0) * (item.price ? item.price : 0)).toFixed(2)
      this.priceCal();
    },

    //总价计算
    priceCal() {
      let purchasePrice = 0
      this.dataList.forEach(item => {
        purchasePrice += Number(item.total)
      })
      this.formInline.purchasePrice = purchasePrice
    },

    async onSubmit() {
      if (this.dataList.length == 0) {
        this.$toast.fail('请添加采购物料');
        return;
      }

      this.dataList.forEach(item => {
        delete item.createBy
        delete item.createTime
        delete item.updateBy
        delete item.updateTime
        delete item.companyId
      })

      this.formInline.purAnticipatePlanSubs = this.dataList
      let data = JSON.stringify(this.formInline)

      const result = this.formInline.id ? await editOrder(data) : await addOrder(data);
      if (result.code == 200) {
        this.$toast.success('操作成功');
        if (this.uploadParams.file) {
          this.uploadParams.businessId = result.data
          setTimeout(async () => {
            await submiteUpload(this.uploadParams);
          }, 1000);
        }
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadParams.file = file.file;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-tit {
  padding: 20px 30px;
  font-size: 24px;
  color: #666666;
}

.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

.list-tit-wrap {
  padding: 24px 30px;
  font-size: 26px;
  color: #333333;
}

.add-btn {
  width: 750px;
  height: 110px;
  background: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  line-height: 110px;
  font-size: 30px;
  color: #54afff;
  cursor: pointer;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end
</style>
