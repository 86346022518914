import request from '@/utils/request'

// 获取我的申请
export function findMyApplyPage(query) {
  return request({
    url: `/system/approve/findMyApplyPage`,
    method: 'GET',
    params: query
  })
}

// 获取待办
export function findWaitPage(query) {
  return request({
    url: `/system/approve/findWaitPage`,
    method: 'GET',
    params: query
  })
}

// 获取已办
export function findFinishPage(query) {
  return request({
    url: `/system/approve/findFinishPage`,
    method: 'GET',
    params: query
  })
}

// 删除审批
export function DeleteProcess(approveId) {
  return request({
    url: `/system/approve/${approveId}`,
    method: 'DELETE'
  })
}

// 审批历史弹窗里的table列表数据
export function selectApprovalHisPage(query) {
  return request({
    url: "/system/approveHistory/selectPage",
    method: "get",
    params: query
  });
}

// 获取审批单条
export function findOne(approveId) {
  return request({
    url: "/system/approve/findOne/"+approveId,
    method: "get"
  });
}

// 通过/驳回
export function operateApprove(data) {
  return request({
    url: "/system/approve/operateApprove",
    method: "put",
    data
  });
}

// 新建审批
export function addApprove(report) {
  return request({
    url: `/system/approve`,
    method: "post",
    data: report
  });
}

// 修改审批
export function updateApprove(data) {
  return request({
    url: "/system/approve",
    method: "put",
    data: data
  });
}

// 会议室列表
export function getListRoom() {
  return request({
    url: `/oa/meeting/getListRoom`,
    method: "GET"
  });
}

export function findCar(carId) {
  return request({
    url: `/oa/car/getId`,
    method: "GET",
    params: { carId }
  });
}

export function findMeetingRoom(meetingRoomId) {
  return request({
    url: `/oa/meeting/getIdRoom`,
    method: "GET",
    params: { meetingRoomId }
  });
}

// 获取待办数量
export function statisticWait() {
  return request({
    url: `/system/approve/statisticWait`,
    method: 'GET'
  })
}

// 下一节点的审批人是否由本节点指定
export function nextCooperateWith(approveId) {
  return request({
    url: `/system/approve/nextCooperateWith/`+approveId,
    method: 'GET'
  })
}

// 提交后是否需要协同人
export function findIsCooperateWith(params) {
  return request({
    url: `/system/approve/findIsCooperateWith`,
    method: 'get',
    params
  })
}
