import * as echarts from "echarts";

export const getEchart = (strList, numList,xname,yname) => {
  return {
    // title: {
    //   text: "销售预测分析图",
    //   subtext: "Feature Sample: Gradient Color, Shadow, Click Zoom"
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      // 这个属性代表 调echarts上下左右的距离间隔，top:5%代表，距离上面是5%的距离
      top: "20%",
      left: "12%",
      right: "1%",
      bottom: "13%"
    },
    xAxis: {
      type: "category", // 这个必填  代表横坐标坐标是显示文案 把 type: "category"改成  type: "value"柱状图就横过来了
      data: strList,
      name: xname,
      nameTextStyle: {
        color: "#666",
        padding: [0, 0, -10, 0],
        fontSize: 14
      },
      axisLabel: {
        color: "#666", // 调横坐标颜色
        fontSize: 14,
        interval: 0
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#384267",
          width: 1
        }
      },
      splitLine: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: "#eee" // 调横坐标轴线的颜色
        },
        show: true
      }
    },
    yAxis: {
      type: "value", // 这个必填  代表纵坐标是显示数字
      name: yname,
      nameTextStyle: {
        color: "#666",
        padding: [0, 0, 0, 0],
        fontSize: 14
      },
      axisLabel: {
        color: "#666", // 调纵坐标颜色
        fontSize: 14
      },
      axisTick: {
        lineStyle: {
          color: "#668092",
          width: 1
        },
        show: false
      },
      splitLine: {
        show: false, // 改为true就会每个刻度都会显示横线
        lineStyle: {
          color: "#335971"
          // "type": "dashed"  这个是调实线还是虚线的。
        }
      },
      axisLine: {
        lineStyle: {
          color: "#eee" // 调纵坐标轴线的颜色
          // "type": "dashed"
        },
        show: true
      }
    },

    series: [
      {
        type: "bar",
        showBackground: true,
        barWidth: 20, // 调柱子的宽读
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#83bff6" },
            { offset: 0.5, color: "#188df0" },
            { offset: 1, color: "#188df0" }
          ])
        },
        emphasis: {
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#2378f7" },
              { offset: 0.7, color: "#2378f7" },
              { offset: 1, color: "#83bff6" }
            ])
          }
        },
        data: numList
      }
    ]
  };
};
