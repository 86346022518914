import * as echarts from "echarts";

export const getEchart = (strList, clueList,customerList,costList,totalList,clueMax,customerMax,costMax,numMax) => {
  const colors = ['#5470C6', '#91CC75', '#EE6666', '#EE6666'];
  return {
    color: colors,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    grid: {
      left: '13%',
      right: '16%',
      bottom: '6%'
    },
    legend: {
    },
    xAxis: [
      {
        type: 'value',
        name: '线索',
        min: 0,
        max: clueMax,
        position: 'bottom',
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[0]
          }
        },
      },
      {
        type: 'value',
        name: '客户',
        min: 0,
        max: customerMax,
        position: 'bottom',
        offset: 80,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[1]
          }
        },
      },{
        type: 'value',
        name: '商机',
        min: 0,
        max: costMax,
        position: 'bottom',
        offset: 160,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[2]
          }
        },
      },
      {
        type: 'value',
        name: '总跟进',
        min: 0,
        max: numMax,
        position: 'top',
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[3]
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'category',
        axisLine: { onZero: false },
        boundaryGap: false,
        data: strList
      }
    ],
    series: [
      {
        name: '线索',
        type: 'bar',
        xAxisIndex: 0,
        data: clueList
      },
      {
        name: '客户',
        type: 'bar',
        xAxisIndex: 1,
        data: customerList
      },
      {
        name: '商机',
        type: 'bar',
        xAxisIndex: 2,
        data: costList
      },
      {
        name: '总跟进',
        type: 'line',
        symbolSize: 10,
        symbol: 'circle',
        smooth: true,
        lineStyle: {
          width: 3,
          shadowColor: 'rgba(0,0,0,0.3)',
          shadowBlur: 10,
          shadowOffsetY: 8
        },
        xAxisIndex: 3,
        data: totalList
      }
    ]
  };
};
