<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="付款标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入付款标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.customerName"
                            label="客户名称"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择客户' }]"
                            @click="showPickerCustomer = true"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.orderName"
                            label="订单"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择订单' }]"
                            @click="showPickerOrder= true"
                    />
                    <van-popup v-model="showPickerOrder" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="orderList"
                                value-key="theme"
                                @confirm="
                onConfirmOrder($event, 'orderId', 'orderName', 'theme', 'showPickerOrder')
              "
                                @cancel="showPickerOrder = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.price"
                            label="总金额"
                            disabled
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.leader"
                            label="负责人"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择负责人' }]"
                            @click="showPop= true"
                    />

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">当前第{{ index + 1 }}期</div>
                            <van-icon name="edit" @click="handleUpdate(item)" style="margin-left: 65%"/>
                            <van-icon name="delete-o" @click="handleDelete(item.id)"/>
                        </div>

                        <van-field
                                v-model="item.planPrice"
                                label="计划付款金额"
                                disabled
                        />

                        <van-field
                                v-model="item.price"
                                label="实际付款金额"
                                disabled
                        />

                        <van-field
                                v-model="item.state"
                                label="付款状态"
                                disabled
                        />

                        <van-field
                                v-model="item.collectionDate"
                                label="付款日期"
                                disabled
                        />

                    </div>
                    <div class="add-btn flex-hor-ver-cen" @click="handleAdd" v-if="type == 'detail'">
                        <van-icon name="add-o" class="mrg-r-20"/>
                        添加子计划
                    </div>
                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <van-dialog
                v-model="showPicker"
                title="付款子计划"
                width="70%"
                style="font-size: 14px;"
                :showConfirmButton="false"
                :close-on-click-overlay="true"
        >
            <van-form validate-first @submit="onSubmit">
                <van-field
                        v-model="subForm.planPrice"
                        label="计划付款金额"
                        placeholder="请输入"
                        label-width="80px"
                        style="line-height: 40px;"
                        :required="true"
                        :rules="[{ required: true, message: '请输入计划付款金额' }]"
                />

                <van-field
                        v-model="subForm.remark"
                        label="备注"
                        placeholder="请输入"
                />
            </van-form>
            <van-button type="info" style="width: 100%;height: 30px;font-size: 18px" @click="submitFormSub">确认
            </van-button>
        </van-dialog>

        <CustomerPop :customerVisible="showPickerCustomer" @submitCustomerList="submitCustomerList"
                     @handleClose="handleCloseCustomer"/>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>
    </div>
</template>
<script>
    import {
        delPaySub,
        fetchPayOrderListByCustomerId,
        findOne,
        savePay,
        savePaySub,
        updatePay,
        updatePaySub
    } from '@/api/crm/pay';
    import SelectOneUser from '@/components/selectOneUser/index'
    import CustomerPop from '@/components/crm/CustomerPop'
    import {getDetailByOrderId} from "@/api/crm/order";

    export default {
        components: {SelectOneUser, CustomerPop},
        data() {
            return {
                formInline: {},
                subForm: {},
                title: '新建付款',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerCustomer: false,
                showPickerOrder: false,
                showPicker: false,
                showPop: false,
                minDate: new Date(2010, 0, 1),
                //明细数组
                dataList: [],
                //订单数组
                orderList: [],
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/crm/pay');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        price: 0
                    };
                    this.title = '新建付款';
                } else {
                    let itemDetail = await findOne(this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑付款';
                    } else {
                        this.title = '付款详情';
                        this.subForm.payId = this.id
                        this.dataList = itemDetail.sub
                    }
                }
            },

            //客户提交
            async submitCustomerList(id, name) {
                this.formInline.customerId = id;
                this.formInline.customerName = name;

                //改变客户后，表单重置
                this.formInline.orderId = null
                this.formInline.orderName = ''
                this.formInline.price = 0
                const result = await fetchPayOrderListByCustomerId({customerId: this.formInline.customerId})
                this.orderList = result.data.records

                this.showPickerCustomer = false
            },

            //关闭选择客户的弹窗
            handleCloseCustomer() {
                this.showPickerCustomer = false;
            },

            //确认订单
            async onConfirmOrder(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];

                const result = await getDetailByOrderId({orderId: this.formInline.orderId})
                this.formInline.price = result.data.discountPrice

                this[visible] = false;
            },

            //选择人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.leaderId = activeId
                this.formInline.leader = names
            },

            //关闭选择人
            handleCloseUser() {
                this.showPop = false
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            //编辑子计划
            async handleUpdate(item) {
                this.subForm = item;
                this.showPicker = true
            },

            //删除子计划
            handleDelete(id) {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除子付款?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await delPaySub(id);
                        this.$toast.success('删除成功');
                        await that.findDetail();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },

            //添加子计划
            handleAdd() {
                this.showPicker = true;
            },

            //子计划提交
            async submitFormSub() {
                this.subForm.id ? await updatePaySub(this.subForm) : await savePaySub(this.subForm);
                this.$toast.success('操作成功');

                await this.findDetail()
                this.showPicker = false
            },

            async onSubmit() {
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updatePay(data) : await savePay(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            }
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
