<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'新建跟进'" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <div class="line"></div>
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.followTypeName"
                            label="跟进方式"
                            placeholder="请选择"
                            @click="showTypePicker = true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择跟进方式' }]"
                    />
                    <van-popup v-model="showTypePicker" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="followTypeOptions"
                                value-key="dictLabel"
                                @confirm="onConfirmType"
                                @cancel="showTypePicker = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            name="calendar"
                            :value="formInline.followDate"
                            label="选择时间"
                            placeholder="点击选择日期"
                            @click="showDataTimePop = true"
                    />

                    <textAreaInput :remark="formInline.followContent" @change="handleContentChange"/>

                    <van-field name="radio" label="是否远程">
                        <template #input>
                            <van-radio-group v-model="otherFollowSelect.longDistance" direction="horizontal">
                                <van-radio name="1">是</van-radio>
                                <van-radio name="2">否</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field
                            v-model="otherFollow.longDistance"
                            :disabled="otherFollowSelect.longDistance == '2'"
                            type="number"
                            label="远程时长(分钟)"
                            name="asyncValidator"
                            placeholder="请输入"
                            :required="otherFollowSelect.longDistance == '1' ? true : false"
                            :rules="[
              {
                required: otherFollowSelect.longDistance == '1' ? true : false,
                message: '请输入远程时长',
              },
            ]"
                    />

                    <van-field name="radio" label="是否通话">
                        <template #input>
                            <van-radio-group v-model="otherFollowSelect.phone" direction="horizontal">
                                <van-radio name="1">是</van-radio>
                                <van-radio name="2">否</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field
                            v-model="otherFollow.phone"
                            :disabled="otherFollowSelect.phone == '2'"
                            type="number"
                            label="通话时长(分钟)"
                            name="asyncValidator"
                            placeholder="请输入"
                            :required="otherFollowSelect.longDistance == '1' ? true : false"
                            :rules="[
              {
                required: otherFollowSelect.phone == '1' ? true : false,
                message: '请输入通话时长',
              },
            ]"
                    />

                    <van-field name="radio" label="微信/QQ">
                        <template #input>
                            <van-radio-group v-model="otherFollowSelect.onlineCommunicate" direction="horizontal">
                                <van-radio name="1">是</van-radio>
                                <van-radio name="2">否</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field
                            v-model="otherFollow.onlineCommunicate"
                            :disabled="otherFollowSelect.onlineCommunicate == '2'"
                            type="number"
                            label="聊天记录(字数)"
                            name="asyncValidator"
                            placeholder="请输入"
                            :required="otherFollowSelect.onlineCommunicate == '1' ? true : false"
                            :rules="[
              {
                required: otherFollowSelect.onlineCommunicate == '1' ? true : false,
                message: '请输入聊天记录',
              },
            ]"
                    />

                    <van-field name="radio" label="是否线下会谈">
                        <template #input>
                            <van-radio-group v-model="otherFollowSelect.meetOffline" direction="horizontal">
                                <van-radio name="1">是</van-radio>
                                <van-radio name="2">否</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field
                            v-model="otherFollow.meetOffline"
                            :disabled="otherFollowSelect.meetOffline == '2'"
                            type="number"
                            label="会谈有效时长(时)"
                            name="asyncValidator"
                            placeholder="请输入"
                            :required="otherFollowSelect.meetOffline == '1' ? true : false"
                            :rules="[
              {
                required: otherFollowSelect.meetOffline == '1' ? true : false,
                message: '请输入会谈有效时长',
              },
            ]"
                    />

                    <van-field name="radio" label="是否报价">
                        <template #input>
                            <van-radio-group v-model="otherFollowSelect.quotedPrice" direction="horizontal">
                                <van-radio name="1">是</van-radio>
                                <van-radio name="2">否</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>

                    <van-field
                            v-model="formInline.address"
                            disabled
                            label="地点"
                    />

                    <div class="line"></div>
                    <van-field name="uploader" label="附件">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" :disabled="submitBtn">提交</van-button>
                </van-form>
            </div>
        </div>

        <van-popup v-model="showDataTimePop" style="width: 70%">
            <van-datetime-picker
                    v-model="currentDate"
                    type="datetime"
                    title="请选择跟进时间"
                    @confirm="onConfirmTime"
            />
        </van-popup>

    </div>
</template>
<script>
    import {changeToCurrentDay} from '@/utils/ruoyi';
    import {addFollowCustomer} from '@/api/crm/customer';
    import {submiteUpload} from '@/api/system/config';
    import {findAddressByLngLatTDT} from "@/api/system/ymxx";

    export default {
        data() {
            return {
                currentDate: new Date(),
                formInline: {
                    followTypeName: '',
                    followType: null,
                    followDate: null,
                    followContent: null,
                    customerId: this.$route.query.id,
                },
                pattern: /\d{6}/,
                showPicker: false,
                showTypePicker: false,
                uploader: [],
                followTypeOptions: [],
                uploadParams: {
                    businessType: 'customer_follow',
                    businessId: undefined,
                    file: null,
                },
                //其他跟进下拉
                otherFollowSelect: {
                    longDistance: '2', //远程
                    phone: '2', //电话
                    onlineCommunicate: '2', //线上通讯
                    meetOffline: '2', //线下会谈
                    quotedPrice: '2', //报价
                },
                //其他跟进的值
                otherFollow: {
                    longDistance: undefined, //远程
                    phone: undefined, //电话
                    onlineCommunicate: undefined, //线上通讯
                    meetOffline: undefined, //线下会谈
                    quotedPrice: undefined, //报价
                },
                showDataTimePop: false,
                //按钮置灰
                submitBtn: false
            };
        },

        mounted() {
            this.submitBtn = true
            setTimeout(() => {
                this.submitBtn = false
                this.loadMap()
            }, 2000)

            // 跟进类型
            this.getDicts('follow_type').then((response) => {
                this.followTypeOptions = response.data;
            });
        },

        methods: {
            loadMap() {
                if (navigator.geolocation) {
                    let that = this
                    navigator.geolocation.getCurrentPosition(async function (position) {
                        let result = await findAddressByLngLatTDT({lngLat: position.coords.longitude + ',' + position.coords.latitude})
                        that.formInline.address = result.data
                        that.$forceUpdate()
                    }, function (error) {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                alert('用户拒绝对获取地理位置的请求。')
                                break;
                            case error.POSITION_UNAVAILABLE:
                                alert('位置信息是不可用的。')
                                break;
                            case error.TIMEOUT:
                                alert('请求用户地理位置超时。')
                                break;
                            case error.UNKNOWN_ERROR:
                                alert('未知错误。')
                                break;
                        }
                    });
                } else {
                    alert('Geolocation is not supported by this browser.')
                }
            },

            handleGoBack() {
                this.$router.replace('/crm/customer');
                this.$router.go(-1)
            },

            onConfirm(value) {
                this.value = value;
                this.showPicker = false;
            },

            onConfirmType(value) {
                this.formInline.followTypeName = value.dictLabel;
                this.formInline.followType = value.dictValue;
                this.showTypePicker = false;
            },

            onConfirmTime(value) {
                const time = changeToCurrentDay(value);
                this.formInline.followDate = time;
                this.showDataTimePop = false;
            },

            async onSubmit() {
                //判断其他跟进方式
                if (this.otherFollowSelect.longDistance == '1' && !this.otherFollow.longDistance) {
                    this.$toast.fail('请输入大于0的远程时间');
                    return;
                } else if (this.otherFollowSelect.longDistance == '2') {
                    this.otherFollow.longDistance = undefined;
                }
                if (this.otherFollowSelect.phone == '1' && !this.otherFollow.phone) {
                    this.$toast.fail('请输入大于0的通话时间');
                    return;
                } else if (this.otherFollowSelect.phone == '2') {
                    this.otherFollow.phone = undefined;
                }
                if (this.otherFollowSelect.onlineCommunicate == '1' && !this.otherFollow.onlineCommunicate) {
                    this.$toast.fail('请输入大于0的聊天字数');
                    return;
                } else if (this.otherFollowSelect.onlineCommunicate == '2') {
                    this.otherFollow.onlineCommunicate = undefined;
                }
                if (this.otherFollowSelect.meetOffline == '1' && !this.otherFollow.meetOffline) {
                    this.$toast.fail('请输入大于0的会谈时长');
                    return;
                } else if (this.otherFollowSelect.meetOffline == '2') {
                    this.otherFollow.meetOffline = undefined;
                }
                if (this.otherFollowSelect.quotedPrice == '1') {
                    this.otherFollow.quotedPrice = 1;
                } else if (this.otherFollowSelect.quotedPrice == '2') {
                    this.otherFollow.quotedPrice = 0;
                }
                this.formInline.otherFollow = JSON.stringify(this.otherFollow);

                const result = await addFollowCustomer(this.formInline);
                if (result.code == 200) {
                    this.$toast.success('添加成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            handleContentChange(val) {
                this.formInline.followContent = val;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end

  ::v-deep .van-radio__label {
    line-height: 100px;
  }

  ::v-deep .van-radio__icon .van-icon {
    width: none;
    height: none;
    line-height: none;
  }

  ::v-deep .van-radio__icon {
    line-height: 0;
  }
</style>
