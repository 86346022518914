<template>
    <div class="app-container" v-if="showEcharts">
        <div style="width: 100%;height: 500px;padding-top: 10px" ref="echartsRef1"></div>
        <div style="width: 100%;height: 300px;padding-top: 10px" ref="echartsRef2"></div>
        <div style="width: 100%;height: 500px;padding-top: 30px" ref="echartsRef3"></div>
    </div>
</template>
<script>
    import {getEchartLine,getEchartBar,getEchartPie} from '@/echarts/report/purchase/supplierPortrait'
    import {getSupplierType,getSupplierLevel,getSupplierIndustry} from '@/api/report/purReport/purReport'

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    this.showEcharts = this.isShow
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart1) {
                                this.dataEchart1.resize()
                            }
                            if (this.dataEchart2) {
                                this.dataEchart2.resize()
                            }
                            if (this.dataEchart3) {
                                this.dataEchart3.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                showEcharts: false,
                dataEchart1: null,
                dataEchart2: null,
                dataEchart3: null,
                // 查询参数
                queryParams: {
                }
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                let resultBar = await getSupplierIndustry(this.queryParams)
                let resultPie = await getSupplierLevel(this.queryParams)
                let resultLine = await getSupplierType(this.queryParams)

                // 柱状图
                let xname = '供应商'
                let yname = '行业数量'
                const strList = []
                const dataList = []
                resultBar.data.forEach(item=>{
                    strList.push({
                        'value': item.name
                    })
                    dataList.push({
                        'value': item.num
                    })
                })
                this.dataEchart1 = this.$echarts.init(this.$refs.echartsRef1)
                this.dataEchart1.setOption(getEchartBar(strList, dataList,xname,yname))

                // 饼状图
                const priceList = []
                resultPie.data.forEach(item=>{
                    priceList.push({
                        "value": item.num,
                        "name": item.name
                    })
                })
                this.dataEchart2 = this.$echarts.init(this.$refs.echartsRef2)
                this.dataEchart2.setOption(getEchartPie(priceList))

                // 折线图
                let xname1 = '供应商'
                let yname1 = '数量'
                const nameList = []
                const quaList = []
                resultLine.data.forEach(item=>{
                    nameList.push({
                        'value': item.name
                    })
                    quaList.push({
                        'value': item.num
                    })
                })
                this.dataEchart3 = this.$echarts.init(this.$refs.echartsRef3)
                this.dataEchart3.setOption(getEchartLine(nameList, quaList,xname1,yname1))
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart1)
                if (this.dataEchart1) {
                    this.dataEchart1.clear();
                    this.dataEchart1.dispose();
                    this.dataEchart1 = null;
                }
                window.removeEventListener('resize', this.dataEchart2)
                if (this.dataEchart2) {
                    this.dataEchart2.clear();
                    this.dataEchart2.dispose();
                    this.dataEchart2 = null;
                }
                window.removeEventListener('resize', this.dataEchart3)
                if (this.dataEchart3) {
                    this.dataEchart3.clear();
                    this.dataEchart3.dispose();
                    this.dataEchart3 = null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
