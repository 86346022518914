<template>
  <div class="inner-container">
    <navBar :title="'公司介绍'" @handleGoBack="handleGoBack"> </navBar>
    <div class="content">
      壹米咻咻erp管理系统隐私权保护声明 <br />
      更新时间：2022/07/18 <br />
      提示条款：<br />
      您的信任是我们赖以前行的基础，我们深知用户信息安全对您的重要性，我们将按中国法律法规要求，采取相应的安全保护措施，竭力保证您的信息安全可控。鉴此，山东壹米咻咻信息科技有限公司（或简称“我们”）制定本《隐私权保护声明》（下称“本声明”）并提醒您：
      本声明仅适用于本平台（壹米咻咻客户端或网页（域名:https://www.yimixiuxiu.com/））自身的功能、产品及服务。如我们公司关联的产品或服务中使用了壹米咻咻提供的产品或服务但未设独立的隐私权保护声明，则本声明同样适用于该部分产品或服务。
      在使用壹米咻咻提供的各项服务前，请您务必仔细阅读并透彻理解本声明，同意后方可开始使用相关产品和服务。本声明中涉及的专业词汇已尽量简明通俗地向您表述，以便于您理解，并将重点以粗体标出。如对本声明内容有任何疑问、意见或建议，您可通过本声明文末的联系方式与我们联系。一旦您开始使用壹米咻咻所提供的服务，则被视为对本声明内容的接受和认可。
      定义： <br />
      1.壹米咻咻服务提供者：指提供壹米咻咻产品和服务的法律主体，为山东壹米咻咻软件科技有限公司，以下简称“我们”。<br />
      2.企业/组织用户：指通过企业或其他组织授权申请注册成为壹米咻咻的用户，并通过管理员用户在壹米咻咻平台上传企业/组织员工通讯录搭建企业/组织架构后，由管理员用户代表企业/组织开通、管理和使用壹米咻咻服务。壹米咻咻企业/组织用户包括但不限于法人、政府机构、其他组织、合伙或个体工商户等。<br />
      3.企业/组织管理员：指经企业/组织用户授权或指定，拥有企业/组织用户管理平台系统操作权限，代表企业/组织开通、管理和使用壹米咻咻服务的壹米咻咻个人用户。<br />
      4.个人用户：指企业/组织用户管理员权限的个人用户、作为企业/组织用户通讯录一员但不具有企业/组织管理员权限的个人用户，以及企业/组织用户管理员创建的外部用户，以下称“您”。<br />
      5.个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<br />
      6.业务数据：不同于用户信息，是指壹米咻咻的用户利用壹米咻咻的服务上传、下载等通过壹米咻咻的技术服务处理的数据。<br />
      7.个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。壹米咻咻服务的个人敏感信息包括身份证件号码、手机号码、个人生物识别信息、银行账号、财产信息、交易信息、14岁以下（含）儿童的个人信息等。<br />
      8.未成年用户：指不满十四周岁的未成年人。
      9.个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。<br />
      10.个人信息匿名化：通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。<br />
      11.个人信息控制者：有权决定个人信息处理目的、方式等的组织或个人。本协议壹米咻咻服务中的部分个人信息控制者为企业/组织用户。<br />
      12.除另有约定外，本声明所用定义与《壹米咻咻服务协议》中的定义具有相同的涵义。
      <br />
      法律声明：<br />
      1.权利归属：<br />
      1.1壹米咻咻网站的
      Logo、“壹米咻咻erp管理系统”等文字、图形及其组合，以及壹米咻咻网站的其他标识、徽记、等为壹米咻咻及其关联公司在中国和其他国家的注册商标。未经壹米咻咻书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。<br />
      1.2
      壹米咻咻网站所有的产品、服务、技术与所有程序(以下或简称“技术服务”）的知识产权均归属于壹米咻咻或归其权利人所有。<br />
      1.3.除非壹米咻咻另行声明，壹米咻咻拥有壹米咻咻网站内发布文档等信息(包括但不限于文字、图形、图片、照片、音频、视频、图标、色彩、版面设计、电子文档）的所有权利(包括但不限于版权、商标权、专利权、商业秘密和其他所有相关权利）。未经壹米咻咻许可，任何人不得擅自使用如上内容（包括但不限于通过程序或设备监视、复制、转播、展示、镜像、上传、下载壹米咻咻网站内的任何内容）。被授权浏览、复制、打印和传播属于壹米咻咻网站内信息内容的，该等内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此权利声明。<br />
      2.责任限制<br />
      2.1壹米咻咻用户在壹米咻咻网站的论坛、社区自行上传、提供、发布相关信息，包括但不限于用户名称、公司名称、联系人及联络信息，相关图片、资讯等，该等信息均由用户自行提供，壹米咻咻的用户须对其提供的任何信息依法承担全部责任。<br />
      2.2.壹米咻咻网站上转载作品（包括论坛内容）出于传递更多信息之目的，并不意味壹米咻咻赞同其观点或证实其内容的真实性。壹米咻咻官网可能存在指向第三方网站的链接，壹米咻咻提供这些链接仅仅在于为访问者提供方便，并不表示壹米咻咻对这些信息的认可和推荐，也不是用于宣传或广告目的。是否访问这些链接将由您作出决定，壹米咻咻并不保证这些链接所指向的网站上所提供的任何信息、数据、观点、图片、陈述或建议的准确、完整、和可靠。<br />
      2.3.壹米咻咻在此提示，您在使用壹米咻咻服务期间应当遵守中华人民共和国的法律，不得危害网络安全，不得利用壹米咻咻的服务从事他人侵犯名誉、隐私、知识产权和其他合法权益的活动。尽管有前述提示，壹米咻咻不对您使用壹米咻咻服务的用途和目的承担任何责任。<br />
      3.知识产权保护<br />
      壹米咻咻尊重知识产权，反对并打击侵犯知识产权的行为。任何组织或个人认为壹米咻咻网站的网页（含www.yimixiuxiu.com网页内容以及客户端页面)内容（如转载文章等）可能侵犯其合法权益的，可以通过向壹米咻咻(yimixiuxiu77@163.com)提出书面权利通知，壹米咻咻将在收到知识产权权利人合格通知后依法尽快处理。
      <br />隐私权声明 <br />本隐私权保护声明将帮助您了解以下内容：<br />
      一、我们如何收集和使用您的个人信息<br />
      二、我们如何使用Cookie和同类技术<br />
      三、我们如何共享、转让、公开披露您的信息<br />
      四、用户业务数据和公开信息 <br />五、我们如何保护您的信息 <br />六、您如何管理您的信息<br />
      七、未成年人用户信息的特别规定 <br />八、隐私权保护声明更新<br />
      九、您如何联系我们<br />
      一、我们如何收集和使用您的个人信息<br />
      在您使用我们的产品及服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种:<br />
      1、为实现向您提供我们壹米咻咻产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务;<br />
      2、为实现向您提供我们产品及/或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。<br />
      您理解并同意：<br />
      我们在持续努力改进我们的技术为了给您带来更好的产品和服务体验，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本声明、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过壹米咻咻提供的各种联系方式与我们联系，我们会尽快为您作出解答。
      为了向您及企业/组织用户提供壹米咻咻服务，维护壹米咻咻服务的正常运行，改进及优化我们的服务体验并保障您的账号安全，我们会出于本声明下述目的及方式收集您在注册、使用壹米咻咻服务时主动提供、授权提供或基于您所在企业/组织用户要求提供的信息，以及基于您使用壹米咻咻服务时产生的信息：<br />
      (一）帮助您成为壹米咻咻注册用户<br />
      1.1 注册成为壹米咻咻企业用户信息<br />
      为注册成为企业用户，以便我们基于您所在企业的雇佣及管理行为提供壹米咻咻服务，诸如壹米咻咻人事应用服务(请假管理)、壹米咻咻智能办公应用服务（包括基于企业管理审批、日志、日程、绑定邮箱）等，您需要提供您的企业名称、联系方式、企业所在地、行业类型、人员规模信息，用以创建壹米咻咻企业账户，否则您将不能基于企业管理行为使用壹米咻咻服务。
      <br />
      (二)使用壹米咻咻服务过程中收集信息<br />

      当您在使用壹米咻咻服务过程中，为向您提供更契合您需求的壹米咻咻服务、交互展示、搜索结果、识别账号异常状态，维护壹米咻咻服务的正常运行，改进及优化您对壹米咻咻服务的体验并保障您的账号安全，需要收集您使用壹米咻咻服务以及使用方式的信息，并将这些信息进行关联:
      <br />
      2.1 使用信息 <br />
      我们会收集您作为壹米咻咻个人用户使用壹米咻咻服务时提交或产生的信息。 <br />
      2.2 设备信息 <br />
      我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS/北斗位置信息以及能够提供相关信息的Wi-Fi接入点、和基站等传感器信息）。
      <br />
      2.3 日志信息<br />
      当您使用我们的客户端、网站提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间、您访问的网页记录、审批日志信息、外勤日志信息、HRM对象操作日志信息等操作日志信息。
      请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将有可能被视为个人信息,除取得您授权（企业/组织用户依据本声明享有的数据控制权视为您授权）或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
      <br />
      2.4 您通过我们的客服或参加我们举办的活动时所提交的信息
      <br />
      例如，您参与我们举办的活动（线上或线下）时填写的调查问卷中可能包含您的姓名、电话、家庭地址等信息。
      <br />
      2.5 企业控制数据 <br />

      如您所在的企业管理员选择开通、管理壹米咻咻服务的,我们会根据该企业管理员的指令，记录该企业用户的个人用户使用壹米咻咻相关功能/应用过程中提交或产生的信息和数据（下称“企业控制数据”），企业控制数据可能包括:
      如您所在企业管理员选择开通、管理和使用壹米咻咻企业通讯录、壹米咻咻智能办公应用服务的，企业用户可能提交或要求您提供：您的身份信息（姓名、生日、就职日期）、企业任职信息（部门、职位、职级、入职日期、员工编号）、企业及您个人的联系方式（手机、固定电话、邮箱)等企业分配给您的信息或您的其他个人信息。您所在企业分配给您的职位、隶属部门、主营行业、办公邮箱账号、办公电话等信息，以及您为完成企业日常经营管理开通的审批、签到、日志、邮箱功能而要求提供或产生的地理位置等个人敏感信息以及外勤签到信息、审批记录、发布日志及日程信息等。
      企业用户在上传企业通讯信息以及其他要求所在企业/组织的个人用户提交人事信息、外部业务联系人信息等个人信息之前，应事先获得个人用户的明确同意，并仅限收集实现企业用户运营及管理目的所必需的个人用户信息，并确保已充分告知个人用户相关数据收集的目的、范围及使用方式等。
      您理解并同意，企业/组织用户为上述企业所控制数据的控制者，我们仅根据企业/组织用户管理员的指令开通、管理和使用上述应用服务处理企业/组织控制数据或您的个人信息。

      <br />
      2.6 您向我们提供的信息 <br />
      在壹米咻咻服务使用过程中，您可以对壹米咻咻的产品、功能、应用及服务的体验反馈问题，帮助我们更好地了解您使用我们产品或服务的体验和需求，改善我们的产品或服务,为此我们会记录您的联系信息、反馈的问题或建议,以便我们进一步联系您反馈我们的处理意见。
      <br />
      2.7 第三方向我们提供的信息<br />
      我们将对其他用户进行与您相关的操作时收集关于您的信息，亦可能从壹米咻咻关联公司、合作第三方或通过其他合法途径获取的您的个人数据信息。例如，为完成您的壹米咻咻账号注册，您同意运营商基于您个人的手机号码向壹米咻咻发送短信验证码，否则无法注册壹米咻咻账户。
      <br />
      2.8 客服及争议处理<br />
      当您与我们联系或提出售中售后、争议纠纷处理申请时,为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的用户身份。
      为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括账号信息、工单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和工单信息。
      <br />
      2.9 其他 <br />
      为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中（包括接入的第三方服务)可能会收集和使用您的个人信息。如果您不提供这些信息，不会影响您使用壹米咻咻数字化办公、沟通与协同等基本服务，但您可能无法获得这些附加服务给您带来的用户体验。这些附加服务包括：
      <br />
      2.9.1基于安装，在安装壹米咻咻erp管理系统时，根据您的安装渠道的不同，壹米咻咻将在必要时获取唤起未知来源应用弹窗页面、获取已安装应用列表、获取当前运行进程以及绑定安装应用等权限。
      <br />
      2.9.2基于存储，相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可在开启拍照权限与相册权限后上传您的照片/图片/视频，以实现在壹米咻咻内更换头像、上传照片等。
      <br />
      2.9.3位置权限：您可以打开您的位置权限(包括地理位置权限或WIFI、识别范围)，以完成您所在企业安排的外勤工作或您主动分享您所在位置。

      <br />
      您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置信息（地理位置）、摄像头（相机）、相册及存储的访问权限以实现这些权限所涉及信息的收集和使用。同时本软件将在一定情况下使用IPC唤起相机、获取GPS状态以及GPS状态监听、NMEA监听、注册GNSS导航消息回调、获取当前定位信息、获取网络类型与手机类型、获取WI-FI列表与主动扫描WI-FI信号、获取SIM卡序列号与获取SIM卡状态、获取本机MAC地址、获取IMEI（国际移动设备识别码）、获取MEID（移动终端识别号）、获取部分已安装应用列表、获取当前运行进程、获取设备屏幕宽度，高度，密度、获取SIM卡状态、获取网络类型、获取手机类型、获取IMSI（国际移动用户识别码），获取设备传感器信息、以及特殊情况下的监听位置改变等。同时，根据不同功能的使用将会获取不同权限，如：通话功能将会开启您给某人拨打电话的页面与唤起拨打电话页面(即拨打电话和管理通话权限)、复制粘贴时将设置或读取剪切板内容、截屏时获取获取设备屏幕宽度、获取设备屏幕高度与获取设备屏幕密度、刷脸时将获取身体活动识别权限等。若手机本身有外部储存卡，则需访问外部存储卡。
      您可在您的设备的“设置-应用权限”或客户端“我的-设置-通用或隐私”中查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
      您理解并同意，我们向您提供的功能和服务场景是不断迭代升级的，如我们未在上述场景中明示您需要收集的个人信息，我们将会通过页面提示、交互设计等方式另行向您明示信息收集的内容、范围和目的并征得您同意。
      如我们停止运营壹米咻咻产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以提前30天以公告的形式通知您，并依照所适用的法律对所持有的您的个人信息进行删除或匿名化处理。
      <br />
      二、我们如何使用Cookie和同类技术
      <br />
      为使您获得更轻松的访问体验，您使用壹米咻咻产品或服务时，我们可能会通过采用各种技术收集和存储您访问壹米咻咻服务的相关数据，在您访问或再次访问壹米咻咻服务时,我们能识别您的身份,并通过分析数据为您提供更好更多的服务。包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash
      Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
      请您理解，我们的全部服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝壹米咻咻的Cookie，但拒绝壹米咻咻的Cookie您将无法使用依赖于cookies的壹米咻咻服务。
      <br />
      三、我们如何共享、转让、公开披露您的信息<br />
      (一)共享<br />
      我们不会与您所在企业组织用户以外的公司、组织和个人共享您的个人信息，但以下情况除外：
      <br />
      1.1
      在获取您同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息，您确认共享的信息均为您有权披露和共享的信息。
      <br />1.2
      在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
      <br />1.3
      您主动选择情况下的共享：只有共享您的个人信息、个人敏感信息，才能提供您所要求的第三方产品和服务,如：侵权投诉处置，在您被他人投诉侵犯知识产权或其他合法权利时，我们依法需要向投诉人披露您的必要资料,以便进行投诉处理。
      <br />
      1.4
      与关联公司间共享：为便于我们基于壹米咻咻账号共同向您提供服务，识别账号异常，保护壹米咻咻关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息(如为便于您使用壹米咻咻账号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账号信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
      1.5与授权合作伙伴共享：仅为实现本隐私声明中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的，除非另行获得您的明示同意除外。对于涉及未成年用户个人信息的，我们不允许合作伙伴进行转委托。
      <br />
      1)供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术合作研发服务、云计算服务、提供壹米咻咻平台软件推广部署服务、提供客户服务、以及其他合作场景等。
      <br />
      2)为了使您能够接收信息推送、使用地图服务、播放视频等必要的功能用途，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。如您使用华为/小米/魅族手机时，华为/小米/魅族Push
      SDK需要读取您的设备识别号、联网相关信息，用于推送/下发通知栏消息；如您使用考勤/外勤服务，高德地图SDK需要您打开设备位置权限并收集设备所在位置相关信息信息、设备识别号、联网相关信息。
      <br />
      3)我们会对授权合作伙伴获取有关信息的应用程序接口(API)、软件工具开发包(SDK）进行严格的安全检测,并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私声明以及其他任何相关的保密和安全措施来处理个人信息。
      <br />
      (二)转让 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      <br />
      2.1 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。
      <br />
      2.2
      在壹米咻咻服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本声明的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
      <br />
      (三)公开披露 我们仅会在以下情况下，公开披露您的个人信息：
      <br />
      3.1 获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
      <br />
      3.2
      如果我们确定您出现违反法律法规或严重违反壹米咻咻相关协议规则的情况或为保护壹米咻咻及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或壹米咻咻相关协议规则披露关于您的个人信息，包括相关违规行为以及壹米咻咻已对您采取的措施。

      <br />
      (四)共享、转让、公开披露个人信息时事先征得授权同意的例外
      <br />
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意:
      <br />
      4.1 与国家安全、国防安全有关的;
      <br />4.2 与公共安全、公共卫生、重大公共利益有关的； <br />
      4.3 与犯罪侦查、起诉、审判和判决执行等有关的;
      <br />
      4.4 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;
      <br />4.5 您自行向社会公众公开的个人信息； <br />
      4.6 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      <br />
      请您注意，根据法律规定，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。

      <br />
      四、用户业务数据和公开信息
      <br />
      不同于您的用户信息，对于用户业务数据和公开信息，壹米咻咻将按如下方式处理:
      <br />
      (一)用户业务数据
      <br />1.1
      您通过壹米咻咻提供的服务，加工、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业务数据。壹米咻咻作为服务提供商，我们只会严格执行您的指示处理您的业务数据，除按与您协商一致或执行明确的法律法规要求外，不对您的业务数据进行任何非授权的使用或披露。
      <br />1.2
      您应对您的用户业务数据来源及内容负责，壹米咻咻提示您谨慎判断数据来源及内容的合法性。因您的用户业务数据内容违反法律法规、部门规章或国家声明而造成的全部结果及责任均由您自行承担。
      <br />
      1.3.壹米咻咻恪守对用户的安全承诺，根据适用的法律保护用户上传在壹米咻咻中的数据。
      <br />
      (二)公开信息

      <br />
      1.1
      公开信息是指您公开分享的任何信息，任何人都可以在使用和未使用壹米咻咻网站服务期间查看或访问这些信息。例如您在壹米咻咻开源社区论坛发布的信息。
      五、我们如何保护您的信息<br />
      (一）技术安全防护<br />
      为保障您的信息安全，我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如。在您的壹米咻咻客户端APP与服务器之间交换数据时受SSL协议加密保护；我们同时对壹米咻咻官网提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
      我们指定了专人负责儿童信息的保护，严格设定信息访问权限，对可能接触到儿童信息的工作人员采取最小够用授权原则，并采取技术措施对工作人员处理儿童信息的行为进行记录和管控，避免违法复制、下载儿童个人信息。
      <br />(二)安全体系认证<br />
      壹米咻咻有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经通过ISO27001：2013信息安全管理体系标准认证、ISO9001质量管理体系认证、网络安全等级保护三级测评认证。
      <br />
      （三)人员安全管理<br />
      我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我方会按现有技术提供相应的安全措施来保护您的信息,提供合理的安全保障，我方将尽力做到使您的信息不被泄漏、毁损或丢失。我们会举办安全和隐私保护培训课程,加强员工对于保护个人信息重要性的认识。
      <br />
      (四)存储地点及存储安全<br />
      我们将在中华人民共和国境内运营壹米咻咻服务并将基于运营中收集和产生的个人信息存储在中华人民共和国境内。
      我们会采取合理可行的措施存储您的个人信息，尽力避免收集无关的个人信息。我们只会在限于达成本声明所述目的所需的期限以及所适用法律法规所要求的期限内保留您的个人信息。例如，壹米咻咻将依据《中华人民共和国电子商务法》的要求，就您开通和使用订货通服务中生成的交易商品、服务信息、交易信息，保存时间自交易完成之日起不少于三年；依据《中华人民共和国网络安全法》的要求，就您使用壹米咻咻应用服务留存相关的网络日志不少于六个月；依据《移动互联网应用程序信息服务管理规定》，就您的日志信息保存不少于六十日。而我们判断前述期限的标准包括：
      <br />
      4.1 完成与您相关的服务目的、维护相应服务及业务记录、应对您可能的查询或投诉;
      <br />
      4.2 保证我们为您提供服务的安全和质量;<br />
      4.3 您是否同意更长的留存期间;<br />
      4.4 是否存在保留期限的其他特别约定。<br />
      在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。
      <br />
      (五)密码保护提示<br />
      互联网并非绝对安全的环境，使用壹米咻咻服务时请使用复杂密码，协助我们保证您的账号安全。我们将合理商业努力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施非因不可抗力遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      <br />
      （六)信息安全事件处理 发生个人信息安全事件后，我们将按照法律法规的要求(最迟不迟于30
      个自然日内)向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
      互联网环境并非百分之百安全，尽管我们有这些安全措施，但请注意在互联网上不存在“完善的安全措施”，我们将基于正常的商业努力确保您的信息的安全。
      <br />
      六、您如何管理您的信息 您可以通过以下方式访问及管理您的信息：<br />
      (一)查询、更正和补充您的信息<br />
      1.1 壹米咻咻企业用户<br />
      作为壹米咻咻企业用户的管理员，您有权查询、更正或补充您所在企业的信息。您可以通过以下方式自行进行:
      <br />
      打开壹米咻咻官方主页（www.pro.yimixiuxiu.com）通过手机号、密码，登陆“壹米咻咻”，点击右上角“头像-个人中心”，可以查询、更正企业信息，包括修改登陆密码。
      <br />
      1.2 壹米咻咻个人用户<br />
      作为壹米咻咻个人用户，您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：<br />

      登录壹米咻咻手机客户端，通过操作“我-设置-个人资料”可以查询、更正个人资料及个人账户相关信息：姓名、头像、电话、性别、邮箱、等信息。
      <br />
      登录手机客户端，通过以下路径“我-设置-关于壹米咻咻-联系我们”通过拨打壹米咻咻客服热线（400-7757-090）（7*9小时（9:00-18:00））寻求帮助，协助您查询、更正或补充您的信息。
      <br />
      (二)删除您的信息<br />
      您可以通过上述“（一)查询、更正和补充您的信息”中列明的方式或通过您所属的企业用户管理员操作删除您的部分信息。<br />
      在以下情形中，您可以向我们提出删除个人信息的请求:<br />
      2.1 如果我们处理个人信息的行为违反法律法规;<br />
      2.2 如果我们处理个人信息的行为严重违反了与您的约定;<br />
      2.3 如果您所在的企业/组织用户不再使用我们的产品或服务;<br />
      2.4
      如果我们永久不再为您提供产品或服务。若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除(除非法律法规另有规定,或这些主体已独立获得您的授权)
      当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其进一步处理隔离,直到备份可以清除或实现匿名。
      <br />
      (三)改变您授权同意的范围<br />
      每个业务功能需要一些基本的个人信息才能得以完成，除此之外，您可以操作改变您的授权同意范围：
      当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
      <br />
      （四)注销您的账户<br />
      当您所在企业/组织用户注销壹米咻咻企业/组织账户时,我们将一并注销您的个人用户账户并将根据适用法律的要求删除您的个人信息或做匿名化处理。
      <br />
      4.1
      如您所在企业组织用户为付费用户，若购买的壹米咻咻产品服务因到期而停用达到30天仍未续费申请复用的，则我们有权注销该企业账户；如您所在企业组织用户为免费用户，壹米咻咻将依据《壹米咻咻服务协议》收回账户后注销；您所在企业组织用户也可以拨打客服热线(400-7757-090)（7*9小时（9:00-18:00））主动申请账户注销，我们将协助注销账户。
      <br />4.2
      您可以向所在企业组织管理员申请注销个人账户或在您所在企业组织账户注销的同时您的个人账户将一并注销。
      <br />
      (五)约束信息系统自动决策<br />
      在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害壹米咻咻商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
      <br />
      (六)响应您的上述请求<br />
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
      <br />
      我们将在15天内做出答复。如您不满意，还可以通过壹米咻咻客服发起投诉。
      <br />
      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
      在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br />
      6.1 与国家安全、国防安全有关的; <br />6.2与公共安全、公共卫生、重大公共利益有关的；<br />
      6.3与犯罪侦查、起诉、审判和执行判决等有关的；<br />
      6.4 有充分证据表明个人信息主体存在主观恶意或滥用权利的;
      <br />6.5 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的； <br />6.6
      涉及壹米咻咻企业/组织用户商业秘密的。 <br />七、未成年人用户信息的特别约定<br />
      （一）我们主要面向成人提供产品和服务。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权声明，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
      <br />
      (二)对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
      <br />
      八、隐私权声明更新我们的隐私权声明可能修订。

      <br />
      (一)未经您明确同意，我们不会限制您按照本隐私权声明所应享有的权利。我们会在专门页面上发布对隐私权声明所做的任何修订。
      <br />
      (二)对于重大修订,我们还会提供更为显著的通知（包括对于某些服务，我们会通过网站公示的方式进行通知甚至向您提供弹窗提示，说明隐私权声明的具体变更内容）。
      <br />
      (三)本声明所指的重大变更包括但不限于:<br />
      3.1我们的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；
      <br />
      3.2.我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等;
      <br />
      3.3.用户信息共享、转让或公开披露的主要对象发生变化；
      <br />
      3.4.您参与用户信息处理方面的权利及其行使方式发生重大变化；
      <br />
      3.5.我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时;
      <br />
      3.6.用户信息安全影响评估报告表明存在高风险时。我们还会将本隐私权声明的历史版本在网站专门页面存档，供您查阅。
      <br />
      九、您如何联系我们如果您对壹米咻咻的隐私保护声明或数据处理有任何疑问、意见或建议，或者您对壹米咻咻对本隐私权保护声明的实践以及操作上有任何疑问和意见，可以拨打400-7757-090
      （9:00-18:00）与我们联系 电子邮箱： yimixiuxiu77@163.com<br />
      一般情况下，我们将在收到您相关联系信息并核实您身份后两个工作日内回复。<br />
      如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向法院提起诉讼来寻求解决方案。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleGoBack() {
      this.$router.replace('/staticSystem/about');
        this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container {
  min-height: 100vh;
  background: #f3f4f5;
}
.content {
  padding: 20px 40px;
  line-height: 45px;
  font-size: 28px;
  color: #666;
}
</style>
