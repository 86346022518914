<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'公告管理'" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-icon name="plus" class="mrg-r-20" @click="handleAdd"/>
                </template>
            </navBar>

            <div class="main">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                    >
                        <div class="list" v-for="item in dataList" :key="item.id">
                            <div class="timeTitle">{{ item.createTime }}</div>
                            <div class="item-tab" @click="handleGoDetail(item.id)">
                                <div class="title-wrap flex-spa-ver-cen">
                                    <div style="font-weight: bold; color: red;"
                                         v-if="item.unreadPerson && item.unreadPerson.indexOf(realname)!=-1">
                                        ·
                                    </div>
                                    <div class="tit over_hidden">
                                        {{ item.noticeTitle || '--' }}
                                    </div>
                                </div>
                                <div class="con">
                                    <div class="desc-wrap flex">
                                        <div class="value" v-html="item.noticeContent"></div>
                                    </div>
                                </div>
                                <div class="title-wrap flex-spa-ver-cen">
                                    <div style="font-weight: 400; font-size: 14px">
                                        详情
                                    </div>
                                    <div style="font-size: 10px">
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
</template>

<script>
    import {querynotice} from '@/api/oa/notice';

    export default {
        components: {},

        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                queryParams: {
                    pageNum: 1,
                    pageSize: 5
                },
                dataList: [],
                realname: localStorage.getItem('realname')
            };
        },

        mounted() {
            this.getList();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/oa/oaWorkbench');
                this.$router.go(-1)
            },

            async getList() {
                this.loading = true;
                const result = await querynotice(this.queryParams);
                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.dataList = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.dataList = records;
                } else {
                    this.dataList = [...this.dataList, ...records];
                }
                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
            },

            onLoad() {
                if (this.refreshing) {
                    this.dataList = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.dataList.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },

            handleAdd() {
                // 新增线索页面
                this.$router.push({
                    path: '/oa/notice/add',
                });
            },

            handleGoDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/oa/notice/detail',
                    query: {
                        id,
                    },
                });
            },

        },
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

  .timeTitle {
    width: 100%;
    height: 100px;
    line-height: 0px;
    font-weight: 400;
    color: #999999;
    font-size: 24px;
    text-align: center;
    padding-top: 60px;
    background-color: #FAFAFA;
  }

  .item-tab {
    margin-left: 50px;
    margin-right: 50px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        margin-left: 5px;
        font-size: 32px;
        font-weight: 600;
        color: #111111;
        width: 0;
        flex: 1;
      }
    }

    .con {
      padding: 0 0 20px 0;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;

        .value {
          color: #777777;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 50px;
        }
      }
    }
  }

  ::v-deep .van-tabs__line {
    background-color: #0091ff;
  }
</style>
