import request from "@/utils/request";

// 获取列表
export function findPage(params) {
  return request({
    url: "/crm/pay/findPage",
    method: "get",
    params
  });
}

// 获取单条信息
export function findOne(payId) {
  return request({
    url: "/crm/pay/"+payId,
    method: "get"
  });
}

// 根据订单id查询
export function findByOrderId(payId) {
  return request({
    url: "/crm/pay/findByOrderId/"+payId,
    method: "get"
  });
}

// 新建
export function savePay(data) {
  return request({
    url: "/crm/pay",
    method: "post",
    data
  });
}

// 修改
export function updatePay(data) {
  return request({
    url: "/crm/pay",
    method: "put",
    data
  });
}

// 删除
export function delPay(ids) {
  return request({
    url: "/crm/pay/" + ids,
    method: "delete"
  });
}

// 获取数量
export function getNumber() {
  return request({
    url: "/crm/pay/getNumber",
    method: "get"
  });
}

// 根据客户id查询订单id
export function fetchPayOrderListByCustomerId(params) {
  return request({
    url: "/crm/pay/findOrderIdByCustomer",
    method: "get",
    params
  });
}

// 新建子回款
export function savePaySub(data) {
  return request({
    url: "/crm/pay/savePaySub",
    method: "post",
    data
  });
}

// 修改子回款
export function updatePaySub(data) {
  return request({
    url: "/crm/pay/updatePaySub",
    method: "put",
    data
  });
}

// 删除子回款
export function delPaySub(ids) {
  return request({
    url: "/crm/pay/delPaySub/"+ids,
    method: "delete"
  });
}

// 财务-销售付款
export function cwCrmPay(data) {
  return request({
    url: "/crm/pay/cwCrmPay",
    method: "put",
    data
  });
}
