

// 获取数量 fetchCount
import request from "@/utils/request";

export function fetchCount(query) {
  return request({
    url: "/crm/repair/getNumber",
    method: "get"
  });
}

export function selectPage(query) {
  return request({
    url: "/crm/repair/getList",
    method: "get",
    params: query
  });
}
  export function dealService(id, state) {
    const data = {
      id,
      state
    }
    return request({
      url: '/crm/suggested/update',
      method: 'put',
      data: data
    })
}
// 删除
export function delList(ids) {
  return request({
    url: "/crm/repair/deleteRepair/" + ids,
    method: "delete"
  });
}
// 获取订单集合
export function fetchAllOrderList(query) {
  return request({
    url: "/crm/repair/getOrder",
    method: "get",
    params: query
  });
}
export function fetchGetOrderDetails(query) {
  return request({
    url: "/crm/repair/getOrderDetails",
    method: "get",
    params: query
  });
}
// 获取联系人集合
export function fetchAllContactsList(query) {
  return request({
    url: "/crm/currency/getContactsListByCustomerIdNoPage",
    method: "get",
    params: query
  });
}
// 新增
export function addData(data) {
  return request({
    url: "/crm/repair/addRepair",
    method: "post",
    data: data
  });
}

// 修改
export function updateData(data) {
  return request({
    url: "/crm/repair/updateRepair",
    method: "put",
    data: data
  });
}
export function updateDoData(data) {
  return request({
    url: "/crm/repair/repairDo",
    method: "put",
    data: data
  });
}
  // 根据id查询
  export function selectOne(query) {
    return request({
      url: "/crm/repair/getId",
      method: "get",
      params: query
    });
  }
// 提交订单
export function dataOrderSubmit(params) {
  return request({
    url: "/crm/repair/submit",
    method: "put",
    params
  });
}
// 审批-驳回订单
export function disAppvoral(data) {
  return request({
    url: "/crm/repair/inspectRepair",
    method: "put",
    data
  });
}
