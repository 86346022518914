<template>
    <div class="app-container">
        <navBar :title="'规则设置'" @handleGoBack="handleGoBack"></navBar>

        <div class="add-form">
            <div class="line"></div>
            <van-form validate-first @failed="onFailed">
                <van-field
                        v-model="formInline.groupName"
                        label="组标题"
                        name="groupName"
                        placeholder="请输入"
                        required
                        :rules="[{ required: true, message: '请输入组标题' }]"
                />

                <van-field
                        readonly
                        clickable
                        name="upTime"
                        v-model="formInline.upTime"
                        label="上班时间"
                        placeholder="点击选择上班时间"
                        @click="showCalendar = true"
                        required
                        :rules="[{ required: true, message: '请选择上班时间' }]"
                />
                <van-popup v-model="showCalendar" position="bottom" :style="{ height: '30%' }">
                    <van-datetime-picker
                            v-model="formInline.upTime"
                            type="time"
                            title="选择上班时间"
                            @confirm="onUpTimeConfirm"
                    />
                </van-popup>
                <van-field
                        readonly
                        clickable
                        name="downTime"
                        v-model="formInline.downTime"
                        label="下班时间"
                        placeholder="点击选择下班时间"
                        @click="showDownTime = true"
                        required
                        :rules="[{ required: true, message: '请选择下班时间' }]"
                />
                <van-popup v-model="showDownTime" position="bottom" :style="{ height: '30%' }">
                    <van-datetime-picker
                            v-model="formInline.downTime"
                            type="time"
                            title="选择下班时间"
                            @confirm="onDownTimeConfirm"
                    />
                </van-popup>

                <van-field
                        readonly
                        clickable
                        v-model="formInline.applyTypeName"
                        label="适用人群"
                        placeholder="请选择"
                        required
                        :rules="[{ required: true, message: '请选择适用人群' }]"
                        @click="showRangePicker = true"
                />

                <van-field
                        v-model="formInline.timeLength"
                        label="工作时长"
                        name="timeLength"
                        placeholder="请输入"
                        type="digit"
                        required
                        :rules="[{ required: true, message: '请输入工作时长' }]"
                />

                <div class="desc">设置符合你企业要求的考勤方式</div>
                <template v-if="true">
                    <template v-if="wifiInfoList.length > 0">
                        <van-cell v-for="(item, index) in wifiInfoList" :key="index">
                            <template #title>
              <span class="wifi-title" style="color: #333"
              ><i class="iconfont icon-wifi1 mrg-r-10"/>{{ item }}</span
              >
                            </template>

                            <template #default>
                                <van-icon name="delete-o" @click="handleDeleteWifi(index)"/>
                            </template>
                        </van-cell>
                    </template>
                    <van-cell v-else>
                        <template #title>
            <span class="address-title" style="color: #bbbbbb"
            ><i class="iconfont icon-wifi1 mrg-r-15"/>暂未添加WIFI</span
            >
                        </template>
                    </van-cell>

                    <van-cell @click="handleAddWifi">
                        <template #title>
                            <div class="add-ads-btn">
                                <van-icon name="add-o" class="mrg-r-10"/>
                                添加办公WIFI
                            </div>
                        </template>
                    </van-cell>
                </template>
                <!-- 添加wifi弹窗 -->
                <van-dialog
                        v-model="showWifi"
                        title="当前wifi"
                        show-cancel-button
                        @confirm="handleWifiConfirm"
                        @cancel="handleWifiCancel"
                >
                    <van-cell class="wifi-cell-con">
                        <template #title>
              <span class="wifi-title" style="color: #bbbbbb"
              ><i class="iconfont icon-wifi1 mrg-r-10"/>{{ ip }}</span
              >
                        </template>
                    </van-cell>
                </van-dialog>

                <van-cell :value="formInline.validRange || '请选择'" @click="showRange = true">
                    <template #title>
                        <span style="color: red">*</span><span class="custom-title">打卡有效范围</span>
                    </template>
                </van-cell>
                <van-popup v-model="showRange" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="range"
                            @confirm="onRangeConfirm"
                            @cancel="showRange = false"
                    />
                </van-popup>
                <van-cell>
                    <template #title>
            <span class="address-title" style="color: #bbbbbb"
            ><van-icon name="location-o" class="mrg-r-10"/>{{
                    formInline.officeAddress ? formInline.officeAddress : '暂未添加办公地点'
                }}</span
            >
                    </template>
                </van-cell>

                <template>
                    <van-cell @click="handleAddressOpen">
                        <template #title>
                            <div class="add-ads-btn">
                                <van-icon name="add-o" class="mrg-r-10"/>
                                <span v-if="!formInline.officeAddress">添加办公地点</span>
                                <span v-else>编辑办公地点</span>
                            </div>
                        </template>
                    </van-cell>
                </template>

                <!-- 地图弹窗 -->
                <van-dialog
                        v-model="showMap"
                        title="选择办公地点"
                        show-cancel-button
                        @confirm="handleAddressConfirm"
                        @cancel="handleAddressCancel"
                >
                    <div style="margin-left: 10px;margin-top: 10px">
                        <span style="color: red;">*</span>
                        <span style="font-size: 12px">拖到红色标识选择办公地点</span>
                    </div>

                    <div id="mapDiv" style="width: 100%;height: 300px"></div>
                </van-dialog>
                <van-button class="btn" block type="info" native-type="submit" @click="onSubmit"
                >提交
                </van-button
                >
            </van-form>
        </div>
        <selectUserAndDept :showRangePicker="showRangePicker" @handleClose="handleCloseRange"/>
    </div>
</template>
<script>
    import selectUserAndDept from '@/components/selectUserAndDept/selectUserAndDept';
    import {findDeptNameByDeptId} from '@/api/system/dept';
    import {getAllUser} from '@/api/system/user';
    import {addCheckRule, editCheckRule, fetchCheckRuleDetail, getIp} from '@/api/hr/hrAttendance';
    import {findAddressByLngLatTDT} from "@/api/system/ymxx";

    export default {
        components: {
            selectUserAndDept,
        },
        data() {
            return {
                formInline: {},
                showCalendar: false,
                showDownTime: false,
                columns: ['所有人', '用户', '部门'],
                pages: {
                    用户: '/attendance/chooseUser',
                    部门: '/attendance/department',
                },
                showRange: false,
                //
                range: ['50m', '100m', '200m', '300m', '400m', '500m'],
                showWifi: false,
                ip: null, // 当前用户ip
                showAddedWifi: false,
                showMap: false,
                currentPoint: '',
                currentAddress: [],
                dataId: undefined,
                dragendAddress: '',
                wifiInfoList: [],
                showRangePicker: false,
                userList: [],
                markerObject: [],
                clustererObject: null
            };
        },

        computed: {
            applyIdList() {
                return this.$store.state.attendance.applyIdList;
            },
            ruleSetForm() {
                return this.$store.state.attendance.ruleSetForm;
            },
        },

        watch: {
            $route: {
                handler(val) {
                    if (val.query.sign) {
                        this.formInline = this.ruleSetForm;
                        if (val.query.sign == 'dept') {
                            const deptName = this.applyIdList.map((ele) => ele.name);
                            this.formInline.applyTypeName = deptName.join();
                        } else {
                            const name = this.applyIdList.map((ele) => ele.realname);
                            this.formInline.applyTypeName = name.join();
                        }
                    } else if (val.params.id != 0) {
                        this.findAllUser();
                        this.getDetail();
                    } else {
                        this.formInline = {
                            validRange: '300m'
                        };
                    }
                },
                immediate: true,
                deep: true,
            },
        },

        mounted() {
            this.dataId = this.$route.params.id;

            let script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = 'http://api.tianditu.gov.cn/api?v=4.0&tk=b54dd3458b0735d8e7c8c0e9753f1891'
            document.body.appendChild(script)
        },

        methods: {
            async getDetail() {
                const result = await fetchCheckRuleDetail(this.$route.params.id);
                this.formInline.id = result.data.id;
                this.formInline.upTime = result.data.upTime;
                this.formInline.downTime = result.data.downTime;

                this.formInline.timeLength = result.data.timeLength;
                this.formInline.groupName = result.data.groupName;
                this.formInline.validRange = result.data.validRange + 'm';
                // 处理地址回显
                if (result.data.isAddress) {
                    this.formInline.officeAddress = result.data.officeAddress;
                    this.formInline.officeLonLat = result.data.officeLonLat;
                }
                // 处理WIFI回显
                if (result.data.isWifi) {
                    this.wifiInfoList = result.data.wifiInfoList.map((item) => item.wifiIp);
                }

                //适用人群
                this.formInline.applyType = result.data.applyType;
                this.formInline.applyIdList = result.data.applyIdList;
                if (result.data.applyType == 0) {
                    this.formInline.applyTypeName = '所有人';
                } else if (result.data.applyType == 1) {
                    // 部门是1
                    let deptData = await findDeptNameByDeptId(JSON.stringify(result.data.applyIdList));
                    this.formInline.applyTypeName = deptData.data;
                } else if (result.data.applyType == 2) {
                    // 用户是2
                    this.formInline.applyTypeName = this.userList.map((item) => item.realname).join(',');
                }
                this.$forceUpdate();
            },

            //查询所有用户
            async findAllUser() {
                let result = await getAllUser();
                this.userList = result.data;
            },

            //关闭适用类型弹窗
            handleCloseRange(list, applyType) {
                this.showRangePicker = false;

                this.formInline.applyType = applyType;
                if (list && list.length > 0) {
                    this.formInline.applyIdList = list.map((item) => item.id);
                    this.formInline.applyTypeName = list.map((item) => item.name).join(',');
                } else {
                    this.formInline.applyTypeName = '所有人'
                }
            },

            handleAddWifi() {
                this.showWifi = true;
                this.findIp();
            },

            //查询IP
            async findIp() {
                //获取不到ip时调用后端接口获取IP
                let result = await getIp();
                this.ip = result.data;
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            async onSubmit() {
                if (this.wifiInfoList.length == 0 && !this.formInline.officeAddress) {
                    this.$toast({
                        message: '考勤方式二选一',
                        icon: 'fail',
                    });
                    return;
                }

                if (!this.formInline.validRange) {
                    this.$toast({
                        message: '请选择打卡有效范围',
                        icon: 'fail',
                    });
                    return;
                }

                if (this.wifiInfoList.length != 0) {
                    this.isWifi = true;
                    const wifiInfoList = JSON.parse(JSON.stringify(this.wifiInfoList));
                    this.formInline.wifiInfoList = wifiInfoList.map((ele) => {
                        return {wifiIp: ele};
                    });
                }

                if (!this.formInline.officeAddress) {
                    this.isAddress = true;
                }

                if (this.formInline.validRange) {
                    this.formInline.validRange = this.formInline.validRange.replace('m', '');
                }
                const result = +this.dataId
                    ? await editCheckRule(this.formInline)
                    : await addCheckRule(this.formInline);

                if (result.code == 200) {
                    this.$toast('操作成功');
                    this.handleGoBack();
                }
            },

            onRangeConfirm(value) {
                this.formInline.validRange = value;
                this.showRange = false;
            },

            onDownTimeConfirm(value) {
                this.formInline.downTime = `${value}:00`;
                this.showDownTime = false;
            },

            onUpTimeConfirm(value) {
                this.formInline.upTime = `${value}:00`;
                this.showCalendar = false;
            },

            handleWifiConfirm() {
                this.showAddedWifi = true;
                this.showWifi = false;
                this.wifiInfoList.push(this.ip);
            },

            handleWifiCancel() {
                this.showWifi = false;
            },

            handleDeleteWifi(index) {
                this.wifiInfoList.splice(index, 1);
            },

            handleAddressOpen() {
                this.showMap = true
                //获取当前经纬度
                if (navigator.geolocation) {
                    let that = this
                    navigator.geolocation.getCurrentPosition(async function (position) {
                        let lon = position.coords.longitude
                        let lat = position.coords.latitude

                        //设置当前地点为办公地点
                        that.currentPoint = lon + ',' + lat

                        let zoom = 15
                        let map;

                        await new Promise((resolve, reject) => {
                            if (window.T) {
                                console.log('正在初始化....')
                                console.log('地图初始化成功')
                                resolve(window.T)
                                console.log('正在转化...')
                                reject('error')
                                console.log('请求无拒绝')
                            } else {
                                console.log('不支持此系统')
                            }
                        }).then(async T => {
                            map = new T.Map('mapDiv', {
                                attributionControl: false,
                                inertia: false
                            })
                            map.setMinZoom(5)// 设置最小变动层级
                            map.setMaxZoom(18)// 设置最大变动层级
                            map.enableDrag()// 启用拖拽
                            map.enableScrollWheelZoom()// 启用滚轮放大缩小
                            map.centerAndZoom(new T.LngLat(lon, lat), zoom);

                            if (that.formInline.officeLonLat) {
                                let lngLat = that.formInline.officeLonLat.split(',')
                                let marker = new T.Marker(new T.LngLat(lngLat[0], lngLat[1]))
                                map.addOverLay(marker);
                            }

                            let cp = new T.CoordinatePickup(map, {
                                callback: async (lnglat) => {
                                    that.currentPoint = lnglat.lng.toFixed(6) + "," + lnglat.lat.toFixed(6);

                                    //每次点击都清除点
                                    map.clearOverLays();

                                    let marker = new T.Marker(new T.LngLat(lnglat.lng.toFixed(6), lnglat.lat.toFixed(6)))
                                    map.addOverLay(marker);
                                }
                            })
                            cp.addEvent();
                        })
                    }, function (error) {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                alert('用户拒绝对获取地理位置的请求。')
                                break;
                            case error.POSITION_UNAVAILABLE:
                                alert('位置信息是不可用的。')
                                break;
                            case error.TIMEOUT:
                                alert('请求用户地理位置超时。')
                                break;
                            case error.UNKNOWN_ERROR:
                                alert('未知错误。')
                                break;
                        }
                    });
                }
            },

            async handleAddressConfirm() {
                this.showMap = false;

                if (this.currentPoint) {
                    //设置当前地点为办公地点
                    let result = await findAddressByLngLatTDT({lngLat: this.currentPoint})
                    this.dragendAddress = result.data
                }

                this.formInline.officeAddress = this.dragendAddress;
                this.formInline.officeLonLat = this.currentPoint;
                this.$forceUpdate()
            },

            handleAddressCancel() {
                this.showMap = false;
                this.currentPoint = '';
                this.formInline.officeAddress = '';
            },

            handleGoBack() {
                this.$router.replace('/attendance/setting');
                this.$router.go(-1)
            },
        },
    };
</script>
<style lang="scss" scoped>
  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .desc {
    font-size: 24px;
    color: #999999;
    background: #fafafa;
    padding: 20px 35px;
  }

  .add-ads-btn {
    text-align: center;
    color: #54afff;
    cursor: pointer;
  }

  ::v-deep .van-picker__toolbar {
    height: 70px !important;
  }

  ::v-deep .van-picker__title,
  ::v-deep .van-picker__cancel,
  ::v-deep .van-picker__confirm {
    height: 40px;
    line-height: 40px;
    font-size: 28px;
  }

  ::v-deep .van-picker-column {
    font-size: 30px;
  }

  .wifi-cell-con {
    width: 500px;
    text-align: center;

    .iconfont,
    .wifi-title {
      font-size: 30px;
    }
  }

  ::v-deep .van-dialog {
    width: 600px;
    // width: 100%;
  }

  ::v-deep .van-dialog__header {
    font-size: 30px;
  }

  ::v-deep .van-dialog__cancel,
  ::v-deep .van-dialog__confirm {
    font-size: 30px;
    height: 60px;
  }

  .map {
    width: 100%;
    height: 400px;
    margin: 20px 0;
  }

  > > > .anchorBL,
    /*>>>.anchorTR,去除二维或三维的*/
  > > > .BMap_zlHolder {
    display: none;

    visibility: hidden;
  }
</style>
