import request from '@/utils/request'

// 查询列表
export function findPage(query) {
  return request({
    url: '/inventory/experiment/findPage',
    method: 'get',
    params: query
  })
}

// 查询记录列表
export function findAllDetailPage(query) {
  return request({
    url: '/inventory/experiment/findAllDetailPage',
    method: 'get',
    params: query
  })
}

// 查询详细
export function findOne(id) {
  return request({
    url: '/inventory/experiment/' + id,
    method: 'get'
  })
}

// 新增
export function addData(data) {
  return request({
    url: 'inventory/experiment',
    method: 'post',
    data: data
  })
}

// 修改
export function updateData(data) {
  return request({
    url: '/inventory/experiment',
    method: 'put',
    data: data
  })
}

// 申请出库
export function applyExpend(id) {
  return request({
    url: '/inventory/experiment/applyExpend/'+id,
    method: 'put'
  })
}

// 删除
export function delData(id) {
  return request({
    url: '/inventory/experiment/' + id,
    method: 'delete'
  })
}

// 填报结果
export function writeResult(data) {
  return request({
    url: '/inventory/experiment/writeResult',
    method: 'put',
    data: data
  })
}
