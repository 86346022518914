import request from '@/utils/request'

// 查询保修列列表
export function listMaintenance(query) {
  return request({
    url: '/crm/maintenance/list',
    method: 'get',
    params: query
  })
}

// 查询保修列详细
export function getMaintenance(id) {
  return request({
    url: '/crm/maintenance/findById?id='+id,
    method: 'get'
  })
}

// 新增保修列
export function addMaintenance(data) {
  return request({
    url: '/crm/maintenance',
    method: 'post',
    data: data
  })
}

// 修改保修列
export function updateMaintenance(data) {
  return request({
    url: '/crm/maintenance',
    method: 'put',
    data: data
  })
}

// 删除保修列
export function delMaintenance(id) {
  return request({
    url: '/crm/maintenance/' + id,
    method: 'delete'
  })
}
