<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            readonly
                            clickable
                            v-model="formInline.orderTitle"
                            label="销售订单"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择销售订单' }]"
                            @click="type == 'add' ? showPickerOrder = true : showPickerOrder = false"
                    />
                    <van-popup v-model="showPickerOrder" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="orderList"
                                value-key="theme"
                                @confirm="
                onConfirmOrder($event, 'orderId', 'orderTitle', 'theme', 'showPickerOrder')
              "
                                @cancel="showPickerOrder = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.consegnaDate"
                            label="订单交期(天)"
                            placeholder="请输入"
                            type="digit"
                            :required="true"
                            :rules="[{ required: true, message: '请输入订单交期' }]"
                    />

                    <van-field
                            v-model="formInline.finalDeliveryDate"
                            label="厂内最终交期"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.tempoDeliveryRate"
                            label="准时交货率(%)"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.leadingDeliveryRate"
                            label="超前交货率(%)"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.delayDeliveryRate"
                            label="延迟交货率(%)"
                            placeholder="请输入"
                    />

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">{{ item.materialName || '--' }}</div>
                        </div>

                        <van-field
                                v-model="item.lavorazioneProject"
                                label="加工项目"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.beginDate"
                                label="开始日期"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.planFinishDate"
                                label="计划完成时间"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.realArrivalDate"
                                label="实际来料时间"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.progress"
                                label="进度情况(剩余天数)"
                                placeholder="请输入"
                                type="digit"
                                :required="true"
                                :rules="[{ required: true, message: '请输入进度情况' }]"
                        />

                        <van-field
                                v-model="item.qualityResult"
                                label="QC检验/结果"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.personLiable"
                                label="责任人"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.remark"
                                label="备注"
                                placeholder="请输入"
                        />
                    </div>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
    import {addData, findByOrderId, findOrderList, updateData} from '@/api/custom/productFlow'
    import {findProductByOrderId} from "@/api/crm/order";

    export default {
        data() {
            return {
                formInline: {},
                title: '新建生产流程',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerOrder: false,
                //订单数组
                orderList: [],
                //明细数组
                dataList: []
            };
        },

        mounted() {
            //查询所有销售订单
            findOrderList().then(response => {
                this.orderList = response.data
            })
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {};
                    this.title = '新建生产流程';
                } else {
                    let itemDetail = await findByOrderId(this.id);
                    itemDetail = itemDetail.data
                    this.dataList = itemDetail
                    this.formInline = {
                        orderId: itemDetail[0].orderId,
                        orderTitle: this.$route.query.orderTitle,
                        consegnaDate: itemDetail[0].consegnaDate,
                        finalDeliveryDate: itemDetail[0].finalDeliveryDate,
                        tempoDeliveryRate: itemDetail[0].tempoDeliveryRate,
                        leadingDeliveryRate: itemDetail[0].leadingDeliveryRate,
                        delayDeliveryRate: itemDetail[0].delayDeliveryRate
                    };
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑生产流程';
                    } else {
                        this.title = '生产流程详情';
                    }
                }
            },

            //确认订单
            async onConfirmOrder(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];

                let result = await findProductByOrderId(this.formInline.orderId)
                this.dataList = result.data

                this[visible] = false;
            },

            async onSubmit() {
                let dataList = this.dataList
                for (let i = 0; i < dataList.length; i++) {
                    let item = dataList[i]
                    item.orderId = this.formInline.orderId
                    item.consegnaDate = this.formInline.consegnaDate
                    item.finalDeliveryDate = this.formInline.finalDeliveryDate
                    item.tempoDeliveryRate = this.formInline.tempoDeliveryRate
                    item.leadingDeliveryRate = this.formInline.leadingDeliveryRate
                    item.delayDeliveryRate = this.formInline.delayDeliveryRate
                }
                let formdata = new FormData()
                formdata.set('jsonArray', JSON.stringify(dataList))

                const result = this.type == 'edit' ? await updateData(formdata) : await addData(formdata);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            }
        }
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
