<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <div class="base-tit">基本信息</div>
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="对手名称"
                            name="name"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入正确内容' }]"
                    />
                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.natureName"
                            label="企业性质"
                            placeholder="请选择"
                            @click="showNaturePicker = true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择企业性质' }]"
                    />
                    <van-popup v-model="showNaturePicker" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="natureOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'nature', 'natureName', 'dictLabel', 'showNaturePicker')
              "
                                @cancel="showNaturePicker = false"
                        />
                    </van-popup>
                    <van-field
                            readonly
                            clickable
                            v-model="formInline.levelName"
                            label="威胁级别"
                            name="level"
                            placeholder="请选择"
                            @click="showLevelPicker = true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择威胁级别' }]"
                    />
                    <van-popup v-model="showLevelPicker" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="levelOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'level', 'levelName', 'dictLabel', 'showLevelPicker')
              "
                                @cancel="showLevelPicker = false"
                        />
                    </van-popup>
                    <van-field
                            v-model="formInline.legalPerson"
                            label="法人代表"
                            name="asyncValidator"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入正确内容' }]"
                    />
                    <van-field
                            v-model="formInline.capital"
                            type="number"
                            label="注册资本(元)"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.number"
                            type="number"
                            label="人员规模(人)"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.scale"
                            type="number"
                            label="资产规模(元)"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.annualSales"
                            type="number"
                            label="年销售额(元)"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.annualProfit"
                            type="number"
                            label="年利润额(元)"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.phone"
                            label="办公电话"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.email"
                            label="电子邮箱"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.address"
                            label="公司地址"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.website"
                            label="官网网址"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <div class="base-tit">对手分析及策略</div>

                    <van-field
                            v-model="formInline.briefIntroduction"
                            label="对手简介"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.businessDirection"
                            label="业务方向"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.mainProducts"
                            label="主要产品"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.recentSigning"
                            label="签单情况"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.competitiveEdge"
                            label="竞争优势"
                            name="asyncValidator"
                            placeholder="请输入"
                    />
                    <van-field
                            v-model="formInline.competitiveDisadvantage"
                            label="竞争劣势"
                            name="asyncValidator"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.copingStrategies"
                            label="应对策略"
                            name="asyncValidator"
                            placeholder="请输入"
                    />

                    <van-button class="btn" block type="info" native-type="submit">提交</van-button>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
    import {addCompete, editCompete} from '@/api/crm/competitor';

    export default {
        data() {
            return {
                formInline: {
                    level: ''
                },
                showLevelPicker: false,
                showNaturePicker: false,
                natureOptions: [],
                levelOptions: [],
                title: '新增竞争对手',
            };
        },

        mounted() {
            //查询字典
            this.findDict()
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace( '/crm/compete');
                this.$router.go(-1)
            },

            //查询字典
            async findDict() {
                // 企业性质
                await this.getDicts('compete_nature').then((response) => {
                    this.natureOptions = response.data;
                });

                // 危险极别
                await this.getDicts('compete_level').then((response) => {
                    this.levelOptions = response.data;
                });
            },

            //回显
            findDetail() {
                const itemDetail = this.$route.query.obj ? JSON.parse(this.$route.query.obj) : '';
                if (itemDetail) {
                    this.formInline = itemDetail;
                    this.title = '编辑竞争对手';
                } else {
                    this.formInline = {};
                    this.title = '新增竞争对手';
                }
            },

            onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            async onSubmit(values) {
                const params = {
                    ...values,
                    ...this.formInline,
                };
                // const data = this.objectToFormData(params);
                const data = JSON.stringify(this.formInline)
                const result = this.formInline.id ? await editCompete(data) : await addCompete(params);
                if (result.code == 200) {
                    this.$toast(result.message);
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
