import request from "@/utils/request";

// 获取销售合同抬头
export function fetchHeadList(params) {
  return request({
    url: "/cw/invoiceHead/getList",
    method: "get",
    params
  });
}

// 新建销售合同
export function addCrmContract(data) {
  return request({
    url: "/crm/contract/addContract",
    method: "post",
    data
  });
}
// 获取数量 fetchCount
export function fetchCount(query) {
  return request({
    url: "/crm/contract/getNumber",
    method: "get"
  });
}
// 获取查询列表
export function selectPage(query) {
  return request({
    url: "crm/contract/getList",
    method: "get",
    params: query
  });
}
// 删除
export function delList(ids) {
  return request({
    url: "/crm/contract/deleteContract/" + ids,
    method: "delete"
  });
}

// 提交销售合同管理
export function dataContractSubmit(params) {
  return request({
    url: "/crm/contract/submitContract",
    method: "put",
    params
  });
}

// 获取单条信息
export function selectItemByContract(params) {
  return request({
    url: "/crm/contract/getId",
    method: "get",
    params
  });
}

// 回显抬头信息
export function fetchHeadDetail(query) {
  return request({
    url: "/crm/invoice/getHeadId",
    method: "get",
    params: query
  });
}
// 更新销售合同
export function updateCrmContract(data) {
  return request({
    url: "/crm/contract/updateContract",
    method: "put",
    data
  });
}

//
export function getStatisticsAdd(params) {
  return request({
    url: "/crm/saleWork/getStatisticsAdd",
    method: "get",
    params
  });
}

// 删除产品
export function removeProduct(data) {
  return request({
    url: "/crm/contract/inspectContract",
    method: "put",
    data
  });
}

// 查询客户下所有的订单
export function fetchAllOrderByCustomerId(params) {
  return request({
    url: "/crm/contract/getOrder",
    method: "get",
    params
  });
}

// 根据订单查询合同
export function selectItemByOrder(params) {
  return request({
    url: "/crm/contract/findByOrderId",
    method: "get",
    params
  });
}
