<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.theme"
                            label="售后维修标题"
                            placeholder="请输入"
                            :disabled="type == 'repair'"
                            :required="true"
                            :rules="[{ required: true, message: '请输入售后维修标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.customer"
                            label="客户名称"
                            placeholder="请选择"
                            :disabled="type == 'repair'"
                            :required="true"
                            :rules="[{ required: true, message: '请选择客户' }]"
                            @click="handleOpenPop('showPickerCustomer')"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.order"
                            label="订单"
                            placeholder="请选择"
                            :disabled="type == 'repair'"
                            :required="true"
                            :rules="[{ required: true, message: '请选择订单' }]"
                            @click="handleOpenPop('showPickerOrder')"
                    />
                    <van-popup v-model="showPickerOrder" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="orderList"
                                value-key="theme"
                                @confirm="
                onConfirmOrder($event, 'orderId', 'order', 'theme', 'showPickerOrder')
              "
                                @cancel="showPickerOrder = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.contactsStr"
                            label="客户联系人"
                            placeholder="请选择"
                            :disabled="type == 'repair'"
                            @click="handleOpenPop('showPickerContact')"
                    />
                    <van-popup v-model="showPickerContact" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="contactList"
                                value-key="name"
                                @confirm="
                onConfirmContact($event, 'contactsId', 'contactsStr', 'name', 'showPickerContact')
              "
                                @cancel="showPickerContact = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.contactsPhone"
                            label="联系人电话"
                            disabled
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.leaderStr"
                            label="负责人"
                            placeholder="请选择"
                            :disabled="type == 'repair'"
                            :required="true"
                            :rules="[{ required: true, message: '请选择负责人' }]"
                            @click="handleOpenPop('showPop')"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.repairStr"
                            label="维修人员"
                            placeholder="请选择"
                            :disabled="type == 'repair'"
                            :required="true"
                            :rules="[{ required: true, message: '请选择维修人员' }]"
                            @click="handleOpenPop('showPopRepair')"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.typeStr"
                            label="接待方式"
                            placeholder="请选择"
                            :disabled="type == 'repair'"
                            @click="handleOpenPop('showPickerType')"
                            :required="true"
                            :rules="[{ required: true, message: '请选择收入类型' }]"
                    />
                    <van-popup v-model="showPickerType" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="serviceOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'type', 'typeStr', 'dictValue','dictLabel', 'showPickerType')
              "
                                @cancel="showPickerType = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.urgencyStr"
                            label="紧急程度"
                            placeholder="请选择"
                            :disabled="type == 'repair'"
                            @click="handleOpenPop('showPickerUrgency')"
                            :required="true"
                            :rules="[{ required: true, message: '请选择紧急程度' }]"
                    />
                    <van-popup v-model="showPickerUrgency" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="levelOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'urgency', 'urgencyStr', 'dictValue','dictLabel', 'showPickerUrgency')
              "
                                @cancel="showPickerUrgency = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.repairDate"
                            label="受理日期"
                            placeholder="点击选择日期"
                            :disabled="type == 'repair'"
                            :required="true"
                            :rules="[{ required: true, message: '请选择受理日期' }]"
                            @click="handleOpenPop('showPickerRepairDate')"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerRepairDate"
                                  @confirm="onConfirmAppointment($event)"/>

                    <van-field
                            v-model="formInline.otherPrice"
                            label="其他费用"
                            type="number"
                            @input="handleInputChange"
                            :disabled="type == 'repair'"
                    />

                    <van-field
                            v-model="formInline.repairPrice"
                            label="维修金额"
                            type="number"
                            disabled
                    />

                    <textAreaInput :remark="formInline.repairAddress" @change="handleRepairAddressChange"
                                   :disabled="type == 'repair'"
                                   :title="'维修地址'"/>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.repairStateStr"
                            label="维修状态"
                            placeholder="请选择"
                            @click="showPickerRepairState= true"
                            v-if="type == 'repair' || type == 'doDetail'"
                    />
                    <van-popup v-model="showPickerRepairState" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="repairStateOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'repairState', 'repairStateStr', 'dictValue','dictLabel', 'showPickerRepairState')
              "
                                @cancel="showPickerRepairState = false"
                        />
                    </van-popup>

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
                        </div>

                        <van-field
                                v-model="item.materialUnit"
                                label="单位"
                                disabled
                        />

                        <van-field
                                v-model="item.price"
                                label="单价"
                                disabled
                        />

                        <van-field
                                v-model="item.returnQuantity"
                                label="可维修数量"
                                disabled
                        />

                        <van-field
                                v-model="item.repairNumber"
                                label="维修数量"
                                type="number"
                                :disabled="type == 'repair'"
                        />

                        <van-field
                                v-model="item.repairPrice"
                                label="小计维修金额"
                                type="number"
                                :disabled="type == 'repair'"
                                @input="handleInputChange"
                        />

                        <van-field
                                readonly
                                clickable
                                v-model="item.repairDate"
                                label="交付日期"
                                placeholder="点击选择日期"
                                @click="handlePop('showPickerDetailRepairDate',index)"
                        />
                        <van-calendar :min-date="minDate" v-model="showPickerDetailRepairDate"
                                      @confirm="onConfirmApply($event,'repairDate','showPickerDetailRepairDate')"/>

                        <van-field
                                v-model="item.repairSituation"
                                label="维修情况"
                        />

                        <van-field
                                v-model="item.repairProblem"
                                label="问题描述"
                        />
                    </div>

                    <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit"
                                v-if="type != 'detail' && type != 'doDetail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <CustomerPop :customerVisible="showPickerCustomer" @submitCustomerList="submitCustomerList"
                     @handleClose="handleCloseCustomer"/>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>

        <SelectOneUser2 :showPop="showPopRepair" @popSubmit="popSubmit2" @handleClose="handleCloseUser2"/>
    </div>
</template>
<script>
    import {
        addData,
        fetchAllContactsList,
        fetchAllOrderList,
        fetchGetOrderDetails,
        selectOne,
        updateData,
        updateDoData
    } from '@/api/crm/repair/index'
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import CustomerPop from '@/components/crm/CustomerPop'
    import SelectOneUser from '@/components/selectOneUser/index'
    import SelectOneUser2 from '@/components/selectOneUser/index'
    import {formatDate} from "@/utils/ruoyi";
    import {submiteUpload} from "@/api/system/config";

    export default {
        components: {AppendixVue, SelectOneUser, SelectOneUser2, CustomerPop},
        data() {
            return {
                formInline: {},
                title: '新建维修',
                id: this.$route.query.id,
                type: this.$route.query.type,
                uploader: [],
                uploadParams: {
                    businessType: 'repair',
                    businessId: undefined,
                    file: null,
                },
                minDate: new Date(2010, 0, 1),
                showPickerCustomer: false,
                showPickerContact: false,
                showPickerRepairDate: false,
                showPickerOrder: false,
                showPickerType: false,
                showPickerUrgency: false,
                showPop: false,
                showPopRepair: false,
                showPickerDetailRepairDate: false,
                showPickerRepairState: false,
                //订单数组
                orderList: [],
                //联系人数组
                contactList: [],
                //明细数组
                dataList: [],
                //接待方式
                serviceOptions: [],
                //紧急程度
                levelOptions: [],
                //维修状态
                repairStateOptions: [
                    {dictLabel: '未维修', dictValue: 0},
                    {dictLabel: '部分维修', dictValue: 1},
                    {dictLabel: '维修完成', dictValue: 2},
                    {dictLabel: '不予维修', dictValue: 3}
                ],
                //选中的明细索引
                itemIndex: null
            };
        },

        mounted() {
            this.getDicts('follow_type').then(response => {
                this.serviceOptions = response.data
            })
            this.getDicts('urgency_type').then(response => {
                this.levelOptions = response.data
            })
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        enterCategory: 0
                    };
                    this.title = '新建维修';
                } else {
                    let itemDetail = await selectOne({repairId: this.id});
                    itemDetail = itemDetail.data
                    this.dataList = itemDetail.crmCostProducts
                    this.formInline = itemDetail;
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑维修';
                    } else {
                        this.title = '维修详情';
                    }
                }
            },

            //打开弹窗
            handleOpenPop(showPicker) {
                if (this.type != 'repair') {
                    this[showPicker] = true
                }
            },

            //客户提交
            async submitCustomerList(id, name) {
                this.formInline.customerId = id;
                this.formInline.customer = name;

                //改变客户后，表单重置
                this.orderList = []
                this.formInline.orderId = null
                this.formInline.order = ''
                this.formInline.contactsId = null
                this.formInline.contactsStr = ''
                this.dataList = []

                //获取联系人
                await fetchAllContactsList({customerId: id}).then(response => {
                    this.contactList = response.data
                })

                //获取订单
                await fetchAllOrderList({customerId: id}).then(response => {
                    this.orderList = response.data
                })

                this.showPickerCustomer = false
            },

            //关闭选择客户的弹窗
            handleCloseCustomer() {
                this.showPickerCustomer = false;
            },

            //确认联系人
            async onConfirmContact(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;

                this.contactList.forEach(item => {
                    if (item.id == this.formInline.contactsId) {
                        this.formInline.contactsPhone = item.phone
                    }
                })
            },

            //确认订单
            async onConfirmOrder(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];

                fetchGetOrderDetails({orderId: this.formInline.orderId}).then(response => {
                    this.dataList = response.data
                })

                this[visible] = false;
            },

            //受理日期确认
            onConfirmAppointment(value) {
                this.formInline.repairDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerRepairDate = false;
            },

            //负责人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.leaderId = activeId
                this.formInline.leaderStr = names
            },

            //关闭负责人
            handleCloseUser() {
                this.showPop = false
            },

            //维修人提交
            popSubmit2(activeId, names) {
                this.showPopRepair = false
                this.formInline.repairId = activeId
                this.formInline.repairStr = names
            },

            //关闭维修人
            handleCloseUser2() {
                this.showPopRepair = false
            },

            //确认
            onConfirm(event, key, keyName, eventKey1, eventKey2, visible) {
                this.formInline[key] = event[eventKey1];
                this.formInline[keyName] = event[eventKey2];
                this[visible] = false;
            },

            //维修地址
            handleRepairAddressChange(val) {
                this.formInline.repairAddress = val;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            //触发明细弹窗
            handlePop(showPicker, index) {
                this[showPicker] = true;
                this.itemIndex = index
            },

            //明细日期确认
            onConfirmApply(value, attr, showPicker) {
                this.dataList[this.itemIndex][attr] = formatDate(value, 'yyyy-MM-dd');
                this[showPicker] = false;
            },

            // 计算维修金额
            handleInputChange() {
                let price = 0
                this.dataList.forEach(item => {
                    if (item.repairPrice) {
                        price += parseFloat(item.repairPrice)
                    }
                })
                if (this.formInline.otherPrice) {
                    price += parseFloat(this.formInline.otherPrice)
                }
                this.formInline.repairPrice = Number(price)
            },

            async onSubmit() {
                if (!this.dataList.length) {
                    this.$toast.fail('请先填写明细');
                    return
                }
                for (let i = this.dataList.length - 1; i >= 0; i--) {
                    let detail = this.dataList[i]
                    if (!detail.repairNumber) {
                        this.$toast.fail('请先填写维修数量');
                        return
                    }
                    if (!detail.repairProblem) {
                        this.$toast.fail('请先填写问题描述');
                        return
                    }
                    if (!detail.repairPrice) {
                        this.$toast.fail('请先填写维修金额');
                        return
                    }
                    detail.costProductId = detail.id
                }

                this.formInline.crmRepairDetails = this.dataList
                let data = JSON.stringify(this.formInline)

                let result = ''
                if (this.type == 'edit') {
                    result = await updateData(data)
                } else if (this.type == 'add') {
                    result = await addData(data)
                } else if (this.type == 'repair') {
                    result = await updateDoData(data)
                }
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
