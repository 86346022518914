<template>
    <div class="app-container" v-if="showEcharts">
        <div style="width: 100%;height: 500px;padding-top: 10px" ref="echartsRef1"></div>
        <div style="width: 100%;height: 300px;padding-top: 10px" ref="echartsRef2"></div>
        <div style="width: 100%;height: 500px;" ref="echartsRef3"></div>

        <div class="table">
            <div class="table-header flex-spa-bet">
                <span class="se-tr">出库标题</span>
                <span class="se-tr">出库仓库</span>
                <span class="se-tr">负责人</span>
                <span class="se-tr">总金额</span>
                <span class="se-tr">申请时间</span>
            </div>
            <div class="table-body">
                <div class="itemDiv item-tr flex-spa-ver-cen" v-for="(item, index) in tableData" :key="index">
                    <van-divider/>
                    <div class="itemCol name-wrap flex-ver-cen">
                        <div class="se-tr">{{ item.expendTitle }}</div>
                        <div class="se-tr rightData">{{ item.whName }}</div>
                        <div class="se-tr rightData">{{ item.warehouseTube }}</div>
                        <div class="se-tr rightData">{{ item.totalMoney }}</div>
                        <div class="se-tr rightData">{{ item.applyTime }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getEchart1, getEchart2, getEchart3} from '@/echarts/report/inventory/outstockTendency'
    import {
        countByCategory,
        selectExpendTwohundred,
        sevenDaysExpendQuantity
    } from '@/api/report/inReport/outstockTendency/outstockTendency.js'

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    this.showEcharts = this.isShow
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart1) {
                                this.dataEchart1.resize()
                            }
                            if (this.dataEchart2) {
                                this.dataEchart2.resize()
                            }
                            if (this.dataEchart3) {
                                this.dataEchart3.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                showEcharts: false,
                tableData: [],
                dataEchart1: null,
                dataEchart2: null,
                dataEchart3: null,
                // 查询参数
                queryParams: {}
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                //列表
                let result = await selectExpendTwohundred(this.queryParams)
                this.tableData = result.data

                let result1 = await sevenDaysExpendQuantity(this.queryParams)
                let result2 = await countByCategory(this.queryParams)

                // 柱状图
                let time = result1.data.assemblyExpend.map(item => item.date);

                let assemblyExpend = result1.data.assemblyExpend.map(
                    item => item.categoryCount
                );
                let directExpend = result1.data.directExpend.map(item => item.categoryCount);
                let disassemblyExpend = result1.data.disassemblyExpend.map(
                    item => item.categoryCount
                );
                let purReturnExpend = result1.data.purReturnExpend.map(
                    item => item.categoryCount
                );
                let receiveExpend = result1.data.receiveExpend.map(item => item.categoryCount);
                let replenishmentExpend = result1.data.replenishmentExpend.map(
                    item => item.categoryCount
                );
                let saleExpend = result1.data.saleExpend.map(item => item.categoryCount);
                let subcontractExpend = result1.data.subcontractExpend.map(
                    item => item.categoryCount
                );
                let transferExpend = result1.data.transferExpend.map(
                    item => item.categoryCount
                );
                this.dataEchart1 = this.$echarts.init(this.$refs.echartsRef1)
                this.dataEchart1.setOption(getEchart1(time, assemblyExpend, directExpend, disassemblyExpend, purReturnExpend, receiveExpend, replenishmentExpend, saleExpend, subcontractExpend, transferExpend))

                // 饼状图
                let res = result2.data.outstock.map(item => {
                    let top = {};
                    top.value = item.count;
                    top.name = item.category;
                    return top;
                });
                let strList = result2.data.outstock.map(item => item.category);
                let numList = result2.data.outstock.map(item => item.count);
                this.dataEchart2 = this.$echarts.init(this.$refs.echartsRef2)
                this.dataEchart2.setOption(getEchart2(strList, numList))
                this.dataEchart3 = this.$echarts.init(this.$refs.echartsRef3)
                this.dataEchart3.setOption(getEchart3(res))
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart1)
                if (this.dataEchart1) {
                    this.dataEchart1.clear();
                    this.dataEchart1.dispose();
                    this.dataEchart1 = null;
                }
                window.removeEventListener('resize', this.dataEchart2)
                if (this.dataEchart2) {
                    this.dataEchart2.clear();
                    this.dataEchart2.dispose();
                    this.dataEchart2 = null;
                }
                window.removeEventListener('resize', this.dataEchart3)
                if (this.dataEchart3) {
                    this.dataEchart3.clear();
                    this.dataEchart3.dispose();
                    this.dataEchart3 = null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
  .table {
    margin-top: 60px;

    .table-header {
      padding: 10px 2px 5px 2px;

      span {
        display: inline-block;
        color: #b5b5b5;
        font-size: 22px;
      }

      .se-tr {
        width: 20%;
      }
    }

    .table-body {
      padding: 10px 10px 5px 10px;
      font-size: 26px;

      .itemDiv {
        width: 100%;
        margin-top: 30px;

        .itemCol {
          width: 100%;

          .se-tr {
            width: 20%;
          }

          .rightData {
            padding-left: 5px;
          }
        }
      }


    }
  }
</style>
