import * as echarts from "echarts";

export const getEchart = (strList, numList,maxNum) => {
  return {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      data: strList
    },
    series: [
      {
        name: '商机阶段',
        type: 'funnel',
        left: '5%',
        top: 60,
        bottom: 0,
        width: '90%',
        min: 0,
        max: maxNum,
        minSize: '0%',
        maxSize: '100%',
        sort: 'descending',
        gap: 5,
        label: {
          show: true,
          position: 'inside'
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: 'solid'
          }
        },
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1
        },
        emphasis: {
          label: {
            fontSize: 20
          }
        },
        data: numList
      }
    ]
  };
};
