import request from '@/utils/request'

// 查询公司列表
export function findAllCompany(query) {
  return request({
    url: '/system/company/findAllCompany',
    method: 'get',
    params: query
  })
}

// 根据公司id查询公司
export function findCompanyById(query){
  return request({
    url: '/system/company/findById',
    method: 'get',
    params: query
  })
}

// 修改公司
export function updateCompany(data) {
  return request({
    url: '/system/company',
    method: 'put',
    data: data
  })
}

// 修改超管角色
export function editSuperRole(data) {
  return request({
    url: '/system/role/editSuperRole',
    method: 'put',
    data: data
  })
}

//查询本公司是否有某权限
export function findPermByCompany(data) {
  return request({
    url: '/system/company/findPermByCompany',
    method: 'post',
    data
  })
}
