import request from '@/utils/request'

// 查询销售退款列表
export function getAllReturnList(query){
  return request({
    url: "/cw/return/getList",
    method: "get",
    params: query
  });
}

// 查询退款计划详情
export function getAllReturnInfo(query){
  return request({
    url: "/cw/return/getId",
    method: "get",
    params: query
  });
}

// 新增退款子计划
export function addReturnSub(data){
  return request({
    url: "/cw/return/addReturnSub",
    method: "post",
    data: data
  });
}

// 删除退款子计划
export function deleteReturnSub(cwReturnSubId){
  return request({
    url: "/cw/return/deleteReturnSub",
    method: "delete",
    params: cwReturnSubId
  });
}
