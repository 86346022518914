<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="'新增团队成员'"> </navBar>
      <div class="add-form">
        <div class="line"></div>
        <van-form validate-first @failed="onFailed">
          <van-field
            v-model="formInline.name"
            label="客户名称"
            name="pattern"
            placeholder="请输入"
            :rules="[{ required: true, message: '请输入正确内容' }]"
          />
          <van-field
            readonly
            clickable
            name="picker"
            v-model="formInline.source"
            label="新增成员"
            placeholder="请选择"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>

          <van-button class="btn" block type="info" native-type="submit">提交</van-button>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import { areaList } from '@vant/area-data';
export default {
  data() {
    return {
      formInline: {
        name: '',
        sourse: null,
        email: null,
        phone: null,
        level: null,
        industry: null,
        area: null,
        address: null,
        time: null,
        remark: null,
      },
      pattern: /\d{6}/,
      showPicker: false,
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      customerList: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      customerIndustry: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      areaList,
      showArea: false,
      showCalendar: false,
    };
  },

  methods: {
    handleGoBack() {
      this.$router.go('-1');
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },

    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      return /1\d{10}/.test(val);
    },
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        Toast.loading('验证中...');

        setTimeout(() => {
          Toast.clear();
          resolve(/\d{6}/.test(val));
        }, 1000);
      });
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}
// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}
::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}
// 导航样式 --end
</style>
