import request from '@/utils/request'

// 查询列表
export function selectPage(query) {
  return request({
    url: "/purchase/return/getList",
    method: "get",
    params: query
  });
}

// 查询明细
export function selectOne(id) {
  return request({
    url: '/purchase/return/'+id,
    method: "get"
  });
}

// 获取订单产品
export function getOrderDetails(id) {
  return request({
    url: '/purchase/return/getOrderDetails/'+id,
    method: "get"
  });
}

// 新增
export function addData(data) {
  return request({
    url: "/purchase/return",
    method: "post",
    data: data
  });
}

// 修改
export function updateData(data) {
  return request({
    url: "/purchase/return",
    method: "put",
    data: data
  });
}

// 提交
export function submitData(id) {
  return request({
    url: "/purchase/return/submitReturn/"+id,
    method: "put"
  });
}

// 审批
export function approvalData(data) {
  return request({
    url: "/purchase/return/approve",
    method: "put",
    data: data
  });
}

// 删除
export function delData(id) {
  return request({
    url: "/purchase/return/"+id,
    method: "delete"
  });
}



