export const getEchart1 = (res) => {
    return {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'horizontal',
            bottom: 'bottom',
            left: 'center',
            itemWidth: 16,
            itemHeight: 6,
            textStyle: {
                color: '#333'
            }
        },
        color: [
            '#fb7c54',
            '#fbcc34',
            '#83bb74',
            '#64b384',
            'rgba(168, 190, 178, 1)',
            'rgba(84, 116, 195, 1)',
            'rgba(116, 196, 219, 1)'
        ], //颜色
        series: [
            {
                type: 'pie',
                radius: '50%',
                data: res,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
};

export const getEchart2 = (strList, numList) => {
    return {
        backgroundColor: '#fff',
        barWidth: 10,
        grid: {
            top: '10%',
            bottom: '10%',
            left: '6',
            right: '15%',
            containLabel: true
        },
        // grid: {
        //   top: "10%",
        //   left: "10%",
        //   width: "85%",
        //   height: "65%",
        //   containLabel: false
        // },
        xAxis: {
            type: 'value',
            name: '数量',
            nameTextStyle: { color: '#666' },
            splitLine: {
                lineStyle: {
                    color: '#ddd',
                    type: 'dashed'
                }
            },
            axisTick: {
                show: false
            },
            axisLine: {
                //  改变y轴颜色
                lineStyle: {
                    color: '#eee'
                }
            },
            axisLabel: {
                //  改变x轴字体颜色和大小
                textStyle: {
                    color: '#333',
                    fontSize: 14
                }
            }
        },
        yAxis: {
            type: 'category',
            data: strList,
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                //  改变y轴颜色
                lineStyle: {
                    color: '#eee'
                }
            },
            axisLabel: {
                interval: 0,
                formatter: function(value) {
                    if (value.length > 10) {
                        return `${value.slice(0, 10)}`
                    }
                    return value
                },
                margin: 15,
                //  改变y轴字体颜色和大小
                //formatter: '{value} m³ ', //  给y轴添加单位
                textStyle: {
                    align: 'right',
                    color: '#333',
                    fontSize: 14
                }
            }
        },
        series: [
            {
                type: 'bar',
                name: '产出',
                barWidth: 20,
                data: numList
            }
        ]
    }
};

export const getEchart3 = (time,directEnter,productQuality,productReturn,productScrap,purchase,purchaseQuality,saleReturn) => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                lineStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'rgba(126,199,255,0)' // 0% 处的颜色
                            },
                            {
                                offset: 0.5,
                                color: 'rgba(126,199,255,1)' // 100% 处的颜色
                            },
                            {
                                offset: 1,
                                color: 'rgba(126,199,255,0)' // 100% 处的颜色
                            }
                        ],
                        global: false // 缺省为 false
                    }
                }
            }
        },
        legend: {
            type: 'plain',
            icon: 'rect',
            data: [
                '直接入库',
                '生产质检',
                '生产返料',
                '生产质料',
                '采购入库',
                '采购质检',
                '销售退货入库'
            ],
            bottom: 'bottom'
        },
        grid: {
            left: '3%',
            right: '15%',
            bottom: '20%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            name: '数量',
            nameTextStyle: {
                color: '#666',
                padding: [0, 0, 0, 0],
                fontSize: 14
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#eee'
                }
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#333',
                    fontSize: 14
                }
            }
        },
        yAxis: {
            type: 'category',
            name: '时间',
            nameTextStyle: {
                color: '#666',
                padding: [0, 0, 0, 0],
                fontSize: 14
            },
            boundaryGap: false,
            data: time,
            axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                    color: '#eee'
                }
            },
            axisLabel: {
                //坐标轴刻度标签的相关设置
                textStyle: {
                    color: '#333',
                    // padding: 16,
                    fontSize: 14
                }
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                }
            }
        },
        series: [
            {
                name: '直接入库',
                type: 'line',
                stack: 'Total',
                data: directEnter
            },
            {
                name: '生产质检',
                type: 'line',
                stack: 'Total',
                data: productQuality
            },
            {
                name: '生产返料',
                type: 'line',
                stack: 'Total',
                data: productReturn
            },
            {
                name: '生产质料',
                type: 'line',
                stack: 'Total',
                data: productScrap
            },
            {
                name: '采购入库',
                type: 'line',
                stack: 'Total',
                data: purchase
            },
            {
                name: '采购质检',
                type: 'line',
                stack: 'Total',
                data: purchaseQuality
            },
            {
                name: '销售退货入库',
                type: 'line',
                stack: 'Total',
                data: saleReturn
            }
        ],
        color: [
            'rgba(84, 116, 195, 1)',
            'rgba(145, 204, 117, 1)',
            'rgba(250, 200, 88, 1)',
            'rgba(238, 102, 102, 1)',
            'rgba(115, 192, 222, 1)',
            'rgba(59, 162, 114, 1)',
            'rgba(252, 132, 82, 1)'
        ] //颜色
    }
};
