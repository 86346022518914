<template>
    <div class="app-container">
            <navBar :title="'消息通知'" @handleGoBack="handleGoBack">
            </navBar>

        <van-collapse v-model="activeNames">
            <van-collapse-item>
                <div class="title" slot="title">
                    <van-icon name="bulb-o" size="35px" style="float: left"/>
                    <div style="font-size: 16px;float: left;margin-top: 13px;margin-left: 5px">消息通知</div>
                    <van-badge v-if="informlist && informlist.length > 0" :content="informlist.length"  style="float: left;margin-top: 7px;margin-left: 7px">
                    </van-badge>
                </div>
                <van-list
                        v-model="loadingInform"
                        :finished="true"
                        finished-text="没有更多了"
                >
                    <van-cell v-for="item in informlist" :key="item.id">
                        <div slot="title" class="cell-div" @click="navTo(item,'inform')">
                            <van-badge dot v-if="!item.isRead">
                            </van-badge>
                            <span :style="{color: item.isRead?'':'rgb(251,196,114)', fontSize: '14px',  float: 'left'}">{{item.title}}</span>
                            <div style="clear: both"></div>
                            <div style="font-size: 14px;margin-top: 10px">
                                发送者：{{item.createBy}} | 时间：{{parseTime(item.createTime,'{y}-{m}-{d} {h}:{i}')}}
                            </div>
                            <div style="height: 1px;background-color: #c0c4cb;margin-top: 20px"></div>
                        </div>
                    </van-cell>
                </van-list>
            </van-collapse-item>
            <van-collapse-item>
                <div class="title" slot="title">
                    <van-icon name="volume-o" size="30px" style="float: left;margin-left: 3px"/>
                    <div style="font-size: 16px;float: left;margin-top: 13px;margin-left: 5px">公告列表</div>
                    <van-badge v-if="noticelist && noticelist.length > 0" :content="noticelist.length"  style="float: left;margin-top: 7px;margin-left: 7px">
                    </van-badge>
                </div>
                <van-list
                        v-model="loadingNotice"
                        :finished="true"
                        finished-text="没有更多了"
                >
                    <van-cell v-for="item in noticelist" :key="item.id">
                        <div slot="title" class="cell-div" @click="navTo(item)">
                            <van-badge dot v-if="!item.isRead">
                            </van-badge>
                            <span :style="{color: item.isRead?'':'rgb(251,196,114)', fontSize: '14px',  float: 'left'}">{{item.title}}</span>
                            <div style="clear: both"></div>
                            <div style="font-size: 14px;margin-top: 10px">
                                发送者：{{item.createBy}} | 时间：{{parseTime(item.createTime,'{y}-{m}-{d} {h}:{i}')}}
                            </div>
                            <div style="height: 1px;background-color: #c0c4cb;margin-top: 20px"></div>
                        </div>
                    </van-cell>
                </van-list>
            </van-collapse-item>
        </van-collapse>

<!--        <scroll-div class="scroll-list" scroll-y="true">
            <u-collapse class="box" :accordion="false" :arrow="false">
                <div class="item">
                    <u-collapse-item :open="true">
                        <div class="title" slot="title">
                            <u-icon name="bell" :size="35"></u-icon>
                            <div class="text">消息通知</div>
                            <u-badge v-if="informlist && informlist.length > 0" :value="informlist.length"></u-badge>
                        </div>
                        <u-cell-group class="list" :border="false">
                            <u-cell :arrow="true" v-for="item in informlist" :key="item.id"
                                    @click="navTo('/pages/sys/msg/form',item,'inform')">
                                <div slot="title" style="display: flex; margin-bottom: 20px;">
                                    <u-badge :isDot="true" style="margin-top: 3px;margin-right: 10px;margin-left: -20px;"
                                             v-if="!item.isRead"></u-badge>
                                    <u&#45;&#45;text :type="item.isRead?'info':'warning'" :text="item.title"></u&#45;&#45;text>
                                </div>
                                <text slot="label">发送者：{{item.createBy}} | 时间：{{parseTime(item.createTime,'{y}-{m}-{d} {h}:{i}')}}</text>
                            </u-cell>
                        </u-cell-group>
                    </u-collapse-item>
                </div>

                <div class="item">
                    <u-collapse-item :open="true">
                        <div class="title" slot="title">
                            <u-icon name="volume" :size="35"></u-icon>
                            <div class="text">公告列表</div>
                            <u-badge v-if="noticelist && noticelist.length > 0" :value="noticelist.length"></u-badge>
                        </div>
                        <u-cell-group class="list" :border="false">
                            <u-cell :arrow="true" v-for="(item,index) in noticelist" :key="index"
                                    @click="navTo('/pages/sys/msg/form',item)">
                                <div slot="title" style="display: flex; margin-bottom: 20px;">
                                    <u-badge :isDot="true" style="margin-top: 3px;margin-right: 10px;margin-left: -20px;"
                                             v-if="!item.isRead"></u-badge>
                                    <u&#45;&#45;text :type="item.isRead?'info':'warning'" :text="item.title"></u&#45;&#45;text>
                                </div>
                                <text slot="label">时间：{{parseTime(item.createTime,'{y}-{m}-{d} {h}:{i}')}}</text>
                            </u-cell>
                        </u-cell-group>
                    </u-collapse-item>
                </div>
            </u-collapse>
        </scroll-div>-->
    </div>
</template>
<script>
    import {parseTime} from "@/utils/ruoyi";
    import {read} from "@/api/message";
    import {getInformList, getNoticeList} from "@/api/system/message";

    export default {
        data() {
            return {
                activeNames: [],
                keywords: '',
                //消息列表
                informlist: [],
                //通知列表
                noticelist: [],
                loadingInform: false,
                loadingNotice: false,
            };
        },
        created() {
            //查询通知列表
            this.findInformlist()
            //查询公告列表
            this.findNoticeList()
        },
        methods: {
            handleGoBack() {
                this.$router.replace(localStorage.getItem('indexPath'));
                this.$router.go(-1)
            },

            //转换时间
            parseTime(time, pattern){
                return parseTime(time, pattern)
            },

            //跳转
            navTo(item, type) {
                //已读
                if(item.id && type == 'inform'){
                    read(item.id).then(res=>{
                        if(res.code == 200){
                            this.findInformlist()
                        }
                    })
                }

                // 进入详情页面
                this.$router.push({
                    path: '/staticSystem/msg/detail',
                    query: {
                        content: JSON.stringify(item),
                    },
                });
            },

            //通知列表
            async findInformlist() {
                try {
                    const {
                        data
                    } = await getInformList()
                    this.informlist = data

                    //未读计数
                    this.totalUnreadCount()
                } catch (err) {}
            },

            //查询公告列表
            async findNoticeList() {
                const {
                    data
                } = await getNoticeList()
                this.noticelist = data

                //未读计数
                this.totalUnreadCount()
            },

            //统计未读的数量
            totalUnreadCount() {
                this.unReadCount = 0
                this.informlist.forEach(item => {
                    if (!item.isRead) {
                        this.unReadCount++
                    }
                })
                this.noticelist.forEach(item => {
                    if (!item.isRead) {
                        this.unReadCount++
                    }
                })
            }

        }
    };
</script>
<style lang="scss" scoped>
  page {
    background-color: #f8f8f8;
  }
  .title {
    ::v-deep .van-badge{
      font-size: 26px;
      padding: 2px 20px 2px 20px
    }
  }
  .cell-div{
    margin-bottom: 20px;

    ::v-deep .van-badge{
      font-size: 26px;
      padding: 5px 5px 5px 5px;
      margin-top: 3px;
      margin-right: 10px;
      float: left;
    }
  }
</style>
