import request from "@/utils/request";
import { serverUrl } from "@/utils/request";

export const baseURL = serverUrl;
// 分页查询
export function queryassets(query) {
  return request({
    url: `/oa/assets/getList`,
    method: "GET",
    params: query
  });
}
// 获取资产管理树
export function querygetTree() {
  return request({
    url: `/oa/assets/getTree`,
    method: "GET"
  });
}
// 新增资产类型
export function addAssetsType(assets) {
  return request({
    url: `/oa/assets/addAssetsType`,
    method: "POST",
    data: assets
  });
}
// 修改资产类型
export function updateAssetsType(assets) {
  return request({
    url: `/oa/assets/updateAssetsType`,
    method: "PUT",
    data: assets
  });
}
// 删除资产类型
export function deleteAssetsType(assetsTypeId) {
  return request({
    url: `/oa/assets/deleteAssetsType`,
    method: "DELETE",
    params: { assetsTypeId }
  });
}
// 新增资产
export function addAssets(assets) {
  return request({
    url: `/oa/assets/addAssets`,
    method: "POST",
    data: assets
  });
}
// 修改资产
export function updateAssets(assets) {
  return request({
    url: `/oa/assets/updateAssets`,
    method: "PUT",
    data: assets
  });
}
// 根据id获取
export function getIdassets(assetsId) {
  return request({
    url: `/oa/assets/getId`,
    method: "GET",
    params: { assetsId }
  });
}
// 删除
export function deleteAssets(assetsId) {
  return request({
    url: `/oa/assets/deleteAssets`,
    method: "DELETE",
    params: { assetsId }
  });
}
// 根据 业务类型和业务id 查询操作操作记录
export function operRecord(business) {
  return request({
    url: `/system/operRecord`,
    method: "GET",
    params: business
  });
}