<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.enterTitle"
                            label="入库标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入入库标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.whName"
                            label="入库仓库"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择入库仓库' }]"
                            @click="showPickerWarehouse= true"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.warehouseTubeStr"
                            label="负责人"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择负责人' }]"
                            @click="showPop= true"
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.applyTime"
                            label="申请日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择申请日期' }]"
                            @click="showPickerApply = true"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.supplierName"
                            label="供应商"
                            placeholder="请选择"
                            @click="showPickerSupplier= true"
                    />

                    <van-field
                            v-model="formInline.enterPrice"
                            label="入库总价"
                            placeholder="请输入"
                            type="number"
                    />

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
                            <van-icon name="delete-o" @click="handleDelete(index)"/>
                        </div>

                        <van-field
                                v-model="item.materialSpecification"
                                label="规格型号"
                                disabled
                        />

                        <van-field
                                v-model="item.materialUnit"
                                label="单位"
                                disabled
                        />

                        <van-field
                                v-model="item.price"
                                label="售价"
                                disabled
                        />

                        <van-field
                                v-model="item.detailPrice"
                                label="入库价"
                                type="number"
                                placeholder="请输入"
                                :required="true"
                                :rules="[{ required: true, message: '请输入入库价' }]"
                                @input="itemTotalPriceCal(item)"
                        />

                        <van-field
                                v-model="item.enterQuantity"
                                label="申请数量"
                                type="number"
                                placeholder="请输入"
                                :required="true"
                                :rules="[{ required: true, message: '请输入申请数量' }]"
                                @input="itemTotalPriceCal(item)"
                        />

                        <van-field
                                v-model="item.itemTotalPrice"
                                label="总金额"
                                placeholder="请输入"
                                disabled
                        />

                        <van-field
                                v-model="item.bathNumber"
                                label="批号"
                                placeholder="请输入"
                        />

                        <van-field
                                readonly
                                clickable
                                v-model="item.arrivalDate"
                                label="到货日期"
                                placeholder="点击选择日期"
                                @click="handlePop('showPickerArrival',index)"
                        />
                        <van-calendar :min-date="minDate" :max-date="maxDate" v-model="showPickerArrival"
                                      @confirm="onConfirmApply($event,'arrivalDate','showPickerArrival')"/>

                        <van-field
                                readonly
                                clickable
                                v-model="item.productDate"
                                label="生产日期"
                                placeholder="点击选择日期"
                                @click="handlePop('showPickerProduct',index)"
                        />
                        <van-calendar :min-date="minDate" :max-date="maxDate" v-model="showPickerProduct"
                                      @confirm="onConfirmApply($event,'productDate','showPickerProduct')"/>

                        <van-field
                                v-model="item.qualityGuaranteePeriod"
                                type="digit"
                                label="保质期(天)"
                                placeholder="请输入"
                                @input="calValidDate(item)"
                        />

                        <van-field
                                readonly
                                clickable
                                v-model="item.validDate"
                                label="有效日期"
                                placeholder="点击选择日期"
                                @click="handlePop('showPickerValid',index)"
                        />
                        <van-calendar :min-date="minDate" :max-date="maxDate" v-model="showPickerValid"
                                      @confirm="onConfirmApply($event,'validDate','showPickerValid')"/>

                        <van-field
                                v-model="item.expirationAlarm"
                                type="digit"
                                label="过期前几天提醒"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.remark"
                                label="备注"
                                placeholder="请输入"
                        />

                    </div>
                    <div class="add-btn flex-hor-ver-cen" @click="handleAdd">
                        <van-icon name="add-o" class="mrg-r-20"/>
                        添加物料
                    </div>

                    <AppendixVue :businessType="'instock'" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader" :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <van-popup v-model="showPickerApply" style="width: 70%">
            <van-datetime-picker
                    v-model="currentDate"
                    type="datetime"
                    title="请选择时间"
                    @confirm="onConfirmTime"
                    @cancel="showPickerApply = false"
            />
        </van-popup>

        <WarehouseTree :showPop="showPickerWarehouse" @handleClose="handleCloseWarehouse"/>

        <Supplier :showDialog="showPickerSupplier" @handleClose="handleCloseSupplier" @submitData="submitSupplier"/>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>

        <Material :showDialog="showPickerMaterial" @handleClose="handleCloseMaterial" @submitData="submitMaterial"/>
    </div>
</template>
<script>
    import {addInStock, fetchWaitInDetail, updateInStock} from '@/api/inventory/directIn';
    import AppendixVue from '@/views/components/crm/Appendix.vue';
    import WarehouseTree from '@/components/warehouseTree'
    import Supplier from '@/components/supplier/index'
    import SelectOneUser from '@/components/selectOneUser/index'
    import Material from "@/components/material/index";
    import {changeToCurrentDay, formatDate, getCurrentMonthDay5} from "@/utils/ruoyi";
    import {submiteUpload} from "@/api/system/config";

    export default {
        components: {Material, WarehouseTree, AppendixVue, Supplier, SelectOneUser},
        data() {
            return {
                formInline: {
                    enterCategory: 0
                },
                currentDate: new Date(),
                title: '新建入库',
                id: this.$route.query.id,
                type: this.$route.query.type,
                uploader: [],
                uploadParams: {
                    businessType: 'instock',
                    businessId: undefined,
                    file: null,
                },
                showPickerWarehouse: false,
                showPickerSupplier: false,
                showPickerMaterial: false,
                showPickerArrival: false,
                showPickerProduct: false,
                showPickerValid: false,
                showPop: false,
                showPickerApply: false,
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(2050, 12, 31),
                //物料数组
                dataList: [],
                //选中的明细索引
                itemIndex: null
            };
        },

        mounted() {
            //页面获取焦点后，定位到页面最顶部
            scrollTo(0,0)
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                // this.$router.replace('/inventory/instock');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        enterCategory: 0
                    };
                    this.title = '新建入库';
                } else {
                    let itemDetail = await fetchWaitInDetail({id: this.id});
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    this.dataList = itemDetail.materialList
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑入库';
                    } else {
                        this.title = '入库详情';
                    }
                }
            },

            //关闭仓库弹窗
            handleCloseWarehouse(resultObj) {
                if (resultObj) {
                    this.formInline.whId = resultObj.id
                    this.formInline.whName = resultObj.name
                }
                this.showPickerWarehouse = false
            },

            //日期提交
            onConfirmTime(value) {
                this.formInline.applyTime = changeToCurrentDay(value);
                this.showPickerApply = false;
            },

            //关闭供应商弹窗
            handleCloseSupplier() {
                this.showPickerSupplier = false
            },

            //供应商提交
            submitSupplier(id, name) {
                this.formInline.supplierId = id
                this.formInline.supplierName = name
                this.showPickerSupplier = false
            },

            //选择人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.warehouseTube = activeId
                this.formInline.warehouseTubeStr = names
            },

            //关闭选择人
            handleCloseUser() {
                this.showPop = false
            },

            //日期确认
            onConfirmApply(value, attr, showPicker) {
                this.dataList[this.itemIndex][attr] = formatDate(value, 'yyyy-MM-dd')
                this[showPicker] = false
                this.$forceUpdate()

                if (attr == 'productDate' || attr == 'validDate') {
                    //输入有效期计算保质期
                    this.calQualityGuaranteePeriod(this.dataList[this.itemIndex])
                }
            },

            //输入保质期计算有效期
            calValidDate(row) {
                if (row.productDate && row.qualityGuaranteePeriod) {
                    let dateObj = new Date(row.productDate)
                    row.validDate = formatDate(new Date(dateObj.setDate(dateObj.getDate() + Number(row.qualityGuaranteePeriod))), 'yyyy-MM-dd')
                }
            },

            //输入有效期计算保质期
            calQualityGuaranteePeriod(row) {
                if (row.productDate && row.validDate) {
                    row.qualityGuaranteePeriod = parseInt((new Date(row.validDate).getTime() - new Date(row.productDate).getTime()) / (1000 * 3600 * 24))
                }
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            //触发明细弹窗
            handlePop(showPicker, index) {
                this[showPicker] = true;
                this.itemIndex = index
            },

            //删除节点
            handleDelete(index) {
                this.dataList.splice(index, 1);
            },

            //添加节点
            handleAdd() {
                this.showPickerMaterial = true;
            },

            //关闭物料弹窗
            handleCloseMaterial() {
                this.showPickerMaterial = false
            },

            //物料提交
            submitMaterial(obj) {
                let oldMaterial = this.dataList.find(item => item.materialId == obj.id)
                if (!oldMaterial) {
                    this.dataList.push({
                        materialId: obj.id,
                        materialName: obj.materialName,
                        materialCode: obj.materialCode,
                        materialSpecification: obj.materialSpecification,
                        materialUnit: obj.materialUnit,
                        price: obj.costPrice,
                        detailPrice: obj.costPrice,
                        enterQuantity: 0,
                        bathNumber: getCurrentMonthDay5(),
                        arrivalDate: '',
                        remark: ''
                    });
                }

                this.handleCloseMaterial()
            },

            //物料单条明细总金额计算
            itemTotalPriceCal(item) {
                item.itemTotalPrice = ((item.enterQuantity ? item.enterQuantity : 0) * (item.detailPrice ? item.detailPrice : 0)).toFixed(2)
                this.enterPriceCal();
            },

            //入库总价计算
            enterPriceCal() {
                let totalPrice = 0
                let totalQuantity = 0
                this.dataList.forEach(item => {
                    totalPrice += Number(item.itemTotalPrice)
                    totalQuantity += Number(item.enterQuantity)
                })
                this.formInline.enterPrice = totalPrice
                this.formInline.totalMoney = totalPrice
                this.formInline.totalQuantity = totalQuantity
            },

            async onSubmit() {
                if (this.dataList.length == 0) {
                    this.$toast.fail('请添加入库物料');
                    return;
                }

                let flag = true
                this.dataList.forEach(item => {
                    delete item.createBy
                    delete item.createTime
                    delete item.updateBy
                    delete item.updateTime
                    delete item.companyId
                    if (!item.enterQuantity) {
                        this.$toast.fail('请先填写申请入库数量！')
                        flag = false
                        return;
                    }
                })

                if(!flag){
                    return
                }

                this.formInline.materialJson = JSON.stringify(this.dataList)
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateInStock(data) : await addInStock(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    if (this.uploadParams.file) {
                        this.uploadParams.businessId = result.data
                        setTimeout(async () => {
                            await submiteUpload(this.uploadParams);
                        }, 1000);
                    }
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file = file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
