<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="收票名称"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入收票名称' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.typeStr"
                            label="收票类型"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择收票类型' }]"
                            @click="showPickerType= true"
                    />
                    <van-popup v-model="showPickerType" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="invoiceTypeList"
                                value-key="dictLabel"
                                @confirm="
                onConfirmType($event, 'type', 'typeStr', 'dictLabel', 'showPickerType')
              "
                                @cancel="showPickerType = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.supplier"
                            label="供应商"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择供应商' }]"
                            @click="showPickerSupplier = true"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.order"
                            label="采购订单"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择采购订单' }]"
                            @click="showPickerOrder= true"
                    />
                    <van-popup v-model="showPickerOrder" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="orderList"
                                value-key="title"
                                @confirm="
                onConfirmOrder($event, 'orderId', 'order', 'title', 'showPickerOrder')
              "
                                @cancel="showPickerOrder = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.price"
                            label="收票金额"
                            type="number"
                            :required="true"
                            :rules="[{ required: true, message: '请填写收票金额' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.leaderName"
                            label="负责人"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择负责人' }]"
                            @click="showPop= true"
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.invoiceDate"
                            label="收票日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择收票日期' }]"
                            @click="showPickerApply = true"
                    />
                    <van-calendar :min-date="minDate" v-model="showPickerApply"
                                  @confirm="onConfirmApply($event)"/>

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.crmInvoiceHead.headName"
                            label="发票抬头"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择发票抬头' }]"
                            @click="showPickerHead= true"
                    />
                    <van-popup v-model="showPickerHead" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="invoiceHeadList"
                                value-key="name"
                                @confirm="
                onConfirmHead($event, 'headId', 'headName', 'name', 'showPickerHead')
              "
                                @cancel="showPickerHead = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.crmInvoiceHead.typeStr"
                            label="抬头类型"
                            disabled
                    />

                    <van-field
                            v-model="formInline.crmInvoiceHead.number"
                            label="纳税人识别号"
                            disabled
                    />

                    <van-field
                            v-model="formInline.crmInvoiceHead.bank"
                            label="开户行"
                            disabled
                    />

                    <van-field
                            v-model="formInline.crmInvoiceHead.accountName"
                            label="开户账号"
                            disabled
                    />

                    <van-field
                            v-model="formInline.crmInvoiceHead.accountAddress"
                            label="开票地址"
                            disabled
                    />

                    <van-field
                            v-model="formInline.crmInvoiceHead.phone"
                            label="电话"
                            disabled
                    />

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>

        <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>

        <Supplier :showDialog="showPickerSupplier" @handleClose="showPickerSupplier = false"
                  @submitData="submitSupplier"/>
    </div>
</template>
<script>
    import {addCrmInvoice, getAllInvoiceHeadInfo, updateCrmInvoice} from '@/api/cw/purInvoiceHead/purInvoiceHead';
    import SelectOneUser from '@/components/selectOneUser/index'
    import Supplier from '@/components/supplier/index'
    import {formatDate} from "@/utils/ruoyi";
    import {getInvoiceHeadList} from "@/api/cw/invoiceHead/invoiceHead";
    import {selectList} from "@/api/purchase/order";

    export default {
        components: {SelectOneUser, Supplier},
        data() {
            return {
                formInline: {
                    crmInvoiceHead: {}
                },
                title: '新建发票',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerSupplier: false,
                showPickerOrder: false,
                showPickerType: false,
                showPickerHead: false,
                showPop: false,
                showPickerApply: false,
                minDate: new Date(2010, 0, 1),
                //订单数组
                orderList: [],
                //开票类型数组
                invoiceTypeList: [],
                //开票抬头数组
                invoiceHeadList: []
            };
        },

        mounted() {
            // 获取开票类型  invoice_type
            this.getDicts('invoice_type').then(response => {
                this.invoiceTypeList = response.data
            })
            // 获取发票抬头
            getInvoiceHeadList().then(response => {
                this.invoiceHeadList = response.data
            })
            // 查询所有采购订单
            selectList(0).then(response => {
                this.orderList = response.data;
            });
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        crmInvoiceHead: {}
                    };
                    this.title = '新建发票';
                } else {
                    let itemDetail = await getAllInvoiceHeadInfo({invoiceId: this.id});
                    itemDetail = itemDetail.data
                    itemDetail.crmInvoiceHead.headName = itemDetail.crmInvoiceHead.name
                    this.formInline = itemDetail;
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑发票';
                    } else {
                        this.title = '发票详情';
                    }
                }
            },

            //供应商提交
            submitSupplier(id, name) {
                this.formInline.supplierId = id
                this.formInline.supplier = name
                this.showPickerSupplier = false
                this.$forceUpdate()
            },

            //确认订单
            async onConfirmOrder(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];

                let obj = this.orderList.find(item => item.id = this.formInline.orderId)
                this.formInline.price = obj.purchasePrice

                this[visible] = false;
            },

            //选择人提交
            popSubmit(activeId, names) {
                this.showPop = false
                this.formInline.leaderId = activeId
                this.formInline.leaderName = names
            },

            //关闭选择人
            handleCloseUser() {
                this.showPop = false
            },

            //日期确认
            onConfirmApply(value) {
                this.formInline.invoiceDate = formatDate(value, 'yyyy-MM-dd');
                this.showPickerApply = false;
            },

            //开票类型确认
            onConfirmType(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //确认抬头
            async onConfirmHead(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];
                this.formInline['typeStr'] = event['typeStr']
                this.formInline['number'] = event['number']
                this.formInline['bank'] = event['bank']
                this.formInline['accountName'] = event['accountName']
                this.formInline['accountAddress'] = event['accountAddress']
                this.formInline['phone'] = event['phone']
                this[visible] = false;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            async onSubmit() {
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateCrmInvoice(data) : await addCrmInvoice(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
