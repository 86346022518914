import request from "@/utils/request";
export function get7Price() {
  return request({
    url: "/cw/work/get7Price",
    method: "get"
  });
}
export function getStatisticsAdd(query) {
  return request({
    url: "/cw/work/getStatisticsAdd",
    method: "get",
    params: query
  });
}
export function get7RAE() {
  return request({
    url: "/cw/work/get7RAE",
    method: "get"
  });
}
export function getEntryType(query) {
  return request({
    url: "/cw/work/getEntryType",
    method: "get",
    params: query
  });
}
export function getCollection(query) {
  return request({
    url: "/cw/work/getCollection",
    method: "get",
    params: query
  });
}
export function getxpenditureType(query) {
  return request({
    url: "/cw/work/getExpenditureType",
    method: "get",
    params: query
  });
}
