<template>
  <div class="follow-list">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="list"
    >
      <div class="item-list flex" v-for="item in dataList" :key="item.id">
        <van-circle
          v-model="currentRate"
          :rate="(item.stage+1)*25"
          layer-color="#ebedf0"
          :text="(item.stage+1)*25+'%'"
          class="avatar"
        />
        <div class="mrg-l-15">
          <div class="name-wrap flex">
            <div class="name">{{ item.name }}</div>
            <div class="post">{{ item.stageStr }}</div>
          </div>
          <div class="desc">商机金额：{{ item.price }}</div>
            <div class="desc">预计成交时间：{{ item.closingDate }}</div>
        </div>
      </div>
    </van-list>
    <div class="bg"></div>
<!--    <div class="btn" @click="handleAdd"><van-icon name="plus" class="mrg-r-10" /> 新增商机</div>-->
  </div>
</template>
<script>
import {fetchBusinessListByCustomrId} from "@/api/crm/customer";

export default {
  props: {
    pages: String,
  },

  data() {
    return {
      loading: false,
      finished: true,
      pathObj: {
        clue: '/crm/clue/addFollow',
      },
      currentRate: 0,
        dataList: []
    };
  },

    mounted() {
        this.getList();
    },
  methods: {
    onLoad() {},

      async getList() {
          const result = await fetchBusinessListByCustomrId({customerId: this.$route.query.id});
          const {data = []} = result;
          this.dataList = data;
      },

    handleAdd() {
      this.$router.push({
        path: this.pathObj[this.pages],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  box-sizing: border-box;
  background: #fff;
}
.item-list {
  padding: 25px 40px 15px;
  border-top: 20px solid #fafafa;

  .avatar {
    width: 70px;
    height: 70px;
    border-radius: 100%;
  }
  .name-wrap {
    .name {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
    }
    .post {
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      margin: 5px 0 0 10px;
    }
  }
  .desc {
    font-size: 20px;
    font-weight: 400;
    color: #999999;
    margin-top: 10px;
  }
}
.bg {
  margin-bottom: 140px;
  width: 100%;
  height: 90px;
  background: #fafafa;
}

.btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 110px;
  line-height: 110px;
  background: #fff;
  color: #0091ff;
  font-size: 30px;
  text-align: center;
}
</style>
