import request from '@/utils/request'

// 查询发票抬头列表
export function getAllInvoiceHeadList(query){
  return request({
    url: "/cw/invoiceHead/getList",
    method: "get",
    params: query
  });
}
// 新建
export function addInvoiceHead(data){
  return request({
    url: "/cw/invoiceHead/addInvoiceHead",
    method: "post",
    data: data
  });
}
// 修改
export function updateInvoiceHead(data){
  return request({
    url: "/cw/invoiceHead/updateInvoiceHead",
    method: "put",
    data: data
  });
}
// 删除
export function deleteInvoiceHead(invoiceHeadId){
  return request({
    url: "/cw/invoiceHead/deleteInvoiceHead",
    method: "delete",
    params: invoiceHeadId
  });
}
// 查询开票抬头详情
export function getInvoiceHeadInfo(query){
  return request({
    url: "/cw/invoiceHead/getId",
    method: "get",
    params: query
  });
}
// 查询全部抬头
export function getInvoiceHeadList(){
  return request({
    url: "/cw/invoiceHead/getHeadList",
    method: "get"
  });
}
