<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'物料实验'" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-icon name="plus" class="mrg-r-20" @click="handleAdd"
                              v-if="$auth.hasPermi('inventory:experiment:add')"/>
                </template>
            </navBar>

            <div class="common-wrap">
                <van-search
                        show-action
                        v-model="queryParams.title"
                        placeholder="请输入实验标题"
                        @search="handleQuery"
                >
                    <template #action>
                        <div class="search-btn-label" @click="handleQuery">搜索</div>
                    </template>
                </van-search>
            </div>

            <div class="main">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                            v-model="loading"
                            offset="100"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                    >
                        <div class="list" v-for="item in dataList" :key="item.id">
                            <div class="line"></div>
                            <div class="item-tab" @click="handleGoDetail(item.id)">
                                <div class="title-wrap flex-spa-ver-cen">
                                    <div class="tit over_hidden">
                                        {{ item.title || '--' }}
                                    </div>
                                </div>
                                <div class="con">
                                    <div class="desc-wrap flex">
                                        <div class="label">填报人：</div>
                                        <div class="value">{{ item.reportStr || '--' }}</div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">填报日期：</div>
                                        <div class="value">{{ item.reportDate || '--' }}</div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">实验人：</div>
                                        <div class="value red">{{ item.experimentStr || '--' }}</div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">实验日期：</div>
                                        <div class="value red">{{ item.experimentDate || '--' }}</div>
                                    </div>
                                    <div class="desc-wrap flex">
                                        <div class="label">仓库名称：</div>
                                        <div class="value red">{{ item.whName || '--' }}</div>
                                    </div>
                                </div>
                                <div class="time-wrap flex-spa-ver-cen" @click.stop="operateAction(item)">
                                    <div class="time">出库状态：{{ item.expendState || '--' }}</div>
                                    <van-icon name="weapp-nav"/>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
        <van-action-sheet
                v-model="actionShow"
                :actions="actions"
                @select="onSelect"
                cancel-text="取消"
                close-on-click-action
        />
    </div>
</template>

<script>
    import {applyExpend, delData, findPage} from '@/api/inventory/experiment'

    export default {
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                queryParams: {
                    pageNum: 1,
                    pageSize: 5
                },
                dataList: [],
                //操作的动作面板
                actionShow: false,
                //操作面板的操作
                actions: [
                    {name: '编辑', className: 'customer-opea-action'},
                    {name: '删除', className: 'customer-opea-action'}
                ],
                itemId: null,
            };
        },

        mounted() {
            this.getList()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/inventory/inventoryWorkbench');
                this.$router.go(-1)
            },

            async getList() {
                this.loading = true;
                let result = await findPage(this.queryParams)

                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.dataList = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.dataList = records;
                } else {
                    this.dataList = [...this.dataList, ...records];
                }
                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
            },

            onLoad() {
                if (this.refreshing) {
                    this.dataList = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.dataList.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },

            handleAdd() {
                // 新增页面
                this.$router.push({
                    path: '/inventory/experiment/add',
                    query: {
                        type: 'add'
                    },
                });
            },

            handleGoDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/inventory/experiment/add',
                    query: {
                        id,
                        type: 'detail'
                    },
                });
            },

            handleWrite() {
                // 进入详情页面
                this.$router.push({
                    path: '/inventory/experiment/add',
                    query: {
                        id: this.itemId,
                        type: 'writeResult'
                    },
                });
            },

            //点击操作展示动作面板
            operateAction(detail) {
                this.itemId = detail.id;

                if (detail.expendId == null && this.$auth.hasPermi('inventory:experiment:applyExpend')) {
                    let obj = this.actions.find(item => item.name == '申请出库')
                    if (!obj) {
                        this.actions.push(
                            {name: '申请出库', className: 'customer-opea-action'}
                        )
                    }
                } else {
                    this.actions = this.actions.filter(item => item.name != '申请出库')
                }

                if (detail.expendState != '未出库' && this.$auth.hasPermi('inventory:experiment:write')) {
                    let obj = this.actions.find(item => item.name == '填报结果')
                    if (!obj) {
                        this.actions.push(
                            {name: '填报结果', className: 'customer-opea-action'}
                        )
                    }
                } else {
                    this.actions = this.actions.filter(item => item.name != '填报结果')
                }

                this.actionShow = true;
            },

            //操作的动作面板被点击
            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                switch (item.name) {
                    case '编辑':
                        this.handleEdit();
                        break;
                    case '删除':
                        this.handleDelete();
                        break;
                    case '申请出库':
                        this.applyExpend()
                        break
                    case '填报结果':
                        this.handleWrite()
                        break
                }
            },

            // 编辑
            handleEdit() {
                let obj = this.dataList.find(item => item.id == this.itemId)

                if(obj.expendState == '已出库'){
                    this.$toast.fail('已出库的数据无法修改')
                    return;
                }
                // 新增页面
                this.$router.push({
                    path: '/inventory/experiment/add',
                    query: {
                        id: this.itemId,
                        type: 'edit'
                    },
                });
            },

            // 删除
            handleDelete() {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除实验?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await delData(this.itemId);
                        this.$toast.success('删除成功');
                        await that.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },

            // 申请出库
            applyExpend() {
                let that = this
                this.$dialog
                    .confirm({
                        title: '申请出库',
                        message: '是否确认申请出库?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await applyExpend(this.itemId);
                        this.$toast.success('操作成功');
                        await that.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

  .search {
    padding-top: 40px;
    background: #fff;
  }

  .my-tabs {
    padding-top: 30px;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        line-height: 38px;
        width: 0;
        flex: 1;
      }

      .level-btn {
        width: 80px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          white-space: nowrap;
          color: #999;
        }

        .value {
          color: #111;
          white-space: nowrap;
          overflow: hidden;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;
    }
  }

  ::v-deep .van-tabs__line {
    background-color: #0091ff;
  }

  .transfer-select-wrap {
    font-size: 28px;
  }

  ::v-deep .van-checkbox__label {
    line-height: 40px;
    font-size: 28px;
    padding-top: 10px;
  }

  ::v-deep .van-checkbox__icon .van-icon {
    width: 28px;
    height: 28px;
  }
</style>
