<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'报销申请'" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <div class="line"></div>
                <van-form @submit="onSubmit">
                    <van-field
                            v-model="formInline.deptName"
                            label="申请部门"
                            placeholder="请输入"
                            disabled
                    />

                    <van-field
                            v-model="formInline.name"
                            label="申请人"
                            name="contactWay"
                            placeholder="请输入"
                            disabled
                    />

                    <van-field
                            v-model="formInline.postName"
                            label="岗位"
                            name="contactWay"
                            placeholder="请输入"
                            disabled
                    />

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.flowName"
                            label="审批流程"
                            placeholder="请选择"
                            @click="showFlowPicker = true"
                            :rules="[{ required: true, message: '请选择审批流程' }]"
                    />
                    <van-popup v-model="showFlowPicker" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="flowList"
                                value-key="flowTitle"
                                @confirm="onConfirmFlow"
                                @cancel="showFlowPicker = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.time"
                            label="申请时间"
                            placeholder="请输入"
                            disabled
                    />

                    <van-field
                            readonly
                            clickable
                            name="customerId"
                            v-model="formInline.customerName"
                            label="关联客户"
                            placeholder="请选择"
                            @click="showCustomerDialog"
                    />

                    <van-field
                            v-model="formInline.input"
                            label="附单据(张)"
                            type="digit"
                            placeholder="请输入"
                    />

                    <div v-for="(item,index) in formInline.detailList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">报销单（{{ index + 1 }}）</div>
                            <van-icon name="delete-o" @click="handleDelete(index)"/>
                        </div>
                        <van-field
                                readonly
                                clickable
                                name="datetimePicker"
                                :value="item.time"
                                label="日期"
                                placeholder="点击选择日期"
                                @click="item.showDataTimePop = true"
                        />

                        <van-popup v-model="item.showDataTimePop" style="width: 70%">
                            <van-datetime-picker
                                    v-model="item.itemTime"
                                    type="datetime"
                                    title="请选择日期"
                                    @confirm="onConfirmTime(item)"
                                    @cancel="item.showDataTimePop = false"
                            />
                        </van-popup>

                        <van-field
                                v-model="item.project"
                                label="费用说明"
                                name="contactWay"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.category"
                                label="费用类别"
                                name="contactWay"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.quantity"
                                label="发票数量"
                                name="contactWay"
                                placeholder="请输入"
                        />

                        <van-field
                                v-model="item.money"
                                label="金额"
                                name="contactWay"
                                @input="calExpenseclaimval"
                                placeholder="请输入"
                        />

                        <textAreaInput
                                :title="'备注'"
                                :remark="item.itemRemark"
                                @change="handleContentChange(item)"
                        />
                    </div>

                    <div class="add-btn flex-hor-ver-cen" @click="handleAdd">
                        <van-icon name="add-o" class="mrg-r-20"/>
                        添加报销单
                    </div>

                    <van-field
                            v-model="formInline.moneytotal"
                            label="金额合计"
                            name="contactWay"
                            type="number"
                            disabled
                    />

                    <van-field
                            v-model="formInline.Uppercase"
                            label="金额大写"
                            disabled
                    />
                    <div class="line"></div>
                    <van-field name="uploader" label="附件">
                        <template #input>
                            <van-uploader v-model="fileList" multiple :max-count="2">
                                <div class="flex">
                                    <i class="iconfont icon-fujian1 mrg-r-10" style="font-size: 20px"></i>
                                    <span>点击上传</span>
                                </div>
                            </van-uploader>
                        </template>
                    </van-field>
                    <van-button class="btn" block type="info" native-type="submit">提交</van-button>
                </van-form>
            </div>
        </div>

        <CustomerPop
                :customerVisible="customerVisible"
                @submitCustomerList="submitCustomerList"
                @handleClose="handleClose"
        />
    </div>
</template>
<script>
    import {changeToCurrentDay, getCurrentMonthDay, number_chinese, parseTime} from '@/utils/ruoyi';
    import {submiteUpload} from '@/api/system/config';
    import {addApprove} from "@/api/approve/approve";
    import CustomerPop from "@/components/crm/CustomerPop";
    import {findFlowByUserId} from "@/api/approve/approveFlow";

    export default {
        components: {
            CustomerPop
        },

        data() {
            return {
                formInline: {
                    deptName: localStorage.getItem('deptName'),
                    postName: localStorage.getItem('postName'),
                    name: localStorage.getItem('realname'),
                    time: getCurrentMonthDay(),
                    detailList: []
                },
                customerVisible: false,
                showDataTimePop: false,
                showFlowPicker: false,
                fileList: [],
                flowList: [],
                userId: localStorage.getItem('userId'),
                typeName: '报销申请'
            };
        },

        created() {
            //查询流程
            this.findFlowList()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/approve/myApply');
                this.$router.go(-1)
            },

            //查询流程
            findFlowList() {
                findFlowByUserId({ typeName: this.typeName, userId: this.userId }).then(res => {
                    this.flowList = res.data
                    if (this.flowList && this.flowList.length == 1) {
                        this.formInline.flowId = this.flowList[0].id
                        this.formInline.flowName = this.flowList[0].flowTitle
                    }
                })
            },

            handleAdd() {
                this.formInline.detailList.push({
                    'itemTime': new Date(),
                    'showDataTimePop': false
                });
            },

            handleDelete(index) {
                this.formInline.detailList.splice(index, 1)
            },

            onConfirmTime(item) {
                item.time = parseTime(item.itemTime, '{y}-{m}-{d}');
                item.showDataTimePop = false;
            },

            //计算报销金额
            calExpenseclaimval() {
                let moneytotal = 0
                for (let i = 0; i < this.formInline.detailList.length; i++) {
                    let item = this.formInline.detailList[i]
                    if (item.money) {
                        moneytotal += Number(item.money)
                    }
                }
                this.formInline.moneytotal = moneytotal
                this.formInline.Uppercase = number_chinese(moneytotal)
            },

            //展示选择客户的弹窗
            showCustomerDialog() {
                this.customerVisible = true;
            },

            //客户提交
            submitCustomerList(id, name) {
                this.formInline.customerId = id;
                this.formInline.customerName = name;
                this.customerVisible = false
            },

            //关闭选择客户的弹窗
            handleClose() {
                this.customerVisible = false;
            },

            //审批流程
            onConfirmFlow(value) {
                this.formInline.flowName = value.flowTitle;
                this.formInline.flowId = value.id;
                this.showFlowPicker = false;
            },

            async onSubmit() {
                if (this.formInline.detailList.length == 0) {
                    this.$toast.fail('请添加报销明细');
                    return;
                }

                let form = {}
                form.approveTitle = this.formInline.name + '-报销申请'

                form.remark = JSON.stringify(this.formInline)
                form.route = '/approve/waitApprove'
                form.typeStr = this.typeName
                form.customerId = this.formInline.customerId
                form.flowId = this.formInline.flowId


                let result = await addApprove(form)
                let businessId = result.data;

                if (result.code == 200) {
                    if (this.fileList) {
                        this.fileList.forEach(item => {
                            let params = new FormData();
                            params.append('file', item.file);
                            params.append('businessType', 'sys_approve');
                            params.append('businessId', businessId);
                            submiteUpload(params);
                        })
                        this.fileList = []
                    }
                    this.$toast.success('添加成功');
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            handleContentChange(item) {
                item.remark = item.itemRemark;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end

  ::v-deep .van-radio__label {
    line-height: 100px;
  }

  ::v-deep .van-radio__icon .van-icon {
    width: none;
    height: none;
    line-height: none;
  }

  ::v-deep .van-radio__icon {
    line-height: 0;
  }

  ::v-deep .van-picker__toolbar {
    height: 80px;
  }

  ::v-deep .van-picker__title {
    line-height: 80px;
    font-size: 26px;
  }
</style>
