<template>
    <div class="app-container" v-if="showEcharts">
        <div style="width: 100%;height: 500px;padding-top: 10px" ref="echartsRef1"></div>
        <div style="width: 100%;height: 300px;padding-top: 10px" ref="echartsRef2"></div>
        <div style="width: 100%;height: 500px;padding-top: 30px" ref="echartsRef3"></div>

        <div class="table">
            <div class="table-header flex-spa-bet">
                <span class="se-tr">时间</span>
                <span class="se-tr">供应商数量</span>
                <span class="se-tr">订单数量</span>
                <span class="se-tr">物料数量</span>
                <span class="se-tr">总金额</span>
            </div>
            <div class="table-body">
                <div class="itemDiv item-tr flex-spa-ver-cen" v-for="(item, index) in tableData" :key="index">
                    <van-divider/>
                    <div class="itemCol name-wrap flex-ver-cen">
                        <div class="se-tr">{{ item.date }}</div>
                        <div class="se-tr rightData">{{ item.supplierNum }}</div>
                        <div class="se-tr rightData">{{ item.orderNum }}</div>
                        <div class="se-tr rightData">{{ item.materialNum }}</div>
                        <div class="se-tr rightData">{{ item.price }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getEchartLine,getEchartBar,getEchartPie} from '@/echarts/report/purchase/purchasePrice'
    import {getPriceMaterial,getMaterialTypePrice,getPriceOrderNumber,getList} from '@/api/report/purReport/purReport'
    import {getCurrentYear} from "@/utils/ruoyi";

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    this.showEcharts = this.isShow
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart1) {
                                this.dataEchart1.resize()
                            }
                            if (this.dataEchart2) {
                                this.dataEchart2.resize()
                            }
                            if (this.dataEchart3) {
                                this.dataEchart3.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                showEcharts: false,
                tableData: [],
                dataEchart1: null,
                dataEchart2: null,
                dataEchart3: null,
                // 查询参数
                queryParams: {
                    date: getCurrentYear()
                }
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                //列表
                let result = await getList(this.queryParams)
                this.tableData = result.data

                let resultBar = await getPriceOrderNumber(this.queryParams)
                let resultPie = await getMaterialTypePrice(this.queryParams)
                let resultLine = await getPriceMaterial(this.queryParams)

                // 柱状图
                let xname = '物料名称'
                let yname = '统计金额'
                const strList = []
                const dataList = []
                resultBar.data.forEach(item=>{
                    strList.push({
                        'value': item.date
                    })
                    dataList.push({
                        'value': item.price
                    })
                })
                this.dataEchart1 = this.$echarts.init(this.$refs.echartsRef1)
                this.dataEchart1.setOption(getEchartBar(strList, dataList,xname,yname))

                // 饼状图
                const priceList = []
                resultPie.data.forEach(item=>{
                    priceList.push({
                        "value": item.num,
                        "name": item.name
                    })
                })
                this.dataEchart2 = this.$echarts.init(this.$refs.echartsRef2)
                this.dataEchart2.setOption(getEchartPie(priceList))

                // 折线图
                let xname1 = '时间'
                let yname1 = '采购金额'
                const nameList = []
                const quaList = []
                resultLine.data.forEach(item=>{
                    nameList.push({
                        'value': item.name
                    })
                    quaList.push({
                        'value': item.price
                    })
                })
                this.dataEchart3 = this.$echarts.init(this.$refs.echartsRef3)
                this.dataEchart3.setOption(getEchartLine(nameList, quaList,xname1,yname1))
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart1)
                if (this.dataEchart1) {
                    this.dataEchart1.clear();
                    this.dataEchart1.dispose();
                    this.dataEchart1 = null;
                }
                window.removeEventListener('resize', this.dataEchart2)
                if (this.dataEchart2) {
                    this.dataEchart2.clear();
                    this.dataEchart2.dispose();
                    this.dataEchart2 = null;
                }
                window.removeEventListener('resize', this.dataEchart3)
                if (this.dataEchart3) {
                    this.dataEchart3.clear();
                    this.dataEchart3.dispose();
                    this.dataEchart3 = null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
  .table {
    margin-top: 60px;

    .table-header {
      padding: 10px 2px 5px 2px;

      span {
        display: inline-block;
        color: #b5b5b5;
        font-size: 22px;
      }

      .se-tr {
        width: 20%;
      }
    }

    .table-body {
      padding: 10px 10px 5px 10px;
      font-size: 26px;

      .itemDiv {
        width: 100%;
        margin-top: 30px;

        .itemCol {
          width: 100%;

          .se-tr {
            width: 20%;
          }

          .rightData {
            padding-left: 15px;
          }
        }
      }


    }
  }
</style>
