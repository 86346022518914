<template>
    <van-dialog
            v-model="dataDialog"
            title="报损信息"
            width="95%"
            style="font-size: 20px"
            :showConfirmButton="false"
    >
        <div class="common-wrap">
            <van-search
                    show-action
                    v-model="queryParams.title"
                    placeholder="请输入实验标题"
                    @search="handleQuery"
            >
                <template #action>
                    <div class="search-btn-label" @click="handleQuery" style="font-size: 14px">搜索</div>
                </template>
            </van-search>
        </div>
        <div class="main">
            <van-list finished-text="没有更多了">
                <div class="list" v-for="item in queryList" :key="item.id">
                    <div class="line"></div>
                    <div class="item-tab" @click="handleDetail(item.experimentId)">
                        <div class="title-wrap flex-spa-ver-cen">
                            <div class="tit over_hidden">
                                {{ item.materialName }}({{ item.materialCode }})
                            </div>
                            <div class="level-btn">
                                {{ item.reportStr || '--' }}
                            </div>
                        </div>
                        <div class="con">
                            <div class="desc-wrap flex">
                                <div class="label">物料规格：</div>
                                <div class="value">{{ item.materialSpecification || '--' }}</div>
                            </div>
                            <div class="desc-wrap flex">
                                <div class="label">领用量：</div>
                                <div class="value">{{ item.collectNum || '--' }}</div>
                            </div>
                            <div class="desc-wrap flex">
                                <div class="label">使用量：</div>
                                <div class="value">{{ item.useNum || '--' }}</div>
                            </div>
                            <div class="desc-wrap flex">
                                <div class="label">报损量：</div>
                                <div class="value">{{ item.lossNum || '--' }}</div>
                            </div>
                            <div class="desc-wrap flex">
                                <div class="label">实验量：</div>
                                <div class="value">{{ item.experimentNum || '--' }}</div>
                            </div>
                            <div class="desc-wrap flex">
                                <div class="label">耗损率：</div>
                                <div class="value">{{ item.lossRate || '--' }}</div>
                            </div>
                            <div class="desc-wrap flex">
                                <div class="label">实验标题：</div>
                                <div class="value">{{ item.title || '--' }}</div>
                            </div>
                            <div class="desc-wrap flex">
                                <div class="label">实验日期：</div>
                                <div class="value">{{ item.experimentDate || '--' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
        <van-pagination
                v-show="total > 0"
                v-model="queryParams.pageNum"
                :total-items="total"
                :show-page-size="pageCount > 3 ? 3 : pageCount"
                :items-per-page="3"
                force-ellipses
                style="margin-bottom: 20px; margin-top: 20px; font-size: 16px"
                @change="getList"
        />
        <van-button
                type="info"
                style="width: 100%; height: 50px; font-size: 20px"
                @click="handleClose"
        >取消
        </van-button>
    </van-dialog>
</template>

<script>
    import {findAllDetailPage} from '@/api/inventory/experiment.js'

    export default {
        props: {
            addDialog: Boolean,
            id: Number
        },

        watch: {
            addDialog: {
                handler(val) {
                    this.dataDialog = val
                    if (val) {
                        this.handleQuery()
                    }
                },
                immediate: true,
                deep: true
            },
        },

        data() {
            return {
                // 控制弹窗显示隐藏
                dataDialog: false,
                // 物料列表表格数据
                queryList: [],
                // 请求物料列表表格数据的传参
                queryParams: {
                    pageNum: 1,
                    pageSize: 3
                },
                total: 0,
                //总页数
                pageCount: 0,
            }
        },

        methods: {
            /** 查询入库列表 */
            async getList() {
                this.loading = true
                this.queryParams.isLoss = 1
                this.queryParams.whId = this.id
                await findAllDetailPage(this.queryParams).then(response => {
                    this.queryList = response.data.records
                    this.total = response.data.total
                    this.pageCount = response.data.pages
                })
            },

            // 关闭弹窗
            handleClose() {
                this.$emit('handleClose', 'lossNumDialog')
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.whId = this.id
                this.queryParams.pageNum = 1
                this.getList()
            },

            // 进入详情
            handleDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/inventory/experiment/add',
                    query: {
                        id,
                        type: 'writeResult'
                    },
                });
            },
        }
    }
</script>
<style lang="scss" scoped>
  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 5px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        width: 0;
        line-height: 38px;
        flex: 1;
      }

      .level-btn {
        width: 150px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;

        .label {
          color: #999;
        }

        .value {
          color: #111;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
