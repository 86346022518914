import request from '@/utils/request'

// 获取消息列表
export function getInformList(isRead) {
  return request({
    url: '/system/inform/getList',
    method: 'GET',
    params: { isRead }
  })
}

export function getNoticeList(isRead) {
  return request({
    url: '/system/inform/getNoticeList',
    method: 'GET',
    params: { isRead }
  })
}

//根据消息的明细id设置消息为已读
export function read(informDetailId) {
  return request({
    url: '/system/inform/read/'+informDetailId,
    method: 'PUT'
  })
}

//根据业务类型、业务id设置消息为已读
export function readByBusinessIdAndType(data) {
  return request({
    url: '/system/inform/readByBusinessIdAndType',
    method: 'PUT',
    data
  })
}

export function deletes() {
  return request({
    url: '/system/inform/delete',
    method: 'DELETE'
  })
}
