import request from "@/utils/request";
// 查询客户下所有发票管理
export function fetchOrderListByCustomerId(params) {
  return request({
    url: "/crm/invoice/getOrder",
    method: "get",
    params
  });
}

// 获取发票抬头
export function fetchHeadList(params) {
  return request({
    url: "/cw/invoiceHead/getList",
    method: "get",
    params
  });
}

// 新建发票
export function addCrmInvoice(data) {
  return request({
    url: "/crm/invoice/addCrmInvoice",
    method: "post",
    data
  });
}
// 获取数量 fetchCount
export function fetchCount(query) {
  return request({
    url: "/crm/invoice/getNumber",
    method: "get"
  });
}
// 获取查询列表
export function selectPage(query) {
  return request({
    url: "/crm/invoice/getList",
    method: "get",
    params: query
  });
}
// 删除
export function delList(ids) {
  return request({
    url: "/crm/invoice/deleteCrmInvoice/" + ids,
    method: "delete"
  });
}

// 审批-驳回
export function disAppvoral(data) {
  return request({
    url: "/crm/invoice/inspectInvoice",
    method: "put",
    data
  });
}

// 提交发票管理
export function dataOrderSubmit(params) {
  return request({
    url: "/crm/invoice/submitInvoice",
    method: "put",
    params
  });
}
// 根据发票管理id获取单条
export function selectItemByInvoice(query) {
  return request({
    url: "/crm/invoice/getId",
    method: "get",
    params: query
  });
}
// 根据订单id获取单条发票
export function selectItemByOrder(query) {
  return request({
    url: "/crm/invoice/getByOrderId",
    method: "get",
    params: query
  });
}
// 回显抬头信息
export function fetchHeadDetail(query) {
  return request({
    url: "/crm/invoice/getHeadId",
    method: "get",
    params: query
  });
}
// 更新发票
export function updateCrmInvoice(data) {
  return request({
    url: "/crm/invoice/updateCrmInvoice",
    method: "put",
    data
  });
}
