<template>
  <div class="detail-inner-con">
    <div class="title">基本信息</div>
    <div class="list-wrap">
      <div class="item-list flex-spa-bet">
        <div class="label">客户名称</div>
        <div class="value over_hidden">{{ customerDetail.name || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">客户级别</div>
        <div class="value over_hidden">{{ customerDetail.levelStr || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">联系方式</div>
        <div class="value over_hidden">{{ customerDetail.contactWay || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">手机</div>
        <div class="value over_hidden">{{ customerDetail.phone || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">客户来源</div>
        <div class="value over_hidden">{{ customerDetail.customerSourceStr || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">客户行业</div>
        <div class="value over_hidden">{{ customerDetail.industryStr || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">地址</div>
        <div class="value over_hidden">{{ customerDetail.address || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">下次联系时间</div>
        <div class="value over_hidden">{{ customerDetail.nextContactTime || '--' }}</div>
      </div>
      <div class="item-list flex-spa-bet" style="border-bottom: none">
        <div class="label">网址</div>
        <div class="value over_hidden">{{ customerDetail.website || '--' }}</div>
      </div>
      <div class="item-list flex-spa-bet" style="border-bottom: none">
        <div class="label">备注</div>
        <div class="value over_hidden">{{ customerDetail.remark || '--' }}</div>
      </div>
    </div>

    <div class="title">系统信息</div>
    <div class="list-wrap">
      <div class="item-list flex-spa-bet">
        <div class="label">负责人</div>
        <div class="value over_hidden">{{ customerDetail.leaderId || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">最后跟进记录</div>
        <div class="value over_hidden">{{ customerDetail.followContent || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">创建人</div>
        <div class="value over_hidden">{{ customerDetail.createBy || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">创建时间</div>
        <div class="value over_hidden">{{ customerDetail.createTime || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">更新时间</div>
        <div class="value over_hidden">{{ customerDetail.updateTime || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet">
        <div class="label">最后跟进时间</div>
        <div class="value over_hidden">{{ customerDetail.followTime || '--' }}</div>
      </div>

      <div class="item-list flex-spa-bet"
           style="border-bottom: none"
           v-for="item in customerDetail.customerCardList"
           :key="item.id">
          <van-image
                  width="10rem"
                  fit="contain"
                  :src="item.annexUrl"
                  @click="imgClick(item.annexUrl)"
          />
      </div>
    </div>
  </div>
</template>
<script>
    import {mapGetters} from 'vuex';
    import { ImagePreview } from 'vant';

    export default {
        computed: {
            ...mapGetters(['customerDetail']),
        },

        data() {
            return {};
        },
        methods:{
            //图片被点击
            imgClick(annexUrl){
                ImagePreview({images:[annexUrl],closeable:true});
            }
        }
    };
</script>
<style lang="scss" scoped>
.detail-inner-con {
}
.title {
  padding: 20px 40px;
  background: #fafafa;
  font-size: 24px;
  color: #666666;
}

.list-wrap {
  padding: 20px 40px;
  background: #fff;
  .item-list {
    padding: 30px 0;
    border-bottom: 1px solid #ececec;
    font-size: 28px;
    .label {
      width: 200px;
      color: #999999;
    }
    .value {
      width: 0;
      flex: 1;
      color: #333;
      text-align: right;
      margin-left: 20px;

      white-space:normal;
      word-break:break-all;
      overflow:hidden;
    }
  }
}
</style>
