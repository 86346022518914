<template>
    <div class="app-container" v-if="showEcharts">
        <div style="width: 100%;height: 500px;padding-top: 10px" ref="echartsRef1"></div>
        <div style="width: 100%;height: 300px;padding-top: 10px" ref="echartsRef2"></div>

        <div class="table">
            <div class="table-header flex-spa-bet">
                <span class="se-tr">供应商名称</span>
                <span class="se-tr">退单数量</span>
                <span class="se-tr">退货数量</span>
                <span class="se-tr">退款金额</span>
                <span class="se-tr">区间评分</span>
            </div>
            <div class="table-body">
                <div class="itemDiv item-tr flex-spa-ver-cen" v-for="(item, index) in tableData" :key="index">
                    <van-divider/>
                    <div class="itemCol name-wrap flex-ver-cen">
                        <div class="se-tr">{{ item.supplierName }}</div>
                        <div class="se-tr rightData">{{ item.orderNum }}</div>
                        <div class="se-tr rightData">{{ item.num }}</div>
                        <div class="se-tr rightData">{{ item.price }}</div>
                        <div class="se-tr rightData">{{ item.avgScore }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getEchartBar1,getEchartBar2} from '@/echarts/report/purchase/purchaseReturn'
    import {getPurchaseReturn} from '@/api/report/purReport/purReport'

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    this.showEcharts = this.isShow
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart1) {
                                this.dataEchart1.resize()
                            }
                            if (this.dataEchart2) {
                                this.dataEchart2.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                showEcharts: false,
                tableData: [],
                dataEchart1: null,
                dataEchart2: null,
                // 查询参数
                queryParams: {
                }
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                //列表
                let result = await getPurchaseReturn(this.queryParams)
                this.tableData = result.data

                // 柱状图
                let xname = '供应商'
                let yname = '退货物料数量'
                const strList1 = []
                const dataList1 = []
                this.tableData.forEach(item=>{
                    strList1.push(item.supplierName),
                        dataList1.push(item.num)
                })
                this.dataEchart1 = this.$echarts.init(this.$refs.echartsRef1)
                this.dataEchart1.setOption(getEchartBar1(strList1,dataList1,xname,yname))

                let xname1 = '供应商'
                const strList2 = ['supplierName','采购数量','采购金额']
                const dataList2 = []
                this.tableData.forEach(item=>{
                    strList2.push(item.supplierName)
                    dataList2.push({
                        'supplierName': item.supplierName,
                        '采购数量': item.num,
                        '采购金额': item.price
                    })
                })
                this.dataEchart2 = this.$echarts.init(this.$refs.echartsRef2)
                this.dataEchart2.setOption(getEchartBar2(strList2, dataList2,xname1))
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart1)
                if (this.dataEchart1) {
                    this.dataEchart1.clear();
                    this.dataEchart1.dispose();
                    this.dataEchart1 = null;
                }
                window.removeEventListener('resize', this.dataEchart2)
                if (this.dataEchart2) {
                    this.dataEchart2.clear();
                    this.dataEchart2.dispose();
                    this.dataEchart2 = null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
  .table {
    margin-top: 60px;

    .table-header {
      padding: 10px 2px 5px 2px;

      span {
        display: inline-block;
        color: #b5b5b5;
        font-size: 22px;
      }

      .se-tr {
        width: 20%;
      }
    }

    .table-body {
      padding: 10px 10px 5px 10px;
      font-size: 26px;

      .itemDiv {
        width: 100%;
        margin-top: 30px;

        .itemCol {
          width: 100%;

          .se-tr {
            width: 20%;
          }

          .rightData {
            padding-left: 15px;
          }
        }
      }


    }
  }
</style>
