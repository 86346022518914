import request from '@/utils/request'

// 查询销售发票列表
export function getAllCrmInvoiceList(query){
  return request({
    url: "/cw/crmInvoice/getList",
    method: "get",
    params: query
  });
}
// 查询销售发票详情
export function getAllInvoiceHeadInfo(query){
  return request({
    url: "/cw/crmInvoice/getId",
    method: "get",
    params: query
  });
}
// 开票
export function updateCrmInvoice(invoiceId){
  return request({
    url: "/cw/crmInvoice/updateCrmInvoice",
    method: "put",
    params: invoiceId
  });
}
