<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.name"
                            label="付款标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入付款标题' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.order"
                            label="采购订单"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择采购订单' }]"
                            @click="showPickerOrder= true"
                    />
                    <van-popup v-model="showPickerOrder" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="orderOptions"
                                value-key="title"
                                @confirm="
                onConfirm($event, 'orderId', 'order', 'title', 'showPickerOrder')
              "
                                @cancel="showPickerOrder = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.price"
                            label="总金额"
                            disabled
                    />

                    <van-field
                            readonly
                            clickable
                            :value="formInline.startTime"
                            label="开始日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择始日期' }]"
                            @click="showPickerBegin = true"
                    />
                    <van-calendar :min-date="minDate" :max-date="maxDate" v-model="showPickerBegin"
                                  @confirm="onConfirmBegin($event)"/>

                    <van-field
                            readonly
                            clickable
                            :value="formInline.endTime"
                            label="结束日期"
                            placeholder="点击选择日期"
                            :required="true"
                            :rules="[{ required: true, message: '请选择结束日期' }]"
                            @click="showPickerEnd = true"
                    />
                    <van-calendar :min-date="minDate" :max-date="maxDate" v-model="showPickerEnd"
                                  @confirm="onConfirmEnd($event)"/>

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">当前第{{ index+1 }}期</div>
                            <van-icon name="edit" @click="handleUpdate(item.id)" style="margin-left: 65%"/>
                            <van-icon name="delete-o" @click="handleDelete(item.id)"/>
                        </div>

                        <van-field
                                v-model="item.planPrice"
                                label="计划付款金额"
                                disabled
                        />

                        <van-field
                                v-model="item.price"
                                label="实际付款金额"
                                disabled
                        />

                        <van-field
                                v-model="item.state"
                                label="付款状态"
                                disabled
                        />

                        <van-field
                                v-model="item.paymentDate"
                                label="付款日期"
                                disabled
                        />

                    </div>
                    <div class="add-btn flex-hor-ver-cen" @click="handleAdd" v-if="type == 'detail'">
                        <van-icon name="add-o" class="mrg-r-20"/>
                        添加子计划
                    </div>
                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
                    </van-button>
                </van-form>
            </div>
        </div>


        <van-dialog
                v-model="showPicker"
                title="付款子计划"
                width="70%"
                style="font-size: 14px;"
                :showConfirmButton="false"
                :close-on-click-overlay="true"
        >
            <van-form validate-first @submit="onSubmit">
                <van-field
                        v-model="subForm.planPrice"
                        label="计划付款金额"
                        placeholder="请输入"
                        label-width="80px"
                        style="line-height: 40px;"
                        :required="true"
                        :rules="[{ required: true, message: '请输入计划付款金额' }]"
                />

                <van-field
                        v-model="subForm.remark"
                        label="备注"
                        placeholder="请输入"
                />
            </van-form>
            <van-button type="info" style="width: 100%;height: 30px;font-size: 18px" @click="submitFormSub">确认
            </van-button>
        </van-dialog>

    </div>
</template>
<script>
    import {addData, addSub, delSub, selectOne, selectOneSub, updateData, updateSub} from '@/api/purchase/payment';
    import {selectList, selectOrderOne} from '@/api/purchase/order';
    import {formatDate} from "@/utils/ruoyi";

    export default {
        data() {
            return {
                formInline: {},
                subForm: {},
                title: '新建付款',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerOrder: false,
                showPickerBegin: false,
                showPickerEnd: false,
                showPicker: false,
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(2050, 12, 31),
                //明细数组
                dataList: [],
                //采购订单
                orderOptions: []
            };
        },

        mounted() {
            //采购订单
            this.selectAllOrder();
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/purchase/purchasePayment');
                this.$router.go(-1)
            },

            //查询所有采购订单
            async selectAllOrder() {
                await selectList(3).then(response => {
                    this.orderOptions = response.data
                })
            },

            //回显
            async findDetail() {
                if (this.type == 'add') {
                    this.formInline = {
                        price: 0
                    };
                    this.title = '新建付款';
                } else {
                    let itemDetail = await selectOne(this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑付款';
                    } else {
                        this.title = '付款详情';
                        this.subForm.paymentId = this.id
                        this.dataList = itemDetail.sub
                    }
                }
            },

            //确认订单
            async onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['id'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;

                await selectOrderOne(this.formInline.orderId).then(response => {
                    this.formInline.price = response.data.price;
                });
            },

            //开始日期确认
            onConfirmBegin(value) {
                this.formInline.startTime = formatDate(value, 'yyyy-MM-dd');
                this.showPickerBegin = false;
            },

            //开始日期确认
            onConfirmEnd(value) {
                this.formInline.endTime = formatDate(value, 'yyyy-MM-dd');
                this.showPickerEnd = false;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            //编辑子计划
            async handleUpdate(id){
                await selectOneSub(id).then(response => {
                    this.subForm = response.data;
                    this.showPicker = true
                });
            },

            //删除子计划
            handleDelete(id) {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除子付款?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await delSub(id);
                        this.$toast.success('删除成功');
                        await that.findDetail();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },

            //添加子计划
            handleAdd() {
                this.showPicker = true;
            },

            //子计划提交
            async submitFormSub() {
                this.subForm.id ? await updateSub(this.subForm) : await addSub(this.subForm);
                this.$toast.success('操作成功');

                await this.findDetail()
                this.showPicker = false
            },

            async onSubmit() {
                let data = JSON.stringify(this.formInline)

                const result = this.formInline.id ? await updateData(data) : await addData(data);
                if (result.code == 200) {
                    this.$toast.success('操作成功');
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            }
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
