<template>
    <div class="app-container">
        <Header :moudle="'oa'"/>
        <div class="content-one flex-spa-bet">
            <div
                    class="item-card flex-ver-cen"
                    v-if="this.$auth.hasPermi('system:approve:wait')"
                    @click="comeApprove"
            >
                <img src="../../../assets/images/oa_indexpage_img1.png" class="icon"/>
                <div class="con-wrap">
                    <div class="tit">待办事项({{waitCount}})</div>
                    <div class="desc">+创建待办</div>
                </div>
            </div>
            <div
                    class="item-card flex-ver-cen"
                    v-if="this.$auth.hasPermi('oa:notice:list')"
                    @click="comeNotice"
            >
                <img src="../../../assets/images/oa_indexpage_img2.png" class="icon"/>
                <div class="con-wrap">
                    <div class="tit">我收到的({{workCount}})</div>
                    <div class="desc">处理汇报</div>
                </div>
            </div>
        </div>
        <div class="new-list common-wrap" v-if="this.$auth.hasPermi('oa:notice:list')">
            <div class="title-wrap flex-spa-bet">
                <div class="tit">最新公告 <span>热点新闻</span></div>
                <div class="view-more-btn" @click="comeNotice">查看更多</div>
            </div>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div class="item-list" v-for="item in dataList" :key="item.id" @click="noticeDetail(item.id)">
                    <div class="tit over_hidden">
                        {{ item.noticeTitle || '--' }}
                    </div>
                    <div class="flex">
                        <van-icon name="underway-o" class="icon1"/>
                        <div class="time">{{ item.createTime }}</div>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>
<script>
    import Header from '@/components/header';
    import {querynotice} from "@/api/oa/notice";
    import {statisticWait} from "@/api/approve/approve";
    import {statisticWork} from "@/api/oa/word";

    export default {
        components: {
            Header,
        },

        data() {
            return {
                dataList: [],
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                queryParams: {
                    pageNum: 1,
                    pageSize: 5
                },
                //待办数量
                waitCount: 0,
                //接收的汇报数量
                workCount: 0
            };
        },

        mounted() {
            //获取待办数量
            this.statisticWait()
            //获取接收的汇报数量
            this.statisticWork()
        },

        methods: {
            //获取待办数量
            statisticWait(){
                statisticWait().then(response=>{
                    this.waitCount = response.data
                })
            },
            //获取接收的汇报数量
            statisticWork(){
                statisticWork().then(response=>{
                    this.workCount = response.data
                })
            },

            async getList() {
                this.loading = true;
                const result = await querynotice(this.queryParams);
                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.dataList = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.dataList = records;
                } else {
                    this.dataList = [...this.dataList, ...records];
                }
                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
            },

            onLoad() {
                if (this.refreshing) {
                    this.dataList = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.dataList.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            //查看更多公告
            comeNotice() {
                this.$router.push('/oa/oaNotice');
            },

            //查看更多公待办审批
            comeApprove() {
                this.$router.push('/approve/myApply');
            },

            //公告详情
            noticeDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/oa/notice/detail',
                    query: {
                        id,
                    },
                });
            },
        },
    };
</script>
<style lang="scss" scoped>
  .content-one {
    width: 710px;
    margin: 0 auto;

    .item-card {
      width: 347px;
      height: 110px;
      background: #ffffff;
      border-radius: 14px 14px 14px 14px;

      .icon {
        width: 33px;
        height: 37px;
        margin: 0 37px 0 32px;
      }

      .con-wrap {
        .tit {
          font-size: 26px;
        }

        .desc {
          font-size: 20px;
          color: #40a0ff;
          margin-top: 10px;
        }
      }
    }
  }

  .new-list {
    margin: 20px auto;
    padding: 35px;

    .title-wrap {
      margin-bottom: 33px;

      .tit {
        font-size: 32px;
        color: #333333;

        span {
          display: inline-block;
          color: #777777;
          font-size: 26px;
          margin-left: 40px;
        }
      }

      .view-more-btn {
        color: #0091ff;
        font-size: 22px;
      }
    }

    .item-list {
      border-bottom: 1px solid #ececec;
      color: #999;
      padding: 24px 0;

      .tit {
        font-size: 28px;
        margin-bottom: 23px;
        color: #333;
      }

      .icon1 {
        font-size: 20px;
        margin-right: 5px;
      }

      .icon {
        margin-left: 90px;
      }

      .time {
        font-size: 20px;
      }
    }
  }
</style>
