<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <div class="line"></div>
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
              v-model="formInline.name"
              label="商机名称"
              name="name"
              placeholder="请输入"
              :required="true"
              :rules="[{ required: true, message: '请输入' }]"
          />

          <van-field
              readonly
              clickable
              name="customerId"
              v-model="formInline.customerName"
              label="客户名称"
              placeholder="请选择"
              @click="showCustomerDialog"
          />

          <van-field
              v-model="formInline.probability"
              label="成交率(%)"
              type="number"
              name="probability"
              placeholder="请输入"
          />

          <van-field
              readonly
              clickable
              name="closingDate"
              :value="formInline.closingDate"
              label="预计成交日期"
              placeholder="点击选择日期"
              @click="showPicker = true"
          />
          <van-calendar v-model="showPicker" @confirm="onConfirm"/>
          <div class="line"></div>
          <textAreaInput :title="'备注'" v-model="formInline.remark"/>
          <van-button class="btn" block type="info" native-type="submit">提交</van-button>
        </van-form>
      </div>
    </div>

    <CustomerPop
        :customerVisible="customerVisible"
        @submitCustomerList="submitCustomerList"
        @handleClose="handleClose"
    />
  </div>
</template>
<script>
import {fetchAddBusiness, updateBusiness} from '@/api/crm/bussiness';
import CustomerPop from "@/components/crm/CustomerPop";
import {getCurrentMonthDay3} from "@/utils/ruoyi";

export default {
  components: {
    CustomerPop
  },

  data() {
    return {
      value: '',
      formInline: {},
      customerVisible: false,
      showPicker: false,
      title: '新增商机'
    };
  },

  mounted() {
    const itemDetail = this.$route.query.obj ? JSON.parse(this.$route.query.obj) : '';
    if (itemDetail) {
      this.formInline = itemDetail;
      this.formInline.customerName = itemDetail.customer;

      delete this.formInline.leaderId;

      this.title = '编辑商机';
    } else {
      this.formInline = {};
      this.title = '新增商机';
    }
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/crm/cost');
      this.$router.go(-1)
    },

    //展示选择客户的弹窗
    showCustomerDialog() {
      this.customerVisible = true;
    },

    //客户提交
    submitCustomerList(id, name) {
      this.formInline.customerId = id;
      this.formInline.customerName = name;
      this.customerVisible = false

      this.formInline.name = this.formInline.customerName + '的商机' + getCurrentMonthDay3()
    },

    //关闭选择客户的弹窗
    handleClose() {
      this.customerVisible = false;
    },

    //日期提交
    onConfirm(value) {
      this.formInline.closingDate = this.formatDate(value, 'yy-MM-dd');
      this.showPicker = false;
    },

    //日期格式化
    formatDate(date, key) {
      if (key) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      } else {
        return `${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}
      `;
      }
    },

    async onSubmit() {
      const data = JSON.stringify(this.formInline);
      const result = this.formInline.id ? await updateBusiness(data) : await fetchAddBusiness(data);
      if (result.code == 200) {
        this.$toast(result.message);
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end
</style>
