<template>
  <div class="inner-container">
    <navBar :title="'服务协议'" @handleGoBack="handleGoBack"> </navBar>
    <div class="content">
      请您认真阅读并充分理解《壹米咻咻erp管理系统服务协议》（下称“本协议”）。<br />
      您安装、注册、登录、使用“壹米咻咻erp管理系统”等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
      <br />
      本协议是您（下称“用户”）与山东壹米咻咻信息科技有限公司(下称“壹米咻咻”)之间关于用户安装、注册、登录、使用“壹米咻咻erp管理系统”服务（下称“本服务”）所订立的协议。
      <br />
      一、注册信息和隐私保护
      <br />1.1
      用户在使用本服务时，可能需要提供一些必要的能够对用户进行个人辨识或涉及个人通信的信息，用户应按照要求提供及时、详尽及准确的个人资料，并不断更新个人资料。因个人信息不真实而引起的风险由用户自行承担。
      <br />
      1.2
      壹米咻咻erp管理系统帐号的所有权归壹米咻咻所有。用户不应将其帐号、密码转让、出售或出借予他人使用,若用户授权他人使用账户，应对被授权人在该账户下发生所有行为负全部责任。
      <br />
      1.3
      保护用户个人隐私信息是壹米咻咻的一贯制度，壹米咻咻将会采取合理的措施保护用户的个人隐私信息。具体详见《隐私权保护声明》。
      <br />
      二、提供服务
      <br />
      2.1
      用户使用本服务需要下载壹米咻咻erp管理系统客户端软件。壹米咻咻发放给用户不可转让及非排他性的许可用于下载软件。用户仅可为访问或使用本服务的目的而使用这些软件及服务。
      <br />
      2.2
      壹米咻咻保留因业务发展需要，单方面对本服务的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销的权利，用户需承担此风险。
      <br />
      三、使用规则<br />
      3.1
      用户在使用本服务时，必须遵守中华人民共和国相关法律法规的规定，不得利用本服务进行任何违法或不正当的活动，包括但不限于制作、上载、展示、张贴、传播或以其它方式传送如下内容:
      <br />
      (1)反对宪法所确定的基本原则的;
      <br />
      (2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;
      <br />
      (3)损害国家荣誉和利益的；
      <br />
      (4)煽动民族仇恨、民族歧视、破坏民族团结的;
      <br />
      (5)破坏国家宗教政策,宣扬邪教和封建迷信的;
      <br />
      (6)散布谣言，扰乱社会秩序，破坏社会稳定的;
      <br />
      (7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      <br />
      (8)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
      <br />
      (9)含有任何性或性暗示、骚扰、辱骂、恐吓、威胁、侵害他人隐私等恶意信息的；
      <br />
      (10)含有法律、行政法规禁止的其他内容的信息。
      <br />
      3.2
      用户充分了解并同意对自己账号下的一切行为负责,包括但不限于用户所传送的任何内容以及由此产生的任何结果。用户承担法律责任的形式包括但不限于：对遭受侵害者进行赔偿，以及在壹米咻咻首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户给予壹米咻咻等额的赔偿。壹米咻咻有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收壹米咻咻erp管理系统帐号、追究法律责任等措|施。对恶意注册壹米咻咻erp管理系统帐号或利用壹米咻咻erp管理系统帐号进行违法活动或其他违反本协议的行为，壹米咻咻有权回收其帐号。同时，壹米咻咻会视司法部门的要求，协助调查。
      <br />
      3.3
      用户不得对本服务任何部分或本服务之使用或获得,进行复制、拷贝、出售、转售或用于任何其它商业目的。

      <br />
      四、免责及责任限制
      <br />
      4.1
      用户因互联网网络故障、计算机病毒、黑客攻击、系统不稳定、电信部门的通讯线路故障及其他各种不可抗力原因而遭受的一切损失，用户须自行承担，壹米咻咻不承担任何责任。
      <br />
      4.2
      用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿壹米咻咻，并使之免受损害。
      <br />
      4.3
      用户须了解，在使用本服务过程中存在有来自他人的包括威胁性的、诽谤性的、令人反感的非法的内容或行为，或对他人权利的侵犯（包括知识产权）的匿名或冒名的信息的风险，用户须承担以上风险，壹米咻咻对本服务不作任何类型的担保。
      <br />
      4.4
      维护软件安全与正常使用是壹米咻咻和用户的共同责任，壹米咻咻将按照行业标准合理审慎地采取必要技术措施保护用户的终端设备信息和数据安全，但是用户承认和同意壹米咻咻并不能就此提供完全保证。壹米咻咻不对用户在本服务中相关数据的删除或存储失败负责。
      <br />
      4.5
      在任何情况下，壹米咻咻均不对间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害承担责任，壹米咻咻对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用本服务而支付给壹米咻咻的费用。
      <br />
      五、权利声明本服务涉及的一切知识产权，以及与本服务相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、电子文档等均为壹米咻咻所有（涉及第三方授权的除外），受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及知识产权法律法规的保护。
      <br />
      六、其他
      <br />
      6.1
      壹米咻咻郑重提醒用户注意本协议中免除壹米咻咻责任的条款，请用户仔细阅读，自主考虑风险。壹米咻咻有权根据互联网的发展、中华人民共和国有关法律法规的变化以及公司经营状况和经营策略的调整，不断地完善本服务质量并依此修改本协议条款。更新后的协议条款一旦公布即代替原来的协议条款，用户可通过网页查阅最新版协议条款。如果用户不接受修改后的条款，请立即停止使用本服务，用户继续使用本服务将被视为接受修改后的协议。
      <br />
      6.2
      本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和壹米咻咻之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交壹米咻咻所在地有管辖权的人民法院管辖。
      <br />
      6.3 以上各项条款内容的最终解释权及修改权归壹米咻咻所有。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleGoBack() {
      this.$router.replace('/staticSystem/about');
        this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container {
  height: 100vh;
  background: #f3f4f5;
}
.content {
  padding: 20px 40px;
  line-height: 45px;
  font-size: 28px;
  color: #444;
}
</style>
