<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'会议申请'" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <div class="line"></div>
                <van-form @submit="onSubmit">

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.flowName"
                            label="审批流程"
                            placeholder="请选择"
                            @click="showFlowPicker = true"
                            :rules="[{ required: true, message: '请选择审批流程' }]"
                    />
                    <van-popup v-model="showFlowPicker" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="flowList"
                                value-key="flowTitle"
                                @confirm="onConfirmFlow"
                                @cancel="showFlowPicker = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="formInline.name"
                            label="会议名称"
                            placeholder="请输入"
                    />

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.conveneName"
                            label="召集人"
                            placeholder="请选择"
                            @click="showConvenePicker = true"
                            :rules="[{ required: true, message: '请选择召集人' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.contactName"
                            label="联系人"
                            placeholder="请选择"
                            @click="showContactPicker = true"
                            :rules="[{ required: true, message: '请选择联系人' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.roomName"
                            label="会议地点"
                            placeholder="请选择"
                            @click="showRoomPicker = true"
                            :rules="[{ required: true, message: '请选择会议地点' }]"
                    />
                    <van-popup v-model="showRoomPicker" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="roomList"
                                value-key="name"
                                @confirm="onConfirmRoom"
                                @cancel="showRoomPicker = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            name="calendar"
                            :value="formInline.startTime"
                            label="开始时间"
                            placeholder="点击选择日期"
                            @click="showDataTimePop = true"
                    />

                    <van-popup v-model="showDataTimePop" style="width: 70%">
                        <van-datetime-picker
                                v-model="formInline.beginTimeModel"
                                type="datetime"
                                title="请选择开始时间"
                                @confirm="onConfirmTime"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            name="calendar"
                            :value="formInline.endTime"
                            label="结束时间"
                            placeholder="点击选择日期"
                            @click="showEndTimePop = true"
                    />

                    <van-popup v-model="showEndTimePop" style="width: 70%">
                        <van-datetime-picker
                                v-model="formInline.endTimeModel"
                                type="datetime"
                                title="请选择结束时间"
                                @confirm="onConfirmEndTime"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.joinName"
                            label="参会人员"
                            placeholder="请选择"
                            @click="showJoinPicker = true"
                            :rules="[{ required: true, message: '请选择参会人员' }]"
                    />

                    <van-field
                            v-model="formInline.otherStaff"
                            label="其他人员"
                            placeholder="请输入"
                    />

                    <textAreaInput
                            :title="'会议内容'"
                            :remark="formInline.meetRoom"
                            @change="handleContentChange"
                    />

                    <div class="line"></div>
                    <van-field name="uploader" label="附件">
                        <template #input>
                            <van-uploader v-model="fileList" multiple :max-count="2">
                                <div class="flex">
                                    <i class="iconfont icon-fujian1 mrg-r-10" style="font-size: 20px"></i>
                                    <span>点击上传</span>
                                </div>
                            </van-uploader>
                        </template>
                    </van-field>
                    <van-button class="btn" block type="info" native-type="submit">提交</van-button>
                </van-form>
            </div>
        </div>

        <van-popup v-model="showConvenePicker" style="width: 80%; padding: 20px; border-radius: 8px">
            <van-tree-select
                    class="treeSelect mrg-b-30"
                    :items="userDeptList"
                    :active-id.sync="activeConveneId"
                    :main-active-index.sync="conveneIndex"
            />
            <van-button style="width: 100%; height: 40px" block type="info" @click="convenePopSubmit"
            >提交
            </van-button
            >
        </van-popup>
        <van-popup v-model="showContactPicker" style="width: 80%; padding: 20px; border-radius: 8px">
            <van-tree-select
                    class="treeSelect mrg-b-30"
                    :items="userDeptList"
                    :active-id.sync="activeContactId"
                    :main-active-index.sync="contactIndex"
            />
            <van-button style="width: 100%; height: 40px" block type="info" @click="contactPopSubmit"
            >提交
            </van-button
            >
        </van-popup>
        <van-popup v-model="showJoinPicker" style="width: 80%; padding: 20px; border-radius: 8px">
            <van-tree-select
                    class="treeSelect mrg-b-30"
                    :items="userDeptList"
                    :active-id.sync="activeJoinIds"
                    :main-active-index.sync="joinIndex"
            />
            <van-button style="width: 100%; height: 40px" block type="info" @click="joinPopSubmit"
            >提交
            </van-button
            >
        </van-popup>

    </div>
</template>
<script>
    import {changeToCurrentDay} from '@/utils/ruoyi';
    import {submiteUpload} from '@/api/system/config';
    import {getListRoom} from "@/api/oa/meeting";
    import {addApprove} from "@/api/approve/approve";
    import {treeAllDeptAndUser} from "@/api/system/dept";
    import {getAllUser} from "@/api/system/user";
    import {findFlowByUserId} from "@/api/approve/approveFlow";

    export default {
        data() {
            return {
                currentDate: new Date(),
                formInline: {
                    realname: localStorage.getItem('realname'),
                    beginTimeModel: new Date(),
                    endTimeModel: new Date()
                },

                showRoomPicker: false,
                roomList: [],

                showApplyPicker: false,
                showEndTimePop: false,
                showDataTimePop: false,
                showFlowPicker: false,
                fileList: [],

                //用户-部门
                userDeptList: [],
                //用户
                userList: [],
                //召集人
                activeConveneId: 0,
                conveneIndex: 0,
                showConvenePicker: false,

                //联系人
                activeContactId: 0,
                contactIndex: 0,
                showContactPicker: false,

                //参会人员
                activeJoinIds: [],
                joinIndex: 0,
                showJoinPicker: false,

                flowList: [],
                userId: localStorage.getItem('userId'),
                typeName: '会议申请'
            };
        },

        mounted() {
            //查询所有会议地点
            this.findAllRoom();
            //查询所有用户-部门
            this.findUserDept();
            //查询所有用户
            this.findAllUser();
            //查询流程
            this.findFlowList()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/approve/myApply');
                this.$router.go(-1)
            },

            //查询流程
            findFlowList() {
                findFlowByUserId({ typeName: this.typeName, userId: this.userId }).then(res => {
                    this.flowList = res.data
                    if (this.flowList && this.flowList.length == 1) {
                        this.formInline.flowId = this.flowList[0].id
                        this.formInline.flowName = this.flowList[0].flowTitle
                    }
                })
            },

            //查询所有会议地点
            async findAllRoom() {
                let result = await getListRoom()
                this.roomList = result.data
            },

            //查询所有用户-部门
            async findUserDept() {
                const resultData = await treeAllDeptAndUser();
                this.userDeptList = resultData.data;

                this.userDeptList.forEach((item) => {
                    this.packItems(item);
                });
            },

            //查询所有用户
            async findAllUser() {
                let result = await getAllUser();
                this.userList = result.data
            },

            //递归label赋值给text
            packItems(item) {
                item.text = item.label;
                if (item.children) {
                    item.children.forEach((detail) => {
                        this.packItems(detail);
                    });
                }
            },

            //会议地点确定
            onConfirmRoom(value) {
                this.formInline.roomId = value.id;
                this.formInline.roomName = value.name;
                this.showRoomPicker = false;
            },

            onConfirmType(value) {
                this.formInline.followTypeName = value.dictLabel;
                this.formInline.followType = value.dictValue;
                this.showApplyPicker = false;
            },

            //开始时间确认
            onConfirmTime(value) {
                const time = changeToCurrentDay(value);
                this.formInline.startTime = time;
                this.showDataTimePop = false;
            },

            //结束时间确认
            onConfirmEndTime(value) {
                const time = changeToCurrentDay(value);
                this.formInline.endTime = time;
                this.showEndTimePop = false;
            },

            //召集人
            convenePopSubmit() {
                this.formInline.conveneId = this.activeConveneId

                if (this.activeConveneId) {
                    let selectedUser = this.userList.find(item => this.activeConveneId == item.id);
                    this.formInline.conveneName = selectedUser.realname
                }
                this.showConvenePicker = false
            },

            //联系人
            contactPopSubmit() {
                this.formInline.contactId = this.activeContactId

                if (this.activeContactId) {
                    let selectedUser = this.userList.find(item => this.activeContactId == item.id);
                    this.formInline.contactName = selectedUser.realname
                }
                this.showContactPicker = false
            },

            //参会人员
            joinPopSubmit() {
                this.formInline.staffIds = this.activeJoinIds.join(",")

                if (this.activeJoinIds) {
                    let selectedUserList = this.userList.filter(item => {
                        return this.activeJoinIds.indexOf(item.id) >= 0
                    });
                    this.formInline.joinName = selectedUserList.map(item => item.realname).join(",")
                }
                this.showJoinPicker = false
            },

            //审批流程
            onConfirmFlow(value) {
                this.formInline.flowName = value.flowTitle;
                this.formInline.flowId = value.id;
                this.showFlowPicker = false;
            },

            async onSubmit() {
                let form = {}
                form.approveTitle = this.formInline.realname + '-会议申请'
                form.remark = JSON.stringify(this.formInline)
                form.route = '/approve/waitApprove'
                form.typeStr = this.typeName
                form.flowId = this.formInline.flowId
                let result = await addApprove(form)
                let businessId = result.data;

                if (result.code == 200) {
                    if (this.fileList) {
                        this.fileList.forEach(item => {
                            let params = new FormData();
                            params.append('file', item.file);
                            params.append('businessType', 'sys_approve');
                            params.append('businessId', businessId);
                            submiteUpload(params);
                        })
                        this.fileList = []
                    }
                    this.$toast.success('添加成功');
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            handleContentChange(val) {
                this.formInline.remark = val;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end

  ::v-deep .van-radio__label {
    line-height: 100px;
  }

  ::v-deep .van-radio__icon .van-icon {
    width: none;
    height: none;
    line-height: none;
  }

  ::v-deep .van-radio__icon {
    line-height: 0;
  }

  ::v-deep .van-picker__toolbar {
    height: 80px;
  }

  ::v-deep .van-picker__title {
    line-height: 80px;
    font-size: 26px;
  }
</style>
