<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="'销售订单'" @handleGoBack="handleGoBack">
                <template v-slot:icon1>
                    <van-icon name="plus" class="mrg-r-20" @click="handleAdd" v-if="$auth.hasPermi('crm:order:add')"/>
                </template>
            </navBar>

            <div class="common-wrap">
                <van-search
                        show-action
                        v-model="queryParams.theme"
                        placeholder="请输入订单编号/标题"
                        @search="handleQuery"
                >
                    <template #action>
                        <div class="search-btn-label" @click="handleQuery">搜索</div>
                    </template>
                </van-search>
            </div>

            <van-tabs class="my-tabs" @click="getListParams" v-model="active">
                <van-tab v-for="(item, index) in list" :title="item.name" :key="index" :color="'#0091FF'">
                    <div class="main">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    offset="100"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"
                            >
                                <div class="list" v-for="item in dataList" :key="item.id">
                                    <div class="line"></div>
                                    <div class="item-tab" @click="handleGoDetail(item.id)">
                                        <div class="title-wrap flex-spa-ver-cen">
                                            <div class="tit over_hidden">
                                                {{ item.theme || '--' }}（{{ item.number || '--' }}）
                                            </div>
                                            <div class="level-btn">
                                                {{ item.stateStr || '--' }}
                                            </div>
                                        </div>
                                        <div class="con">
                                            <div class="desc-wrap flex">
                                                <div class="label">客户名称：</div>
                                                <div class="value">{{ item.customer || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">订单总额：</div>
                                                <div class="value">{{ item.price }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">优惠额度：</div>
                                                <div class="value red">{{ item.discount }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">优惠后总额：</div>
                                                <div class="value red">{{ item.discountPrice }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">负责人：</div>
                                                <div class="value red">{{ item.leaderStr || '--' }}</div>
                                            </div>
                                            <div class="desc-wrap flex">
                                                <div class="label">签单日期：</div>
                                                <div class="value red">{{ item.signingDate || '--' }}</div>
                                            </div>
                                        </div>
                                        <div class="time-wrap flex-spa-ver-cen"
                                             @click.stop="operateAction(item.id,item.state)">
                                            <div class="time">创建日期：{{ item.createTime || '--' }}</div>
                                            <van-icon name="weapp-nav"/>
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <van-action-sheet
                v-model="actionShow"
                :actions="actions"
                @select="onSelect"
                cancel-text="取消"
                close-on-click-action
        />
    </div>
</template>

<script>
    import {delList, selectPage, dataOrderSubmit} from '@/api/crm/order';

    export default {
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false, // 下拉
                activeId: null,
                list: [
                    {
                        name: '我负责',
                        value: '1',
                    },
                    {
                        name: '下属负责',
                        value: '2',
                    }
                ],
                active: 0,
                queryParams: {
                    screen: '1',
                    pageNum: 1,
                    pageSize: 5
                },
                dataList: [],
                //操作的动作面板
                actionShow: false,
                //操作面板的操作
                actions: [],
                itemId: null,
                //是否为管理员
                isAdmin: JSON.parse(localStorage.getItem('isAdmin'))
            };
        },

        mounted() {
            if (this.isAdmin) {
                this.list.unshift({name: '全部', value: '0'})
            }
            this.queryParams.screen = this.list[0].value
            this.getList()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/sale/saleWork');
                this.$router.go(-1)
            },

            async getList() {
                this.loading = true;
                let result = await selectPage(this.queryParams)

                const {records = [], total = 0} = result.data;
                // 空数组
                if (records.length == 0) {
                    this.dataList = [];
                    this.finished = true;
                    this.loading = true;
                    return;
                }
                if (this.queryParams.pageNum == 1) {
                    this.dataList = records;
                } else {
                    this.dataList = [...this.dataList, ...records];
                }
                this.finished = false;
                this.loading = false;
                this.queryParams.pageNum += 1;
                const end = records.length; //已获取的数据的总条数
                if (end >= total) {
                    //如果两个相等就停止加载，显示没有更多
                    this.finished = true;
                    return;
                }

                this.total = total;
            },

            onLoad() {
                if (this.refreshing) {
                    this.dataList = [];
                    this.refreshing = false;
                    this.queryParams.pageNum = 1;
                }
                if (this.dataList.length == this.total) {
                    this.finished = true;
                    return;
                }
                this.loading = true;
                this.getList();
            },

            //下拉的话refreshing为true
            onRefresh() {
                this.finished = false;
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                this.onLoad();
            },

            getListParams(index) {
                this.finished = false;
                this.loading = false;
                this.dataList = [];
                this.queryParams.screen = this.list[index].value;
                this.handleQuery();
            },

            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },

            handleAdd() {
                // 新增页面
                this.$router.push({
                    path: '/crm/order/add',
                    query: {
                        type: 'add'
                    },
                });
            },

            handleGoDetail(id) {
                // 进入详情页面
                this.$router.push({
                    path: '/crm/order/add',
                    query: {
                        id,
                        type: 'detail'
                    },
                });
            },

            //点击操作展示动作面板
            operateAction(id, state) {
                this.itemId = id;

                if((state != 0 && state != 1)){
                    this.setAction('crm:order:submit','提交')
                }
                this.setAction('crm:order:update','编辑')
                this.setAction('crm:order:delete','删除')

                this.actionShow = true;
            },

            //设置权限
            setAction(perm, permName){
                if(this.$auth.hasPermi(perm)){
                    let obj = this.actions.find(item => item.name == permName)
                    if (!obj) {
                        this.actions.unshift(
                            {name: permName, className: 'customer-opea-action'}
                        )
                    }
                } else {
                    this.actions = this.actions.filter(item => item.name != permName)
                }
            },

            //操作的动作面板被点击
            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                switch (item.name) {
                    case '编辑':
                        this.handleEdit();
                        break;
                    case '删除':
                        this.handleDelete();
                        break;
                    case '提交':
                        this.handleSubmit();
                        break;
                }
            },

            // 编辑
            handleEdit() {
                // 新增页面
                this.$router.push({
                    path: '/crm/order/add',
                    query: {
                        id: this.itemId,
                        type: 'edit'
                    },
                });
            },

            // 删除
            handleDelete() {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认删除此订单?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await delList(this.itemId);
                        this.$toast.success('删除成功');
                        await that.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            },

            // 提交
            handleSubmit() {
                let that = this
                this.$dialog
                    .confirm({
                        title: '删除',
                        message: '是否确认提交订单?',
                        className: 'appendix-del-dialog',
                        width: '300',
                    })
                    .then(async () => {
                        await dataOrderSubmit({orderId: this.itemId});
                        this.$toast.success('提交成功');
                        await that.handleQuery();
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
        },
    };
</script>

<style lang="scss" scoped>
  .wrap {
    background: #fff;
  }

  .search {
    padding-top: 40px;
    background: #fff;
  }

  .my-tabs {
    padding-top: 30px;
  }

  .item-tab {
    padding: 0 33px;
    margin-top: 30px;

    .title-wrap {
      padding: 20px 0;

      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #111111;
        line-height: 38px;
        width: 0;
        flex: 1;
      }

      .level-btn {
        width: 150px;
        height: 40px;
        background: #f7ad00;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
      }
    }

    .con {
      padding: 20px 0;
      border-top: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;

      .desc-wrap {
        font-size: 28px;
        margin-bottom: 20px;

        .label {
          white-space: nowrap;
          color: #999;
        }

        .value {
          color: #111;
          white-space: nowrap;
          overflow: hidden;
        }

        .red {
          color: #f7ad00;
        }
      }

      .desc-wrap:last-child {
        margin-bottom: 0;
      }
    }

    .time-wrap {
      font-size: 26px;
      margin: 20px 0 20px;
    }
  }

  ::v-deep .van-tabs__line {
    background-color: #0091ff;
  }

  .transfer-select-wrap {
    font-size: 28px;
  }

  ::v-deep .van-checkbox__label {
    line-height: 40px;
    font-size: 28px;
    padding-top: 10px;
  }

  ::v-deep .van-checkbox__icon .van-icon {
    width: 28px;
    height: 28px;
  }
</style>
