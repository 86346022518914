<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title + '审批流程'" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <div class="line"></div>
                <van-form @submit="onSubmit">
                    <van-field
                            v-model="formInline.flowTitle"
                            label="流程标题"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入流程标题' }]"
                    />
                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.typeName"
                            label="流程类型"
                            placeholder="请选择"
                            @click="showTypePop = true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择流程类型' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            v-model="formInline.applyTypeStr"
                            label="适用人群"
                            placeholder="请选择"
                            :required="true"
                            :rules="[{ required: true, message: '请选择适用人群' }]"
                            @click="showRangePicker = true"
                    />

                    <div v-for="(item, index) in dataList" :key="index">
                        <div class="flex-spa-bet list-tit-wrap">
                            <div class="left-tit">节点（{{ index + 1 }}）</div>
                            <van-icon name="delete-o" @click="handleDelete(index)"/>
                        </div>

                        <van-field
                                readonly
                                clickable
                                v-model="item.approverStr"
                                label="审批人"
                                placeholder="请选择"
                                @click="clickApprovePop(index)"
                                :required="!(item.cooperateWith || item.isInitiateApprove)"
                                :rules="[{ required: !(item.cooperateWith || item.isInitiateApprove), message: '请选择审批人' }]"
                        />

                        <van-field
                                readonly
                                clickable
                                v-model="item.allPassToNextStr"
                                label="该节点是否全部审批通过才能到下个节点"
                                placeholder="该节点是否全部审批通过才能到下个节点"
                                label-width="280px"
                                @click="clickPop(index,'showPicker')"
                        />

                        <van-field
                                readonly
                                clickable
                                v-model="item.cooperateWithStr"
                                label="是否由上节点选择本节点审批人"
                                placeholder="是否由上节点选择本节点审批人"
                                label-width="280px"
                                @click="clickPop(index,'showPickerCooperateWith')"
                        />

                        <van-field
                                readonly
                                clickable
                                v-model="item.isInitiateApproveStr"
                                label="该节点是否由审批发起人自行审批"
                                placeholder="该节点是否由审批发起人自行审批"
                                label-width="280px"
                                @click="clickPop(index,'showPickerIsInitiateApprove')"
                        />
                    </div>
                    <div class="add-btn flex-hor-ver-cen" @click="handleAdd">
                        <van-icon name="add-o" class="mrg-r-20"/>
                        添加节点
                    </div>
                    <van-button class="btn" block type="info" native-type="submit">提交</van-button>
                </van-form>
            </div>
        </div>

        <!-- 流程类型弹窗 -->
        <van-popup v-model="showTypePop" position="bottom" :style="{ height: '43%' }">
            <div class="pop-main">
                <div class="flex-wrap">
                    <van-cascader
                            v-model="typeIdOne"
                            :options="typeOptions"
                            @finish="onFinish"
                            :field-names="{ text: 'label', value: 'id', children: 'children' }"
                    />
                </div>

                <div class="confirm-btn" @click="handleConfirm">确认</div>
            </div>
        </van-popup>

        <!--审批人弹窗-->
        <van-popup v-model="showApproverPicker" style="width: 80%; padding: 20px; border-radius: 8px">
            <van-tree-select
                    class="treeSelect mrg-b-30"
                    :items="userDeptList"
                    :active-id.sync="activeApproverIds"
                    :main-active-index.sync="approverIndex"
            />
            <van-button style="width: 100%; height: 40px" block type="info" @click="approverPopSubmit"
            >提交
            </van-button>
        </van-popup>

        <!--节点设置-->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="['否', '是']"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
            />
        </van-popup>

        <!--是否由上节点选择本节点审批人-->
        <van-popup v-model="showPickerCooperateWith" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="['否', '是']"
                    @confirm="onConfirmCooperateWith"
                    @cancel="showPickerCooperateWith = false"
            />
        </van-popup>

        <!--该节点是否由审批发起人自行审批-->
        <van-popup v-model="showPickerIsInitiateApprove" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="['否', '是']"
                    @confirm="onConfirmIsInitiateApprove"
                    @cancel="showPickerIsInitiateApprove = false"
            />
        </van-popup>

        <selectUserAndDept :showRangePicker="showRangePicker" @handleClose="handleCloseRange"/>
    </div>
</template>
<script>
    import {addApproveFlow, findAllType, findOne, updateApproveFlow} from '@/api/approve/approveFlow';
    import {findDeptNameByDeptId, treeAllDeptAndUser} from '@/api/system/dept';
    import {getAllUser} from '@/api/system/user';
    import selectUserAndDept from '@/components/selectUserAndDept/selectUserAndDept';

    export default {
        components: {selectUserAndDept},
        data() {
            return {
                flowId: 0,
                formInline: {
                    //流程类型
                    typeName: '',
                    typeIdArray: '',
                },
                showPicker: false,
                //节点数组
                dataList: [
                    {
                        approverList: [],
                        approverStr: '',
                        allPassToNext: false,
                        allPassToNextStr: '否',
                        cooperateWith: false,
                        cooperateWithStr: '否',
                        isInitiateApprove: false,
                        isInitiateApproveStr: '否',
                    },
                ],
                //流程类型弹窗
                showTypePop: false,
                //是否由上节点选择本节点审批人
                showPickerCooperateWith: false,
                //该节点是否由审批发起人自行审批
                showPickerIsInitiateApprove: false,
                typeIdOne: '',
                //流程类型树结构
                typeOptions: [],
                //审批人弹窗
                showApproverPicker: false,
                //用户-部门
                userDeptList: [],
                //用户
                userList: [],
                //审批人
                activeApproverIds: [],
                approverIndex: 0,
                //被点击的审批人所在的节点
                node: 0,
                //适用人群弹窗
                showRangePicker: false,
                //标题
                title: '新建',
            };
        },

        mounted() {
            //查询流程类型树结构
            this.findAllType();
            //查询所有用户-部门
            this.findUserDept();
            //查询所有用户
            this.findAllUser();
            //回显
            this.findOne();
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/myApply/setting');
                this.$router.go(-1)
            },

            //查询所有用户-部门
            async findUserDept() {
                const resultData = await treeAllDeptAndUser();
                this.userDeptList = resultData.data;

                this.userDeptList.forEach((item) => {
                    this.packItems(item);
                });
            },

            //递归label赋值给text
            packItems(item) {
                item.text = item.label;
                if (item.children) {
                    item.children.forEach((detail) => {
                        this.packItems(detail);
                    });
                }
            },

            //查询所有用户
            async findAllUser() {
                let result = await getAllUser();
                this.userList = result.data;
            },

            //查询流程类型树结构
            async findAllType() {
                let result = await findAllType();
                let typeOptions = result.data;
                typeOptions.forEach((item) => {
                    item.id = String(item.id);
                    if (item.children) {
                        item.children.forEach((detail) => {
                            detail.id = String(detail.id);
                        });
                    }
                });
                this.typeOptions = typeOptions;
            },

            //回显
            async findOne() {
                this.flowId = this.$route.query.id;
                if (this.flowId) {
                    let that = this;
                    setTimeout(async () => {
                        that.title = '编辑';
                        let result = await findOne(this.flowId);
                        let dataObj = result.data;

                        //流程类型
                        let parentType = dataObj.typeIdArray[0];
                        let subType = dataObj.typeIdArray[1];
                        that.typeIdOne = String(subType);
                        that.typeOptions.forEach((item) => {
                            if (item.id == parentType) {
                                item.children.forEach((detail) => {
                                    if (detail.id == subType) {
                                        dataObj.typeName = detail.label;
                                    }
                                });
                            }
                        });

                        //节点数组
                        that.dataList = [];
                        dataObj.approveNodeList.forEach((item) => {
                            that.dataList.push({
                                approveNode: item.approveNode,
                                approver: item.approver,
                                flowId: item.flowId,
                                approverList: item.approverList,
                                approverStr: item.approverStr,
                                allPassToNext: item.allPassToNext,
                                allPassToNextStr: item.allPassToNext ? '是' : '否',
                                cooperateWith: item.cooperateWith,
                                cooperateWithStr: item.cooperateWith ? '是' : '否',
                                isInitiateApprove: item.isInitiateApprove,
                                isInitiateApproveStr: item.isInitiateApprove ? '是' : '否',
                            });
                        });

                        //适用人群
                        if (dataObj.applyType == 0) {
                            dataObj.applyTypeStr = '所有人';
                        } else if (dataObj.applyType == 1) {
                            let deptData = await findDeptNameByDeptId(JSON.stringify(dataObj.rangeIdList));
                            dataObj.applyTypeStr = deptData.data.join(',');
                        } else if (dataObj.applyType == 3) {
                            dataObj.applyTypeStr = this.userList.filter(item => dataObj.rangeIdList.indexOf(item.id) != -1)
                                .map(item => item.realname).join(',');
                        }
                        that.formInline = dataObj;
                    }, 300);
                }
            },

            //流程类型弹窗 确定
            handleConfirm() {
                this.showTypePop = false;
            },

            // 全部选项选择完毕后，会触发 finish 事件
            onFinish({selectedOptions}) {
                this.formInline.typeName = selectedOptions[selectedOptions.length - 1].label;
                this.formInline.typeIdArray = selectedOptions.map((option) => option.id);
            },

            //关闭适用类型弹窗
            handleCloseRange(list, applyType) {
                this.showRangePicker = false;

                this.formInline.applyType = applyType;
                if (list && list.length > 0) {
                    this.formInline.rangeIdList = list.map((item) => item.id);
                    this.formInline.applyTypeStr = list.map((item) => item.name).join(',');
                } else {
                    this.formInline.applyTypeStr = '所有人'
                }
            },

            //添加节点
            handleAdd() {
                this.dataList.push({
                    approverList: [],
                    approverStr: '',
                    allPassToNext: false,
                    allPassToNextStr: '否',
                    cooperateWith: false,
                    cooperateWithStr: '否',
                    isInitiateApprove: false,
                    isInitiateApproveStr: '否',
                });
            },

            //删除节点
            handleDelete(index) {
                this.dataList.splice(index, 1);
            },

            //打开审批人弹窗
            clickApprovePop(node) {
                this.node = node;
                this.activeApproverIds = this.dataList[this.node].approverList;
                this.showApproverPicker = true;
            },

            //审批人弹窗提交
            approverPopSubmit() {
                if (this.activeApproverIds && this.activeApproverIds.length > 0) {
                    //审批人id
                    this.dataList[this.node].approverList = this.activeApproverIds;

                    //审批人姓名
                    let selectedUserList = this.userList.filter((item) => {
                        return this.activeApproverIds.indexOf(item.id) >= 0;
                    });
                    this.dataList[this.node].approverStr = selectedUserList
                        .map((item) => item.realname)
                        .join(',');
                }

                this.showApproverPicker = false;
                this.activeApproverIds = [];

                this.dataList[this.node].cooperateWith = false
                this.dataList[this.node].cooperateWithStr = '否'
                this.dataList[this.node].isInitiateApprove = false
                this.dataList[this.node].isInitiateApproveStr = '否'
            },

            //审批设置弹窗
            clickPop(node, showPicker) {
                this.node = node;
                this[showPicker] = true;
            },

            //审批设置提交
            onConfirm(value) {
                this.dataList[this.node].allPassToNext = value == '是';
                this.dataList[this.node].allPassToNextStr = value;
                this.showPicker = false;

                if (this.dataList[this.node].cooperateWith) {
                    this.dataList[this.node].cooperateWith = false
                    this.dataList[this.node].cooperateWithStr = '否'
                    this.dataList[this.node].isInitiateApprove = false
                    this.dataList[this.node].isInitiateApproveStr = '否'
                }
            },

            //是否由上节点选择本节点审批人
            onConfirmCooperateWith(value) {
                this.dataList[this.node].cooperateWith = value == '是';
                this.dataList[this.node].cooperateWithStr = value;
                this.showPickerCooperateWith = false;

                if (this.dataList[this.node].cooperateWith) {
                    this.dataList[this.node].approverList = []
                    this.dataList[this.node].approverStr = ''
                    this.dataList[this.node].allPassToNext = false
                    this.dataList[this.node].allPassToNextStr = '否'
                    this.dataList[this.node].isInitiateApprove = false
                    this.dataList[this.node].isInitiateApproveStr = '否'
                }
            },

            //该节点是否由审批发起人自行审批
            onConfirmIsInitiateApprove(value) {
                this.dataList[this.node].isInitiateApprove = value == '是';
                this.dataList[this.node].isInitiateApproveStr = value;
                this.showPickerIsInitiateApprove = false;

                if (this.dataList[this.node].isInitiateApprove) {
                    this.dataList[this.node].approverList = []
                    this.dataList[this.node].approverStr = ''
                    this.dataList[this.node].allPassToNext = false
                    this.dataList[this.node].allPassToNextStr = '否'
                    this.dataList[this.node].cooperateWith = false
                    this.dataList[this.node].cooperateWithStr = '否'
                }
            },

            async onSubmit() {
                let errMsg = ''
                this.dataList.forEach(item => {
                    if (!item.cooperateWith && !item.isInitiateApprove && (item.approverStr ==
                        undefined || item.approverStr == '')) {
                        errMsg = '审批人不能为空';
                    }
                    if (item.allPassToNextStr == undefined || item.allPassToNextStr == '') {
                        errMsg = '请填写节点设置';
                    }
                })

                if (errMsg !== '') {
                    this.$toast.fail(errMsg);
                    return;
                }

                this.formInline.approveNodeList = this.dataList;
                let jsonStr = JSON.stringify(this.formInline);

                let result = this.formInline.id
                    ? await updateApproveFlow(jsonStr)
                    : await addApproveFlow(jsonStr);
                if (result.code == 200) {
                    this.$toast.success(result.message);
                } else {
                    this.$toast.fail(result.message);
                }
                this.handleGoBack();
            },
        },
    };
</script>

<style lang="scss" scoped>
  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  .list-tit-wrap {
    padding: 24px 30px;
    font-size: 26px;
    color: #333333;
  }

  .add-btn {
    width: 750px;
    height: 110px;
    background: #ffffff;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    font-size: 30px;
    color: #54afff;
    cursor: pointer;
  }

  .confirm-btn {
    width: 100%;
    height: 90px;
    background: #3768ef;
    border-radius: 12px 12px 12px 12px;
    font-size: 28px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 90px;
    margin-top: 60px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end

  ::v-deep .van-radio__label {
    line-height: 100px;
  }

  ::v-deep .van-radio__icon .van-icon {
    width: none;
    height: none;
    line-height: none;
  }

  ::v-deep .van-radio__icon {
    line-height: 0;
  }

  ::v-deep .van-picker__toolbar {
    height: 80px;
  }

  ::v-deep .van-picker__title {
    line-height: 80px;
    font-size: 26px;
  }
</style>
