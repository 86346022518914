<template>
    <div class="app-container" v-if="showEcharts">
        <div style="width: 100%;height: 500px;" ref="echartsRef1"></div>
        <div style="width: 100%;height: 300px;padding-top: 10px" ref="echartsRef2"></div>
    </div>
</template>
<script>
    import {getEchart1, getEchart2} from '@/echarts/report/inventory/outstockAchievement'
    import {selectExpendRank} from '@/api/report/inReport/instockTendency/instockTendency'

    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isShow: {
                handler() {
                    this.showEcharts = this.isShow
                    if (this.isShow) {
                        window.addEventListener('resize', () => {
                            if (this.dataEchart1) {
                                this.dataEchart1.resize()
                            }
                            if (this.dataEchart2) {
                                this.dataEchart2.resize()
                            }
                        })
                        this.$nextTick(() => {
                            this.handleQuery()
                        })

                    } else {
                        this.echartDestroy()
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        data() {
            return {
                showEcharts: false,
                dataEchart1: null,
                dataEchart2: null,
                // 查询参数
                queryParams: {}
            };
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            /** 搜索按钮操作 */
            async handleQuery() {
                let result = await selectExpendRank(this.queryParams)
                let strList = result.data.rank.map(item => item.warehouseTube);
                let numList = result.data.rank.map(item => item.sumQuantity);
                //X轴
                let enterCategory = Array.from(
                    new Set(result.data.rank3D.map(item => item.warehouseTube))
                );

                //y轴
                let materialNames = Array.from(
                    new Set(result.data.rank3D.map(item => item.materialName))
                );
                let materialCount = [];
                let underData = result.data.rank3D;
                for (let i = 0; i < underData.length; i++) {
                    let detailObj = underData[i];

                    //不用设置z轴，只设置数量
                    let zData = [];
                    for (let j = 0; j < materialNames.length; j++) {
                        if (materialNames[j] == detailObj.materialName) {
                            zData.push(j);
                        }
                    }

                    for (let j = 0; j < enterCategory.length; j++) {
                        if (enterCategory[j] == detailObj.warehouseTube) {
                            zData.push(j);
                        }
                    }
                    zData.push(detailObj.sumQuantity);
                    materialCount.push(zData);
                }

                this.dataEchart1 = this.$echarts.init(this.$refs.echartsRef1)
                this.dataEchart1.setOption(getEchart1(strList, numList))
                this.dataEchart2 = this.$echarts.init(this.$refs.echartsRef2)
                this.dataEchart2.setOption(getEchart2(materialNames, enterCategory, materialCount))
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart1)
                if (this.dataEchart1) {
                    this.dataEchart1.clear();
                    this.dataEchart1.dispose();
                    this.dataEchart1 = null;
                }
                window.removeEventListener('resize', this.dataEchart2)
                if (this.dataEchart2) {
                    this.dataEchart2.clear();
                    this.dataEchart2.dispose();
                    this.dataEchart2 = null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
