import request from "@/utils/request";
export function getAdd(query) {
  return request({
    url: "/purchase/work/getAdd",
    method: "get",
    params: query
  });
}
export function getTypeNum(query) {
  return request({
    url: "/purchase/work/getTypeNum",
    method: "get",
    params: query
  });
}
export function getNum(query) {
  return request({
    url: "/purchase/work/getNum",
    method: "get",
    params: query
  });
}
export function getPlan(query) {
  return request({
    url: "/purchase/work/getPlan",
    method: "get",
    params: query
  });
}
export function get30Order() {
  return request({
    url: "/purchase/work/get30Order",
    method: "get"
  });
}
