<template>
    <div class="app-container">
        <van-nav-bar>
            <template #title>
                <SelectDown/>
            </template>
            <template #right>
                <div class="flex">
                    <div class="add-btn icon-tianjia iconfont" @click="handleAdd" v-if="$auth.hasPermi('system:approve:add')"></div>
                    <div class="add-btn mrg-l-20 icon-shezhi1 iconfont" @click="handleSet" v-if="$auth.hasPermi('system:approveFlow:list')"></div>
                </div>
            </template>
        </van-nav-bar>

        <!-- 搜索 -->
        <div class="flex-ver-cen search-bg">
            <van-search
                    class="search-input"
                    v-model="approveTitle"
                    shape="round"
                    placeholder="搜索申请标题"
            />
            <van-button class="search-btn" round @click="handleFilter">
                <template #icon>
                    <div class="iconfont icon-shaixuan"></div>
                </template>
                筛选
            </van-button
            >
        </div>

        <!-- tab页面 -->
        <div class="tabs-wrap">
            <van-tabs v-model="active">
                <van-tab title="我的申请">
                    <ListCardVue
                            :type="'myApply'"
                            :approveState="approveState"
                            :showFilter="showFilter"
                            :approveTitle="approveTitle"
                            :typeId="typeId"/>
                </van-tab>
                <van-tab title="待办流程">
                    <ListCardVue
                            :type="'wait'"
                            :approveState="approveState"
                            :showFilter="showFilter"
                            :approveTitle="approveTitle"
                            :typeId="typeId"/>
                </van-tab>
                <van-tab title="已办流程">
                    <ListCardVue
                            :type="'finish'"
                            :approveState="approveState"
                            :showFilter="showFilter"
                            :approveTitle="approveTitle"
                            :typeId="typeId"/>
                </van-tab>
            </van-tabs>
        </div>

        <!-- 筛选弹窗 -->
        <van-popup v-model="showFilter" position="bottom" :style="{ height: '75%' }">
            <div class="pop-main">
                <div class="title">筛选</div>
                <div class="tit">状态</div>
                <div class="flex-wrap">
                    <div
                            class="btn"
                            v-for="item in statusList"
                            :key="item.label"
                            :class="{ 'active-btn': approveState == item.value }"
                            @click="handleBtnClick(item.value, 'approveState')"
                    >
                        {{ item.label }}
                    </div>
                </div>
                <div class="tit">流程类型</div>
                <div class="flex-wrap">
                    <van-cascader
                            v-model="typeIdOne"
                            :options="typeOptions"
                            @finish="onFinish"
                            :field-names="{ text: 'label', value: 'id', children: 'children' }"
                    />
                </div>
                <div class="confirm-btn" @click="handleConfirm">确认</div>
            </div>
        </van-popup>

        <!-- 加号弹窗 -->
        <van-popup v-model="showAdd" position="bottom" :style="{ height: '36%' }">
            <div class="add-pop-main">
                <div
                        class="item-list"
                        v-for="item in addPopList"
                        :key="item.name"
                        @click="handleGo(item.value)"
                >
                    {{ item.name }}
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import SelectDown from '@/components/header/SelectDown';
    import ListCardVue from './components/ListCard.vue';
    import {findAllType} from "@/api/approve/approveFlow";

    export default {
        components: {
            SelectDown,
            ListCardVue,
        },

        data() {
            return {
                value: '',
                active: 0,
                showFilter: false,
                statusList: [
                    {
                        label: '全部',
                        value: undefined,
                    },
                    {
                        label: '审批中',
                        value: 0,
                    },
                    {
                        label: '审批通过',
                        value: 1,
                    },
                    {
                        label: '已驳回',
                        value: 2,
                    },
                ],
                currentForm: 0,
                formList: [
                    {
                        label: '全部',
                        value: 0,
                    },
                    {
                        label: '请假',
                        value: 1,
                    },
                    {
                        label: '外出',
                        value: 2,
                    },
                    {
                        label: '出差',
                        value: 3,
                    },
                    {
                        label: '报销',
                        value: 4,
                    },
                    {
                        label: '会议室',
                        value: 5,
                    },
                    {
                        label: '用车',
                        value: 6,
                    },
                    {
                        label: '资产',
                        value: 7,
                    },
                ],

                showAdd: false,
                addPopList: [
                    {
                        name: '请假申请',
                        value: '/myApply/applyLeave',
                    },
                    {
                        name: '外出申请',
                        value: '/myApply/outApply',
                    },
                    {
                        name: '出差申请',
                        value: '/myApply/officialBusinessOutApply',
                    },
                    {
                        name: '报销申请',
                        value: '/myApply/reimburseApply',
                    },
                    {
                        name: '会议申请',
                        value: '/myApply/mettingApply',
                    },
                    {
                        name: '用车申请',
                        value: '/myApply/useCarApply',
                    },
                    {
                        name: '资产申请',
                        value: '/myApply/assetApply',
                    },
                ],
                //申请标题
                approveTitle: '',
                //状态
                approveState: undefined,
                //流程类型
                typeId: '',
                typeIdOne:'',
                //流程类型树结构
                typeOptions: []
            };
        },

        mounted() {
            //查询流程类型树结构
            this.findAllType();
        },

        methods: {
            //查询流程类型树结构
            async findAllType(){
                let result = await findAllType();
                let typeOptions = result.data
                typeOptions.forEach(item=>{
                    item.id = String(item.id)
                    if(item.children){
                        item.children.forEach(detail=>{
                            detail.id = String(detail.id)
                        })
                    }
                })
                this.typeOptions = typeOptions
            },

            handleAdd() {
                this.showAdd = true;
            },

            handleGo(path) {
                this.$router.push(path);
            },

            handleSet() {
                this.$router.push('/myApply/setting');
            },

            handleBtnClick(value, currentKey) {
                this[currentKey] = value;
            },

            handleFilter() {
                this.showFilter = true;
            },

            handleConfirm() {
                this.showFilter = false;
            },

            // 全部选项选择完毕后，会触发 finish 事件
            onFinish({ selectedOptions }) {
                this.typeId = selectedOptions.map((option) => option.id).join(',');
            },
        },
    };
</script>

<style lang="scss" scoped>
  ::v-deep .van-nav-bar .van-icon {
    margin-left: 10px;
    color: #222;
  }

  .add-btn {
    font-size: 34px;
    font-weight: bold;
    cursor: pointer;
  }

  .search-bg {
    padding: 0 22px 0 10px;
    background: #fff;

    .search-btn {
      width: 170px;
      height: 70px;
    }

    .search-input {
      flex: 1;
    }
  }

  .tabs-wrap {
    background: #fff;
    padding-top: 40px;

    ::v-deep .van-tab {
      font-size: 30px !important;
    }

    ::v-deep .van-tabs--line .van-tabs__wrap {
      height: 60px;
      padding-top: 10px;
    }

    ::v-deep .van-tab {
      color: #999;
    }

    ::v-deep .van-tab--active {
      font-weight: bold;
      color: #000;
    }

    ::v-deep .van-tabs__line {
      background-color: #000 !important;
    }

    ::v-deep .van-tabs__content {
      background: #fafafa;
    }
  }

  // 筛选
  .pop-main {
    padding: 0 50px;

    .title {
      text-align: center;
      font-weight: bold;
      color: #111111;
      font-size: 32px;
      padding-top: 48px;
    }

    .tit {
      font-size: 24px;
      font-weight: 500;
      color: #111111;
      margin: 50px 0 30px;
    }

    .btn {
      width: 190px;
      height: 70px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #cccccc;
      text-align: center;
      line-height: 70px;
      font-size: 28px;
      color: #666666;
      cursor: pointer;
      margin-right: 40px;
      margin-bottom: 40px;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    .active-btn {
      border: 1px solid #3768ef;
      background: #3768ef;
      color: #fff;
    }

    .confirm-btn {
      width: 650px;
      height: 90px;
      background: #3768ef;
      border-radius: 12px 12px 12px 12px;
      font-size: 28px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 90px;
      margin-top: 60px;
    }
  }

  // 添加弹窗
  .add-pop-main {
    padding-top: 50px;
    font-size: 30px;
    line-height: 80px;

    .item-list {
      text-align: center;
      color: #333;
      cursor: pointer;
    }
  }
</style>
