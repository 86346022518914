<template>
    <div class="app-container">
        <Header :moudle="'cw'"/>

        <div class="common-wrap">
            <div class="flex-spa-bet border-bom">
                <span class="title">金额统计</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>近七天</span>
            </div>
            <div style="width: 100%;height: 400px;padding-top: 10px" ref="echartsRef1"></div>
        </div>

        <div class="common-wrap">
            <div class="flex-spa-bet border-bom">
                <span class="title">逾期回款</span>
                <span class="more"><van-icon name="send-gift-o" class="mrg-r-10"/>所有</span>
            </div>
            <div class="table-header flex-spa-bet">
                <span class="se-tr-customer">客户名称</span>
                <span class="se-tr-leaderId">销售人员</span>
                <span class="se-tr-price">总金额</span>
                <span class="se-tr-collectionPrice">回款金额</span>
            </div>
            <div class="overdue">
                <div class="table-body">
                    <div class="flex-spa-ver-cen" v-for="(item, index) in queryList" :key="index">
                        <div class="flex-ver-cen" style="width: 100%;margin-top: 5px">
                            <span class="se-tr-customer">{{ item.customer }}</span>
                            <span class="se-tr-leaderId">{{ item.leaderId }}</span>
                            <span class="se-tr-price">{{ item.price }}</span>
                            <span class="se-tr-collectionPrice">{{ item.collectionPrice }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '@/components/header';
    import {get7Price, getCollection} from "@/api/cw/workbench/workbench";
    import {getEchart} from "@/echarts/report/cwReport/cwWork";

    export default {
        components: {
            Header,
        },

        data() {
            return {
                queryList: [],
                dataEchart1: null,
            };
        },

        mounted() {
            //金额统计
            this.get7Price()

            //逾期回款列表
            this.getOverCollection()

            window.addEventListener('resize', () => {
                if (this.dataEchart1) {
                    this.dataEchart1.resize()
                }
            })
        },

        beforeDestroy() {
            this.echartDestroy()
        },

        methods: {
            //金额统计
            async get7Price(){
                const { data } = await get7Price()
                let time = data.crmCollectionList.map(item => item.time)
                let crmCollectionList = data.crmCollectionList.map(item => item.num)
                let crmReturns = data.crmReturns.map(item => item.num)
                let proSubPaymentList = data.proSubPaymentList.map(item => item.num)
                let purPaymentList = data.purPaymentList.map(item => item.num)

                this.dataEchart1 = await this.$echarts.init(this.$refs.echartsRef1)
                const echartOption = getEchart(time, crmCollectionList,crmReturns,proSubPaymentList,purPaymentList)
                this.dataEchart1.setOption(echartOption)
            },

            //逾期回款列表
            async getOverCollection() {
                if (this.$auth.hasPermi('cw:collection:list')) {
                    const {data} = await getCollection(this.query)
                    this.queryList = data
                }
            },

            echartDestroy() {
                window.removeEventListener('resize', this.dataEchart1)
                if (this.dataEchart1) {
                    this.dataEchart1.clear();
                    this.dataEchart1.dispose();
                    this.dataEchart1 = null;
                }
            }
        },
    };
</script>
<style lang="scss" scoped>
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
  }

  .more {
    font-size: 22px;
    font-weight: 400;
    color: #b5b5b5;
  }

  .common-wrap {
    padding: 20px;
    margin-top: 20px;

    .left-tit {
      font-size: 28px;
      color: #444444;

      .tit {
        width: 460px;
      }
    }

    .day {
      font-size: 22px;
      color: #e02020;
    }

    .time {
      font-size: 22px;

      color: #999999;
    }

    .item-sale {
      .item {
        padding: 28px 50px 36px;
        flex: 1;

        .label {
          font-size: 24px;
          color: #aaaaaa;
        }

        .count {
          font-size: 32px;
          font-weight: 800;
          color: #333333;
          margin-top: 10px;
        }
      }
    }
  }

  .table-header {
    padding: 35px 0px 5px 10px;
    width: 100%;
  }

  .table-body {
    padding: 10px 0px 5px 10px;
    width: 100%;
  }

  .overdue {
    display: flex;
    flex: 1;
    overflow: scroll;
    height: 400px;
    margin-top: 10px;
  }

  .se-tr-customer {
    color: #aaaaaa;
    font-size: 28px;
    width: 35%;
  }

  .se-tr-leaderId {
    color: #aaaaaa;
    font-size: 28px;
    width: 18%;
  }

  .se-tr-price {
    color: #aaaaaa;
    font-size: 28px;
    width: 24%;
  }

  .se-tr-collectionPrice {
    color: #aaaaaa;
    font-size: 28px;
    width: 22%;
  }
</style>
